import React, { useEffect, useState} from 'react'
import { useFormik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { CreateButton, FilterButton,CancelButton,SubmitButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
// import EmployeeCard from './employeeCard';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import * as yup from 'yup'
import { Modal } from 'antd';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import { currencies, formattedBloodGroups } from "../../../../assets/CountryMini";
import { NuInputText, NuSelect, NuSelectwithSearch } from "../../../../Components/Utils/NuInput";
import Pagination from '../../../../Components/Utils/Pagenation';
import DataTable from '../../../../Components/Layout/DataTable';
import ViewMoreMovements from './ViewMoreMovements';
import NuDate from '../../../../Components/Utils/NuDate';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function MovementsIndex() {
  const { value: FromPlace } = useFetchMataData('FromPlace') 
  const { value: Status } = useFetchMataData('Status') 


  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  
  const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, totalCount, getData } = UseGetDataSearch( '/movements/',params);
    console.log(data,'dddd');
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
  // const { contextHolder, handle } = useDeleteData(getData);
  // const handleDelete = async (removevalue,id) => handle(`/employees/${id}`, removevalue, 'employees');
  // const { data: branches } = UseDropDown('/company_branch/drop-down');
    const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const[filterMovements,setfilterMovements]=useState({
        fromPlace:"",
        status:"",
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterMovements,
        validateOnChange: false,  
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, fromPlace: value?.fromPlace,status: value?.status,})
          closeFilterDialog();
          setFilter(true)
          setfilterMovements({
            fromPlace:"",
        status:"",
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          fromPlace:"",
        status:"",
       
        })
        formik.values.fromPlace=""
        formik.values.status=""
          setFilter(false)
      }
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState('');
  // const  { data, totalCount, getData } = UseGetData(1,20,'/employees/');
  // console.log('ddd',data);
  
  // const userAccess = true;JSON.parse(localStorage.getItem("userAccess"));
  let AllUserCreateAccess = true;//userAccess
  // ? userAccess.some((permission) => permission.permissionName == "Settings_Alluser_create")
  // : false;
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[5%]",
    },
    // {
    //   label: <span>Meal Id</span>,
    //   width: "w-[5%]",
    // },
    {
      label: <span>Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>From Place</span>,
      width: "w-[10%]",
    },
    {
      label: <span>To Camp</span>,
      width: "w-[10%]",
    },
    // {
    //   label: <span>Status</span>,
    //   width: "w-[10%]",
    // },
    

    {
      label: <span>Actions</span>,
      width: "w-[5%]",
    },
  ];

  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
 
return (
<div className='w-full h-full px-4 py-2'>
<div className='w-full flex justify-between items-center'>
<BreadCrumb items={[
  {title:(<Link to="/main/dashboard">
  <Dashboard color="gray" width={10} height={10} />{" "}
</Link>)},
  {title:(<p className=' text-[12px] '>Listing</p>)},
  {title:(<p className=' text-[12px] '>Movements</p>)}
  ]} />
<div className=' flex gap-2'>
 <SearchBar
 handleChange={(e) =>
  setParams({ ...params, search: e.target.value })
}
 />
 <FilterButton height='8' 
  handleClick={() => setShowFilterDialog(true)}/>
  {filter && ( 
       <FilterButton 
      handleClick={() => clearFilter()} 
      label="Clear" /> 
    )}
 <CreateButton label='Import' />
 <Link to={`/main/listing/movements/${officeId}/create`}><CreateButton label='Movements'/></Link> 
</div>
</div>
<DataTable
 bodyHeight='max-h-[calc(100vh-260px)]'
 height='h-[calc(100vh-135px)]'
 headers={headers} 
 total={totalCount}
 pageIndex={page}
 setPageIndex={setPage}
 dataCount={pageCount}
 setDataCount={setPageCount}
>
{data.length>0?data.map((item, i) => {
        let getCurrency;
        currencies.find((i) => {
          if(i.value == item?.currency){
            getCurrency=i.label;
          }
        })
        return (
          <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
            <td className="w-[5%] p-2 px-[1px] text-center ">{item?.movementId||""}</td>
            {/* <td className="w-[5%] p-2 px-[1px] text-center ">{item?.menuId||"#"}</td> */}
            {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
            <td className="w-[10%] p-2 px-[1px] text-center ">{item?.date && NuDate({value:item?.date,format:"DD-MM-YYYY"})||""}</td>
            <td className="w-[10%] p-2 px-[1px] text-center ">{item?.fromPlace||""}</td>
            <td className="w-[10%] p-2 px-[1px] text-center ">{item?.campName||""}</td>
            {/* <td className="w-[10%] p-2 px-[1px] text-center ">{item?.status||""}</td> */}
            {/* <td className="w-[10%] p-2 px-[1px] text-center ">{item?.equipmentType||""}</td>
            <td className="w-[10%] p-2 px-[1px] text-center ">{item?.manufacturer||""}</td> */}
            
            <td className="w-[5%] p-2 px-[1px] text-center ">
                <ViewMoreMovements data={item} fetcher={getData} id={item?.movementId} />
            </td>
          </tr>
);
      })
      :
      <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
    }
</DataTable>

      <Modal
        open={showFilterDialog}
        title={"Filter Movements"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="">
                    
                    {/* <NuInputText label='Gender' placeholder='M/F/T' formik={formik} name='gender' width='w-full ' />
                    <NuSelectwithSearch
                                    options={formattedBloodGroups}
                                    label={'Blood Group'}
                                    placeholder={'AB-'}
                                    formik={formik}
                                    name={'bloodGroup'}
                                    width={'w-full'}
                                /> */}
                    {/* <NuInputText label='Religion' placeholder='Religion' formik={formik} name='religion' width='w-full' /> */}
                    <NuSelect
                                    label='From Place'
                                    placeholder='choose'
                                    formik={formik}
                                    options={FromPlace}
                                    name='fromPlace'
                                    width='w-full' />
                                    <NuSelect
                                    label={'Status'}
                                    options={Status}
                                    placeholder={'Choose'}
                                    name={'status'}
                                    formik={formik}
                                    width={'w-full'}
                                />
                    </div>
                    
                   <div className="flex justify-end gap-2 mt-5">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>

           
    </div>
  )
}

export default MovementsIndex