import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'

import MealType from './MealType'
import FoodCategory from './FoodCategory'
import Status from './Status'

const TakeAwayReq = () => {
  return (
    <>
    <TextMediumSM content="Take Away Request" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <FoodCategory/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <MealType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Status/>
     </div>
    
    
     </div>
     </>
  )
}

export default TakeAwayReq