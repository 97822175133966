import React from 'react'

function Dashboard() {
  return (
    <div className='bg-[#E6F2F2] w-full h-full flex justify-center pt-32 overflow-hidden'>
      <h1 className='font-normal font-sans text-gray-500 whitespace-break-spaces'>No  Data  to  Display  on  Dashboard</h1>
    </div>
  )
}

export default Dashboard