import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axiosCommon';

const UseGetData = (page, pageCount, url) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  async function getData() {
    const params = {
      page: page,
      per_page: pageCount,
    };
    try {
      const res = await axiosInstance.get(url, { params });
      setData(res?.data?.data?.data || []);
      setTotalCount(res?.data?.data?.total || 0);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, [page, pageCount, url]);

  return { data, totalCount , getData };
};

export default UseGetData;