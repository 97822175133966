import React from 'react'

export default function Clinic() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="#b1d8d5" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
    <path id="Vector" d="M4.39591 0.53708C3.80197 0.712025 3.34089 1.09372 3.02048 1.6782C2.78994 2.09966 2.77431 2.24677 2.77431 3.88092C2.77431 4.93059 2.78603 5.35205 2.81729 5.35205C2.93061 5.35205 3.36043 5.06975 3.55971 4.86698C3.94655 4.47732 4.10285 4.02803 4.10285 3.3203V2.92667L6.81073 2.93463L9.5147 2.94655L9.53424 3.49127C9.55769 4.02803 9.55769 4.03599 9.7218 4.36997C9.84293 4.61649 9.94453 4.7636 10.1125 4.91866C10.3157 5.10951 10.7104 5.35205 10.8198 5.35205C10.8901 5.35205 10.8745 2.57678 10.8042 2.23882C10.6401 1.43964 9.97188 0.735881 9.18257 0.521176C9.00673 0.473464 8.57691 0.461535 6.79901 0.465511C4.85308 0.465511 4.60691 0.473464 4.39591 0.53708Z" fill="#b1d8d5"/>
    <path id="Vector_2" d="M5.21648 4.16722C5.20867 4.19506 5.16178 4.35012 5.11489 4.50916C4.83355 5.45546 3.92311 6.31825 2.9775 6.54489L2.76649 6.5926L2.78212 7.71384C2.79775 8.79134 2.80557 8.84303 2.90716 9.18497C3.33308 10.6163 4.32167 11.6223 5.72445 12.0596C6.05659 12.163 6.11911 12.167 6.81855 12.167C7.51408 12.167 7.58441 12.159 7.91264 12.0596C9.21774 11.6541 10.222 10.676 10.6557 9.38775C10.8159 8.91062 10.8628 8.47326 10.8628 7.49914V6.60055L10.601 6.52898C9.5616 6.25066 8.69804 5.35208 8.44015 4.2865L8.39717 4.11951H6.81464C5.58379 4.11951 5.2282 4.13144 5.21648 4.16722ZM6.18944 8.93448C6.2207 9.14521 6.41217 9.34401 6.6388 9.40763C6.94749 9.49113 7.24837 9.35594 7.38122 9.07364C7.42811 8.98219 7.46328 8.87484 7.46328 8.83906C7.46328 8.77942 7.5258 8.77146 8.05331 8.77146H8.64725L8.61989 8.97822C8.51439 9.82909 7.91264 10.4613 7.07644 10.6084C6.40826 10.7237 5.65803 10.3738 5.27509 9.76547C5.12661 9.53089 5.00157 9.14521 5.00157 8.92653V8.77146H5.58379H6.16209L6.18944 8.93448Z" fill="#b1d8d5"/>
    <path id="Vector_3" d="M17.9353 13.5825C17.9353 13.8887 17.9275 14.1391 17.9158 14.1391C17.908 14.1391 17.5016 13.9244 17.021 13.662L16.1418 13.1809L14.2154 14.2306L12.289 15.2763L12.2773 16.0993C12.2734 16.5526 12.2773 16.9224 12.289 16.9224C12.3007 16.9224 13.1643 16.4651 14.2076 15.9085C15.2548 15.3518 16.1222 14.8946 16.1418 14.8946C16.1574 14.8946 17.0249 15.3518 18.0721 15.9085C19.1154 16.4651 19.9789 16.9224 19.9906 16.9224C19.9985 16.9224 20.0024 16.5526 19.9985 16.0993L19.9867 15.2723L19.5491 15.0337L19.1076 14.7952V13.9125V13.0259H18.5214H17.9353V13.5825Z" fill="#b1d8d5"/>
    <path id="Vector_4" d="M5.58769 13.4196C5.58769 13.4355 5.86512 13.7416 6.20507 14.0995L6.81855 14.7476L7.43593 14.0875C7.77197 13.7257 8.0494 13.4196 8.0494 13.4076C8.0494 13.3957 7.49454 13.3838 6.81855 13.3838C6.14255 13.3838 5.58769 13.3997 5.58769 13.4196Z" fill="#b1d8d5"/>
    <path id="Vector_5" d="M3.45421 13.4672C3.14943 13.5189 2.62973 13.6939 2.32495 13.8529C1.35199 14.3499 0.593936 15.2167 0.242264 16.2465C0.0273523 16.8707 0 17.1729 0 18.8389V20.3418H6.09566H12.1913V19.3478C12.1913 18.7991 12.1835 18.3538 12.1757 18.3538C12.1679 18.3538 11.9217 18.481 11.6287 18.6361L11.0972 18.9184L11.105 16.7395L11.1168 14.5567L11.4567 14.3778C11.6443 14.2784 11.8006 14.1869 11.8006 14.171C11.8006 14.1153 11.1949 13.7814 10.8784 13.666C10.5228 13.5348 9.94453 13.4116 9.77651 13.4315C9.69445 13.4394 9.35059 13.7814 8.24868 14.9463C7.46719 15.7733 6.81464 16.4413 6.79901 16.4373C6.78338 16.4294 6.13083 15.7495 5.35324 14.9225C3.77071 13.2486 3.94655 13.3837 3.45421 13.4672Z" fill="#b1d8d5"/>
    <path id="Vector_6" d="M14.7234 16.9741L13.3675 17.6977L13.3636 19.0178V20.3418H14.4577H15.5518V19.3876V18.4333H16.1379H16.724V19.3876V20.3418H17.8181H18.9122V19.0178V17.6977L17.5446 16.9701C16.7943 16.5725 16.1574 16.2465 16.1301 16.2505C16.1027 16.2505 15.4697 16.5765 14.7234 16.9741Z" fill="#b1d8d5"/>
    </g>
    </svg>
    
  )
}
