import React, { useState,useEffect} from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { currencies } from '../../../../assets/CountryMini';
import ViewMoreServiceRequest from './ViewMoreServiceRequest';
import DataTable from '../../../../Components/Layout/DataTable';
import { CreateButton, FilterButton,CancelButton,SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import {  NuSelect,  NuDatePicker} from "../../../../Components/Utils/NuInput";
import UseGetData from '../../../../Components/Hooks/useFetchData';
import { Modal } from 'antd';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function ServiceRequestIndex() {

  const { value: value1 } = useFetchMataData('ServiceRequest_Status')

  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, totalCount, getData} = UseGetDataSearch( '/request/maintenances',params);
    console.log(data,'dddd');

    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])

     const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const[filterServices,setfilterService]=useState({
        attendedOn:"",
        status:"",
        
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterServices,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, status: value?.status, attendedOn: value?.attendedOn,})
          closeFilterDialog();
          setFilter(true)
          setfilterService({
            attendedOn: "",
            status: "",
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          attendedOn: "",
          status: "",
       
        })
        formik.values.attendedOn=""
        formik.values.status=""
          setFilter(false)
      }
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState('');
  // const  { data, totalCount, getData } = UseGetData(1,20,'/employees/');
  // console.log('ddd',data);
  
  // const userAccess = true;JSON.parse(localStorage.getItem("userAccess"));
  let AllUserCreateAccess = true;//userAccess
  // ? userAccess.some((permission) => permission.permissionName == "Settings_Alluser_create")
  // : false;
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
  
      let headers = [
          {
            label: <span>ID</span>,
            width: "w-[5%]",
          },
          {
            label: <span>Req Type</span>,
            width: "w-[15%]",
          },
          {
            label: <span>AttendedOn</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Requested Service</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Requested On</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Name</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Comments</span>,
            width: "w-[10%]",
          },
         
          {
            label: <span>Actions</span>,
            width: "w-[5%]",
          },
        ];
       
    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <BreadCrumb items={[
        {title:(<Link to="/main/dashboard">
        <Dashboard color="gray" width={10} height={10} />{" "}
      </Link>)},
        {title:(<p className=' text-[12px] '>Maintenance</p>)},
        {title:(<p className=' text-[12px] '>Service-Request</p>)}
        ]} />
      <div className=' flex gap-2'>
       <SearchBar
       handleChange={(e) =>
        setParams({ ...params, search: e.target.value })
      }
       />
       <FilterButton height='8'
       handleClick={() => setShowFilterDialog(true)}/>
       {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
       <CreateButton label='Import' />
       <Link to={`/main/maintenance/service-request/${officeId}/create`}><CreateButton label='Service-Request'/></Link> 
      </div>
      </div>
  
      <DataTable
       bodyHeight='max-h-[calc(100vh-260px)]'
       height='h-[calc(100vh-130px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={page}
       setPageIndex={setPage}
       dataCount={pageCount}
       setDataCount={setPageCount}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              currencies.find((i) => {
                if(i.value == item?.currency){
                  getCurrency=i.label;
                }
              })
              return (
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                  <td className="w-[5%] px-3 py-2 text-center ">{item?.roomserviceId||"#"}</td>
                  {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                  <td className="w-[15%] px-3 py-2 text-center ">{item?.reqType||""}</td>
                  <td className="w-[10%] px-3 py-2  text-center ">{item?.attendedOn||""}</td>
                  <td className="w-[10%] px-3 py-2  text-center ">{item?.requestedService||""}</td>
                  <td className="w-[10%] px-3 py-2 text-center">{item?.requestedOn||""}</td>
                  <td className="w-[10%] px-3 py-2  text-center">{item?.occupantName ||""}</td>
                  <td className="w-[10%] px-3 py-2 truncate text-center">{item?.comments ||""}</td>

                  <td className="w-[5%] px-3 py-2 text-center ">
                      <ViewMoreServiceRequest  data={item} fetcher={getData} id={item?.roomserviceId}/>
                  </td>
                </tr>
              );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
          }
      </DataTable>

      <Modal
        open={showFilterDialog}
        title={"Filter Services"}
        width={"50%"}
        className="my-modal"

        
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="">
                    <NuDatePicker
              label="Attended on"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="attendedOn"
              width="w-full "
            />
          <NuSelect
              label="Status"
              options={value1}
              formik={formik}
              placeholder="Choose"
              name="status"
              width="w-full "
            />
                    
                    </div>
                    
                   <div className="flex justify-end gap-2 mt-5">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
    </div>
    )
  }
export default ServiceRequestIndex