import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Severity = () => {
  return (
    <SettingsMetaData propertyName={"Severity_Incident"} viewPropertyName={"Severity"} />

  )
}

export default Severity