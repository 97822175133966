import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { Modal } from 'antd';

function ViewStocks() {
  const { officeId } = useParams();
  const { stocksViewId } = useParams();
  const location = useLocation();
    const data = location.state.data;
    console.log(location.state,'state');

    const [url,setUrl] = useState(false);

    console.log("bbb",data?.stockId);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };

    return (
        <>
                <div className=' bg-[#E6F2F2] h-full'>
            <div className='mx-6 pt-3'>
                <BreadCrumb items={[
                    { title: <p className='text-[13px]'>Invontary</p> },
                    { title: <p className='text-[13px]'>Stocks</p> },
                    { title: <p className='text-[13px]'>View</p> },

                ]}
                />
            </div>
            <div className='flex justify-end'>
                <div className='w-1/2 flex justify-end'>
                    <div className='pr-6 flex gap-3'>
                        <div className='flex justify-end gap-2'>
                        <Link to={`/main/inventary/stocks/${officeId}`}><BackButton link={true} /></Link>
          <Link to={`/main/inventary/stocks/${officeId}/edit/${data?.stockId}`} state={{ from: `/main/inventary/stocks/${officeId}/view/${data?.stockId}`, data: data }}><EditButton height='8' /></Link>
       
                            </div>
                    </div>
                </div>
            </div>
            {/*  Section-1 */}
            <div className='px-6 py-5 flex   gap-9'>
                {/* <div className='flex flex-col gap-2 px-5 py-3 w-[30%] h-auto bg-[#FFF] rounded-[5px] p-5'>
                  
                    <div className='flex gap-5 h-full'>
                        <div className='w-full h-full flex items-center p-5 gap-2 bg-[#E6F2F2] rounded-[5px]'>
                        {data?.image && <img className='w-full h-auto' src={data?.image } alt='Product' />}
                        </div>
                        <div className='mx-10 border-r border-#E6F2F2' />
                        
                    </div>
                </div> */}
                <div className='flex flex-col gap-2 px-5 py-3 w-full h-[calc(100vh-170px)] bg-[#FFF] rounded-[5px] p-5'>
                    <h3 className='text-[#212121] text-sm font-bold leading-normal'>Details</h3>
                    
                    <div className='w-full flex flex-wrap h-[350px] overflow-scroll'>
                            <div className='w-full grid grid-cols-5 gap-x-8 m-7'>
                            <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.stockId || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Code</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.code|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Name</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.name || '-'}</p>
                  </div>
                  {/* <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Company Id</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.companyId || '-'}</p>
                  </div> */}
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.category || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Sub Category</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?. subCategory || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Serial No</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.serialNo || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Brand</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.brand|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Manufacturer</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.manufacturer || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Manufacture Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mfdDate|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Expire Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.expDate || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Supplier</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.supplier || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Batch No</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.batchNo || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Unit</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.unit || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Purchased Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.purchasedDate|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'> Quantity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?. quantity || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'> Condition</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?. condition || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>MRP Price</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mrp|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Unit Price</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.unitPrice || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Warrenty Duration</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.warrentyDuration || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Warrenty Expire Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.warrentyEndDate || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Stock Location</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.stockLocation || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Minimum Stock Level</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.minStockLevel || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Barcode</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.barcode || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Stock Quantity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?. stockQty || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'> Adjusted Quantity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?. adjustedQty|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reason For Adjust</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.reasonForAdjust || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Warrenty Applicable</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.warrentyApplicable || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Warrenty Start Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.warrentyStartDate || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Caliber Request</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.calibReq || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Caliber Expire Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.calibExp || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Received Quantity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.receivedQty || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Issued Quantity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?. issuedQty || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'> Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?. comments|| '-'}</p>
                  </div>
                            
                            </div> 
                           
                        </div>
                    
                    <h3>Product Attachement Files</h3>
                    <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
    {/* {data?.image.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return ( */}
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                // key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                                // onClick={() => handleImageClick(imgs)}
                                                onClick={() => {setShowFilterDialog(true);setUrl(data?.image?.file)}}
                                                >
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    <img src={data?.image?.file} alt={data?.image?.file} />
                                                </div>
                                            </div>
                                        {/* );
                                    })} */}
                                    </div>
                </div>
               


            </div>
          
          
        </div>
        
        
        <Modal
        open={showFilterDialog}
        title={"Stocks"}
        width={"80%"}
        
        className="my-modal"
        onCancel={closeFilterDialog}
        footer={[]}
      >
        <div className='w-full h-[80vh] overflow-hidden'>
        <img src={url} alt="" className='' width={"100%"} />
        </div>
           </Modal>
        </>

    )
}

export default ViewStocks