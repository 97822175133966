import React, { useState } from 'react'
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import NuDate from '../../../../Components/Utils/NuDate';
import { useFormik } from 'formik';
import * as yup from 'yup';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuDateTime, NuInputText, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import { notification } from 'antd';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx", "mp4", "mov", "wmv", "avi", "flv", "mkv"];

function EditMeeting() {
  const {data:user} =UseDropDown('/user/drop-down')
  const { value: value } = useFetchMataData('Category_Meeting')

  const navigate = useNavigate()
  const [productList, setProductList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null)
  
  const { EditMeeting } = useParams();
  const location=useLocation()
  const data =location.state.data
  const from=location.state.from
  const [images, setImages] = useState([]);
  // const [division, setDivision] = useState([]);
  const [api,] = notification.useNotification();
  // console.log(location.state,'from');
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
  const [createNew, setCreateNew] = useState({
    // date: (data?.date && NuDate({value:data?.date,format:"YYYY-MM-DD"}) )||"",
    title: data?.title,
    category: data?.category,
    dateTime: data?.dateTime,
    location: data?.location,
    presenter: data?.presenter,
    agendaItems:data?.agendaItems,
    // startDate: (data?.startDate && NuDate({value:data?.startDate,format:"YYYY-MM-DD"}) )||"",
    comments: data?.comments,
    // endDate: (data?.endDate && NuDate({value:data?.endDate,format:"YYYY-MM-DD"}) )||"",
     
  })
  console.log(data);
  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item => {
      if (item.size < maxFileSize) {
        fileArr.push(item)
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item => {
      if (item.size < maxFileSize) {
        let fileName = item?.name
        console.log('fname', fileName);
        let fileExtention = fileName.split(".").reverse()[0]
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName)
        } else {
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
    })
    console.log(tempArr, 'temp');
    setImages(tempArr)
  }

  const [url,setUrl] = useState(false);

  const [showFilterDialog, setShowFilterDialog] = useState(false);

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        const withAgendaValues={
          ...value,
          agendaItems: productList
        }
        let formData = new FormData();
        formData.append('json_data', JSON.stringify(withAgendaValues));
        if (selectedFile !== null) {
          selectedFile.forEach((file) => {
            formData.append("attachments", file);
          });
        } else {
          formData.append("attachments", [])
        }
  
        editFormData(`/meeting/edit-meeting/${data.meetingId}`, formData, resetForm,from);
      }
  })
  const handleAddProducts = () => {
    const products = formik.values.agendaItems
    // console.log("man",products)
    setProductList((prevList) => [...prevList, products]);
    formik.setFieldValue('agendaItems', '');
  };
  const handleDeleteProduct = (index) => {
    setProductList((prevList) => prevList.filter((_, i) => i !== index));
  };
return (
  <div className="w-full h-full p-2 bg-[#F1F2FB]">
  <div className="flex justify-between px-4">
      <BreadCrumb items={[
      {
          title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
          </Link>)
      },
      { title: (<p className=' text-[12px] '>Meeting</p>) },
      { title: (<p className=' text-[12px] '>Meeting</p>) },
      { title: (<p className=' text-[12px] '>Edit</p>) },
  ]} />
  <BackButton/>
  </div>

  <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto bg-white rounded-xl overflow-hidden mt-4">
        <div className=" w-full h-[calc(100vh-180px)] py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
        <NuInputText
            // isRequired={true}
            label=" Title"
            placeholder="header"
            formik={formik}
            name="title"
            width="w-full md:w-1/3"
          />
          <NuSelect
                      label="Category"
                      options={value}
                      formik={formik}
                      placeholder="Choose"
                      name="category"
                      width="w-full md:w-1/3 md:-mt-2"
                    />
          <NuDateTime
          label="Date & Time"
          placeholder="date&time"
          formik={formik}
          name="dateTime"
           width="w-full md:w-1/3"
          />
            <NuInputText
              // isRequired={true}
              label="Location"
              placeholder=" Location"
              formik={formik}
              name="location"
              width="w-full md:w-1/3"
            />
             <NuSelect
              label="Presenter"
              options={user}
              formik={formik}
              placeholder="Choose"
              name="presenter"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuTextArea
            label="comments"
            formik={formik}
            placeholder="comments"
            name="comments"
            width="w-full md:w-1/2 lg:w-1/3"
            
            />
            <div className="flex flex-col md:flex-row justify-start gap-2"> 
                <div className="w-full flex flex-col ">
                <div className="flex justify-start ">
                  <NuInputText
                   type="text"
                   label="Agenda items"
                   placeholder="Agenda Items"
                   formik={formik}
                   name="agendaItems"
                   width="w-11/12"
                   
                   
                   />
                   <button
                onClick={handleAddProducts} 
                  type="button"
                 className="mt-10 w-10 h-9 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
                </div>
                <div className="w-12/12 h-28 ml-[4.1%] rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {data?.agendaItems.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
                  </span>
                ))}
                {productList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
                  </span>
                ))}
              </div>


                  </div>

                  </div>
                  
          
                  <Attachement
                      handleChange={handleImageChange}
                      preview={images || ''}
                      width="w-full"
                      label="Attach"
                      multiple={true}
                    />
                    {/* <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
    { data?.attchments?.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (imgs.file===null ? <p>Attachements not found</p>:
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                                // onClick={() => handleImageClick(imgs)}
                                                onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                                >
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    {/* <img src={imgs?.file} alt={imgs?.file} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    </div> */} 
                                     <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
  {data?.attchments?.map((imgs, idx) => {
                                      console.log('img', imgs);
                                      return (
                                          <div
                                              className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                              key={idx}
                                              style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                          >
                                              <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                              // onClick={() => handleImageClick(imgs)}
                                              onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                              >
                                                  {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                  <img src={imgs?.file} alt={imgs?.file} />
                                              </div>
                                          </div>
                                   );
                                   })} 

                                  <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton handleClick={()=>{navigate(-1)}} />
              <SubmitButton />
            </div>
          </div> 
                                  </div>
        
           
          
        </div>
      </div>
    </form>
    </div>
)
}

export default EditMeeting