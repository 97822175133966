export function TextBaseBold ({text,width="w-full",overflow}){
    return (
        <p className={` ${width} ${overflow?"truncate":''} text-mainColor-400 font-semibold text-[14px] lg:text-[15px] xl:text-[16px] `} >{text}</p>
    )
}

export function TextBold ({text}){
    return (
        <p className=" text-mainColor-400  font-semibold text-[13px] lg:text-[14px] xl:text-[15px]">{text}</p>
    )
}

export function TextNormal ({text}){
    return (
        <p className=" text-mainColor-400  font-medium text-[13px] lg:text-[14px] xl:text-[15px]">{text}</p>
    )
}


export function TextSmallBold ({text}){
    return (
        <p className=" text-mainColor-400  font-semibold text-[11px] lg:text-[12px] xl:text-[13px]">{text}</p>
    )
}

export function TextSmallNormal ({text}){
    return (
        <p className=" text-textColor-300  truncate text-[11px] lg:text-[12px] xl:text-[13px]">{text}</p>
    )
}

export function TextSmallMedium ({text}){
    return (
        <p className=" text-textColor-300 font-medium  truncate text-[11px] lg:text-[12px] xl:text-[13px]">{text}</p>
    )
}

export function TextTinyNormal ({text,color="text-textColor-200"}){
    return (
        <p className={` ${color} truncate text-[10px] lg:text-[11px] xl:text-[12px]`}>{text}</p>
    )
}

export function TextTinyMedium ({text}){
    return (
        <p className=" text-mainColor-400 font-medium  truncate text-[10px] lg:text-[11px] xl:text-[12px]">{text}</p>
    )
}