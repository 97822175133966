import { useFormik } from 'formik';
import { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate,useNavigate, useLocation, useParams } from 'react-router-dom';
import { NuDatePicker,  NuSelect, NuTextArea, NuInputText,NuInputNumber,NuDateTime } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditStaffDetails() {

  const { value: value } = useFetchMataData('Shift')
  const { value: Designation } = useFetchMataData('Designation_Staff')
  const { value: value1 } = useFetchMataData('Staff_Status')

  const navigate=useNavigate()
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const {data:employees} = UseDropDown('/employees/drop-down')
  
    const { EditId } = useParams();
    const location=useLocation()
    const data =location.state.data
    const from=location.state.from
    // console.log(location.state,'from');
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
      employeeId: data?.employeeId,
      designation: data?.designation,
      shift: data?.shift,
      joinedOn: data?.joinedOn,
      releavedOn: data?.releavedOn,
      status: data?.status,
      comments: data?.comments,
       
    })
    console.log(data);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
      
            editFormData(`/staffs/edit-staff/${EditId}`, value, resetForm, from);

        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Staff</p>) },
        { title: (<p className=' text-[12px] '>Staff Details</p>) },
        { title: (<p className=' text-[12px] '>Edit</p>) },
    ]} />
    <BackButton/>
    </div>
 
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          <NuSelect
              label="Employee Id"
              options={employees}
              formik={formik}
              placeholder="Choose"
              name="employeeId"
              width="w-full md:w-1/2 lg:w-1/3"
          />
            <NuSelect
                                    label='Desigination'
                                    placeholder='choose'
                                    formik={formik}
                                    options={Designation}
                                    name='designation'
                                    width='w-full md:w-1/3' />
                                    <NuSelect
              label="Shift"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="shift"
              width="w-full md:w-1/2 lg:w-1/3"
          />
            
            <NuDatePicker
              // isRequired={true}
              label="Joined On"
              placeholder="Joined On"
              formik={formik}
              name="joinedOn"
              width="w-full md:w-1/3"
            />
          <NuDatePicker
              // isRequired={true}
              label="Releaved On"
              placeholder="Releaved On"
              formik={formik}
              name="releavedOn"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Status"
              options={value1}
              formik={formik}
              placeholder="Choose"
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
  )
}

export default EditStaffDetails