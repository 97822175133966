import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Religion = () => {
  return (
    <SettingsMetaData propertyName={"Religion"} viewPropertyName={"Religion"} />

  )
}

export default Religion