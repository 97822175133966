import { Route } from "react-router-dom"
import MainProcurment from "../Views/Web/Procurement/MainProcurment"
import MainPurchaseRequest from "../Views/Web/Procurement/PurchaseRequest/MainPurchaseRequest"
import PurchaseRequestIndex from "../Views/Web/Procurement/PurchaseRequest/PurchaseRequestIndex"
import CreatePurchaseRequest from "../Views/Web/Procurement/PurchaseRequest/CreatePurchaseRequest"
import EditPurchaseRequest from "../Views/Web/Procurement/PurchaseRequest/EditPurchaseRequest"
import ViewPurchaseRequest from "../Views/Web/Procurement/PurchaseRequest/ViewPurchaseRequest"
import MainPurchaseOrder from "../Views/Web/Procurement/PurchaseOrder/MainPurchaseOrder"
import PurchaseOrderIndex from "../Views/Web/Procurement/PurchaseOrder/PurchaseOrderIndex"
import CreatePurchaseOrder from "../Views/Web/Procurement/PurchaseOrder/CreatePurchaseOrder"
import EditPurchaseOrder from "../Views/Web/Procurement/PurchaseOrder/EditPurchaseOrder"
import ViewPurchaseOrder from "../Views/Web/Procurement/PurchaseOrder/ViewPurchaseOrder"
import MainDelivaryNotes from "../Views/Web/Procurement/DelivaryNotes/MainDelivaryNotes"
import DelivaryNotesIndex from "../Views/Web/Procurement/DelivaryNotes/DelivaryNotesIndex"
import CreateDelivaryNotes from "../Views/Web/Procurement/DelivaryNotes/CreateDelivaryNotes"
import EditDelivaryNotes from "../Views/Web/Procurement/DelivaryNotes/EditDelivaryNotes"
import ViewDelivaryNotes from "../Views/Web/Procurement/DelivaryNotes/ViewDelivaryNotes"
import MainProducts from "../Views/Web/Procurement/Products/MainProducts"
import ProductsIndex from "../Views/Web/Procurement/Products/ProductsIndex"
import CreateProducts from "../Views/Web/Procurement/Products/CreateProducts"
import EditProducts from "../Views/Web/Procurement/Products/EditProducts"
import ViewProducts from "../Views/Web/Procurement/Products/ViewProducts"


const ProcurmentRoutes = () =>{
    return(
        <Route path="procurment" element={<MainProcurment/>}>

          <Route path="purchase-request/:officeId" element={<MainPurchaseRequest/>}>
              <Route index element={<PurchaseRequestIndex/>}/>
              <Route path="create" element={<CreatePurchaseRequest/>}/>
              <Route path='edit/:purchaseRequestEditId' element={<EditPurchaseRequest/>} />
              <Route path='view/:purchaseRequestViewId' element={<ViewPurchaseRequest/>} />
          </Route>

          <Route path="purchase-order/:officeId" element={<MainPurchaseOrder/>}>
              <Route index element={<PurchaseOrderIndex/>}/>
              <Route path="create" element={<CreatePurchaseOrder/>}/>
              <Route path='edit/:purchaseOrderEditId' element={<EditPurchaseOrder/>} />
              <Route path='view/:purchaseOrderViewId' element={<ViewPurchaseOrder/>} />
          </Route>
          <Route path="delivary-notes/:officeId" element={<MainDelivaryNotes/>}>
              <Route index element={<DelivaryNotesIndex/>}/>
              <Route path="create" element={<CreateDelivaryNotes/>}/>
              <Route path='edit/:delivaryNotesEditId' element={<EditDelivaryNotes/>} />
              <Route path='view/:delivaryNotesViewId' element={<ViewDelivaryNotes/>} />
          </Route>

          <Route path="products/:officeId" element={<MainProducts/>}>
              <Route index element={<ProductsIndex/>}/>
              <Route path="create" element={<CreateProducts/>}/>
              <Route path='edit/:productsEditId' element={<EditProducts/>} />
              <Route path='view/:productsViewId' element={<ViewProducts/>} />
          </Route>

        </Route>
    )
}

export default ProcurmentRoutes;