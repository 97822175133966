import React from 'react'

function ImageView({size=24,image}) {
  return (
    <div className={` w-24 h-24 flex justify-center items-center rounded-full bg-red-300 overflow-hidden`}>
        {image?<img width="110%" src={image} alt='#'/>:""} 
    </div>
  )
}

export default ImageView;