import React, { useState } from 'react'
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import NuDate from '../../../../Components/Utils/NuDate';
import { useFormik } from 'formik';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputText, NuMobileNumber, NuSelect } from '../../../../Components/Utils/NuInput';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditParticipants() {
  // const {data:user} =UseDropDown('/user/drop-down')
  const { data: meeting } = UseDropDown('/meeting/drop-down')
  const { value: value } = useFetchMataData('Designation_Participant')

  const navigate = useNavigate()
  
  const { EditTakeAwayRequest } = useParams();
  const location=useLocation()
  const data =location.state.data
  const from=location.state.from
  // console.log(location.state,'from');
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
  const [createNew, setCreateNew] = useState({
    // date: (data?.date && NuDate({value:data?.date,format:"YYYY-MM-DD"}) )||"",
    meetingId: data?.meetingId,
    participentName: data?.participentName,
    designation: data?.designation,
    mobile: data?.mobile,
    email: data?.email,
 
  
  })
  console.log(data);

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
    
          editFormData(`/participents/edit-participent/${data.participentId}`, value, resetForm, from);

      }
  })
return (
  <div className="w-full h-full p-2 bg-[#F1F2FB]">
  <div className="flex justify-between px-4">
      <BreadCrumb items={[
      {
          title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
          </Link>)
      },
      { title: (<p className=' text-[12px] '>Meetings</p>) },
      { title: (<p className=' text-[12px] '>Participants</p>) },
      { title: (<p className=' text-[12px] '>Edit</p>) },
  ]} />
  <BackButton/>
  </div>

  <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
        <div className=" w-full h-full py-10 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
        <NuSelect
              label="Meeting Id"
              options={meeting}
              formik={formik}
              placeholder="Choose"
              name="meetingId"
              width="w-full md:w-1/3 md:-mt-2"
            />
        <NuInputText
              // isRequired={true}
              label=" Participent Name"
              placeholder=" participentName"
              formik={formik}
              name="participentName"
              width="w-full md:w-1/3 md:-mt-2"

            />
          <NuSelect
                                    label='Desigination'
                                    placeholder='choose'
                                    formik={formik}
                                    options={value}
                                    name='designation'
                                    width='w-full md:w-1/3  md:-mt-2' />
            <NuMobileNumber
              label="Mobile"
              placeholder="monbile number"
              formik={formik}
              name="mobile"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInputEmail
            label="Email"
            placeholder="email"
            formik={formik}
            name="email"
            width="w-full md:w-1/3 md:-mt-2"
            />
            
            
        
           
          <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton handleClick={()=>{navigate(-1)}} />
              <SubmitButton />
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
)
}

export default EditParticipants