import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import RoomType from './RoomType'
import RoomUsage from './RoomUsage'
import RoomInventories from './RoomInventories'
import RoomFacilities from './RoomFacilities'
import RoomCondition from './RoomCondition'
import MaxOccupancy from './MaxOccupancy'
import BedType from './BedType'
import BedStatus from './BedStatus'

const Room = () => {
  return (
    <>
    <TextMediumSM content="Room" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <RoomType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <RoomUsage/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <RoomInventories/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <RoomFacilities/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <RoomCondition/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <MaxOccupancy/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <BedType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <BedStatus/>
     </div>
     
    </div>
     </>
  )
}

export default Room