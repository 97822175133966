// import React, { useState,useEffect } from 'react'
// import { Link } from 'react-router-dom';
// import SearchBar from '../../../../Components/Utils/SearchBar';
// import { CreateButton, FilterButton, SubmitButton, CancelButton,DeleteButton} from '../../../../Components/Utils/Buttons';
// import DataTable from '../../../../Components/Layout/DataTable';
// import { currencies } from '../../../../assets/CountryMini';
// import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
// import Dashboard from '../../../../Components/Icons/Dasboard';
// import { NuInputNumber, NuSelect, NuInputText } from '../../../../Components/Utils/NuInput';
// import { useFormik } from 'formik';
// import * as yup from 'yup'
// import UseDropDown from '../../../../Components/Hooks/useDropDown';
// import { Modal } from 'antd';
// import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
// import ViewMoreAsserts from './ViewMoreAsserts';

import { useEffect, useState } from "react";
import UseGetDataSearch from "../../../Components/Hooks/useFetchDataBySearch";
import { useFormik } from "formik";
import { CancelButton, CreateButton, FilterButton, SubmitButton } from "../../../Components/Utils/Buttons";
import { NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch } from "../../../Components/Utils/NuInput";
import { Modal } from "antd";
import DataTable from "../../../Components/Layout/DataTable";
import SearchBar from "../../../Components/Utils/SearchBar";
import { Link, useParams } from "react-router-dom";
import Dashboard from "../../../Components/Icons/Dasboard";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import { CountryMIN, currencies } from "../../../assets/CountryMini";
import * as yup from 'yup';
import ViewMoreVendor from "./ViewMoreVendor"

function VendorIndex() {
  
  const {officeId} = useParams();
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState(20)
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const { data, totalCount, getData } = UseGetDataSearch( '/vendors/',params);
  console.log('dddd',data);
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
  const [showFilterDialog, setShowFilterDialog] = useState(false);
const [filter, setFilter] = useState(false);
  const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
    const[filtervendor,setfiltervendor]=useState({
      country:"",
      performanceRating:"",
        vendorStatus:"",
        products:"",
     
    });
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filtervendor,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params, country: value?.country,performanceRating: value?.performanceRating, vendorStatus: value?.vendorStatus,products: value?.products})
        closeFilterDialog();
        setFilter(true)
        setfiltervendor({
          country:"",
          performanceRating:"",
            vendorStatus:"",
            products:"",
    
        });
      }
    })
    const clearFilter = ()=> {
      setParams({...params,
        country:"",
        performanceRating:"",
        vendorStatus:"",
        products:"",
      
     
      })
      formik.values.country=""
      formik.values.performanceRating=""
      formik.values.vendorStatus=""
      formik.values.products=""
     
        setFilter(false)
    }

    let headers = [
        {
          label: <span>ID</span>,
          width: "w-[5%]",
        },
        // {
        //   label: <span>Meal Id</span>,
        //   width: "w-[5%]",
        // },
        {
          label: <span>Contact Person</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Company Name</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Designation</span>,
          width: "w-[10%]",
        },
        // {
        //   label: <span>Products</span>,
        //   width: "w-[10%]",
        // },
        {
          label: <span>Phone</span>,
          width: "w-[10%]",
        },
       
        {
          label: <span>Actions</span>,
          width: "w-[5%]",
        },
      ];

      useEffect(()=>{
        setParams({...params,page:page,per_page:pageCount})
       },[page,pageCount])
     
return (
  <div className='w-full h-full px-4 py-2'>
    <div className='w-full flex justify-between items-center'>
    <BreadCrumb items={[
      {title:(<Link to="/main/dashboard">
      <Dashboard color="gray" width={10} height={10} />{" "}
    </Link>)},
      {title:(<p className=' text-[12px] '>Vendor</p>)},
      // {title:(<p className=' text-[12px] '>Asserts</p>)}
      ]} />
    <div className=' lg:flex gap-2 hidden'>
     <SearchBar
     handleChange={(e) =>
      setParams({ ...params, search: e.target.value })
    }
     />
     <FilterButton height='8' 
      handleClick={() => setShowFilterDialog(true)}/>
      {filter && ( 
           <FilterButton 
          handleClick={() => clearFilter()} 
          label="Clear" /> 
        )}
     <CreateButton label='Import' />
     <Link to={`/main/vendors/${officeId}/create`}><CreateButton label='Vendor'/></Link> 
    </div>
    </div>
    <DataTable
     bodyHeight='max-h-[calc(100vh-260px)]'
     height='h-[calc(100vh-130px)]'
     headers={headers} 
     total={totalCount}
     pageIndex={page}
     setPageIndex={setPage}
     dataCount={pageCount}
     setDataCount={setPageCount}
    >
    {data.length>0?data.map((item, i) => {
            let getCurrency;
            currencies.find((i) => {
              if(i.value == item?.currency){
                getCurrency=i.label;
              }
            })
            return (
              <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                <td className="w-[5%] p-2 px-[1px] text-center ">{item?.vendorId||""}</td>
                {/* <td className="w-[5%] p-2 px-[1px] text-center ">{item?.menuId||"#"}</td> */}
                {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={/main/accomodation/building/view/${item?.branchId}} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.contactPerson||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.companyName||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.designation||""}</td>
                
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.phone||""}</td>
                {/* <td className="w-[10%] p-2 px-[1px] text-center ">{item?.stockLocation||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.mrp||""}</td> */}
                
                <td className="w-[5%] p-2 px-[1px] text-center ">
                    <ViewMoreVendor data={item} fetcher={getData} id={item?.vendorId} />
                </td>
              </tr>
            );
          })
          :
          <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
        }
    </DataTable>

    <Modal
      open={showFilterDialog}
      title={"Filter Vendor"}
      width={"50%"}
      className="my-modal"
      centered
      onCancel={closeFilterDialog}
      footer={[]}
    >
               <form onSubmit={formik.handleSubmit}> 
                  <div className="flex flex-col mb-4">
                  <NuSelectwithSearch
                                    label="Country"
                                    options={CountryMIN}
                                    formik={formik}
                                    placeholder="Choose"
                                    name="country"
                                    width="w-full"
                                />
          
            {/* <NuInputText
            label="Manufacturer"
            placeholder="Manufacturer"
            formik={formik}
            name="manufacturer"
            width="w-full "
          />
          <NuInputText
            label="Brand"
            placeholder="Brand"
            formik={formik}
            name="brand"
            width="w-full "
          /> */}
          <NuInputNumber
            // isRequired={true}
            label="Performance Rating"
            placeholder="Performance Rating"
            formik={formik}
            name="performanceRating"
            width="w-full "
          />
          <NuSelect
            label="Vendor Status"
            options={[
              { value: "Active", label: "Active" },
              { value: "In Active", label: "In Active" },
            ]}
            formik={formik}
            placeholder="Vendor Status"
            name="vendorStatus"
            width="w-full "
          />
          <NuInputText
            label="Products"
            placeholder="Products"
            formik={formik}
            name="products"
            width="w-full "
          />
          {/* <NuInputNumber
            // isRequired={true}
            label="Unit"
            placeholder="Unit"
            formik={formik}
            name="unit"
            width="w-full"
          /> */}
                  </div>
                  
                 <div className="flex justify-end gap-2">
                   <CancelButton handleClick={closeFilterDialog} />
                   <SubmitButton name='Apply' />
                 </div>
                </form> 
             {/* </div>
           </div>
         )} */}
         </Modal>         
  </div>
)
}

export default VendorIndex