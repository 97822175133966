import React, { useState } from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';

const ViewReferences = () => {

  
  const [url,setUrl] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const { id } = useParams();
    const location = useLocation();
    const data = location.state.data;
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: (<p className=' text-[12px] '>Clinic</p>) },
            { title: (<p className=' text-[12px] '>Reference</p>) },
            { title: (<p className=' text-[12px] '>View</p>) },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/clinic/reference/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/clinic/reference/${officeId}/edit/${data?.referenceId}`} state={{data: data, from:`/main/clinic/reference/${officeId}/view/${data?.referenceId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
              <h2 className='text-base font-normal text-[#111537] p-3'>Details</h2>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-5 gap-x-8 m-10">
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.category || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Subject </p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.subject || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Details</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.details || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments || '-'}</p>
                  </div>
                  <div>
                    <h3>Attachement Files</h3>
                    <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
    {/* {data?.image.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return ( */}
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                // key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                                // onClick={() => handleImageClick(imgs)}
                                                onClick={() => {setShowFilterDialog(true);setUrl(data?.attachments.file)}}
                                                >
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    <img src={data?.attachments.file} alt={data?.attachments.file} />
                                                </div>
                                            </div>
                                        {/* );
                                    })} */}
                                    </div>
                  </div>
                  
                 
                  </div>
                  </div>
          </div>
          
      </div>
  
  )
};

export default ViewReferences