import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Condition = () => {
  return (
    <SettingsMetaData propertyName={"Condition"} viewPropertyName={"Condition"} />

  )
}

export default Condition