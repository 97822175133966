import React, { useEffect, useState } from "react";
import { NormalHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';

function ViewFoodSchedule() {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const location = useLocation();
  const data = location.state.data;
    return (
      <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: (<p className=' text-[12px] '>Catering</p>) },
            { title: (<p className=' text-[12px] '>Food Schedule</p>) },
            { title: (<p className=' text-[12px] '>View</p>) },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={'/main/catering/food-Schedule/${officeId}'}><BackButton link={true}/></Link>
          <Link to={`/main/catering/food-Schedule/${officeId}/edit/${data?.id}`} state={{data: data, from:`/main/catering/food-schedule/${officeId}/view/${data?.id}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
              <h2 className='text-base font-normal text-[#111537] p-3'>Details</h2>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-5 gap-x-8 m-10">
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.id || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Meal Type</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mealType || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Menu By Days</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.menuByDays || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Menu On Special Days</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.menuOnSpecialDays || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Ethnicity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.ethnicity || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>PrparedBy</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.prparedByName|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Menu Id</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.menuName|| '-'}</p>
                  </div>
                  {/* <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.date || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Created date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.createdAt || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Dine Type</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.dineType || '-'}</p>
                  </div> */}
                  {/* <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>drId</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.drId || '-'}</p>
                  </div> */}
                  {/* <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments || '-'}</p>
                  </div> */}
                  
                  </div>
                  </div>
          </div>
          
      </div>
    );
}

export default ViewFoodSchedule