import React from 'react'

export default function Maintanance() {
  return (
<svg width="20" height="21" fill='#b1d8d5' viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g>
        <g>
            <path d="M31.8,18.2l-3.2-1.3c-0.2-0.6-0.4-1.2-0.6-1.8l1.4-3.1c0.2-0.4,0.1-0.9-0.1-1.2l-1.8-3.2c-0.2-0.4-0.7-0.6-1.2-0.6
                l-3.2,1.4c-0.5-0.3-1.1-0.5-1.7-0.6L19,0.2C18.8,0.1,18.4,0,18,0h-3.6c-0.4,0-0.8,0.2-1,0.6L12.1,3.9c-0.6,0.2-1.2,0.4-1.8,0.6
                l-3.1-1.4c-0.4-0.2-0.9-0.1-1.2,0.1L2.8,6.5c-0.3,0.2-0.5,0.7-0.4,1.1L3.7,11c-0.3,0.5-0.5,1.1-0.6,1.7L0.2,13
                c-0.4,0.1-0.6,0.4-0.6,0.8v3.5c0,0.4,0.2,0.8,0.6,1L3,20.5c0.2,0.6,0.4,1.2,0.6,1.8L2.2,25.5c-0.2,0.4-0.1,0.9,0.1,1.2l1.8,3.2
                c0.2,0.4,0.7,0.6,1.2,0.6l3.2-1.4c0.6,0.3,1.2,0.5,1.8,0.6l1.4,3.2c0.2,0.4,0.6,0.7,1,0.7H18c0.4,0,0.8-0.2,1-0.6l1.3-3.2
                c0.6-0.2,1.2-0.4,1.8-0.6l3.1,1.4c0.4,0.2,0.9,0.1,1.2-0.1l3.2-1.8c0.4-0.2,0.6-0.7,0.6-1.2l-1.4-3.2c0.3-0.6,0.5-1.1,0.6-1.7
                l3.2-1.3c0.4-0.2,0.7-0.6,0.7-1V19C32.4,18.6,32.2,18.3,31.8,18.2z M20.1,27.8c-0.5,0.2-1,0.5-1.5,0.8c-0.2,0.1-0.3,0.2-0.4,0.4
                c-0.1,0.1-0.2,0.3-0.3,0.5l-0.8,1.9h-2.4L13.3,29c-0.2-0.5-0.5-1-0.8-1.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.2-0.5-0.3
                l-1.9-0.8l-2.1,0.9l-1.4-2.5l1.1-2.1l-0.8-1.8c-0.5-0.2-1-0.5-1.5-0.8c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.2-0.3-0.3-0.5l-1.8-0.8
                v-2.4L3.5,18c0.2-0.5,0.5-1,0.8-1.5c0.1-0.2,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.2,0.5-0.3l1.9-0.8L6.1,13L4.7,10.5l2.5-1.4l1.8,0.8
                c0.5-0.3,1-0.5,1.5-0.8c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5l0.8-1.9h2.4l1.2,1.9c0.2,0.5,0.5,1,0.8,1.5
                c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3l1.9,0.8l2.1-0.9l1.4,2.5l-1.1,2.1l0.8,1.8c0.5,0.3,1,0.5,1.5,0.8
                c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.2,0.3,0.3,0.5l1.9,0.8v2.4l-1.9,1.2c-0.2,0.5-0.5,1-0.8,1.5c-0.1,0.2-0.2,0.3-0.4,0.4
                c-0.1,0.1-0.3,0.2-0.5,0.3L20.1,27.8z" fill='#b1d8d5'></path>
            <path d="M16,13.5c-1.4,0-2.6,1.1-2.6,2.6c0,1.4,1.1,2.6,2.6,2.6s2.6-1.1,2.6-2.6C18.6,14.6,17.4,13.5,16,13.5z" fill='#b1d8d5'></path>
            <path d="M16.2,7.2C16,7.1,15.8,7.2,15.7,7.3l-5,5c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0,0.4,0.1,0.5l2.2,3.8l-7.3,7.3
                c-0.4,0.4-0.5,1.1-0.2,1.5l1,1.3c0.2,0.2,0.5,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l7.3-7.3l3.8,2.2c0.1,0.1,0.3,0.1,0.5,0.1
                c0.2,0,0.4-0.1,0.5-0.2l5-5c0.2-0.2,0.2-0.5,0-0.7L16.9,7.2C16.7,7.2,16.5,7.1,16.2,7.2z" fill='#b1d8d5'></path>
        </g>
    </g>
</svg>
  )
}
