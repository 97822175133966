import React from 'react'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { BackButton } from '../../../../Components/Utils/Buttons'
import CampInfo from './CampInfo/Index'
import Occupants from './Occupants/Index'
import Room from './Room/Index'
import VisitorsTracking from './Visitors Tracking/Index'
import CheckIn from './CheckIn/Index'
import CheckOut from './CheckOut/Index'
import ServiceRequest from './ServiceRequest/Index'
import Dashboard from '../../../../Components/Icons/Dasboard'
import { Link } from 'react-router-dom'

const MainAccomodation = () => {
  return (
    <div className='w-full h-full px-4 py-2'>
          <div className='w-full flex justify-between items-center'>
          <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Settings</p>) },
                    { title: (<p className=' text-[12px] '>Accomodation</p>) },
                ]} />
          <BackButton/>
          </div>
          <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md ">
              <div className="p-3"><CampInfo/></div>
              <div className="p-3"><Occupants/></div>
              <div className="p-3"><Room/></div>
              <div className="p-3"><VisitorsTracking/></div>
              <div className="p-3"><CheckIn/></div>
              <div className="p-3"><CheckOut/></div>
              <div className="p-3"><ServiceRequest/></div>

          </div>
          </div>
  )
}

export default MainAccomodation