import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from 'yup';
import { notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { CountryMIN } from "../../../../assets/CountryMini";
import {
  NuInputText,
  NuInputEmail,
  NuDatePicker,
  NuInputNumber,
  NuMobileNumber,
  NuSelectwithSearch
} from "../../../../Components/Utils/NuInput";
import LogoUpload from "../../../../Components/Utils/LogoUpload";
import axiosInstance from "../../../../Services/axiosCommon";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import CustomImgUpload from "../../../../Components/Utils/CustomImgUpload";

const EditCompanyInfo = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const data = location.state.data;
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [items, setItems] = useState(data?.socialMediaLinks && data?.socialMediaLinks);
  console.log("socialmediaLinks",items);
  const [newItem, setNewItem] = useState('');
  // useEffect(() => {
  //   getCompanyINfo();
  //   localStorage.setItem('items', JSON.stringify(items));
  // }, [items]);
  // useEffect(() => {
  //   getCompanyINfo();
  // }, [])
  const [createNew, setCreateNew] = useState(
    // console.log("22",items),
    {
      city: data?.address?.city,
      streetName: data?.address?.streetName,
      district: data?.address?.district,
      state: data?.address?.state,
      country: data?.address?.country,
      postalCode: data?.address?.postalCode,
      companyName: data?.companyName,
      registrationNo: data?.registrationNo,
      businessDesc: data?.businessDesc,
      businessIndustry: data?.businessIndustry,
      email: data?.email,
      website: data?.website,
      phone: data?.phone,
      mobile: data?.mobile?.number,
      establishedOn: data?.establishedOn,
      taxId: data?.taxId,
      companyLogo: data?.compLogo,
      countryCode: data?.mobile?.countryCode,
    },
    // console.log("22",items),
  );

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  // function getCompanyINfo() {
  //   axiosInstance
  //     .get(`/company-info/company-details/1`)
  //     .then((response) => {
  //       // console.log("cc", response?.data?.data);
  //       response.data?.data &&
  //         setCreateNew({
  //           city: response?.data?.data?.address?.city,
  //           streetName: response?.data?.data?.address?.streetName,
  //           district: response?.data?.data?.address?.district,
  //           state: response?.data?.data?.address?.state,
  //           country: response?.data?.data?.address?.country,
  //           postalCode: response?.data?.data?.address?.postalCode,
  //           companyName: response?.data?.data?.companyName,
  //           registrationNo: response?.data?.data?.registrationNo,
  //           businessDesc: response?.data?.data?.businessDesc,
  //           businessIndustry: response?.data?.data?.businessIndustry,
  //           email: response?.data?.data?.email,
  //           website: response?.data?.data?.website,
  //           phone: response?.data?.data?.phone,
  //           mobile: response?.data?.data?.mobile?.number,
  //           establishedOn: response?.data?.data?.establishedOn,
  //           socialMediaLinks: response?.data?.data?.socialMediaLinks,
  //           taxId: response?.data?.data?.taxId,
  //           companyLogo: response?.data?.data?.compLogo,
  //           countryCode: response?.data?.data?.mobile?.countryCode,
  //         });
  //       setItems(response?.data?.data?.socialMediaLinks || [])
  //     })
  //     .catch((error) => { console.log(error); });
  // }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      email: yup.string().required("Email is required")
    }),
    onSubmit: async (value, { resetForm }) => {
      const valueWithItems = {
        ...value,
        socialMediaLinks: items,
      };
      let formData = new FormData();
      console.log("yy", valueWithItems);
      formData.append("companyData", JSON.stringify(valueWithItems));
      formData.append("companyLogo", image.raw ? image.raw : "");

      axiosInstance
        .put(`/company-info/edit-company-details/1`, formData)
        .then((res) => {
          if (res.data?.status == true) {
            resetForm();
            navigate(-1);
            api.success({
              message: `Success`,
              description: res?.data?.msg || 'Updated successfully.',
              placement: 'bottomRight',
            });
          }
          else {
            api.error({
              message: `Failure`,
              description: res?.data?.msg || 'Updation failed.',
              placement: 'bottomRight',
            });
          }
        }
        )
        .catch((err) => {
          console.log(err);
        })
    },
  });

  const handleNewItemChange = (event) => {
    setNewItem(event.target.value);
  };

  const handleDeleteImg = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, companyLogo: "" }))
    formik.values.companyLogo = ''
  }

  const handleAddItem = () => {
    if (newItem.trim() !== '') {
      setItems([...items, newItem]);
      setNewItem('');
    }
  };

  const handleDeleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  return (
    <div className='w-full h-full'>
      {contextHolder}
      <div className=" w-full px-3">
        <h1>Company Profile</h1>
        <p className=" text-sm text-[#707884] my-3">
          Update your company photo and details here.
        </p>
      </div>
      <form onSubmit={formik.handleSubmit} className=" overflow-y-scroll">
        <div className=" w-full flex flex-wrap justify-between">
          <CustomImgUpload
            borderStyle="dashed"
            handleChange={handleChangeImg}
            label={'Company Logo'}
            preview={image.preview || createNew.companyLogo || ""}
            removeFunction={handleDeleteImg}
          />
          <NuInputText
            label="Company Name"
            width="w-full md:w-4/12"
            name="companyName"
            formik={formik}
          />
          <NuInputEmail
            label="Email"
            placeholder="example@email.com"
            formik={formik}
            name="email"
            width="w-full md:w-4/12"
            isRequired={true}
          />
          <NuInputText
            label="Website"
            placeholder="www.example.com"
            formik={formik}
            name="website"
            width="w-full md:w-4/12"
          />
          <NuMobileNumber
            nunCode={"countryCode"}
            label="Mobile"
            placeholder="mobile"
            formik={formik}
            name="mobile"
            width="w-full md:w-4/12"
          />
          <NuInputNumber
            label="Phone"
            placeholder="phone"
            formik={formik}
            name="phone"
            width="w-full md:w-4/12"
          />
          <NuDatePicker
            label="Established On"
            placeholder="establishedOn"
            formik={formik}
            name="establishedOn"
            width="w-full md:w-4/12"
          />
          <NuInputNumber
            label="Tax Id"
            placeholder="taxId"
            formik={formik}
            name="taxId"
            width="w-full md:w-4/12"
          />
          <NuInputNumber
            label="Registration No"
            placeholder="registrationNo"
            formik={formik}
            name="registrationNo"
            width="w-full md:w-4/12"
          />
          <NuInputText
            label="Business Describtion"
            placeholder="businessDesc"
            formik={formik}
            name="businessDesc"
            width="w-full md:w-4/12"
          />
          <NuInputText
            label="Business Industry"
            placeholder="businessIndustry"
            formik={formik}
            name="businessIndustry"
            width="w-full md:w-4/12"
          />
          <NuSelectwithSearch
            label="Country"
            options={CountryMIN}
            formik={formik}
            placeholder="Choose"
            name="country"
            width="w-full md:w-4/12"
          />
          <NuInputText
            label="State"
            formik={formik}
            placeholder="state"
            name="state"
            width="w-full md:w-4/12"
          />
          <NuInputText
            label="District"
            placeholder="district"
            formik={formik}
            name="district"
            width="w-full md:w-4/12"
          />
          <NuInputText
            label="City"
            placeholder="City"
            formik={formik}
            name="city"
            width="w-full md:w-4/12"
          />
          <NuInputText
            label="Street Name"
            placeholder="streetName"
            formik={formik}
            name="streetName"
            width="w-full md:w-4/12"
          />
          <NuInputNumber
            label="Postal Code"
            placeholder="postalCode"
            formik={formik}
            name="postalCode"
            width="w-full md:w-4/12"
          />
        </div>

        <h4 className="pb-3">Social Media Links</h4>
        <div className='w-full md:w-5/12 h-10 flex items-start rounded-md bg-gray-100'>
          <div className='w-10/12 h-full'>
            <input
              type="text"
              placeholder="Type"
              className="pl-5 py-2 w-full h-full outline-none border-0 rounded-l-md bg-gray-200 text-smtitle 2xl:text-tiny font-semibold"
              name="socialMediaLinks"
              value={newItem}
              onChange={handleNewItemChange}
            />
            {/* {formik.errors.keyValues ? (
                                        <div className="text-xs font-semibold text-red-500">
                                            {formik.errors.keyValues}
                                        </div>
                                    ) : null} */}
          </div>
          <button
            onClick={handleAddItem} type="button"
            className="w-3/12 md:w-2/12 h-full overflow-hidden border-none rounded-r-md flex items-center justify-center bg-[#048178] cursor-pointer">
            <p className='text-white text-sm font-semibold'>Add</p>
          </button>
        </div>
        {items?.length >= 1 && (<div className="w-full md:w-5/12 mt-3 rounded-md h-44 p-2 border border-solid border-[#EAEBED] overflow-x-hidden overflow-y-auto">
          <ul className="">
            {
              items.map((List, index) => {
                return (
                  <li
                    className=" flex justify-between items-center text-sm text-[#555E6C] rounded font-medium py-2 my-1 mx-auto px-3 bg-[#ECEBF3] cursor-pointer"
                    key={index}
                  >
                    <p>{List || "-"}</p>
                    {List && (
                      <div className='border-0 bg-[#ECEBF3] cursor-pointer'
                        onClick={() => {
                          handleDeleteItem(index)
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                          <path d="M5.34641 5.63905L3.51172 3.80436L1.67703 5.63905C1.57971 5.73637 1.44772 5.79105 1.31009 5.79105C1.17246 5.79105 1.04047 5.73637 0.943151 5.63905C0.845833 5.54174 0.79116 5.40974 0.79116 5.27212C0.79116 5.13449 0.845833 5.0025 0.943151 4.90518L2.77784 3.07049L0.94315 1.23579C0.845832 1.13848 0.79116 1.00648 0.79116 0.868856C0.79116 0.731228 0.845832 0.599236 0.94315 0.501918C1.04047 0.4046 1.17246 0.349927 1.31009 0.349927C1.44772 0.349927 1.57971 0.4046 1.67703 0.501918L3.51172 2.33661L5.34641 0.501918C5.44373 0.4046 5.57572 0.349927 5.71335 0.349927C5.85098 0.349927 5.98297 0.4046 6.08029 0.501918C6.17761 0.599236 6.23228 0.731228 6.23228 0.868856C6.23228 1.00648 6.17761 1.13848 6.08029 1.23579L4.2456 3.07049L6.08029 4.90518C6.17761 5.0025 6.23228 5.13449 6.23228 5.27212C6.23228 5.40975 6.17761 5.54174 6.08029 5.63905C5.98297 5.73637 5.85098 5.79105 5.71335 5.79105C5.57572 5.79105 5.44373 5.73637 5.34641 5.63905Z" fill="#3D348B" />
                        </svg>
                      </div>
                    )}
                  </li>
                );

              })
            }
          </ul>
        </div>)}

        <div className="flex justify-end gap-x-3 mr-2 mt-5 pb-5">
          <div className=" flex items-center gap-3">
            <CancelButton handleClick={() => navigate(-1)} />
            <SubmitButton />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCompanyInfo;