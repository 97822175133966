import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { notification } from 'antd';
import axiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuMultiSelect, NuSelect, NuSelectCurrency, NuTextArea } from '../../../../Components/Utils/NuInput';
import Plus from '../../../../Components/Icons/Plus';
import Delete from '../../../../Components/Icons/Delete';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';


function EditRoom() {
  // const { data: buildings } = UseDropDown('/buildings/drop-down');
  const { value: value } = useFetchMataData('RoomType')
  const { value: value1 } = useFetchMataData('RoomFacilities')
  const { value: value2 } = useFetchMataData('RoomInventories')
  const { value: BedType} = useFetchMataData('BedType')

  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
//   const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
  // const imagetype = ["png", "jpg", "jpeg", "JPG", "PNG", "JPEG"];
  // const [selectedImage, setSelectedImage] = useState(null);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [image, setImage] = useState({ preview: "", raw: "" });
//   const [attachment, setAttachment] = useState([]);
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const { RoomEditId } = useParams();
  const { data: buildings } = UseDropDown('/buildings/drop-down');
  const [floors, setFloors] = useState([]);
  const [floorDisable, setFloorDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [beds, setBeds] = useState([]);
  let navigate = useNavigate();
  const { Oncancel, editFormData } = useEditFormData();
  const [api, contextHolder] = notification.useNotification();
  console.log('ddd',data);
  const [createNew,setCreateNew] = useState({
    floorId:data?.floorId,
    buildingId:data?.buildingId,
    roomNo:data?.roomNumber,
    roomName:data?.roomName,
    roomType:data?.roomType,
    roomUsage:data?.roomUsage,
    noOfBeds:data?.noOfBeds,
    maxOccupancy:data?.maxOccupancy,
    roomCondition:data?.roomCondition,
    roomFacilities:data?.roomFacilities,
    roomInventories:data?.roomInventories,
    roomCondition:data?.roomCondition,
    comments:data?.comments,
  })
//   const handleChangeImg = (e) => {
//     if (e.target.files.length) {
//       setImage({
//         preview: URL.createObjectURL(e.target.files[0]),
//         raw: e.target.files[0],
//       });
//     }
//   };
const [items, setItems] = useState([])
const [newData , setNewData]=useState({
  bedNumber:"",
  bedType:"",
    // numOfRooms:""

    // Total Rooms on Floor:
})
function handleDelete(idx) {
  const list = items.filter((item, i) => i !== idx);
  setItems(list);
  

}
// const handleAddItem = () => {
//   let newItem = {
//     bedNumber:formik.values.bedNumber,
//     bedType:formik.values.bedType
//   };
const handleAdd =()=>{
  // if (items.length < formik.values.numberOfFloors) {
  const newItem = {
      
    bedNumber: formik.values.bedNumber,
      bedType: formik.values.bedType,
      // numOfRooms: formik.values.numOfRooms,
      // Total Rooms on Floor:newData.floorNo
    };
    console.log(newItem,'jkh');
    setItems([...items, newItem]);
    // Update local storage
    setNewData({
      bedNumber: "",
      bedType: "",
      // numOfRooms:"",
     
    });
  console.log(newItem,'new');
  let allData = beds;
  allData.push(newItem);
  console.log(allData,'all');
  setBeds(allData);
  formik.setFieldValue('bedNumber', ''); // Reset bedNumber value
  formik.setFieldValue('bedType', ''); // Reset bedType value
};
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // branch_name : yup.string().required('Branch name is required.'),
      // email : yup.string().required('Email is required.'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("dsg")
      const valueWithItems = {
          ...value,
          floors: beds,
      };    
      editFormData(`/rooms/edit-room/${RoomEditId}`,valueWithItems,  resetForm, from);
  }
  });
  useMemo(() => {
    async function getFloors(url) {
      axiosInstance.get(url).then(res => {
        // console.log('daataa',res.data?.data);
        let data = res.data?.data;
        console.log(data, 'dddd');
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item.id, label: item.name || 'undefined' };
          tempData.push(tempObject);
        });
        res.data?.data && setFloors(tempData);
      }).catch(err => {
        console.log(err, "err");
      })
    }
    if (formik.values.buildingId != '' && buildings.length > 0) {
      getFloors(`/buildings/floor/drop-down/${formik.values.buildingId}`);
      setFloorDisable(false);
    }
  }, [formik.values.buildingId]);
  return (
    <div className="w-full h-full px-4 py-2">
    {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Settings</p> },
            { title: <p className=" text-[12px] ">Rooms</p> },
            { title: <p className=" text-[12px] text-[#4E4B4B]">Edit</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton/>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <NuInputNumber
              isRequired={true}
              label="Room Number"
              placeholder="Room Number"
              formik={formik}
              name="roomNo"
              width="w-full md:w-1/4"
            />
            <NuInputText
              label="Room Name"
              placeholder="Room Name"
              formik={formik}
              name="roomName"
              width="w-full md:w-1/4"
            />
            <NuSelect
              label="Building"
              placeholder="choose"
              options={buildings}
              formik={formik}
              name="buildingId"
              width="w-full md:w-1/4"
            />
            <NuSelect
              isDisabled={floorDisable}
              label="Floor"
              placeholder="choose"
              options={floors}
              formik={formik}
              name="floorId"
              width="w-full md:w-1/4"
            />
            <NuSelect
              label="Room Type"
              placeholder="choose"
              options={value}
              formik={formik}
              name="roomType"
              width="w-full md:w-1/4"
            />
            <NuInputText
              label="Room Usage"
              placeholder="Room Usage"
              formik={formik}
              name="roomUsage"
              width='w-full md:w-1/4'
            />
            <NuInputNumber
              isRequired={true}
              label="Number of Beds"
              placeholder="0"
              formik={formik}
              name="noOfBeds"
              width="w-full md:w-1/4"
            />
            <NuInputNumber
              isRequired={true}
              label="Maximum Occupancy"
              placeholder="0"
              formik={formik}
              name="maxOccupancy"
              width="w-full md:w-1/4"
            />
            <div className='w-full flex'>
              <div className='w-[49%] flex flex-col justify-around'>
                <NuMultiSelect
                  label="Room Facilities"
                  placeholder="choose"
                  options={value1}
                  formik={formik}
                  name="roomFacilities"
                  width="w-full"
                />
                <NuMultiSelect
                  label="Room Inventories"
                  placeholder="choose"
                  options={value2}
                  formik={formik}
                  name="roomInventories"
                  width="w-full"
                />
              </div>
              {/* <div className='border-r border-[1px] border-slate-200/60 border-solid h-full' /> */}
              <div className='w-1/2 px-1 h-[32vh]'>
                <div className='w-full h-1/2 px-2 py-2 items-center rounded-t-md border border-solid border-slate-200 flex justify-start content-start flex-wrap'>
                  <NuInputNumber
                    isRequired={true}
                    label="Bed Number"
                    placeholder="0"
                    formik={formik}
                    name="bedNumber"
                    
                    width="w-full md:w-[45%]"
                  />
                  <NuSelect
                    label="Bed Type"
                    placeholder="choose"
                    options={BedType}
                    formik={formik}
                    name="bedType"
                    width="w-full md:w-[45%]"
                  />
                  <button
                  onClick={handleAdd}
                  type='button'
                   className='flex items-center justify-center bg-[#048178] mt-5 w-9 h-9 border-none rounded-md hover:cursor-pointer'>
                    <Plus color='white' />
                  </button>
                </div>
                <div className='h-1/2  overflow-y-scroll w-full bg-slate-200/30 rounded-b-md border border-solid border-slate-200'>
                  {/* {beds.map((bed,index)=>{
                    console.log("lino",bed)
                    return( */}
                      {/* <>
                      <h1>dhbjhd</h1>
                      <div className='px-2 py-2 flex hover:bg-slate-300/30'>
                      <p className='w-[45%] text-white text-[13px] px-3 text-center py-1'>ygygyy</p>
                      <p className='w-[45%] text-white text-[13px] px-3 text-center py-1'>gnkjg</p>
                      <button 
                      onClick={handleDeleteItem()}
                      type='button'
                      className='w-7 h-7 border-none rounded-md hover:cursor-pointer'>
                        <Delete color='red' />
                      </button>
                    </div>
                    </> */}
                    {/* )
                  })} */}
                  {beds?.map((test,index)=>{
                    return(
                      <div className='px-2 py-2 flex hover:bg-slate-300/30'>
                      <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{test?.bedNumber}</p>
                      <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{test?.bedType}</p>
                      <button className='w-7 h-7 border-none rounded-md hover:cursor-pointer'>
                      <Delete color='red' />
                    </button>
                     </div>
                    )
                  })}
                  {data?.beds?.map((item,index)=>{
                    return(
                      <div className='px-2 py-2 flex hover:bg-slate-300/30'>
                      <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{item?.bedNumber}</p>
                      <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{item?.bedType}</p>
                      <button className='w-5 h-5 border-none rounded-md hover:cursor-pointer'>
                      <Delete color='red' />
                    </button>
                     </div>
                    )
                  }
                  )}
                  {/* <div className='px-2 py-2 flex hover:bg-slate-300/30'>
                    <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{2}</p>
                    <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{'Bunker'}</p>
                    <button className='w-7 h-7 border-none rounded-md hover:cursor-pointer'>
                      <Delete color='red' />
                    </button>
                  </div>
                  <div className='px-2 py-2 flex hover:bg-slate-300/30'>
                    <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{3}</p>
                    <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{'Bunker'}</p>
                    <button className='w-7 h-7 border-none rounded-md hover:cursor-pointer'>
                      <Delete color='red' />
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            <NuInputText
              label="Room Condition"
              placeholder="Room Condition"
              formik={formik}
              name="roomCondition"
              width='w-full md:w-1/4'
            />
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditRoom ;