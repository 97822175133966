import React, { useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Modal } from 'antd';

function ViewMeeting() {
  const { officeId } = useParams();
   // const { EditTakeAwayRequest } = useParams();
    // console.log(EditTakeAwayRequest ,'sd');
    const [url,setUrl] = useState(false);

 
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
    const location = useLocation();
    const data = location.state.data;
    console.log("find",data.agendaItems)
    return (
      <>
      <div className="bg-[#E6F2F2] w-full h-full px-3 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              {
                title: (
                  <Link to="/main/dashboard">
                    <Dashboard color="gray" width={10} height={10} />{" "}
                  </Link>
                ),
              },
              { title: <p className=" text-[12px] ">Meetings</p> },
              { title: <p className=" text-[12px] ">Meeting</p> },
              { title: <p className=" text-[12px] ">View</p> },
            ]}
          />
          <div className=" flex gap-2">
            <Link to={`/main/meetings/meeting/${officeId}`}>
              <BackButton link={true} />
            </Link>
            <Link
              to={`/main/meetings/meeting/${officeId}/edit/${data?.meetingId}`}
              state={{
                from: `/main/meetings/meeting/${officeId}`,
                data: data,
              }}
            >
              <EditButton height="8" />
            </Link>
          </div>
        </div>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-y-scroll p-3">
          <h3 className="text-[#212121] font-semibold pb-3"> Meeting Details</h3>
          <div className="flex flex-wrap gap-y-5 w-full">
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Meeting Id</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.meetingId || '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Presenter</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.presenterName|| '-'}</p>
            </div>
           
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Pub Id</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.pubId|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Title</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.title|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'> Location</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.location|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date & Time</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.dateTime|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Created At</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.createdAt|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.category|| '-'}</p>
            </div> 
          </div>
          <h3 className='mt-3 font-semibold'>AgendaItems</h3>
          <div className="w-2/12 h-28  rounded-md p-1 flex flex-col border border-solid overflow-y-scroll mt-2">
          
          {data?.agendaItems.length>0 ? data?.agendaItems?.map((item)=>{
            console.log("hhh",item);
            return(
                 
                  <p className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">{item||"-"}</p>
                
            )
          }): <p className='text-center'>no data</p>}
          </div>
        <h3 className='mt-5 font-semibold'>Meeting Attachement Files</h3>
        {/* <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
    {data?.image?.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                                // onClick={() => handleImageClick(imgs)}
                                                onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                                >
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    {/* <img src={imgs?.file} alt={imgs?.file} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    </div> */} 
                                    <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
    {data?.attchments.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                                // onClick={() => handleImageClick(imgs)}
                                                onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                                >
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    <img src={imgs?.file} alt={imgs?.file} />
                                                </div>
                                            </div>
                                         );
                                    })} 
                                    </div>
                                  
      </div>
      </div>
      <Modal
        open={showFilterDialog}
        title={"Medical Products"}
        width={"80%"}
        
        className="my-modal"
        onCancel={closeFilterDialog}
        footer={[]}
      >
        <div className='w-full h-[80vh] overflow-hidden'>
        <img src={url} alt="" className='' width={"100%"} />
        </div>
        
           </Modal>
      </>
    );
}

export default ViewMeeting