import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Manufacturer = () => {
  return (
    <SettingsMetaData propertyName={"Manufacturer_Product"} viewPropertyName={"Manufacturer"} />

  )
}

export default Manufacturer