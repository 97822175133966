import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { notification } from 'antd';
import axiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuMultiSelect, NuSelect, NuSelectCurrency, NuTextArea } from '../../../../Components/Utils/NuInput';
import Plus from '../../../../Components/Icons/Plus';
import Delete from '../../../../Components/Icons/Delete';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';


function CreateRoom() {
  // const [selectedImage, setSelectedImage] = useState(null);
  //   const [selectedFile, setSelectedFile] = useState(null);
  //   const [image, setImage] = useState({ preview: "", raw: "" });
  const { data: buildings } = UseDropDown('/buildings/drop-down');
  const { value: value } = useFetchMataData('RoomType')
  const { value: value1 } = useFetchMataData('RoomFacilities')
  const { value: value2 } = useFetchMataData('RoomInventories')
  const { value: BedType} = useFetchMataData('BedType')

//   const [value,setValue] = useState([])
//   function getData(){
//     axiosInstance.get(`dropdown/dropdown-details/RoomType`).then(res=>{
//         console.log("hlo",res?.data?.data?.RoomType);
//         let data = res.data?.data?.RoomType; 
//         let tempData = [];
//         data.forEach((item) => {
//             console.log("test",item);
            
//           let tempObject = { value: item, label: item };
//           tempData.push(tempObject);
//         });
//         res.data?.data?.RoomType && setValue(tempData);
//     }).catch(err=>{
//         console.log("err");
//     })
// }
console.log("vvvv",value);
// const [value1,setValue1] = useState([])
//   function getDataRoom(){
//     axiosInstance.get(`dropdown/dropdown-details/RoomFacilities`).then(res=>{
//         console.log("hlo",res?.data?.data?.RoomFacilities);
//         let data = res.data?.data?.RoomFacilities; 
//         let tempData = [];
//         data.forEach((item) => {
//             console.log("test",item);
            
//           let tempObject = { value: item, label: item };
//           tempData.push(tempObject);
//         });
//         res.data?.data?.RoomFacilities && setValue1(tempData);
//     }).catch(err=>{
//         console.log("err");
//     })
// }

useEffect(()=>{
// getData()
// getDataRoom()
},[])
  const [floors, setFloors] = useState([]);
  const { Oncancel, addFormData} = useAddFormData();
  const [floorDisable, setFloorDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const [tempData, setTempData] = useState({
  //   bedNumber:"",
  //   bedType: undefined
  // })


  const [beds, setBeds] = useState([]);
  console.log ("www",beds)
  let navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    floorId: '',
    buildingId: '',
    roomNo: '',
    roomName: '',
    roomType: '',
    roomUsage: '',
    noOfBeds: '',
    // bedNumber:'',
    // bedType:'',
    maxOccupancy: '',
    roomCondition: '',
    roomFacilities: [],
    roomInventories: [],
    roomCondition:'',
    comments:'',
   
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // branch_name : yup.string().required('Branch name is required.'),
      // email : yup.string().required('Email is required.'),
    }),

    onSubmit: async (value, { resetForm }) => {
      const valueWithItems = {
          ...value,
          beds: beds,
        };
      

      addFormData('/rooms/new-room', valueWithItems, resetForm);
  }
})
const [items, setItems] = useState([])
const [newData , setNewData]=useState({
  bedNumber:"",
  bedType:"",
    // numOfRooms:""

    // Total Rooms on Floor:
})
console.log("ddd", items);
console.log("ddd", items);

function handleDelete(idx) {
    const list = items.filter((item, i) => i !== idx);
    setItems(list);
    

}
const handleAdd =()=>{
    // if (items.length < formik.values.numberOfFloors) {
    const newItem = {
        
      bedNumber: formik.values.bedNumber,
        bedType: formik.values.bedType,
        // numOfRooms: formik.values.numOfRooms,
        // Total Rooms on Floor:newData.floorNo
      };
      console.log(newItem,'jkh');
      setItems([...items, newItem]);
      // Update local storage
      setNewData({
        bedNumber: "",
        bedType: "",
        // numOfRooms:"",
       
      });
   
      formik.values.bedNumber=""
      formik.values.bedType=""
      // formik.values.numOfRooms=""
      
      
    // }
    // else{
    //     alert("You have reached the maximum limit of floors.");
    // }
};
  // const handleAddItem = () => {
  //   let newItem = {
  //     bedNumber:formik.values.bedNumber,
  //     bedType:formik.values.bedType
  //   };
  //   console.log(newItem,'new');
  //   let allData = beds;
  //   allData.push(newItem);
  //   console.log(allData,'all');
  //   setBeds(allData);
  //   formik.setFieldValue('bedNumber', ''); // Reset bedNumber value
  //   formik.setFieldValue('bedType', ''); // Reset bedType value
  // };
  // const handleDeleteItem = (indexToDelete) => {
  //   const updatedItems = beds.filter((_, index) => index !== indexToDelete);
  //   setBeds(updatedItems);
  // };
  useMemo(() => {
    async function getFloors(url) {
      axiosInstance.get(url).then(res => {
        // console.log('daataa',res.data?.data);
        let data = res.data?.data;
        console.log(data, 'dddd');
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item.id, label: item.name || 'undefined' };
          tempData.push(tempObject);
        });
        res.data?.data && setFloors(tempData);
      }).catch(err => {
        console.log(err, "err");
      })
    }
    if (formik.values.buildingId != '' && buildings.length > 0) {
      getFloors(`/buildings/floor/drop-down/${formik.values.buildingId}`);
      setFloorDisable(false);
    }
  }, [formik.values.buildingId]);

  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Accomodation</p> },
            { title: <p className=" text-[12px] ">Rooms</p> },
            { title: <p className=" text-[12px] text-[#4E4B4B]">Create</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInputNumber
              isRequired={true}
              label="Room Number"
              placeholder="Room Number"
              formik={formik}
              name="roomNo"
              width="w-full md:w-1/4"
            />
            <NuInputText
              label="Room Name"
              placeholder="Room Name"
              formik={formik}
              name="roomName"
              width="w-full md:w-1/4"
            />
            <NuSelect
              label="Building"
              placeholder="choose"
              options={buildings}
              formik={formik}
              name="buildingId"
              width="w-full md:w-1/4"
            />
            <NuSelect
              isDisabled={floorDisable}
              label="Floor"
              placeholder="choose"
              options={floors}
              formik={formik}
              name="floorId"
              width="w-full md:w-1/4"
            />
            <NuSelect
              label="Room Type"
              placeholder="choose"
              options={value}
              formik={formik}
              name="roomType"
              width="w-full md:w-1/4"
            />
            <NuInputText
              label="Room Usage"
              placeholder="Room Usage"
              formik={formik}
              name="roomUsage"
              width='w-full md:w-1/4'
            />
            <NuInputNumber
              isRequired={true}
              label="Number of Beds"
              placeholder="0"
              formik={formik}
              name="noOfBeds"
              width="w-full md:w-1/4"
            />
            <NuInputNumber
              isRequired={true}
              label="Maximum Occupancy"
              placeholder="0"
              formik={formik}
              name="maxOccupancy"
              width="w-full md:w-1/4"
            />
            <div className='w-full flex'>
              <div className='w-[49%] flex flex-col justify-around'>
                <NuSelect
                  label="Room Facilities"
                  placeholder="choose"
                  options={value1}
                  formik={formik}
                  name="roomFacilities"
                  width="w-full"
                />
                <NuMultiSelect
                  label="Room Inventories"
                  placeholder="choose"
                  options={value2}
                  formik={formik}
                  name="roomInventories"
                  width="w-full"
                />
              </div>
              {/* <div className='border-r border-[1px] border-slate-200/60 border-solid h-full' /> */}
              <div className='w-1/2 px-1 h-[32vh]'>
                <div className='w-full h-1/2 px-2 py-2 items-center rounded-t-md border border-solid border-slate-200 flex justify-start content-start flex-wrap'>
                  <NuInputNumber
                    isRequired={true}
                    label="Bed Number"
                    placeholder="0"
                    formik={formik}
                    name="bedNumber"
                    
                    width="w-full md:w-[45%]"
                  />
                  <NuSelect
                    label="Bed Type"
                    placeholder="choose"
                    options={BedType}
                    formik={formik}
                    name="bedType"
                    width="w-full md:w-[45%]"
                  />
                  <button
                  onClick={handleAdd}
                  type='button'
                   className='flex items-center justify-center bg-[#048178] mt-5 w-9 h-9 border-none rounded-md hover:cursor-pointer'>
                    <Plus color='white' />
                  </button>
                </div>
                <div className='h-1/2  overflow-y-scroll w-full bg-slate-200/30 rounded-b-md border border-solid border-slate-200'>
                  {/* {beds.map((bed,index)=>{
                    console.log("lino",bed)
                    return( */}
                      {/* <>
                      <h1>dhbjhd</h1>
                      <div className='px-2 py-2 flex hover:bg-slate-300/30'>
                      <p className='w-[45%] text-white text-[13px] px-3 text-center py-1'>ygygyy</p>
                      <p className='w-[45%] text-white text-[13px] px-3 text-center py-1'>gnkjg</p>
                      <button 
                      onClick={handleDeleteItem()}
                      type='button'
                      className='w-7 h-7 border-none rounded-md hover:cursor-pointer'>
                        <Delete color='red' />
                      </button>
                    </div>
                    </> */}
                    {/* )
                  })} */}
                  {/* {beds?.map((test,index)=>{
                    return(
                      <div className='px-2 py-2 flex hover:bg-slate-300/30'>
                      <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{test?.bedNumber}</p>
                      <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{test?.bedType}</p>
                      <button 
                      onClick={handleDeleteItem()}
                      type='button'
                      className='w-7 h-7 border-none rounded-md hover:cursor-pointer'>
                        <Delete color='red' />
                      </button>
                     </div>
                    )
                  })} */}

                  
                  {items.map((data,index)=>{
                                  return(
                                    <div key={index} className="w-full py-2 flex justify-between p-4">
                                    <p className=" w-[20%] text-center text-black text-sm font-light1">
                                      {data?.bedNumber}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.bedType}
                                      <button
                                            onClick={() => handleDelete(index)}
                                            type="button"
                                            className="cursor-pointer w-[15%] h-8 bg-[#048178] rounded border-none ml-16"
                                        >
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            >
                                            <path
                                                d="M10 15.0227L10 12.0227"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M14 15.0227L14 12.0227"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M3 7.02271H21V7.02271C20.0681 7.02271 19.6022 7.02271 19.2346 7.17495C18.7446 7.37793 18.3552 7.76728 18.1522 8.25734C18 8.62488 18 9.09082 18 10.0227V16.0227C18 17.9083 18 18.8511 17.4142 19.4369C16.8284 20.0227 15.8856 20.0227 14 20.0227H10C8.11438 20.0227 7.17157 20.0227 6.58579 19.4369C6 18.8511 6 17.9083 6 16.0227V10.0227C6 9.09082 6 8.62488 5.84776 8.25734C5.64477 7.76728 5.25542 7.37793 4.76537 7.17495C4.39782 7.02271 3.93188 7.02271 3 7.02271V7.02271Z"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M10.0681 3.3933C10.1821 3.28698 10.4332 3.19303 10.7825 3.12603C11.1318 3.05902 11.5597 3.02271 12 3.02271C12.4403 3.02271 12.8682 3.05902 13.2175 3.12603C13.5668 3.19303 13.8179 3.28698 13.9319 3.3933"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            </svg>
                                        </button>
                                    </p>
                                  
                                    </div>
                                  );
                                })}
                  {/* <div className='px-2 py-2 flex hover:bg-slate-300/30'>
                    <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{2}</p>
                    <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{'Bunker'}</p>
                    <button className='w-7 h-7 border-none rounded-md hover:cursor-pointer'>
                      <Delete color='red' />
                    </button>
                  </div>
                  <div className='px-2 py-2 flex hover:bg-slate-300/30'>
                    <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{3}</p>
                    <p className='w-[45%] text-gray-400 text-[13px] px-3 text-center py-1'>{'Bunker'}</p>
                    <button className='w-7 h-7 border-none rounded-md hover:cursor-pointer'>
                      <Delete color='red' />
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            <NuInputText
              label="Room Condition"
              placeholder="Room Condition"
              formik={formik}
              name="roomCondition"
              width='w-full md:w-1/4'
            />
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
            

            <div className="w-full hidden md:block mx-auto my-2 px-3 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { navigate(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateRoom;