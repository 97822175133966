import React, { useState,useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CreateButton, FilterButton, SubmitButton, CancelButton,DeleteButton} from '../../../../Components/Utils/Buttons';
import DataTable from '../../../../Components/Layout/DataTable';
import { currencies } from '../../../../assets/CountryMini';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { NuInputNumber, NuSelect, NuInputText,NuDatePicker } from '../../../../Components/Utils/NuInput';
import { useFormik } from 'formik';
import * as yup from 'yup'
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { Modal } from 'antd';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
// import ViewMorePurchaseOrder from './ViewMorePurchaseOrder';
import ViewMorePurchaseRequest from './ViewMorePurchaseRequest';

function PurchaseRequestIndex() {
  const { officeId } = useParams();
    
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState(20)
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const { data, totalCount, getData } = UseGetDataSearch( '/PR/',params);
  console.log(data,'dddd');
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
  const [showFilterDialog, setShowFilterDialog] = useState(false);
const [filter, setFilter] = useState(false);
  const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
    const[filterPR,setfilterPO]=useState({
      status:"",
      salesPerson:"",
      deadLineFrom:"",
      deadLineTo:""
    });
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterPR,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,status:value?.status, salesPerson: value?.salesPerson, deadLineFrom: value?.deadLineFrom,deadLineTo:value?.deadLineTo})
        closeFilterDialog();
        setFilter(true)
        setfilterPO({
          status:"",
          salesPerson:"",
          deadLineFrom:"",
          deadLineTo:""
        });
      }
    })
    const clearFilter = ()=> {
      setParams({...params,
        status:"",
        salesPerson:"",
        deadLineFrom:"",
        deadLineTo:"",
     
      })
   formik.values.status=""
      formik.values.salesPerson=""
      formik.values.deadLineFrom=""
      formik.values.deadLineTo=""
        setFilter(false)
    }

    let headers = [
        {
          label: <span>ID</span>,
          width: "w-[5%]",
        },
        // {
        //   label: <span>Meal Id</span>,
        //   width: "w-[5%]",
        // },
        {
          label: <span>Quantity</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Project</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Project Code</span>,
          width: "w-[10%]",
        },
        
        {
          label: <span>Dead Line</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Actions</span>,
          width: "w-[5%]",
        },
      ];

      useEffect(()=>{
        setParams({...params,page:page,per_page:pageCount})
       },[page,pageCount])
     
return (
  <div className='w-full h-full px-4 py-2'>
    <div className='w-full flex justify-between items-center'>
    <BreadCrumb items={[
      {title:(<Link to="/main/dashboard">
      <Dashboard color="gray" width={10} height={10} />{" "}
    </Link>)},
      {title:(<p className=' text-[12px] '>Procurement</p>)},
      {title:(<p className=' text-[12px] '>Purchase Request</p>)}
      ]} />
    <div className=' flex gap-2'>
     <SearchBar
     handleChange={(e) =>
      setParams({ ...params, search: e.target.value })
    }
     />
     <FilterButton height='8' 
      handleClick={() => setShowFilterDialog(true)}/>
      {filter && ( 
           <FilterButton 
          handleClick={() => clearFilter()} 
          label="Clear" /> 
        )}
     <CreateButton label='Import' />
     <Link to={`/main/procurment/purchase-request/${officeId}/create`}><CreateButton label='Purchase'/></Link> 
    </div>
    </div>
    <DataTable
     bodyHeight='max-h-[calc(100vh-260px)]'
     height='h-[calc(100vh-130px)]'
     headers={headers} 
     total={totalCount}
     pageIndex={page}
     setPageIndex={setPage}
     dataCount={pageCount}
     setDataCount={setPageCount}
    >
    {data.length>0?data.map((item, i) => {
            let getCurrency;
            currencies.find((i) => {
              if(i.value == item?.currency){
                getCurrency=i.label;
              }
            })
            console.log("aaa",data?.brand);
            return (
              <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                <td className="w-[5%] p-2 px-[1px] text-center ">{item?.prId||"#"}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.productList?.map((n)=>{
                    return(
                      <p>{n?.quantity}</p>
                    )
                  })||""}</td>
                {/* <td className="w-[5%] p-2 px-[1px] text-center ">{item?.menuId||"#"}</td> */}
                {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.project||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.projectCode||"kkk"}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.deadLine||""}</td>
                
                <td className="w-[5%] p-2 px-[1px] text-center ">
                    <ViewMorePurchaseRequest data={item} fetcher={getData} id={item?.prId} />
                </td>
              </tr>
            );
          })
          :
          <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
        }
    </DataTable>

    <Modal
      open={showFilterDialog}
      title={"Purchase Request"}
      width={"50%"}
      className="my-modal"
      centered
      onCancel={closeFilterDialog}
      footer={[]}
    >
               <form onSubmit={formik.handleSubmit}> 
                  <div className="flex flex-col mb-4">
                  <NuSelect
            label="Status"
            options={[
              { value: "Reviewed", label: "Reviewed" },
              { value: "Not Reviewed", label: " Not Reviewed" },
            ]}
            formik={formik}
            placeholder="Choose"
            name="status"
            width="w-full"
          />
                    <NuInputText
                    label='Sales Person'
                    placeholder='Sales Person'
                    formik={formik}
                    name='salesPerson'
                    width='w-full'/>
                  
                  <NuDatePicker
                                        label='Deadline From'
                                         placeholder='Deadline From'
                                          formik={formik} 
                                          name='deadLineFrom'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full' />
                                            <NuDatePicker
                                        label='Deadline To'
                                         placeholder='Deadline To'
                                          formik={formik} 
                                          name='DeadLineTo'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full' />
          
                  </div>
                  
                 <div className="flex justify-end gap-2">
                   <CancelButton handleClick={closeFilterDialog} />
                   <SubmitButton name='Apply' />
                 </div>
                </form> 
             {/* </div>
           </div>
         )} */}
         </Modal>         
  </div>
)
}

export default PurchaseRequestIndex