import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const BedStatus = () => {
  return (
    <SettingsMetaData propertyName={"BedStatus"} viewPropertyName={"Bed Status"} />

  )
}

export default BedStatus