import React, { useState, useEffect } from 'react'
import axiosInstance from '../../Services/axiosCommon';

const useFetchById = (urlWithId) => {
    const [data, setData] = useState({})
    function getData() {
        axiosInstance.get(urlWithId).then(res => {
            // console.log('daataa',res.data?.data);
            res.data?.data && setData(res.data?.data);
        }).catch(err => {
            console.log(err,"err");
        })
    }
    useEffect(() => {
        getData()
    }, []);

  return (
    data || {}
  )
};

export default useFetchById;