import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const ReqService = () => {
  return (
    <SettingsMetaData propertyName={"ReqService"} viewPropertyName={"Requsted Service"} />

  )
}

export default ReqService