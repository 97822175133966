import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Type from './Type'
import Category from './Category'
import SubCategory from './SubCategory'

const FoodMenu = () => {
  return (
    <>
    <TextMediumSM content="Food Menu" />
    <Category/>
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Type/>
     </div>
     {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Category/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <SubCategory/>
     </div> */}
    
    
     </div>
     </>
  )
}

export default FoodMenu