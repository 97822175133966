
import { useFormik } from 'formik';
import { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { NuDatePicker,  NuSelect, NuTextArea, NuInputText,NuInputNumber } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditEquipments() {
  const { value: CalliberReq} = useFetchMataData('CalliberReq') 
  const { value: EquipmentType} = useFetchMataData('EquipmentType')
  const { value: Condition} = useFetchMataData('Condition')

  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  
    const { equipmentsEditId } = useParams();
    const location=useLocation()
    const data =location.state.data
    const from=location.state.from
    // console.log(location.state,'from');
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
      serialNo: data?.serialNo,
      quantity: data?.quantity,
      brand: data?.brand,
      unit: data?.unit,
      manufacturer: data?.manufacturer,
      equipmentType: data?.equipmentType,
      warrentyPeriod: data?.warrentyPeriod,
      WarrentyExpDate: data?.WarrentyExpDate,
      calibExpDate: data?.calibExpDate,
      calibReq: data?.calibReq,
      condition: data?.condition,
      comments: data?.comments,
    })
    console.log(data);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
      
            editFormData(`/equipment/edit/${data?.equipmentId}`, value, resetForm, from);

        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Inventary</p>) },
        { title: (<p className=' text-[12px] '>Equipment</p>) },
        { title: (<p className=' text-[12px] '>Edit</p>) },
    ]} />
    <BackButton/>
    </div>
 
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          <NuInputNumber
              // isRequired={true}
              label=" SerialNo"
              placeholder=" SerialNo"
              formik={formik}
              name="serialNo"
              width="w-full md:w-1/3"
            />
<NuInputNumber
              // isRequired={true}
              label=" Quantity"
              placeholder=" Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />

<NuInputText
              // isRequired={true}
              label="Brand"
              placeholder=" Brand"
              formik={formik}
              name="brand"
              width="w-full md:w-1/3"
            />
             <NuInputNumber
              // isRequired={true}
              label=" Unit"
              placeholder=" Unit"
              formik={formik}
              name="unit"
              width="w-full md:w-1/3"
            />
             <NuInputText
              // isRequired={true}
              label="Manufacturer"
              placeholder=" Manufacturer"
              formik={formik}
              name="manufacturer"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Equipment Type"
              options={EquipmentType}
              formik={formik}
              placeholder="Choose"
              name="equipmentType"
              width="w-full md:w-1/2 lg:w-1/3"
          />
           <NuDatePicker
              label="Warrenty Period"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyPeriod"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Warrenty Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="WarrentyExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Caliber Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="calibExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           <NuSelect
              // isRequired={true}
              label="Condition"
              options={Condition}
              placeholder=" Condition"
              formik={formik}
              name="condition"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Caliber Request"
              options={CalliberReq}
              formik={formik}
              placeholder="caliber Req"
              name="calibReq"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
           
          <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Navigate(-1)}} />
                <SubmitButton  isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
  )
}

export default EditEquipments