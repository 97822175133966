import { useFormik } from 'formik';
import { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate,useNavigate, useLocation, useParams } from 'react-router-dom';
import { NuDatePicker,  NuSelect, NuTextArea, NuInputText,NuInputNumber,NuDateTime } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditInspectionSchedule() {
  const navigate=useNavigate()
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const { value: value } = useFetchMataData('Category_Inspection')
  const { value: value1 } = useFetchMataData('Sub_Category_Inspection')

  

  
    const { inspectionScheduleEditId } = useParams();
    const location=useLocation()
    const data =location.state.data
    const from=location.state.from
    // console.log(location.state,'from');
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
      item: data?.item,
      category: data?.category,
      subCategory: data?.subCategory,
      inspectionSchedule: data?.inspectionSchedule,
      dayAndTime: data?.dayAndTime,
      comments: data?.comments,
       
    })
    console.log(data);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
      
            editFormData(`/inspection-schedule/edit-schedule/${inspectionScheduleEditId}`, value, resetForm, from);

        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Maintenance</p>) },
        { title: (<p className=' text-[12px] '>Inspection-Schedule</p>) },
        { title: (<p className=' text-[12px] '>Edit</p>) },
    ]} />
    <BackButton/>
    </div>
 
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          <NuInputText
              // isRequired={true}
              label="Item"
              placeholder="Item"
              formik={formik}
              name="item"
              width="w-full md:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label="Category"
              options={value}
              placeholder="Category"
              formik={formik}
              name="category"
              width="w-full md:w-1/3"
            />
            
          <NuSelect
              label="Sub Category"
              options={value1}
              formik={formik}
              placeholder="Choose"
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputText
              // isRequired={true}
              label="Inspection Schedule"
              placeholder="Inspection Schedule"
              formik={formik}
              name="inspectionSchedule"
              width="w-full md:w-1/3"
            />
            <NuDateTime
              // isRequired={true}
              label="Day And Time"
              placeholder="dayAndTime"
              formik={formik}
              name="dayAndTime"
              width="w-full md:w-1/3"
            />
          <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
  )
}

export default EditInspectionSchedule