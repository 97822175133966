import React from 'react'

export default function Inventory() {
  return (
    <svg width="13" height="17" viewBox="0 0 16 21" fill="#b1d8d5" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
    <path id="Subtract" d="M2.50217 1.78906C1.96672 1.96198 1.53482 2.2126 1.17157 2.57585C0 3.74743 0 5.63305 0 9.40428V12.4043C0 16.1755 0 18.0611 1.17157 19.2327C2.34315 20.4043 4.22877 20.4043 8.00001 20.4043C11.7712 20.4043 13.6569 20.4043 14.8284 19.2327C16 18.0611 16 16.1755 16 12.4043V9.40428C16 5.63305 16 3.74743 14.8284 2.57585C14.4652 2.2126 14.0333 1.96198 13.4978 1.78906C13.4993 1.82729 13.5 1.8657 13.5 1.90428C13.5 3.56113 12.1569 4.90428 10.5 4.90428H5.5C3.84315 4.90428 2.5 3.56113 2.5 1.90428C2.5 1.8657 2.50073 1.82729 2.50217 1.78906Z" fill="#b1d8d5"/>
    <path id="Rectangle 99" d="M4 1.90234C4 1.07392 4.67157 0.402344 5.5 0.402344H10.5C11.3284 0.402344 12 1.07392 12 1.90234C12 2.73077 11.3284 3.40235 10.5 3.40235H5.5C4.67157 3.40235 4 2.73077 4 1.90234Z" fill="#b1d8d5"/>
    </g>
    </svg>
    
  )
}
