import React from 'react'
import { Route } from 'react-router-dom';
import ListingMain from '../Views/Web/Listing/ListingMain';
import EmployeeIndex from '../Views/Web/Listing/Employee';
import MainEmployee from '../Views/Web/Listing/Employee/MainEmployee';
import CreateEmployee from '../Views/Web/Listing/Employee/create';
import EditEmployee from '../Views/Web/Listing/Employee/edit';
import ViewEmployee from '../Views/Web/Listing/Employee/viewAll';
import MainGuest from '../Views/Web/Listing/Guest/MainGuest';
import GuestIndex from '../Views/Web/Listing/Guest';
import CreateGuest from '../Views/Web/Listing/Guest/create';
import EditGuest from '../Views/Web/Listing/Guest/edit';
import ViewGuest from '../Views/Web/Listing/Guest/viewAll';
import MainMovements from '../Views/Web/Listing/Movements/MainMovements';
import MovementsIndex from '../Views/Web/Listing/Movements/MovementsIndex';
import CreateMovements from '../Views/Web/Listing/Movements/CreateMovements';
import EditMovements from '../Views/Web/Listing/Movements/EditMovements';
import ViewMovements from '../Views/Web/Listing/Movements/ViewMovements';

const ListingRoute = () => {
  return (
    <Route path='listing' element={<ListingMain/>}>
        <Route path='employees/:officeId' element={<MainEmployee/>} >
            <Route index element={<EmployeeIndex/>} />
            <Route path='create' element={<CreateEmployee/>} />
            <Route path='edit/:id' element={<EditEmployee/>} />
            <Route path='view/:id' element={<ViewEmployee/>} />
        </Route>
        <Route path='guests/:officeId' element={<MainGuest/>} >
            <Route index element={<GuestIndex/>} />
            <Route path='create' element={<CreateGuest/>} />
            <Route path='edit/:id' element={<EditGuest/>} />
            <Route path='view/:id' element={<ViewGuest/>} />
        </Route>
        <Route path='movements/:officeId' element={<MainMovements/>} >
            <Route index element={<MovementsIndex/>} />
            <Route path='create' element={<CreateMovements/>} />
            <Route path='edit/:movementsEditId' element={<EditMovements/>} />
            <Route path='view/:movementsId' element={<ViewMovements/>} />
        </Route>
    </Route>
  )
}

export default ListingRoute;