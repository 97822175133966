import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Division = () => {
  return (
    <SettingsMetaData propertyName={"Division"} viewPropertyName={"Division"} />

  )
}

export default Division