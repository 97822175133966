import { Route } from "react-router-dom"
import MainStaff from "../Views/Web/Staff/MainStaff"
import MainStaffDetails from "../Views/Web/Staff/StaffDetails/MainStaffDetails"
import StaffDetailsIndex from "../Views/Web/Staff/StaffDetails/StaffDetailsIndex"
import CreateStaffDetails from "../Views/Web/Staff/StaffDetails/CreateStaffDetails"
import EditStaffDetails from "../Views/Web/Staff/StaffDetails/EditStaffDetails"
import ViewStaffDetails from "../Views/Web/Staff/StaffDetails/ViewStaffDetails"
import MainStaffAttendance from "../Views/Web/Staff/StaffAttendance/MainStaffAttendance"
import StaffAttendanceIndex from "../Views/Web/Staff/StaffAttendance/StaffAttendanceIndex"
import CreateStaffAttendance from "../Views/Web/Staff/StaffAttendance/CreateStaffAttendance"
import EditStaffAttendance from "../Views/Web/Staff/StaffAttendance/EditStaffAttendance"
import ViewStaffAttendance from "../Views/Web/Staff/StaffAttendance/ViewStaffAttendance"
import MainTrainning from "../Views/Web/Staff/Trainning/MainTrainning"
import TrainningIndex from "../Views/Web/Staff/Trainning/TrainningIndex"
import CreateTrainning from "../Views/Web/Staff/Trainning/CreateTrainning"
import EditTrainning from "../Views/Web/Staff/Trainning/EditTrainning"
import ViewTrainning from "../Views/Web/Staff/Trainning/ViewTrainning"
import MainDailyServiceForStaff from "../Views/Web/Staff/DailyServiceForStaff/MainDailyServiceForStaff"
import DailyServiceForStaffIndex from "../Views/Web/Staff/DailyServiceForStaff/DailyServiceForStaffIndex"
import CreateDailyServiceForStaff from "../Views/Web/Staff/DailyServiceForStaff/CreateDailyServiceForStaff"
import EditDailyServiceForStaff from "../Views/Web/Staff/DailyServiceForStaff/EditDailyServiceForStaff"
import ViewDailyServiceForStaff from "../Views/Web/Staff/DailyServiceForStaff/ViewDailyServiceForStaff"
import MainServiceShedule from "../Views/Web/Staff/ServiceShedule/MainServiceShedule"
import ServiceSheduleIndex from "../Views/Web/Staff/ServiceShedule/ServiceSheduleIndex"
import CreateServiceShedule from "../Views/Web/Staff/ServiceShedule/CreateServiceShedule"
import EditServiceShedule from "../Views/Web/Staff/ServiceShedule/EditServiceShedule"
import ViewServiceShedule from "../Views/Web/Staff/ServiceShedule/ViewServiceShedule"


const StaffRoutes = () =>{
    return(
        <Route path="staff" element={<MainStaff/>}>

          <Route path="staff-details/:officeId" element={<MainStaffDetails/>}>
              <Route index element={<StaffDetailsIndex/>}/>
              <Route path="create" element={<CreateStaffDetails/>}/>
              <Route path='edit/:EditId' element={<EditStaffDetails/>} />
              <Route path='view/:staffDetailsViewId' element={<ViewStaffDetails/>} />
          </Route>

          <Route path="staff-attendance/:officeId" element={<MainStaffAttendance/>}>
              <Route index element={<StaffAttendanceIndex/>}/>
              <Route path="create" element={<CreateStaffAttendance/>}/>
              <Route path='edit/:staffAttendanceEditId' element={<EditStaffAttendance/>} />
              <Route path='view/:staffAttendanceViewId' element={<ViewStaffAttendance/>} />
          </Route>
          
          <Route path="daily-service-for-staff/:officeId" element={<MainDailyServiceForStaff/>}>
              <Route index element={<DailyServiceForStaffIndex/>}/>
              <Route path="create" element={<CreateDailyServiceForStaff/>}/>
              <Route path='edit/:dailyServiceForStaffEditId' element={<EditDailyServiceForStaff/>} />
              <Route path='view/:dailyServiceForStaffViewId' element={<ViewDailyServiceForStaff/>} />
          </Route>
          
          <Route path="service-shedule/:officeId" element={<MainServiceShedule/>}>
              <Route index element={<ServiceSheduleIndex/>}/>
              <Route path="create" element={<CreateServiceShedule/>}/>
              <Route path='edit/:serviceSheduleEditId' element={<EditServiceShedule/>} />
              <Route path='view/:serviceSheduleViewId' element={<ViewServiceShedule/>} />
          </Route>
          
          <Route path="trainning/:officeId" element={<MainTrainning/>}>
              <Route index element={<TrainningIndex/>}/>
              <Route path="create" element={<CreateTrainning/>}/>
              <Route path='edit/:trainningEditId' element={<EditTrainning/>} />
              <Route path='view/:trainningViewId' element={<ViewTrainning/>} />
          </Route>
          
        </Route>
    )}

export default StaffRoutes;