import React, { useState } from 'react'
import UseGetData from '../../../../Components/Hooks/useFetchData';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { Link, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import DataTable from '../../../../Components/Layout/DataTable';
import { currencies } from '../../../../assets/CountryMini';
import ViewMoreShocks from './ViewMoreShocks';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { Modal } from 'antd';
import { NuInputNumber, NuSelect } from '../../../../Components/Utils/NuInput';
import * as yup from 'yup'
import { useFormik } from 'formik';

function StocksIndex() {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, totalCount, getData} = UseGetDataSearch( '/medical-stocks/',params);
    console.log(data,'dddd');
  
      let headers = [
          {
            label: <span>ID</span>,
            width: "w-[5%]",
          },
          {
            label: <span>Supplier</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Serial No</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Unit</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Quantity</span>,
            width: "w-[10%]",
          },
         
          {
            label: <span> Mfd Date</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Expiry Date</span>,
            width: "w-[10%]",
          },
        
         
          {
            label: <span>Actions</span>,
            width: "w-[5%]",
          },
        ];

        const [showFilterDialog, setShowFilterDialog] = useState(false);
        const [filter, setFilter] = useState(false);
          const closeFilterDialog = () => {
              setShowFilterDialog(false);
            };
            const[filterStocks,setfilterStocks]=useState({
              batchNo:"",
              serialNo:"",
              isAsset:"",
              
            });
            const formik = useFormik({
              enableReinitialize: true,
              initialValues: filterStocks,
              validateOnChange: false,
              validateOnBlur: false,
              validationSchema: yup.object({
                // email: yup.string().required('Email is required!'),
              }),
              onSubmit: async (value, { resetForm }) => {
                console.log("ffff",value);
                setParams({...params, batchNo: value?.batchNo, serialNo: value?.serialNo,isAsset: value?.isAsset})
                closeFilterDialog();
                setFilter(true)
                setfilterStocks({
                  batchNo: "",
                  serialNo: "",
                  isAsset:"",
                });
              }
            })
            const clearFilter = ()=> {
              setParams({...params,
                batchNo: "",
                serialNo: "",
                isAsset:"",
             
              })
              formik.values.batchNo=""
              formik.values.serialNo=""
              formik.values.isAsset=""
                setFilter(false)
            }
       
    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <BreadCrumb items={[
        {title:(<Link to="/main/dashboard">
        <Dashboard color="gray" width={10} height={10} />{" "}
      </Link>)},
        { title: <p className='text-[13px]'>Clinic</p> },
        { title: <p className='text-[13px]'>Medical-Stocks</p> },

        ]} />
      <div className=' flex gap-2'>
      <SearchBar
       handleChange={(e) =>
        setParams({ ...params, search: e.target.value })
      }
       />
       <FilterButton height='8'
       handleClick={() => setShowFilterDialog(true)}/>
       {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
       <CreateButton label='Import' />
       <Link to={`/main/clinic/medical-stocks/${officeId}/create`}><CreateButton label='Product'/></Link> 
      </div>
      </div>
  
      <DataTable
       bodyHeight='max-h-[calc(100vh-260px)]'
       height='h-[calc(100vh-130px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={page}
       setPageIndex={setPage}
       dataCount={pageCount}
       setDataCount={setPageCount}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              currencies.find((i) => {
                if(i.value == item?.currency){
                  getCurrency=i.label;
                }
              })
              return (
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                  <td className="w-[5%] p-2 px-[1px] text-center ">{item?.stockId||"#"}</td>
                  {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.supplier||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.serialNo||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.unit||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.quantity||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.mfdDate ||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.expiryDate ||""}</td>

                  <td className="w-[5%] p-2 px-[1px] text-center ">
                      <ViewMoreShocks data={item} fetcher={getData} id={item?.stockId}/>
                  </td>
                </tr>
              );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
          }
      </DataTable>
      <Modal
        open={showFilterDialog}
        title={"Medical Products"}
        width={"50%"}
        className="my-modal"

        
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="">
                    <NuInputNumber
                                         label='Batch No' 
                                         placeholder='Batch No'
                                          formik={formik}
                                           name='batchNo'
                                            width='w-full' />
                                            
                                        <NuInputNumber
                                         label='Serial No' 
                                         placeholder='Serial No'
                                          formik={formik}
                                           name='serialNo'
                                            width='w-full' />
                                            <NuSelect
                                            label="Is Asset"
                                            options={[
                                                { value: true, label: "Yes" },
                                                { value: false, label: "No" },
                                            ]}
                                            formik={formik}
                                            placeholder="Choose"
                                            name="isAsset"
                                            width="w-full "
                                            />
                    
                    </div>
                    
                   <div className="flex justify-end gap-2 mt-5">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
    </div>
    )
  }

export default StocksIndex