import React from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';

const ViewContactList = () => {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const { patientsViewId } = useParams();
    const location = useLocation();
    const data = location.state.data;
    // console.log(data,'jjj');
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: (<p className=' text-[12px] '>Clinic</p>) },
        { title: (<p className=' text-[12px] '>Contact-List</p>) },
        { title: (<p className=' text-[12px] '>View</p>) },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/clinic/contact-list/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/clinic/contact-list/${officeId}/edit/${data.contactListId}`} state={{data: data, from:`/main/clinic/contact-list/${officeId}/view/${data.contactListId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
              <h2 className='text-base font-normal text-[#111537] p-3'>Contact-List Details</h2>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-5 gap-x-8 m-10">
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Type</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.type || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Name</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.name || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.category || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Email</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.email || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Mobile</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mobile || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.status || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Address</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.address|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments || '-'}</p>
                  </div>
                 
                  </div>
                  </div>
          </div>
          
      </div>
  
  )
};
export default ViewContactList