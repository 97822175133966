import React from 'react'

export default function Meetings() {
  return (
    <svg width="14" height="15" viewBox="0 0 20 21" fill="#b1d8d5" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
    <path id="Vector" d="M9.52162 0.814968C9.06701 0.916979 8.72319 1.11315 8.37173 1.47412C7.62678 2.2392 7.46633 3.25539 7.93622 4.23626C8.40229 5.20537 9.52162 5.73112 10.5493 5.4604C11.2216 5.28384 11.7718 4.84048 12.0621 4.23626C12.532 3.25539 12.3715 2.2392 11.6266 1.47412C11.2675 1.10531 10.9275 0.913056 10.4499 0.814968C10.0947 0.740422 9.87309 0.740422 9.52162 0.814968Z" fill="#b1d8d5"/>
    <path id="Vector_2" d="M2.22494 2.00332C1.04448 2.16811 0.158181 3.28631 0.230766 4.51436C0.310991 5.82089 1.38066 6.80569 2.66427 6.73899C3.52764 6.69976 4.26877 6.1897 4.63934 5.39715C5.06721 4.46728 4.89912 3.43932 4.19237 2.70563C3.66517 2.15634 2.95461 1.90131 2.22494 2.00332Z" fill="#b1d8d5"/>
    <path id="Vector_3" d="M17.143 2.00413C16.1841 2.13361 15.3704 2.91438 15.1794 3.89526C15.103 4.27192 15.103 4.49556 15.1756 4.84867C15.4927 6.38668 17.1201 7.19493 18.4992 6.49654C19.4199 6.02965 19.9395 4.92322 19.7255 3.88741C19.6033 3.29104 19.2212 2.71429 18.717 2.37294C18.2967 2.0826 17.6511 1.93351 17.143 2.00413Z" fill="#b1d8d5"/>
    <path id="Vector_4" d="M7.7261 6.37834C7.27531 6.52744 7.14542 6.59414 6.87418 6.80208C6.65261 6.97079 6.37373 7.33176 6.25912 7.59463C6.09103 7.98698 6.08339 8.10469 6.08339 9.90165V11.6201L6.19036 11.7261L6.2935 11.8359H9.99915H13.7048L13.8079 11.7261L13.9149 11.6201V9.88203C13.9149 8.5912 13.9034 8.10076 13.8652 7.97129C13.6972 7.36707 13.3763 6.92763 12.8834 6.63337C12.6886 6.51567 11.9207 6.24494 11.7832 6.24494C11.7412 6.24494 11.6762 6.27241 11.6419 6.3038C11.6075 6.33519 11.2255 7.35137 10.7976 8.55981C10.3697 9.76825 10.0106 10.757 9.99915 10.757C9.98769 10.757 9.62859 9.76825 9.20072 8.55981C8.77285 7.35137 8.39082 6.33519 8.35644 6.3038C8.26475 6.2214 8.16161 6.23317 7.7261 6.37834Z" fill="#b1d8d5"/>
    <path id="Vector_5" d="M9.50634 6.34214C9.4605 6.40099 9.42612 6.46769 9.42612 6.49515C9.42612 6.51869 9.50634 6.66779 9.60185 6.8208L9.77758 7.1033L9.70882 7.72321L9.64005 8.34705L9.78522 8.74332C9.97623 9.26907 10.0183 9.26907 10.2054 8.76686C10.2819 8.55892 10.343 8.34312 10.343 8.28427C10.343 8.22934 10.3162 7.94292 10.2819 7.64474L10.2169 7.11114L10.3926 6.82473C10.492 6.66779 10.5722 6.51869 10.5722 6.49515C10.5722 6.46769 10.5378 6.40099 10.492 6.34214C10.4156 6.24405 10.4003 6.24012 9.99915 6.24012C9.59803 6.24012 9.58275 6.24405 9.50634 6.34214Z" fill="#b1d8d5"/>
    <path id="Vector_6" d="M2.01864 7.47976C1.34246 7.67593 0.834362 8.14675 0.574585 8.81767L0.467618 9.08839L0.456157 12.0192C0.444697 15.2601 0.440876 15.1973 0.685372 15.672C0.937509 16.1585 1.51819 16.6058 2.04538 16.7157C2.16763 16.7431 2.76359 16.7588 3.56202 16.7588H4.88001V18.5362C4.88001 20.5568 4.87237 20.5058 5.18945 20.6745C5.35754 20.7608 5.37282 20.7647 6.60677 20.753C7.79868 20.7412 7.85981 20.7373 7.95914 20.6588C8.01644 20.6156 8.10048 20.5293 8.14251 20.4705C8.22273 20.3645 8.22273 20.3175 8.23419 17.7476C8.24183 16.0369 8.23037 15.0364 8.20363 14.8559C8.10048 14.1536 7.60385 13.5769 6.95823 13.4042C6.78632 13.3572 6.4998 13.3454 5.69373 13.3454H4.6508V11.3326C4.6508 9.10016 4.64316 9.03739 4.39866 8.55872C4.16944 8.10752 3.76068 7.73871 3.28697 7.55038C2.95842 7.4209 2.34336 7.38952 2.01864 7.47976Z" fill="#b1d8d5"/>
    <path id="Vector_7" d="M16.9215 7.47976C16.3255 7.65239 15.8441 8.05259 15.5729 8.6058C15.3552 9.04523 15.3475 9.13547 15.3475 11.3326V13.3454H14.3046C13.4985 13.3454 13.212 13.3572 13.0401 13.4042C12.3945 13.5769 11.8978 14.1536 11.7947 14.8559C11.7679 15.0364 11.7565 16.0369 11.7641 17.7476C11.7756 20.3175 11.7756 20.3645 11.8558 20.4705C11.8978 20.5293 11.9819 20.6156 12.0392 20.6588C12.1385 20.7373 12.1996 20.7412 13.3915 20.753C14.6255 20.7647 14.6408 20.7608 14.8089 20.6745C15.1259 20.5058 15.1183 20.5568 15.1183 18.5362V16.7588H16.4477C17.9109 16.7588 18.0255 16.7431 18.4725 16.5156C18.7705 16.3626 19.1525 15.9781 19.3129 15.672C19.5574 15.1973 19.5536 15.2601 19.5422 12.0192L19.5307 9.08839L19.4237 8.81375C19.1983 8.23307 18.7323 7.75832 18.1783 7.54646C17.8574 7.42483 17.2309 7.38951 16.9215 7.47976Z" fill="#b1d8d5"/>
    </g>
    </svg>
    
  )
}
