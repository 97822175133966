import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const FromPlace = () => {
  return (
    <SettingsMetaData propertyName={"FromPlace"} viewPropertyName={"From Place"} />

  )
}

export default FromPlace