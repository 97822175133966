import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const PurposeofStay = () => {
  return (
    <SettingsMetaData propertyName={"PurposeofStay"} viewPropertyName={"Purpose Of Stay"} />

  )
}

export default PurposeofStay