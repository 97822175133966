import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Supplier from './Supplier'

const Stock = () => {
  return (
    <>
    <TextMediumSM content="Stock" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Supplier/>
     </div>
     
    </div>
     </>
  )
}

export default Stock