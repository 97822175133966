import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Supplier = () => {
  return (
    <SettingsMetaData propertyName={"Supplier"} viewPropertyName={"Supplier"} />

  )
}

export default Supplier