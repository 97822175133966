import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import { Link, useLocation, useParams } from "react-router-dom";
import { BackButton, EditButton } from "../../../../Components/Utils/Buttons";
import axiosInstance from "../../../../Services/axiosCommon";
import Dashboard from "../../../../Components/Icons/Dasboard";

function ViewOccupants() {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const { OccupantsEditId } = useParams();
  const location = useLocation();
  const data = location.state.data;
  return (
    <div className="bg-[#E6F2F2] w-full h-full px-3 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/main/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Accomodation</p> },
            { title: <p className=" text-[12px] ">Occupants</p> },
            { title: <p className=" text-[12px] ">View</p> },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/accomodation/occupants/${officeId}`}>
          
            <BackButton link={true} />
          </Link>
          <Link
            to={`/main/accomodation/occupants/${officeId}/edit/${OccupantsEditId}`}
            state={{
              from: `/main/accomodation/occupants/${officeId}/view/${data?.occupantId}`,
              data: data,
            }}
          >
            <EditButton height="8" />
          </Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-y-scroll p-3">
        <h3 className="text-[#212121] font-semibold pb-3">Occupants Details</h3>
        <div className="flex flex-wrap gap-y-5 w-full">
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">occupantId</h4>
            <p className="text-[#4D4D4D]">{ data?.occupantId || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">occupantName</h4>
            <p className="text-[#4D4D4D]">{ data?.occupantName || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">occupantType</h4>
            <p className="text-[#4D4D4D]">{data?.occupantType || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Employee</h4>
            <p className="text-[#4D4D4D]">{data?.occupantDetails?.name || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Country</h4>
            <p className="text-[#4D4D4D]">{data?.occupantDetails?.email || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Country</h4>
            <p className="text-[#4D4D4D]">{data?.bedDetails?.bedNumber || "-"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewOccupants;
