import React from 'react'

const TaskStatus = ({label="",labelBg="bg-[#DCF0E1]", count=0, countBg="bg-green-500"}) => {
  return (
    <div className={`flex rounded-lg ${labelBg} items-center`}>
        <h3 className='py-1 px-3 text-base text-[#4D4D4D] font-normal'>{label}</h3>
        <div className={`${countBg} py-1 px-3 rounded-lg`}>
            <div className='text-lg text-white font-bold'>{count}</div>
        </div>
    </div>
  )
}

export default TaskStatus