// import React, { useState } from 'react'
// import useAddFormData from '../../../../Components/Hooks/useAddFormData';
// import { useFormik } from 'formik';
// import * as yup from 'yup';
// import Dashboard from '../../../../Components/Icons/Dasboard';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
// import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
// import { Link } from 'react-router-dom';
// import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
// import {  NuInputNumber, NuInputText, NuSelect } from '../../../../Components/Utils/NuInput';
// import { Input } from 'antd';
// import UseDropDown from '../../../../Components/Hooks/useDropDown';

import { useState } from "react";
import { BackButton, CancelButton, SubmitButton } from "../../../Components/Utils/Buttons";
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectwithSearch, NuTextArea } from "../../../Components/Utils/NuInput";
import CustomImgUpload from "../../../Components/Utils/CustomImgUpload";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import Dashboard from "../../../Components/Icons/Dasboard";
import { useFormik } from "formik";
import UseDropDown from "../../../Components/Hooks/useDropDown";
import useAddFormData from "../../../Components/Hooks/useAddFormData";
import * as yup from 'yup';
import { NormalHeading } from "../../../Components/Utils/Heading";
import { CountryMIN, formattedBloodGroups } from "../../../assets/CountryMini";
import useFetchMataData from "../../../Components/Hooks/useFetchMataData";

function CreateVendor() {
    const { value: value } = useFetchMataData('Desigination_Vendor')
    const {data:camps} =UseDropDown('/camp/drop-down')
    const { data: branches } = UseDropDown('/company_branch/drop-down');
    const { data: campBosses } = UseDropDown('/employees/camp-boss/drop-down');
    const [image, setImage] = useState({ preview: "", raw: "" });
    // const [division, setDivision] = useState([]);
    const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
    const [createNew, setCreateNew] = useState({
        contactPerson:"",
        companyName:"",
        email:"",
        phone:"",
        // countryCode:"",
        website:"",
        designation:"",
        buildingNo:"",
        products:"",
        services:"",
        contactPersonEmail:"",
        contactPersonMobile:"",
        taxId:"",
        performanceRating:"",
        comments:"",
        streetName:"",
        country:"",
        city:"",
        state:"",
        district:"",
        postalCode:"",
        vendorStatus:""
        // cpCountryCode:""
       
    })
    
    const [items, setItems] = useState([])
    // const [newData , setNewData]=useState({
    //     floorName:"",
    //     floorNo:"",
    //     numOfRooms:""

    //     // Total Rooms on Floor:
    // })
    // console.log("ddd", items);
    // console.log("ddd", items);

    // function handleDelete(idx) {
    //     const list = items.filter((item, i) => i !== idx);
    //     setItems(list);
        

    // }
    // const handleAdd =()=>{
    //     // if (items.length < formik.values.numberOfFloors) {
    //     const newItem = {
            
    //         floorName: formik.values.floorName,
    //         floorNo: formik.values.floorNo,
    //         numOfRooms: formik.values.numOfRooms,
    //         // Total Rooms on Floor:newData.floorNo
    //       };
    //       console.log(newItem,'jkh');
    //       setItems([...items, newItem]);
    //       // Update local storage
    //       setNewData({
    //         floorName: "",
    //         floorNo: "",
    //         numOfRooms:"",
           
    //       });
       
    //       formik.values.floorName=""
    //       formik.values.floorNo=""
    //       formik.values.numOfRooms=""
          
          
    //     // }
    //     // else{
    //     //     alert("You have reached the maximum limit of floors.");
    //     // }
    // };
        // const handleChange = (e) => {
        //     setNewData({
        //         ...newData,
        //         [e.target.name]: e.target.value,
            // });
        // };
    
    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };
    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            // const valueWithItems = {
            //     ...value,
            //     floors: items,
            //   };
            let formData = new FormData();
            formData.append('vendorDetails', JSON.stringify(value));
            formData.append('vendorImage', image.raw);

            addFormData('/vendors/new-vendor', formData, resetForm);
        }
    })
    
return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
            <div className="flex justify-between px-4">
                <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Vendor</p>) },
                    // { title: (<p className=' text-[12px] '>Camp-Info</p>) },
                    { title: (<p className=' text-[12px] '>Create</p>) },
                ]} />
                <BackButton />
            </div>
            <div className="w-full h-full">
                <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
                    {contextHolder}
                    <div className="w-[96%] mx-auto ">
                        <form onSubmit={formik.handleSubmit} className="w-full">
                            {/* <NormalHeading title={'Vendor Area'} />
                            <div className='flex flex-wrap md:flex-nowrap pb-4'>
                                <div className='w-full md:w-1/3 rounded-md border border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 justify-center items-center'> */}
                                    <CustomImgUpload
                                        borderStyle="dashed"
                                        isRemove={true}
                                        width='w-1/3 md:w-1/2'
                                        removeFunction={handleImageRemove}
                                        handleChange={handleChangeImg}
                                        preview={image.preview || createNew.vendorImage || ""}
                                    />
                                {/* </div> */}
                                <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                    {/* <div className='flex w-full'> */}
                                    <NuInputText label='Contact Person' placeholder='Contact Person' formik={formik} name='contactPerson' width='w-full md:w-1/3' />
                                    <NuInputText label='Company Name' placeholder='Company Name' formik={formik} name='companyName' width='w-full md:w-1/3' />
                                <NuInputEmail label='Email' placeholder={'example@email.com'} formik={formik} name='email' isRequired={true} width={'w-full md:w-1/3'} />
                                <NuMobileNumber
                                    nunCode={"countryCode"}
                                    label="Mobile number"
                                    placeholder="Mobile number"
                                    formik={formik}
                                    name="phone"
                                    width="w-full md:w-1/3"
                                />
                                 <NuInputText label='Website' placeholder={'example@email.com'} formik={formik} name='website' isRequired={true} width={'w-full md:w-1/3'} />
                                 <NuSelect
                                    label='Desigination'
                                    placeholder='choose'
                                    formik={formik}
                                    options={value}
                                    name='designation'
                                    width='w-full md:w-1/3' />
                                      <NuInputNumber 
                                        label='Building Number'
                                         placeholder='Building Number'
                                          formik={formik} 
                                          name='buildingNo'
                                           width='w-full md:w-1/3' />
                                            <NuInputText 
                                        label='Product Name'
                                         placeholder='Product Name' 
                                         formik={formik}
                                          name='products'
                                           width='w-full md:w-1/3' />
                                           <NuInputText 
                                        label='Services'
                                         placeholder='Services' 
                                         formik={formik}
                                          name='services'
                                           width='w-full md:w-1/3' />
                                <NuInputEmail label='Contact Person Email' placeholder={'example@email.com'} formik={formik} name='contactPersonEmail' width='w-full md:w-1/3' />
                                <NuMobileNumber
                                    nunCode={"cpCountryCode"}
                                    label="Contact Person Mobile"
                                    placeholder="Mobile number"
                                    formik={formik}
                                    name="contactPersonMobile"
                                    width="w-full md:w-1/3"
                                />
                               <NuInputNumber 
                                        label='Tax Id'
                                         placeholder='Tax Id'
                                          formik={formik} 
                                          name='taxId'
                                           width='w-full md:w-1/3' />
                                {/* <NuInputText label={'Blood Group'} placeholder={'AB-'} formik={formik} name={'bloodGroup'} width={'w-full md:w-1/3'} /> */}
                                <NuInputNumber
            // isRequired={true}
            label="Performance Rating"
            placeholder="Performance Rating"
            formik={formik}
            name="performanceRating"
            width="w-full md:w-1/3"
          />
           <NuTextArea
                                    label={'Comments'}
                                    name={'comments'}
                                    placeholder={'Write your comments here...'}
                                    formik={formik}
                                    width={'w-full md:w-1/3'}
                                />
                               
                         
                            </div>
                            <p className="text-xs font-semibold text-[#000000] pt-3">Address</p>
                            <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                <NuSelectwithSearch
                                    label="Country"
                                    options={CountryMIN}
                                    formik={formik}
                                    placeholder="Choose"
                                    name="country"
                                    width="w-full md:w-1/3"
                                />
                                <NuInputText label='State' placeholder='state' formik={formik} name='state' width='w-full md:w-1/3' />
                                <NuInputText label='District' placeholder='district' formik={formik} name='district' width='w-1/3' />
                                <NuInputText label='City' placeholder='city' formik={formik} name='city' width='w-full md:w-1/3' />
                                <NuInputText label='Street Name' placeholder='streetName' formik={formik} name='streetName' width='w-full md:w-1/3' />
                                <NuInputNumber label='Postal Code' placeholder='postalCode' formik={formik} name='postalCode' width='w-full md:w-1/3' />
                                {/* <NuInputNumber label='CP Country Code' placeholder='Code' formik={formik} name='cpCountryCode' width='w-full md:w-1/3' /> */}
                            </div>
                            {/* <p className="text-xs font-semibold text-[#000000] pt-3">Contact Person</p>
                            <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                <NuInputText label='Name' placeholder='Name' formik={formik} name='cpName' width='w-full md:w-1/3' />
                                <NuInputText label='Email' placeholder='example@email.com' formik={formik} name='cpEmail' width='w-full md:w-1/3' />
                                <NuMobileNumber nunCode='cpCountryCode' name='cpPhone' label='Mobile' placeholder='Mobile number' formik={formik} width='w-full md:w-1/3' />
                                <NuInputText label='Designation' placeholder='Designation' formik={formik} name='cpDesignation' width='w-full md:w-1/3' />
                            </div> */}
                   
                   
                         
                            <div className="w-full md:block mx-auto my-4 mt-8 ">
                                <div className="md:flex justify-end gap-4">
                                    <CancelButton handleClick={() => { Oncancel() }} />
                                    <SubmitButton/>
                                </div>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
 </div>
  )
 }

export default CreateVendor