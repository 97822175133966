// import React, { useState } from 'react';
// import UseDropDown from '../../../../Components/Hooks/useDropDown';
// import { useFormik } from 'formik';
// import * as yup from 'yup';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
// import { Link, useLocation } from 'react-router-dom';
// import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
// import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
// import { NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectwithSearch, YesOrNoButton } from '../../../../Components/Utils/NuInput';
// import { CountryMIN } from '../../../../assets/CountryMini';
// import Dashboard from '../../../../Components/Icons/Dasboard';
// import { NormalHeading } from '../../../../Components/Utils/Heading';
// import useEditFormData from '../../../../Components/Hooks/useEditFormData';

import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import Dashboard from "../../../Components/Icons/Dasboard";
import { BackButton, CancelButton, SubmitButton } from "../../../Components/Utils/Buttons";
import CustomImgUpload from "../../../Components/Utils/CustomImgUpload";
import { NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectwithSearch, NuTextArea } from "../../../Components/Utils/NuInput";
import useEditFormData from "../../../Components/Hooks/useEditFormData";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { CountryMIN } from "../../../assets/CountryMini";
import useFetchMataData from "../../../Components/Hooks/useFetchMataData";

const EditVendor = () => {
    const { value: value } = useFetchMataData('Desigination_Vendor')

    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    console.log(from,'from');
    // const { data: branches } = UseDropDown('/company_branch/drop-down');
    // const { data: campBosses } = UseDropDown('/employees/camp-boss/drop-down');
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
        contactPerson:data?.contactPerson,
        companyName:data?.companyName,
        email:data?.email,
        phone:data?.phone,
        // countryCode:data?.countryCode,
        website:data?.website,
        designation:data?.designation,
        buildingNo:data?.buildingNo,
        products:data?.products,
        services:data?.services,
        contactPersonEmail:data?.contactPersonEmail,
        contactPersonMobile:data?.contactPersonMobile,
        taxId:data?.taxId,
        performanceRating:data?.performanceRating,
        comments:data?.comments,
        streetName:data?.streetName,
        country:data?.country,
        city:data?.city,
        state:data?.state,
        district:data?.district,
        postalCode:data?.postalCode,
        vendorStatus:data?.vendorStatus,
        oldImage: data?.vendorImage?.file
        // cpCountryCode:data?.cpCountryCode
        // join_date: "",
        // assignedPro: "",
        // releiving_date: "",
    });

    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };

    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
        setCreateNew(prevState => ({ ...createNew,  oldImage: "" }));
        formik.values. oldImage = '';
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required.'),
            // branchId: yup.string().required('Please select one branch'),
            // campBoss: yup.string().required('Please select Camp Boss'),
        }),
        onSubmit: async (value, { resetForm }) => {
            // let neValue = {
            //     ...value, height: parseFloat(value?.height || '0.00').toFixed(2),
            //     width: parseFloat(value?.width || '0.00').toFixed(2)
            // }
            console.log("bbbbbbbbbbb");
            let formData = new FormData();
            formData.append('vendorDetails', JSON.stringify(value));
            formData.append('vendorImage', image.raw);

            editFormData(`/vendors/edit-vendor/${data?.vendorId}`, formData, resetForm, from);
        }
    }
    )
 return (
        <div className="w-full h-full p-2 bg-[#F1F2FB]">
        <div className="flex justify-between px-4">
            <BreadCrumb items={[
                {
                    title: (<Link to="/main/dashboard">
                        <Dashboard color="gray" width={10} height={10} />{" "}
                    </Link>)
                },
                { title: (<p className=' text-[12px] '>Vendor</p>) },
                // { title: (<p className=' text-[12px] '>Camp-Info</p>) },
                { title: (<p className=' text-[12px] '>Create</p>) },
            ]} />
            <BackButton />
        </div>
        <div className="w-full h-full">
            <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
                {contextHolder}
                <div className="w-[96%] mx-auto ">
                        <form onSubmit={formik.handleSubmit} className="w-full">
                            {/* <NormalHeading title={'Vendor Area'} />
                            <div className='flex flex-wrap md:flex-nowrap pb-4'>
                                <div className='w-full md:w-1/3 rounded-md border border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 justify-center items-center'> */}
                                    <CustomImgUpload
                                        borderStyle="dashed"
                                        isRemove={true}
                                        width='w-1/3 md:w-1/2'
                                        removeFunction={handleImageRemove}
                                        handleChange={handleChangeImg}
                                        preview={image.preview || createNew?.oldImage || ""}
                                    />
                                {/* </div> */}
                                <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                    {/* <div className='flex w-full'> */}
                                    <NuInputText label='Contact Person' placeholder='Contact Person' formik={formik} name='contactPerson' width='w-full md:w-1/3' />
                                    <NuInputText label='Company Name' placeholder='Company Name' formik={formik} name='companyName' width='w-full md:w-1/3' />
                                <NuInputEmail label='Email' placeholder={'example@email.com'} formik={formik} name='email' isRequired={true} width={'w-full md:w-1/3'} />
                                <NuMobileNumber
                                    nunCode={"countryCode"}
                                    label="Mobile number"
                                    placeholder="Mobile number"
                                    formik={formik}
                                    name="phone"
                                    width="w-full md:w-1/3"
                                />
                                 <NuInputText label='Website' placeholder={'example@email.com'} formik={formik} name='website' isRequired={true} width={'w-full md:w-1/3'} />
                                 <NuSelect
                                    label='Desigination'
                                    placeholder='choose'
                                    formik={formik}
                                    options={value}
                                    name='designation'
                                    width='w-full md:w-1/3' />
                                      <NuInputNumber 
                                        label='Building Number'
                                         placeholder='Building Number'
                                          formik={formik} 
                                          name='buildingNo'
                                           width='w-full md:w-1/3' />
                                            <NuInputText 
                                        label='Product Name'
                                         placeholder='Product Name' 
                                         formik={formik}
                                          name='products'
                                           width='w-full md:w-1/3' />
                                           <NuInputText 
                                        label='Services'
                                         placeholder='Services' 
                                         formik={formik}
                                          name='services'
                                           width='w-full md:w-1/3' />
                                <NuInputEmail label='Contact Person Email' placeholder={'example@email.com'} formik={formik} name='contactPersonEmail' width='w-full md:w-1/3' />
                                <NuMobileNumber
                                    nunCode={"cpCountryCode"}
                                    label="Contact Person Mobile"
                                    placeholder="Mobile number"
                                    formik={formik}
                                    name="contactPersonMobile"
                                    width="w-full md:w-1/3"
                                />
                               <NuInputNumber 
                                        label='Tax Id'
                                         placeholder='Tax Id'
                                          formik={formik} 
                                          name='taxId'
                                           width='w-full md:w-1/3' />
                                {/* <NuInputText label={'Blood Group'} placeholder={'AB-'} formik={formik} name={'bloodGroup'} width={'w-full md:w-1/3'} /> */}
                                <NuInputNumber
            // isRequired={true}
            label="Performance Rating"
            placeholder="Performance Rating"
            formik={formik}
            name="performanceRating"
            width="w-full md:w-1/3"
          />
           <NuTextArea
                                    label={'Comments'}
                                    name={'comments'}
                                    placeholder={'Write your comments here...'}
                                    formik={formik}
                                    width={'w-full md:w-1/3'}
                                />
                               
                         
                            </div>
                            <p className="text-xs font-semibold text-[#000000] pt-3">Address</p>
                            <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                <NuSelectwithSearch
                                    label="Country"
                                    options={CountryMIN}
                                    formik={formik}
                                    placeholder="Choose"
                                    name="country"
                                    width="w-full md:w-1/3"
                                />
                                <NuInputText label='State' placeholder='state' formik={formik} name='state' width='w-full md:w-1/3' />
                                <NuInputText label='District' placeholder='district' formik={formik} name='district' width='w-1/3' />
                                <NuInputText label='City' placeholder='city' formik={formik} name='city' width='w-full md:w-1/3' />
                                <NuInputText label='Street Name' placeholder='streetName' formik={formik} name='streetName' width='w-full md:w-1/3' />
                                <NuInputNumber label='Postal Code' placeholder='postalCode' formik={formik} name='postalCode' width='w-full md:w-1/3' />
                                {/* <NuInputNumber label='CP Country Code' placeholder='Code' formik={formik} name='cpCountryCode' width='w-full md:w-1/3' /> */}
                            </div>
                            {/* <p className="text-xs font-semibold text-[#000000] pt-3">Contact Person</p>
                            <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                <NuInputText label='Name' placeholder='Name' formik={formik} name='cpName' width='w-full md:w-1/3' />
                                <NuInputText label='Email' placeholder='example@email.com' formik={formik} name='cpEmail' width='w-full md:w-1/3' />
                                <NuMobileNumber nunCode='cpCountryCode' name='cpPhone' label='Mobile' placeholder='Mobile number' formik={formik} width='w-full md:w-1/3' />
                                <NuInputText label='Designation' placeholder='Designation' formik={formik} name='cpDesignation' width='w-full md:w-1/3' />
                            </div> */}
                   
                   
                         
                            <div className="w-full md:block mx-auto my-4 mt-8 ">
                                <div className="md:flex justify-end gap-4">
                                    <CancelButton handleClick={() => { Oncancel() }} />
                                    <SubmitButton  />
                                </div>
                            </div> 
                        </form>
                    </div>
            </div>
        </div>
</div>
 )

}

export default EditVendor