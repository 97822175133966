import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../Services/axiosCommon";
import { Link, NavLink } from "react-router-dom";
import Pagination from "../../../Components/Utils/Pagenation";
import OfficeCard from "../../../Components/Card/OfficeCard";

function OfficeIndex() {
  const [pageIndex, setPageIndex] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataCount, setDataCount] = useState(20);
  const [data, setData] = useState([]);

  function getData() {
    AxiosInstance.get(
      `/company_branch/?page=${pageIndex}&per_page=${dataCount}`
    )
      .then((res) => {
        
        console.log("setdataa",res.data.data.data);
        
        res?.data?.data?.totalRecords &&
          setTotal(res?.data?.data?.totalRecords);
        res?.data?.data?.data &&
          setData(res?.data?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  console.log("dataa",data);
  

  useEffect(() => {
    getData();
  }, [pageIndex, dataCount]);

  // const statusStyle = {
  //     color: status === 'active' ? 'green' : 'red',
  //   };

  return (
    <div className="w-full h-full">
        <div className=" w-full h-[calc(100vh-170px)] overflow-y-auto p-3">
        <div className=" grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {data &&
          data.map((item) => {
            return (
              <>
                {" "}
                <Link
                  className=" text-gray-800 hover:text-gray-800 "
                  to={`/main/dashboard/${item.branchId}`}
                >
                  <OfficeCard
                    name={item?.branch_name}
                    officeType={item?.branchId}
                    address={item?.address}
                    status={item?.status}
                    city={item?.currency}
                  />
                </Link>{" "}
              </>
            );
          })}
      </div>
      </div>
   <div className=" w-full h-[30px] ">
        <Pagination
          total={total}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>
         
    </div>
  );
}

export default OfficeIndex;