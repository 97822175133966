import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Category = () => {
  return (
    <SettingsMetaData propertyName={"Category_Points"} viewPropertyName={"Category"} />

  )
}

export default Category