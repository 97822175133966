import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const WarrentyApplicable = () => {
  return (
    <SettingsMetaData propertyName={"WarrentyApplicable"} viewPropertyName={"Warrenty Applicable"} />

  )
}

export default WarrentyApplicable