import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectwithSearch, NuTextArea, YesOrNoButton } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dashboard from '../../../../Components/Icons/Dasboard';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function EditAsserts() {
  const { value: Category} = useFetchMataData('Category') 
  const { value: SubCategory} = useFetchMataData('SubCategory')
  const { value: CalliberReq} = useFetchMataData('CalliberReq') 
  const { value: WarrentyApplicable} = useFetchMataData('WarrentyApplicable')
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const {data:suppliers} = UseDropDown('/vendors/drop-down')
  const {data:menus} = UseDropDown('/food-menu/drop-down')
  
  const [url,setUrl] = useState(false);

 
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };

  const [images, setImages] = useState([]);
  const [selectedFile,setSelectedFile] =useState(null)
  // const [division, setDivision] = useState([]);
const [ api, ] = notification.useNotification();
  // const [images, setImages] = useState({ preview: "", raw: "" });
  const location = useLocation();
  const data = location.state.data;
  console.log("nj",data);
  const from = location.state.from;
  console.log(location.state,'state');
  const { assertsEditId } = useParams();
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData,contextHolder,isLoading } = useEditFormData();
  const [createNew, setCreateNew] = useState({

      code:data?.code,
      isAsset:false,
      name:data?.name,
      category: data?.category,
      subCategory:data?.subCategory,
      serialNo:data?.serialNo,
      brand:data?.brand,
      manufacturer:data?.manufacturer,
      supplier:data?.supplier,
      batchNo:data?.batchNo,
      unit:data?.unit,
      warrentyDuration:data?.warrentyDuration,
      warrentyEndDate:data?.warrentyEndDate,
      condition:data?.condition,
      quantity:data?.quantity,
      mfdDate:data?. mfdDate,
      expDate:data?.expDate,
      mrp:data?. mrp,
      unitPrice:data?.unitPrice,
      purchasedDate:data?.purchasedDate,
      stockLocation:data?.stockLocation,
      minStockLevel:data?.minStockLevel,
      barcode:data?.barcode,
      stockQty:data?.stockQty,
      adjustedQty:data?.adjustedQty,
      reasonForAdjust:data?.reasonForAdjust,
      warrentyApplicable:data?.warrentyApplicable,
      warrentyStartDate:data?.warrentyStartDate,
      calibReq:data?.calibReq,
      calibExp:data?.calibExp,
      receivedQty:data?.receivedQty,
      issuedQty:data?.issuedQty,
      comments:data?.comments,
    
  })
  const handleChangeImg = (e) => {
      if (e.target.files.length) {
          setImages({
              preview: URL.createObjectURL(e.target.files[0]),
              raw: e.target.files[0],
          });
      }
  };
  const handleImageRemove = () => {
      setImages({
          preview: "",
          raw: "",
      });
      setCreateNew(prevState => ({ ...createNew, image: "" }))
      formik.values.image = ""
  }
  function handleImageChange(e) {
      

      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = []
      files.map(item=>{
        if (item.size < maxFileSize ){
          fileArr.push(item)
        }else{
          api.warning({
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            placement: "bottomRight",
          });
        }
      })    
      let tempArr = []
      setSelectedFile(fileArr);
      files.map(item=>{
        if (item.size < maxFileSize ){
        let fileName = item?.name
        console.log('fname',fileName);
        let fileExtention =  fileName.split(".").reverse()[0]
        if(filetype?.includes(fileExtention)){
          tempArr.push(fileName)
        }else{
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
      })
      console.log(tempArr,'temp');
      setImages(tempArr)
  }

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
          let formData = new FormData();
          formData.append('json_data', JSON.stringify(value));
          formData.append('stockImage', images.raw);

          editFormData(`/stocks/edit/${assertsEditId}`, formData, resetForm, from);
      }
  })
return (
  <div className="w-full h-full p-2 bg-[#F1F2FB]">
  <div className="flex justify-between px-4">
      <BreadCrumb items={[
      {
          title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
          </Link>)
      },
      { title: <p className='text-[13px]'>Invontary</p> },
      { title: <p className='text-[13px]'>Asserts</p> },
      { title: <p className='text-[13px]'>Edit</p> }

  ]} />
  <BackButton/>
  </div>
  <div className="w-full h-full">
              <div className="w-full h-[calc(100vh-130px)] overflow-y-scroll">
                  {contextHolder}
                  <div className="w-[96%] mx-auto  ">
                  <form onSubmit={formik.handleSubmit} className="w-full ">
                      <div className=' p-6  mt-4  bg-white rounded-lg'>
                      <div className='flex gap-8 md:flex-nowrap'>
                          
                          {/* <div className='flex flex-wrap md:flex-nowrap pb-4 bg-white'> */}
                          {/* <div className='w-full md:w-1/2 rounded-md border border-solid border-[0.5px] border-slate-300/50  upload-bg py-3 flex flex-col'>

                              <h3 className=" text-[#231942] w-full text-center capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"Product Image"}</h3>
                              <div className='text-center flex'>
                                  <CustomImgUpload
                                      borderStyle="dashed"
                                      isRemove={true}
                                      width='w-full mx-auto'
                                      justify='justify-center'
                                      removeFunction={handleImageRemove}
                                      handleChange={handleChangeImg}
                                      preview={images.preview || data?.image.file || ""}
                                  />
                                  </div>
                              </div> */}
                              <div className='w-full'>
                                  <div className='flex flex-wrap w-full'>
                                  <NuInputNumber
              // isRequired={true}
              label="Code"
              placeholder="Code"
              formik={formik}
              name="code"
              width="w-full md:w-1/3"
            />
             {/* <NuInputText
              isRequired={true}
              label="Is Asset"
              placeholder="isAsset"
              formik={formik}
              name="manufacturer"
              width="w-full md:w-1/3"
            /> */}
            <NuInputText
              // isRequired={true}
              label="Name"
              placeholder="Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/3"
            />
                 
            <NuSelect
              label="Category"
              options={Category}
              formik={formik}
              placeholder="Category"
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              label="Sub Category"
              options={SubCategory}
              formik={formik}
              placeholder="Sub Category"
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              label="Serial No"
              placeholder="Serial No"
              formik={formik}
              name="serialNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Brand"
              placeholder="Brand"
              formik={formik}
              name="brand"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Manufacturer"
              placeholder="Manufacturer"
              formik={formik}
              name="manufacturer"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Manufacture Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="mfdDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="expDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelectwithSearch
              label="Supplier"
              placeholder="Supplier"
              formik={formik}
              options={suppliers}
              name="supplier"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label=" Batch No"
              placeholder="Batch No"
              formik={formik}
              name="batchNo"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Unit"
              placeholder="Unit"
              formik={formik}
              name="unit"
              width="w-full md:w-1/3"
            />
            
            
            <NuDatePicker
              label="Purchased Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="purchasedDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Condition"
              placeholder="Condition"
              formik={formik}
              name="condition"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="MRP Price"
              placeholder="MRP Price"
              formik={formik}
              name="mrp"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Unit Price"
              placeholder="Unit Price"
              formik={formik}
              name="unitPrice"
              width="w-full md:w-1/3"
            />
            <NuInputText
              label="Warrenty Duration"
              placeholder="Warrenty Duration"
              formik={formik}
              name="warrentyDuration"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Warrenty Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyEndDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Stock Location"
              placeholder="Stock Location"
              formik={formik}
              name="stockLocation"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Minimum Stock Level"
              placeholder="Minimum Stock Level"
              formik={formik}
              name="minStockLevel"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Barcode"
              placeholder="Barcode"
              formik={formik}
              name="barcode"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Stock Quantity"
              placeholder="Stock Quantity"
              formik={formik}
              name="stockQty"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Adjusted Quantity"
              placeholder="Adjusted Quantity"
              formik={formik}
              name="adjustedQty"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Reason For Adjust"
              placeholder="Reason For Adjust"
              formik={formik}
              name="reasonForAdjust"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Warrenty Applicable"
              options={WarrentyApplicable}
              formik={formik}
              placeholder="Warrenty Applicable"
              name="warrentyApplicable"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Warrenty Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyStartDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              label="Caliber Request"
              options={CalliberReq}
              formik={formik}
              placeholder="calibReq"
              name="calibReq"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Caliber Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="calibExp"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Received Quantity"
              placeholder="received Quantity"
              formik={formik}
              name="receivedQty"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Issued Quantity"
              placeholder="Issued Quantity"
              formik={formik}
              name="issuedQty"
              width="w-full md:w-1/3"
            />
            {/* <NuTextArea
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width='w-full md:w-1/3'
            /> */}
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />


                                  </div>
                                  
                              </div>
                              
                          </div>
                          <Attachement
                  handleChange={handleImageChange}
                  preview={images||''}
                  width="w-full"
                  label="Attach"
                  multiple={false}
                />
              <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
  {/* {data?.image?.map((imgs, idx) => {
                                      console.log('img', imgs);
                                      return ( */}
                                          <div
                                              className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                              // key={idx}
                                              style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                          >
                                              <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                              // onClick={() => handleImageClick(imgs)}
                                              onClick={() => {setShowFilterDialog(true);setUrl(data?.image?.file)}}
                                              >
                                                  {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                  <img src={data?.image?.file} alt={data?.image?.file} />
                                              </div>
                                          </div>
                                      {/* );
                                  })} */}
                                  </div>
              </div>
                          
                         
                          <div className="w-full md:block mx-auto my-4 mt-8 ">
                              <div className="md:flex justify-end gap-4">
                                  <CancelButton handleClick={() => { Oncancel() }} />
                                  <SubmitButton isLoading={isLoading} />
                              </div>
                          </div> 
                          
                      </form>
                      
                  </div>
              </div>
          </div>
</div>
)
}

export default EditAsserts