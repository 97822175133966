import React from 'react'

export default function Vendors() {
  return (
    <svg width="14" height="15" viewBox="0 0 23 20" fill="#b1d8d5" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 2607">
    <path id="Vector" d="M6.81771 6.95967L7.76691 0.659668H2.83011L0.767305 6.05967C0.703705 6.25167 0.670105 6.45087 0.670105 6.65967C0.670105 7.98447 2.05011 9.05967 3.75531 9.05967C5.32731 9.05967 6.62691 8.14287 6.81771 6.95967ZM11.4701 9.05967C13.1741 9.05967 14.5553 7.98447 14.5553 6.65967C14.5553 6.61047 14.5517 6.56127 14.5493 6.51447L13.9385 0.659668H9.00171L8.38971 6.50967C8.38687 6.55962 8.38527 6.60964 8.38491 6.65967C8.38491 7.98447 9.76611 9.05967 11.4701 9.05967ZM17.4701 10.3149V15.0597H5.47011V10.3221C4.94451 10.5117 4.36611 10.6197 3.75531 10.6197C3.52131 10.6197 3.29451 10.5921 3.07011 10.5609V18.1797C3.07011 19.1037 3.8249 19.8597 4.7477 19.8597H18.1901C19.1141 19.8597 19.8701 19.1025 19.8701 18.1797V10.5621C19.6433 10.5969 19.4144 10.6165 19.1849 10.6209C18.5998 10.6203 18.0193 10.5167 17.4701 10.3149ZM22.1741 6.05967L20.1089 0.659668H15.1733L16.1213 6.95007C16.3061 8.13807 17.6057 9.05967 19.1849 9.05967C20.8889 9.05967 22.2701 7.98447 22.2701 6.65967C22.2701 6.45087 22.2365 6.25167 22.1741 6.05967Z" fill="#b1d8d5"/>
    </g>
    </svg>
    
  )
}
