import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectwithSearch, NuTextArea, YesOrNoButton } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import { Link, Navigate } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import Dashboard from '../../../../Components/Icons/Dasboard';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateProduct() {
  const { value: Category } = useFetchMataData('Category') 
  const { value: SubCategory } = useFetchMataData('SubCategory') 

    const [images, setImages] = useState([]);
    const [selectedFile,setSelectedFile] =useState(null)
    // const [division, setDivision] = useState([]);
  const [ api, contextHolder ] = notification.useNotification();
    const { Oncancel, addFormData, isLoading } = useAddFormData();
    const [createNew, setCreateNew] = useState({
        productName:"",
        category:"",
        subCategory:"",
        productCode:"",
        brand:"",
        manufacturer:"",
        unit:""
    })
    function handleImageChange(e) {
        const maxFileSize = 200000000; // 200 MB
        const files = Array.from(e.target.files);
        let fileArr = []
        files.map(item=>{
          if (item.size < maxFileSize ){
            fileArr.push(item)
          }else{
            api.warning({
              message: `File Size to big`,
              description: `${item.name} is Removed and not Uploaded`,
              placement: "bottomRight",
            });
          }
        })    
        let tempArr = []
        setSelectedFile(fileArr);
        files.map(item=>{
          if (item.size < maxFileSize ){
          let fileName = item?.name
          console.log('fname',fileName);
          let fileExtention =  fileName.split(".").reverse()[0]
          if(filetype?.includes(fileExtention)){
            tempArr.push(fileName)
          }else{
            let x = URL.createObjectURL(item)
            tempArr.push(x)
          }
        }
        })
        console.log(tempArr,'temp');
        setImages(tempArr)
    }

    console.log("cccc",selectedFile);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            let formData = new FormData();
            formData.append('medicalProductData', JSON.stringify(value));
            if(selectedFile !== null){
                selectedFile.forEach((file) => {
                  formData.append("images", file);
                });
              }else{
                formData.append("images",[])
              }

            addFormData('/medical-products/new', formData, resetForm);
        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Clinic</p>) },
        { title: (<p className=' text-[12px] '>Medical Product</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <div className="w-full h-full">
                <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
                    {contextHolder}
                    <div className="w-[96%] mx-auto  ">
                        <form onSubmit={formik.handleSubmit} className="w-full ">
                        <div className='flex gap-8 md:flex-nowrap p-6  mt-4  bg-white rounded-lg'>
                            
                            {/* <div className='flex flex-wrap md:flex-nowrap pb-4 bg-white'> */}
                            {/* <div className='w-full md:w-1/2 rounded-md border border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 flex-col'>

                                <h3 className=" text-[#231942] w-full text-center capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"Product Image"}</h3>

                                    <CustomImgUpload
                                        borderStyle="dashed"
                                        isRemove={true}
                                        width='w-full mx-auto'
                                        justify='justify-center'
                                        removeFunction={handleImageRemove}
                                        handleChange={handleChangeImg}
                                        preview={image.preview || createNew.image || ""}
                                    />
                                </div> */}
                                <div className='w-full'>
                                    <div className='flex flex-wrap w-full'>
                                        <NuInputText 
                                        label='Product Name'
                                         placeholder='Product Name' 
                                         formik={formik}
                                          name='productName'
                                           width='w-full md:w-1/3 md:-mt-2' />

                                            <NuSelect
                                            label="Category"
                                            options={Category}
                                            formik={formik}
                                            placeholder="Choose"
                                            name="category"
                                            width="w-full md:w-1/3 md:-mt-2"
                                            />
                                             <NuSelect
                                            label="Sub-Category"
                                            options={SubCategory}
                                            formik={formik}
                                            placeholder="Choose"
                                            name="subCategory"
                                            width="w-full md:w-1/3 md:-mt-2"
                                            />


                                        <NuInputText
                                         label='Product code' 
                                         placeholder='Product code'
                                          formik={formik}
                                           name='productCode'
                                            width='w-full md:w-1/3 md:-mt-2' />
                                            <NuInputNumber
                                         label='Unit' 
                                         placeholder='Unit'
                                          formik={formik}
                                           name='unit'
                                            width='w-full md:w-1/3 md:-mt-2' />
                                            <NuInputText 
                                        label='Brand'
                                         placeholder='Brand' 
                                         formik={formik}
                                          name='brand'
                                           width='w-full md:w-1/3 md:-mt-2' />
                                           <NuInputText 
                                        label='Manufacturer'
                                         placeholder='Manufacturer' 
                                         formik={formik}
                                          name='manufacturer'
                                           width='w-full md:w-1/3 md:-mt-2' />
                                           <Attachement
                  handleChange={handleImageChange}
                  preview={images||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
                                    </div>
                                    
                                </div>
                            </div>
                            
                           
                            <div className="w-full md:block mx-auto my-4 mt-8 ">
                                <div className="md:flex justify-end gap-4">
                                    <CancelButton handleClick={() => { Oncancel() }} />
                                    <SubmitButton isLoading={isLoading} />
                                </div>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
 </div>
  )
}

export default CreateProduct