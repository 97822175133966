import React, { useEffect, useState } from "react";
import { NormalHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import NuDate from "../../../../Components/Utils/NuDate";


function ViewMovements() {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const location = useLocation();
  const data = location.state.data;
    return (
      <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: (<p className=' text-[12px] '>Listing</p>) },
            { title: (<p className=' text-[12px] '>Movements</p>) },
            { title: (<p className=' text-[12px] '>View</p>) },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/listing/movements/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/listing/movements/${officeId}/edit/${data?.movementId}`} state={{data: data, from:`/main/listing/movements/${officeId}/view/${data?.movementId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-120px)] bg-white rounded-xl overflow-hidden">
              <h2 className='text-base font-normal text-[#111537] p-3'>Details</h2>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-5 gap-x-8 m-10">
                <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.movementId || '-'}</p>
                  </div>
                <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Employees</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.employeeName|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.date && NuDate({value:data?.date,format:"DD-MM-YYYY"})||""}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>From Place</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.fromPlace || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.status || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>To Camp</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.campName|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Total Employees</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.totalEmployees || '-'}</p>
                  </div>
                
                 
                  </div>
                  
                  
                  
                  </div>
                  </div>
          </div>
          
   
    );
}

export default ViewMovements