import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const MenuByDays = () => {
  return (
    <SettingsMetaData propertyName={"MenuByDays"} viewPropertyName={"Menu By Days"} />

  )
}

export default MenuByDays