import React from "react";

export  function TextXS({
  color = "gray-800",
  truncate,
  wrap = "wrap",
  whitespace = "normal",
  content,
  align
}) {
  return (
    <p
      className={` text-xs text-${align} font-normal text-${color} text-${wrap} whitespace-${whitespace} ${
        truncate ? "truncate" : ""
      }`}
    >
      {content}
    </p>
  );
}

export  function TextSM({
    color = "text-gray-800",
    truncate,
    wrap = "text-wrap",
    whitespace = "whitespace-normal",
    content,
    align
  }) {
    return (
      <p
        className={` text-sm ${align} font-normal ${color} ${wrap} ${whitespace} ${
          truncate ? "truncate" : ""
        }`}
      >
        {content}
      </p>
    );
  }

  export  function TextBase({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left"
  }) {
    return (
      <p
        className={` text-base text-${align} font-normal text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        }`}
      >
        {content}
      </p>
    );
  }

  export  function TextXL({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left"
  }) {
    return (
      <p
        className={` text-xl text-${align} font-normal text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        }`}
      >
        {content}
      </p>
    );
  }

  export  function TextXXL({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left"
  }) {
    return (
      <p
        className={` text-2xl text-${align} font-normal text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        }`}
      >
        {content}
      </p>
    );
  }
