import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { CreateButton, FilterButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import UserCard from './userCard';

function Index() {
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState('');
  const  { data, totalCount, getData } = UseGetData(1,20,'/user/all-users');
  // console.log('ddd',data);
  
  // const userAccess = true;JSON.parse(localStorage.getItem("userAccess"));
  let AllUserCreateAccess = true;//userAccess
  // ? userAccess.some((permission) => permission.permissionName == "Settings_Alluser_create")
  // : false;
  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full h-[46px] flex flex-wrap lg:flex-nowrap justify-between items-center'>
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Settings</p>) },
          { title: (<p className=' text-[12px] '>Users</p>) }
        ]} />
        <div className='lg:flex gap-2 hidden'>
          <SearchBar />
          <FilterButton height='8' />
          <CreateButton label='Import' />
          {AllUserCreateAccess && <Link to={`/settings/users/create`}><CreateButton label='User' /></Link>}
        </div>
      </div>
      <div className='block lg:hidden flex justify-between  gap-2'>
          <div className='w-1/2'>
            <SearchBar />
          </div>
          <div className='flex w-1/2 justify-around'>
            <FilterButton height='8' />
            <CreateButton label='Import' />
            {AllUserCreateAccess && <Link to={`/settings/users/create`}><CreateButton label='User' /></Link>}
          </div>
      </div>
      {/* <NormalHeading title={'Users'}/> */}
      <div className='w-full flex justify-start flex-wrap gap-x-8 gap-y-3 pt-4'>
        {data &&
          data.map((item, idx) => {
            return (<UserCard fetcher={getData} item={item} key={idx}/>);
          })}
      </div>
    </div>
  )
}

export default Index