import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Modal } from 'antd';

function ViewDelivaryNotes() {
  const { officeId } = useParams();
  const [url,setUrl] = useState(false);

 
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
  const location = useLocation();
  const data = location.state.data;
  console.log("find",data.agendaItems)
  return (
    <>
    <div className="bg-[#E6F2F2] w-full h-full px-3 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/main/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Procurement</p> },
            { title: <p className=" text-[12px] ">Delivery Notes</p> },
            { title: <p className=" text-[12px] ">View</p> },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/procurment/delivary-notes/${officeId}`}>
            <BackButton link={true} />
          </Link>
          <Link
            to={`/main/procurment/delivary-notes/${officeId}/edit/${data?.deliveryNoteId}`}
            state={{
              from: `/main/procurment/delivary-notes/${officeId}`,
              data: data,
            }}
          >
            <EditButton height="8" />
          </Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-y-scroll p-3">
        <h3 className="text-[#212121] font-semibold pb-3"> Delivery Notes Details</h3>
        <div className="flex flex-wrap gap-y-5 w-full">
        <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved By</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.approvedBy|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved By Name</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.approvedByName|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approval Status</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.approvalStatus || '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.date|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'> Days Elapsed</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.daysElapsed|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Delivered On</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.deliveredOn|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Delivery Note Id</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.deliveryNoteId|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Delivery Person Name</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.deliveryPersonName|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>DeliveryStatus</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.deliveryStatus|| '-'}</p>
          </div> 
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Delivery Vechicle Type</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.deliveryVechicleType|| 'True'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>DnRef</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.dnRef|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Id Number</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.idNumber|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>IdType</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.idType|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>PoRef</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.poRef|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reviewed By</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.reviewedBy|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ReviewedBy Name</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.reviewedByName|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Verified By</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.verifiedBy|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Verified By Name</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.verifiedByName|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Way Bill No</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.wayBillNo|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>PoRef</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.poRef|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Country Code</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mobile?.countryCode|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Display</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mobile?.display|| '-'}</p>
          </div>
          <div className="w-1/4">
          <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Number</p>
          <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mobile?.number|| '-'}</p>
          </div>

         
         
          
          
          
          
          
          
         
         
         
          
        </div>
        {/* <h3 className='mt-3'>AgendaItems</h3>
        <div className="w-2/12 h-28  rounded-md p-1 flex flex-col border border-solid overflow-y-scroll mt-2">
        
        { data?.agendaItems?.map((item)=>{
          console.log("hhh",item);
          return(
               
                <p className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">{item||"-"}</p>
              
          )
        })}
        </div> */}
      <h3 className='mt-5'>Delivery Notes Attachement Files</h3>
                  <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
  { data?.attachments?.map((imgs, idx) => {
                                      console.log('img', imgs);
                                      return (imgs.file===null ? <p>Attachements not found</p>:
                                          <div
                                              className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                              key={idx}
                                              style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                          >
                                              <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                              // onClick={() => handleImageClick(imgs)}
                                              onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                              >
                                                  {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                  <img src={imgs?.file} alt={imgs?.file} />
                                              </div>
                                          </div>
                                      );
                                  })}
                                  </div>
                                  
                                  <div className='w-full '>
                                        <div className='w-full py-[1%] bg-[#048178] flex justify-between rounded-lg p-4'>
                                        
                                         <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Product Name
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Product Id
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Ordered Qty
                                        </p>
                                       
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Packed Qty
                                        </p>
                                        
                                       
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Product Unit
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Remaining Qty
                                        </p>
                                        </div>
                                        <div className='overflow-scroll h-[340px]'>
                                        {data?.products?.map((data,index)=>{
                                  return(
                                    <div key={index} className="w-full py-2 flex justify-between">
                                    {/* <p className=" w-[20%] text-center text-black text-sm font-light py-1">
                                      {data?.quoteProductId}
                                    </p> */}
                                    <p className=" w-[25%] text-center text-black text-sm font-light py-1">
                                      {data?.product_name}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light py-1">
                                      {data?.productId}
                                      </p>
                                      <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.orderedQty}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.packedQty} 
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.product_unit} 
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.remainingQty} 
                                    </p>
                                    </div>
                                  );
                                })}
                                </div>
                </div>
                                  
    </div>
    </div>
    <Modal
      open={showFilterDialog}
      title={"Medical Products"}
      width={"80%"}
      
      className="my-modal"
      onCancel={closeFilterDialog}
      footer={[]}
    >
      <div className='w-full h-[80vh] overflow-hidden'>
      <img src={url} alt="" className='' width={"100%"} />
      </div>
      
         </Modal>
    </>
  );
}

export default ViewDelivaryNotes