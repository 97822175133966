import React, { useState } from 'react';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { useFormik } from 'formik';
import * as yup from 'yup';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import { NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectwithSearch, YesOrNoButton } from '../../../../Components/Utils/NuInput';
import { CountryMIN } from '../../../../assets/CountryMini';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';

const EditCamp = () => {
    const {officeId} = useParams();
    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    // console.log(from,'from');
    // const { data: branches } = UseDropDown('/company_branch/drop-down');
    const { data: campBosses } = UseDropDown('/employees/camp-boss/drop-down');
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
        city: data?.city,
        state: data?.state,
        country: data?.country,
        postalCode: data?.postalCode,
        campName: data?.campName,
        campNo: data?.campNo,
        campBoss: data?.campBoss,
        layoutDrawing: data?.layoutDrawing,
        branchId: officeId,

        accomodation: data?.facilities?.accomodation,
        dining: data?.facilities?.dining,
        kitchen: data?.facilities?.kitchen,
        laundary: data?.facilities?.laundary,
        recreation: data?.facilities?.recreation,
        medical: data?.facilities?.medical,
        adminOffice: data?.facilities?.adminOffice,
        security: data?.facilities?.security,
        swimmingPool: data?.facilities?.swimmingPool,
        basketballCourt: data?.facilities?.basketballCourt,
        badmintonCourt: data?.facilities?.badmintonCourt,
        tennisCourt: data?.facilities?.tennisCourt,
        footballCourt: data?.facilities?.footballCourt,
        cctvSystem: data?.facilities?.cctvSystem,
        fireProtection: data?.facilities?.fireProtection,
        ambulance: data?.facilities?.ambulance,
        email: data?.email,
        countryCode: data?.mobile?.countryCode,
        mobile: data?.mobile?.number,
        height: data?.layoutArea?.height,
        width: data?.layoutArea?.width,
        // join_date: "",
        // assignedPro: "",
        // releiving_date: "",
    });
    console.log(officeId,'from');

    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };

    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
        setCreateNew(prevState => ({ ...createNew, layoutDrawing: "" }));
        formik.values.layoutDrawing = '';
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            email: yup.string().required('Email is required.'),
            branchId: yup.string().required('Please select one branch'),
            campBoss: yup.string().required('Please select Camp Boss'),
        }),
        onSubmit: async (value, { resetForm }) => {
            let neValue = {
                ...value, height: parseFloat(value?.height || '0.00').toFixed(2),
                width: parseFloat(value?.width || '0.00').toFixed(2)
            }
            let formData = new FormData();
            formData.append('json_data', JSON.stringify(neValue));
            formData.append('layoutDrawing', image.raw);

            editFormData(`/camp/${data?.campId}`, formData, resetForm, from);
        }
    })
    return (
        <div className="w-full h-full p-2 bg-[#F1F2FB]">
            <div className="flex justify-between px-4">
                <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Accomodation</p>) },
                    { title: (<p className=' text-[12px] '>Camp-Info</p>) },
                    { title: (<p className=' text-[12px] '>Edit</p>) },
                ]} />
                <BackButton />
            </div>
            <div className="w-full h-full">
                <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
                    {contextHolder}
                    <div className="w-[96%] mx-auto ">
                        <form onSubmit={formik.handleSubmit} className="w-full">
                            <NormalHeading title={'Layout Area'} />
                            <div className='flex flex-wrap md:flex-nowrap pb-4'>
                                <div className='w-full md:w-1/3 rounded-md border border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 justify-center items-center'>
                                    <CustomImgUpload
                                        borderStyle="dashed"
                                        isRemove={true}
                                        width='w-1/3 md:w-1/2'
                                        removeFunction={handleImageRemove}
                                        handleChange={handleChangeImg}
                                        preview={image.preview || createNew.layoutDrawing || ""}
                                    />
                                </div>
                                <div className='w-full'>
                                    <div className='flex w-full'>
                                        <NuInputText label='Camp Name' placeholder='Camp Name' formik={formik} name='campName' width='w-full md:w-1/2 md:-mt-2' />
                                        <NuInputNumber label='Camp Number' placeholder='Camp Number' formik={formik} name='campNo' width='w-full md:w-1/2 md:-mt-2' />
                                    </div>
                                    <div className='flex w-full'>
                                        <NuInputNumber label='Height' placeholder='0.00' formik={formik} name='height' width='w-full md:w-1/2' />
                                        <NuInputNumber label='Width' placeholder='0.00' formik={formik} name='width' width='w-full md:w-1/2' />
                                    </div>
                                </div>
                            </div>
                            <NormalHeading title={'Address'} />
                            <div className="w-full flex justify-start gap-y-2 content-start pb-4 flex-wrap">
                                <NuSelectwithSearch
                                    label="Country"
                                    options={CountryMIN}
                                    formik={formik}
                                    placeholder="Choose"
                                    name="country"
                                    width="w-full md:w-1/4"
                                />
                                <NuInputText label='State' placeholder='state' formik={formik} name='state' width='w-full md:w-1/4' />
                                {/* <NuInputText label='District' placeholder='district' formik={formik} name='district' width='w-1/4' /> */}
                                <NuInputText label='City' placeholder='city' formik={formik} name='city' width='w-full md:w-1/4' />
                                {/* <NuInputText label='Street Name' placeholder='streetName' formik={formik} name='streetName' width='w-full md:w-1/3' /> */}
                                <NuInputNumber label='Postal Code' placeholder='postalCode' formik={formik} name='postalCode' width='w-full md:w-1/4' />
                                {/* <NuInputText label='Name' placeholder='name' formik={formik} name='name' width='w-full md:w-1/4' /> */}
                                <NuInputEmail label='Email' placeholder={'example@email.com'} formik={formik} name='email' isRequired={true} width={'w-full md:w-1/4'} />
                                <NuMobileNumber
                                    nunCode={"countryCode"}
                                    label="Mobile number"
                                    placeholder="Mobile number"
                                    formik={formik}
                                    name="mobile"
                                    width="w-full md:w-1/4"
                                />
                                {/* <NuSelect
                                    isRequired={true}
                                    label='Branch'
                                    options={branches}
                                    formik={formik}
                                    placeholder='Choose'
                                    name='branchId'
                                    width='w-full md:w-1/4'
                                /> */}
                                <NuSelect
                                    isRequired={true}
                                    label='Camp Boss'
                                    options={campBosses} 
                                    formik={formik}
                                    placeholder='Choose'
                                    name='campBoss'
                                    width='w-full md:w-1/4'
                                />
                            </div>
                            <NormalHeading title={'Facilities'} />
                            <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                <YesOrNoButton label={'Accomodation'} name={'accomodation'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Dining'} name={'dining'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Kitchen'} name={'kitchen'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Laundary'} name={'laundary'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Recreation'} name={'recreation'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Medical'} name={'medical'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Administrative Office'} name={'adminOffice'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Security'} name={'security'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Swimming Pool'} name={'swimmingPool'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Basketball Court'} name={'basketballCourt'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Badminton Court'} name={'badmintonCourt'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Tennis Court'} name={'tennisCourt'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Football Court'} name={'footballCourt'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'CCTV System'} name={'cctvSystem'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Fire Protection'} name={'fireProtection'} formik={formik} width={'w-full md:w-1/6'}/>
                                <YesOrNoButton label={'Ambulance'} name={'ambulance'} formik={formik} width={'w-full md:w-1/6'}/>
                            </div>
                            <div className="w-full md:block mx-auto my-4 mt-8 ">
                                <div className="md:flex justify-end gap-4">
                                    <CancelButton handleClick={() => { Oncancel() }} />
                                    <SubmitButton isLoading={isLoading} />
                                </div>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCamp;