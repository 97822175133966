import React from 'react'
import { Outlet } from 'react-router-dom'

function MainSetting() {
  return (
    <div className='w-full h-full'>
    <Outlet/>
</div>
  )
}

export default MainSetting