
import { useFormik } from 'formik';
import { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { NuDatePicker,  NuSelect, NuTextArea, NuInputText,NuInputNumber, NuDateTime, NuMultiSelect } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import NuDate from '../../../../Components/Utils/NuDate';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditMovements() {
  const { value: FromPlace } = useFetchMataData('FromPlace') 
  const { value: Status } = useFetchMataData('Status') 


  const {data:employees} = UseDropDown('/employees/drop-down')
  const {data:camp} = UseDropDown('/camp/drop-down')
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  
    const { movementId } = useParams();
    const location=useLocation()
    const data =location.state.data
    const from=location.state.from
    // console.log(location.state,'from');
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
      // date: data?.date,
      date: (data?.date && NuDate({value:data?.date,format:"YYYY-MM-DD"}) )||"",
      fromPlace: data?.fromPlace,
      toCamp: data?.toCamp,
      employees: data?.employees,
      status:data?.status,
      // manufacturer: data?.manufacturer,
      // equipmentType: data?.equipmentType,
      // warrentyPeriod: data?.warrentyPeriod,
      // warrentyExpDate: data?.warrentyExpDate,
      // calibExpDate: data?.calibExpDate,
      // calibReq: data?.calibReq,
      // condition: data?.condition,
      // comments: data?.comments,
    })
    console.log(data);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
      
            editFormData(`/movements/${data?.movementId}`, value, resetForm, from);

        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Listing</p>) },
        { title: (<p className=' text-[12px] '>Movements</p>) },
        { title: (<p className=' text-[12px] '>Edit</p>) },
    ]} />
    <BackButton/>
    </div>
 
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-135px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          <NuDatePicker
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
                label='From Place'
                 placeholder='choose'
                formik={formik}
                options={FromPlace}
              name='fromPlace'
             width='w-full md:w-1/2 lg:w-1/3' />
             <NuSelect
              label="To Camp"
              options={camp}
              placeholder="To Camp"
              formik={formik}
              name="toCamp"
              width="w-full md:w-1/3"
            />
             <NuMultiSelect
              // isRequired={true}
              label="Employees"
              options={employees}
              placeholder=" Employees"
              formik={formik}
              name="employees"
              width="w-full md:w-1/3"
            />
            <NuSelect
                                    label={'Status'}
                                    options={Status}
                                    placeholder={'Choose'}
                                    name={'status'}
                                    formik={formik}
                                    width={'w-full md:w-1/3'}
                                />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
  )
}

export default EditMovements