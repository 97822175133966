import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const PaymentStatus = () => {
  return (
    <SettingsMetaData propertyName={"PaymentStatus"} viewPropertyName={"Payment Status"} />

  )
}

export default PaymentStatus