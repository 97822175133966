import React from 'react';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';

const ViewPatients = () => {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const { patientsViewId } = useParams();
    const location = useLocation();
    const data = location.state.data;
    // console.log(data,'jjj');
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: (<p className=' text-[12px] '>Clinic</p>) },
        { title: (<p className=' text-[12px] '>Patients</p>) },
        { title: (<p className=' text-[12px] '>View</p>) },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/clinic/patients/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/clinic/patients/${officeId}/edit/${data.patientId}`} state={{data: data, from:`/main/clinic/patients/${officeId}/view/${data.patientId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
              <h2 className='text-base font-normal text-[#111537] p-3'>Patients Details</h2>
              <div className=" w-full flex  ">
              <div className='w-1/5 h-full  p-1 rounded-[5px]'>                   
                        <img src={data?.insurance?.file} alt="" className=' w-full '/>
                        </div>
                <div className=" w-full grid grid-cols-5  m-10">
                  <div className='w-full '>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.patientId || '-'}</p>
                  </div>
                  <div className='w-full '>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Gender</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.gender === 'M' ? 'Male' : data?.gender === 'F' ? 'Female' : ''}</p>
                  </div>
                  <div className='w-full '>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Nationality</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.nationality || '-'}</p>
                  </div>
                  <div className='w-full'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Problem Reported</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.probReported || '-'}</p>
                  </div>
                  <div className='w-full '>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Medicine And Dosage</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.medAndDosage || '-'}</p>
                  </div>
                  <div className='w-full '>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Revisit Requried</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.revisitReq ?'Yes':'No'}</p>
                  </div>
                  <div className='w-full'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Revisit Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.revisitDate || '-'}</p>
                  </div>
                  <div className='w-full '>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Medication History</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.medHistory || '-'}</p>
                  </div>
                  <div className='w-full'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Insurance Type</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.insuranceType || '-'}</p>
                  </div>
                  <div className='w-full '>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Insurance Expiry</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.insuranceExp || '-'}</p>
                  </div>
                  <div className='w-full'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.status || '-'}</p>
                  </div>
                  <div className='w-full'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments || '-'}</p>
                  </div>
                  </div>
                  </div>
          </div>
          
      </div>
  
  )
};
export default ViewPatients