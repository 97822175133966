import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { Link, useParams } from 'react-router-dom'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons'
import axiosInstance from '../../../../Services/axiosCommon'
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';

function ViewPurchaseOrder() {
    const { officeId } = useParams();
  const { purchaseOrderViewId } = useParams();
  const [data, setData] = useState([]);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    // campId: "",
    // buildName:"",
    // buildNo: "",
    // floors: ""
    poId: data?.poId,
    prId: data?.prId,
    vendorId: data?.vendorId,
    refNumber:data?.refNumber,
    totalPrice: data?.totalPrice,
    requestedDeliveryDate:data?.requestedDeliveryDate,
    requestedBy: data?.requestedBy,
    paymentStatus: data?.paymentStatus,
    status:data?.status,
    approvedBy:data?.approvedBy,
    supportDocType:data?.supportDocType,
    products:data?.products
   
})
const handleChangeImg = (e) => {
    if (e.target.files.length) {
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0],
        });
    }
};
const handleImageRemove = () => {
    setImage({
        preview: "",
        raw: "",
    });
}
  async function getBiding() {
    await axiosInstance
      .get(`/PO/${purchaseOrderViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getBiding();
  },[]);
  console.log(data,"data");
    return (
        <div className=' bg-[#E6F2F2] h-full'>
            <div className='mx-6 pt-3'>
                <BreadCrumb items={[
                    { title: <p className='text-[13px]'>Procurement</p> },
                    { title: <p className='text-[13px]'>Purchase Order</p> },
                    { title: <p className='text-[13px]'>View</p> },
                ]}
                />
            </div>
            <div className='flex justify-end'>
                <div className='w-1/2 flex justify-end'>
                    <div className='pr-6 flex gap-3'>
                        <div className='flex justify-end gap-2'>
                        <Link to={`/main/procurment/purchase-order/${officeId}`}><BackButton link={true} /></Link>
          <Link to={`/main/procurment/purchase-order/${officeId}/edit/${purchaseOrderViewId}`} state={{ from: `/main/procurment/purchase-order/${officeId}/view/${data.employee_id}`, data: data }}><EditButton height='8' /></Link>
       
                            </div>
                    </div>
                </div>
            </div>
            {/*  Section-1 */}
            <div className='px-6 py-5 flex  gap-5'>
                <div className='gap-2 px-5 py-3 w-full h-[75vh] overflow-scroll bg-[#FFF] rounded-[5px] p-5'>
                    {/* <h3 className='text-[#212121] text-sm font-semibold leading-normal'>Building Layout</h3> */}
                    
                    <div className=''>
                       
                        <div className='' />
                        <div className='w-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto'>
                            <div className='w-full grid grid-cols-5 gap-x-8 m-10'>
                            <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Id</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.poId || '-'}</p>
                                </div>
                            <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Purchase Id</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.prId || '-'}</p>
                                </div>
                                {/* <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Vendor Id</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.vendorId || '-'}</p>
                                </div> */}
                                {/* <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Ref Number</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.refNumber|| '-'}</p>
                                </div> */}
                                <div className='w-ful grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Requested By</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.requestedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Requested Delivery Date</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.requestedDeliveryDate || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Total Price</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.totalPrice || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Payment Status</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.paymentStatus || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Approval Status</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.status || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Approved By</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.approvedBy || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Support DocType</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.supportDocType || '-'}</p>
                                </div>
                                {/* <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Vendor Id</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.vendorId || '-'}</p>
                                </div> */}
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Vendor Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.vendorName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Verified By</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.verifiedBy || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Verified By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.verifiedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Ref</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.ref || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Requested By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.requestedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Reviewed By</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.reviewedBy || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Reviewed By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.reviewedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Approved By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.approvedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Billing Address</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.billingAddress || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Delivery Address</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.deliveryAddress || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Delivery Terms</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.deliveryTerms || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Payment Terms</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.paymentTerms || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Prepared By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.preparedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2 '>
                                    <h4 className='text-[#212121] py-3'>Comments</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.comments || '-'}</p>
                                </div>
                              
                            </div>
                           
                        </div>
                        <div className='w-full '>
                                        <div className='w-full py-[1%] bg-[#048178] flex justify-between rounded-lg p-4'>
                                        {/* <p className=" w-[20%] text-center text-white text-sm font-medium">
                                        Quote Product Id
                                        </p> */}
                                        <p className=" w-[20%] text-center text-white text-sm font-medium">
                                        Ordered Qty
                                        </p>
                                       
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Product Id
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Requested Qty
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Price
                                        </p>
                                        </div>
                                        <div className='overflow-scroll h-[340px]'>
                                        {data?.products?.map((data,index)=>{
                                  return(
                                    <div key={index} className="w-full py-2 flex justify-between">
                                    {/* <p className=" w-[20%] text-center text-black text-sm font-light py-1">
                                      {data?.quoteProductId}
                                    </p> */}
                                    <p className=" w-[20%] text-center text-black text-sm font-light py-1">
                                      {data?.orderedQty}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light py-1">
                                      {data?.productId}
                                      </p>
                                      <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.requestedQty}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.price} 
                                    </p>
                                    </div>
                                  );
                                })}
                                </div>
                </div>
                    </div>
                </div>
                
                
               
            </div>

          
          
        </div>
    )
}

export default ViewPurchaseOrder