import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import CustomImgUpload from "../../../../Components/Utils/CustomImgUpload";
import { NuInputText, NuSelect, NuInputNumber, NuLabel, NuInputEmail, NuMobileNumber, NuSelectwithSearch, NuDatePicker, NuTextArea } from "../../../../Components/Utils/NuInput";
import { CountryMIN, formattedBloodGroups } from "../../../../assets/CountryMini";
import { BackButton, CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { Link, useLocation, useParams } from "react-router-dom";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";


function EditGuest() {
  const { value: Designation } = useFetchMataData('Guest_Designation') 
  const { value: Status } = useFetchMataData('Status')
  const { value: IdType } = useFetchMataData('ID_Type_Guest')
  const { value: PurposeofStay} = useFetchMataData('PurposeofStay') 



    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    const { id } = useParams();
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
        city: data?.city,
        streetName: data?.streetName,
        district: data?.district,
        state: data?.state,
        country: data?.country,
        postalCode: data?.postalCode,
        name: data?.name,
        bloodGroup: data?.bloodGroup,
        designation: data?.designation,
        dob: data?.dob,
        gender: data?.gender,
        email: data?.email,
        religion: data?.religion,
        countryCode: data?.mobile?.countryCode,
        mobile: data?.mobile?.number,
        image: data?.image?.file,
        purpose_of_stay: data?.purpose_of_stay,
        company_name: data?.company_name,
        proof_id: data?.proof_id,
        idType: data?.idType,
        comments: data?.comments,
        status: data?.status,
        cpName: data?.cpName,
        cpEmail: data?.cpEmail,
        // cpCountryCode: data?.cpPhone?.countryCode,
        // cpPhone: data?.cpPhone?.number,
        cpPhone: data?.cpPhone,
        cpCountryCode: data?.cpCountryCode,
        cpDesignation: data?.cpDesignation,
        oldImage: data?.image?.file
    })
    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };
    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
        setCreateNew(prevState => ({ ...createNew, oldImage: "" }))
        formik.values.oldImage= ""
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            let formData = new FormData();
            formData.append('data', JSON.stringify(value));
            formData.append('guestImage', image.raw);

            editFormData(`/guest/edit/${id}`, formData, resetForm, from);
        }
    })
    return (
        <div className="w-full h-full p-2 bg-[#F1F2FB]">
            <div className="flex justify-between px-4">
            <BreadCrumb items={[
                {
                    title: (<Link to="/main/dashboard">
                        <Dashboard color="gray" width={10} height={10} />{" "}
                    </Link>)
                },
                { title: (<p className=' text-[12px] '>Listing</p>) },
                { title: (<p className=' text-[12px] '>Guests</p>) },
                { title: (<p className=' text-[12px] '>Edit</p>) },
            ]} />
            <BackButton/>
            </div>
            <div className="w-full h-full">
                <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
                    {contextHolder}
                    <div className="w-[96%] mx-auto ">
                        <form onSubmit={formik.handleSubmit} className="w-full">
                        {/* <NuLabel textColor='text-slate-700' label={`Emplyee ID: ${id}`} /> */}
                        <CustomImgUpload
                            borderStyle="dashed"
                            isRemove={true}
                            width='w-full md:w-1/2'
                            removeFunction={handleImageRemove}
                            handleChange={handleChangeImg}
                            preview={image.preview || createNew?.oldImage || ""}
                        />
                        <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                            <NuInputText label='Name' placeholder='name' formik={formik} name='name' width='w-full md:w-1/3' />
                            <NuInputEmail label='Email' placeholder={'example@email.com'} formik={formik} name='email' isRequired={true} width={'w-full md:w-1/3'} />
                            <NuMobileNumber
                                nunCode={"countryCode"}
                                label="Mobile number"
                                placeholder="Mobile number"
                                formik={formik}
                                name="mobile"
                                width="w-full md:w-1/3"
                            />
                            <NuInputText label='Gender' placeholder='M/F/T' formik={formik} name='gender' width='w-full md:w-1/3' />
                            <NuInputText label='Religion' placeholder='Religion' formik={formik} name='religion' width='w-full md:w-1/3' />
                            <NuDatePicker
                                label={'DOB'}
                                formik={formik}
                                width={'w-full md:w-1/3'}
                                name={'dob'}
                            />
                            {/* <NuInputText label={'Blood Group'} placeholder={'AB-'} formik={formik} name={'bloodGroup'} width={'w-full md:w-1/3'} /> */}
                            <NuSelectwithSearch 
                                options={formattedBloodGroups}
                                label={'Blood Group'} 
                                placeholder={'AB-'} 
                                formik={formik} 
                                name={'bloodGroup'} 
                                width={'w-full md:w-1/3'}
                            />
                            <NuInputText 
                            label='Desigination'
                            options={Designation}
                            placeholder='Designation' 
                            formik={formik} name='designation'
                            width='w-full md:w-1/3' />
                            <NuSelect
                                label='Purpose of Stay'
                                options={PurposeofStay}
                                formik={formik}
                                placeholder='Choose'
                                name='purpose_of_stay'
                                width='w-full md:w-1/3'
                            />
                            <NuInputText
                                placeholder={'Company Name'}
                                label={'Company Name'}
                                name={'company_name'}
                                formik={formik}
                                width={'w-full md:w-1/3'}
                            />
                            <NuInputText
                                label={'Id No'}
                                placeholder={'Id'}
                                name={'proof_id'}
                                formik={formik}
                                width={'w-full md:w-1/3'}
                            />
                            <NuSelect
                                label={'Id Type'}
                                options={IdType}
                                placeholder={'Choose'}
                                name={'idType'}
                                formik={formik}
                                width={'w-full md:w-1/3'}
                            />
                            <NuSelect
                                label={'Status'}
                                options={Status }
                                placeholder={'Choose'}
                                name={'status'}
                                formik={formik}
                                width={'w-full md:w-1/3'}
                            />
                            {/* <NuTextArea
                                label={'Comments'}
                                name={'comments'}
                                placeholder={'Write your comments here...'}
                                formik={formik}
                                width={'w-full md:w-1/2'}
                            /> */}
                        </div>
                        <p className="text-xs font-semibold text-[#000000] pt-3">Address</p>
                        <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                            <NuSelectwithSearch
                                label="Country"
                                options={CountryMIN}
                                formik={formik}
                                placeholder="Choose"
                                name="country"
                                width="w-full md:w-1/3"
                            />
                            <NuInputText label='State' placeholder='state' formik={formik} name='state' width='w-full md:w-1/3' />
                            <NuInputText label='District' placeholder='district' formik={formik} name='district' width='w-1/3' />
                            <NuInputText label='City' placeholder='city' formik={formik} name='city' width='w-full md:w-1/3' />
                            <NuInputText label='Street Name' placeholder='streetName' formik={formik} name='streetName' width='w-full md:w-1/3' />
                            <NuInputNumber label='Postal Code' placeholder='postalCode' formik={formik} name='postalCode' width='w-full md:w-1/3' />
                        </div>
                        <p className="text-xs font-semibold text-[#000000] pt-3">Contact Person</p>
                        <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                            <NuInputText label='Name' placeholder='Name' formik={formik} name='cpName' width='w-full md:w-1/3' />
                            <NuInputText label='Email' placeholder='example@email.com' formik={formik} name='cpEmail' width='w-full md:w-1/3' />
                            <NuMobileNumber nunCode='cpCountryCode' name='cpPhone' label='Mobile' placeholder='Mobile number' formik={formik} width='w-full md:w-1/3' />
                            <NuInputText label='Designation' placeholder='Designation' formik={formik} name='cpDesignation' width='w-full md:w-1/3' />
                        </div>
                        <div className="w-full md:block mx-auto my-4 mt-8 ">
                            <div className="md:flex justify-end gap-4 px-3">
                                <CancelButton handleClick={() => { Oncancel() }} />
                                <SubmitButton isLoading={isLoading}/>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditGuest;