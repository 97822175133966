import { useFormik } from 'formik';
import { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate,useNavigate, useLocation, useParams } from 'react-router-dom';
import { NuDatePicker,  NuSelect, NuTextArea, NuInputText,NuInputNumber } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditVehicles() {
  const { value: FuelType} = useFetchMataData('FuelType') 
  const { value: VehicleType} = useFetchMataData('VehicleType')
  const { value: Status_Vechile} = useFetchMataData('Status_Vechile')
  const navigate=useNavigate()
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const {data:employees} = UseDropDown('/employees/drop-down')
  const {data:vendors} =UseDropDown('/vendors/drop-down')

  
    const { EditVehiclesId } = useParams();
    const location=useLocation()
    const data =location.state.data
    const from=location.state.from
    // console.log(location.state,'from');
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
      allocatedTo: data?.allocatedTo,
      companyId: data?.companyId,
      name: data?.name,
      brand: data?.brand,
      model: data?.model,
      yearOfMan: data?.yearOfMan,
      fuelType: data?.fuelType,
      purchasedOn: data?.purchasedOn,
      condition: data?.condition,
      plateNo: data?.plateNo,
      chasisNo: data?.chasisNo,
      assignedTo: data?.assignedTo,
      comments: data?.comments,
      vendor: data?.vendor,
      vehicleType: data?.vehicleType,
      status: data?.status
       
    })
    console.log(data);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
      
            editFormData(`/vehicle/edit-vehicle/${EditVehiclesId}`, value, resetForm, from);

        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Invontary</p>) },
        { title: (<p className=' text-[12px] '>Vechiles</p>) },
        { title: (<p className=' text-[12px] '>Edit</p>) },
    ]} />
    <BackButton/>
    </div>
 
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          <NuSelect
              label="Status"
              options={Status_Vechile}
              formik={formik}
              placeholder="Choose"
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />  
          <NuSelect
              label="Allocated To"
              // options={user}
              options={employees}
              
              formik={formik}
              placeholder="Choose"
              name="allocatedTo"
              width="w-full md:w-1/2 lg:w-1/3"
          />

          <NuSelect
              label="Company Id"
              options={[
                { value: 1, label: "Company" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="companyId"
              width="w-full md:w-1/2 lg:w-1/3"
          />  

            <NuInputText
              // isRequired={true}
              label="Name"
              placeholder="Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Brand"
              placeholder=" Menu By Days"
              formik={formik}
              name="brand"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Modal"
              placeholder=" Menu By Days"
              formik={formik}
              name="model"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              label="Year Of Manufacture"
              placeholder="year"
              formik={formik}
              name="yearOfMan"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label="Fuel Type"
              options={FuelType}
              placeholder=" Fuel Type"
              formik={formik}
              name="fuelType"
              width="w-full md:w-1/3"
            />

            <NuDatePicker
              label="Purchased On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="purchasedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Condition"
              placeholder=" Menu By Days"
              formik={formik}
              name="condition"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Plate No"
              placeholder=" Menu By Days"
              formik={formik}
              name="plateNo"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Chasis No"
              placeholder=" Menu By Days"
              formik={formik}
              name="chasisNo"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Assigned To"
              // options={user}
              options={employees}
              formik={formik}
              placeholder="Choose"
              name="assignedTo"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          
            <NuSelect
              label="Vendor"
              options={vendors}
              formik={formik}
              placeholder="Choose"
              name="vendor"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuSelect
              label="Vehicle Type"
              options={VehicleType}
              formik={formik}
              placeholder="Choose"
              name="vehicleType"
              width="w-full md:w-1/2 lg:w-1/3"
          />

            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
  )
}

export default EditVehicles