import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const DineType = () => {
  return (
    <SettingsMetaData propertyName={"DineType"} viewPropertyName={"Dine Type"} />

  )
}

export default DineType