import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const InsuranceType = () => {
  return (
    <SettingsMetaData propertyName={"InsuranceType"} viewPropertyName={"Insurance Type"} />

  )
}

export default InsuranceType