import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectCurrency, NuTextArea } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Navigate,useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function CreateServiceRequest() {
  const { value: value } = useFetchMataData('RequestedService')

  const navigate=useNavigate()
  const {data:occupant} =UseDropDown('/occupants/drop-down')
    // const [division, setDivision] = useState([]);
    
    const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData ();
    const [createNew, setCreateNew] = useState({
        reqType: "RoomService",
        attendedOn:"",
        requestedService: "",
        requestedOn: "",
        occupantId:"",
        comments: ""
    })
   

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
           

            addFormData('/request/new-request', value, resetForm);
        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Accommodation</p>) },
        { title: (<p className=' text-[12px] '>Service-Request</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
            {/* <NuInputText
              isRequired={true}
              label=" Name"
              placeholder=" Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/3"
            />
              <NuInputNumber
              label="Id"
              placeholder="Id"
              formik={formik}
              name="roomserviceId"
              width="w-full md:w-1/3"
            /> */}
            <NuSelect
              label="Occupant Id"
              options={occupant}
              formik={formik}
              placeholder="Choose"
              name="occupantId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
{/*           
            <NuInputNumber
              label="Room No"
              placeholder="Room No"
              formik={formik}
              name="Room No"
              width="w-full md:w-1/3"
            />
             <NuInputNumber
              label="Bed No"
              placeholder="Bed No"
              formik={formik}
              name="Bed No"
              width="w-full md:w-1/3"
            /> */}
             <NuSelect
              label="Requested Service"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="requestedService"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDatePicker
              label="Requested on"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="requestedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDatePicker
              label="Attended on"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="attendedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          {/* <NuSelect
              label="Status"
              options={[
                { value: "1", label: "A" },
                { value: "2", label: "B" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="Status"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
                    </div>
               
  )
}

export default CreateServiceRequest