import React from "react"
import { Route } from "react-router-dom"
import CreateIncidents from "../Views/Web/Incidents/CreateIncidents"
import EditIncidents from "../Views/Web/Incidents/EditIncidents"
import ViewIncidents from "../Views/Web/Incidents/ViewIncidents"
import IndexIncident from "../Views/Web/Incidents/index"
import MainIncidents from "../Views/Web/Incidents/MainIncidents"

const IncidentsRoutes = () => {
    return(
        <Route path='incidents/:officeId' element={<MainIncidents />}>
         <Route index element={<IndexIncident/>} />
         <Route path='create' element={<CreateIncidents />} />
         <Route path='edit/:EditIncidentsId' element={<EditIncidents />} />
         <Route path='view/:ViewIncidentsId' element={<ViewIncidents />} />
       </Route>
    )
}

export default IncidentsRoutes;