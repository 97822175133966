import { useFormik } from "formik";
import { useToast } from "../../../../../Components/Others/ToastServices";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../../Services/axiosCommon";
import { NuInputSetting } from "../../../../../Components/Utils/NuInput";
import { TextMediumXS } from "../../../../../Components/Text/MediumText";
import SubCategory from "./SubCategory";


const Category = () => {
  const [data, setData] = useState([]);
  const [subData, setSubData] = useState([]);
  const toast = useToast();
  const [createNew, setCreateNew] = useState({
    module: `ProductCategory`,
    category: ""
  });
  const [activeId, setActiveId] = useState(null);
  const [category,setCategory] = useState("");
  console.log("active",activeId);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
      // category: yup.string().required('Status is Required'),
    // }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance.post(`/category/new-category`, value)
              .then(res => {               
                  if (res.data) {
                      console.log("Created", resetForm)
                      resetForm();
                      GetData();
                      toast.open({
                        type:"success",
                        message: `${createNew?.module}  Added successfully`,
                        // description:'',
                        // placement: 'bottomRight',
                    });
                  }
                  else {
                      console.log("Failed")
                      toast.open({
                        type:"error",
                        message: `${createNew?.module} Added failed`,
                        // description: response.data.msg || 'Network error',
                        // placement: 'bottomRight',
                    });
                  }
              })    
              .catch((err)=>{
                console.log(err);
              })
    },
  });
  const GetData = async () => {
    await axiosInstance.get(`/category/${createNew?.module}`).then(res => {
        console.log("cat", res.data?.data)
        let data = res.data?.data;
        res.data?.data && setData(data) 
        if (data?.length > 0) {
          setActiveId(data[0].id);
        }         
    })
}

const handleDelete = (value) => {
  axiosInstance.delete(`/category/${value}`)
    .then(res => {
      if (res?.status == 200) {
        GetData();
        toast.open({
          type:"success",
          message: `${createNew?.module}  Deleted successfully`,
          // placement: 'bottomRight',
        });
      }
      else {
        console.log("Failed")
        toast.open({
          type:"error",
          message: `${createNew?.module} Deleted failed`,
          // placement: 'bottomRight',
        });
      }
    })
    .catch((err)=>{
      console.log(err);
    })
}

useEffect(() => {
  GetData();
}, [])
  return (
    <div className="w-full flex flex-wrap justify-center md:justify-start  items-center">
    <div className=" w-full md:w-1/2 lg:w-1/3 mt-4">
      <form onSubmit={formik.handleSubmit}>
        <div className={`w-full text-xs`}>
          <div className="px-3 mb-1">
            <label className="font-medium text-textColor-500">
            Product Category
            </label>
            <NuInputSetting
              type="text"
              placeholder="Product Category"
              formik={formik}
              name="category"
            />
          </div>
        </div>
      </form>
      <div className=" px-3">
        <div className="w-full rounded-lg h-44 bg-[#EEF4F6] overflow-x-hidden overflow-y-auto">
          <ul className="">
            {data.map((List, idx) => {
                return (
            <li
              className={` m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50 ${
                activeId === List?.id ? "bg-blue-200" : "bg-[#B3EBD7]"
              }`}
              key={idx}
              onClick={() => {
                setActiveId(List?.id);
                setCategory(List?.name);
              }}
            >
               <TextMediumXS color={"text-[#009858]"} content={List?.name || "-"} />
              {List?.id && (
              <span
                onClick={() => {
                  handleDelete(List?.id);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
               )} 
            </li>
             );
              })} 
          </ul>
        </div>
      </div>
    </div>
    <div className='w-full md:w-1/2 lg:w-1/3 mt-4'>
     <SubCategory id={activeId} category={category} module={"ProductCategory"}/>
 </div>
 </div>
  );
};

export default Category;
