import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { CancelButton, CreateButton, DeleteButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import SearchBar from '../../../../Components/Utils/SearchBar';
import RightArrow from '../../../../Components/Icons/RightArrow';
import image from '../../../../assets/images/loginPage.jpg';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { NuInputNumber, NuSelect } from '../../../../Components/Utils/NuInput';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { Modal } from 'antd';
import Pagination from '../../../../Components/Utils/Pagenation';

const CampInfo = () => {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(20);
    // const { data, getData, totalCount } = UseGetData(page, pageCount, '/camp/');
    // const[params,setParams] = useState([])
    const [params, setParams] = useState({
        page: page,
        per_page: pageCount,
        search: "",
      });
      const { data, totalCount, getData } = UseGetDataSearch( '/camp/',params);
      console.log("ggg",data);
    
      useEffect(()=>{
        setParams({...params,page:page,per_page:pageCount})
       },[page,pageCount])
    const { contextHolder, handle } = useDeleteData(getData);
    const handleDelete = async (removevalue,id) => handle(`/camp/${id}`, removevalue, 'Camp');
    const { data: branches } = UseDropDown('/company_branch/drop-down');
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filter, setFilter] = useState(false); //clear filter
    const closeFilterDialog = () => {
        setShowFilterDialog(false); //close filter
      };
      const[filterCamp,setFilterCamp]=useState({
       campNo:"",
        branchId:""
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterCamp,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, campNo: value?.campNo, branchId: value?.branchId,})
          closeFilterDialog();
          setFilter(true)
          setFilterCamp({
            campNo: "",
            branchId: "",
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          campNo: "",
          branchId: "",
       
        })
        formik.values.campNo=""
        formik.values.branchId=""
          setFilter(false)
      }
    // console.log(data,'dddda');
      
    return (
        <div className=' bg-[#E6F2F2] h-full w-full py-3 px-6'>
            {contextHolder} 
            <div className='w-full items-center flex justify-between'>
                <div>
                    <BreadCrumb items={[
                        {
                            title: (<Link to="/main/dashboard">
                              <Dashboard color="gray" width={10} height={10} />{" "}
                            </Link>)
                        },
                        { title: <p className='text-[13px]'>Accommodation</p> },
                        { title: <p className='text-[13px]'>Camp Info</p> },
                    ]}
                    />
                </div>
                {/* <div><h2 className='font-[Poppins] text-xl'>Camp Info</h2></div> */}
                <div className='flex gap-3'>
                    <div>
                      <SearchBar 
                    handleChange={(e) =>
                        setParams({ ...params, search: e.target.value })
                      }
                    /></div>
                    <FilterButton height='8' 
                     handleClick={() => setShowFilterDialog(true)}/>
                     {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
                    <Link to={`/main/accomodation/camp-info/${officeId}/create`}><div>
                        <CreateButton label='Camp' />
                    </div></Link>
                </div>
            </div>
            <div className='w-full h-[80%]'>
            {data.length>0 ? <div className='py-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-2 lg:gap-3 h-[calc(100vh-170px)] overflow-y-scroll'>
            {data.map((item, index) => {
                return (
                        <div key={index} className='flex flex-col gap-2 px-3 py-3 w-full bg-[#FFF] rounded-[5px]  h-[350px] overflow-hidden' style={{ boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.10)" }}>
                            <h3 className='text-[#212121] text-sm font-semibold leading-normal'>Layout</h3>
                            <div className='flex items-center px-2 py-2 gap-2 bg-[#E6F2F2] rounded-[5px]'>
                                {/* Image Division */}
                                <div className='w-[40%] p-2'>
                                    {item?.layoutDrawing && <img src={item?.layoutDrawing} alt="#" className='w-full h-auto flex items-center justify-center' />}
                                </div>
                                {/* Camp Info Division */}
                                <div className='w-auto flex flex-col gap-2'>
                                    <div>
                                        <h3 className='text-[#212121] leading-normal font-normal text-sm '><b>Camp Name</b></h3>
                                        <p className='text-[#4D4D4D] leading-normal font-normal text-xm'><b>{item?.campName || '-'}</b></p>
                                    </div>
                                    <div>
                                        <h3 className='text-[#212121] leading-normal font-normal text-sm'>Camp Number</h3>
                                        <p className='text-[#4D4D4D] leading-normal font-normal text-xs'>{item?.campNo || '-'}</p>
                                    </div>
                                    <div>
                                        <h3 className='text-[#212121] leading-normal font-normal text-sm'>Camp Layout Area</h3>
                                        <p className='text-[#4D4D4D] leading-normal font-normal text-xs'>Width : {item?.layoutArea?.width || '0.00'} m, Height : {item?.layoutArea?.height || '0.00'} m </p>
                                    </div>
                                </div>
                            </div>
                            {/*  Address Division */}
                            <div>
                                <h3 className='text-[#212121] text-sm font-semibold leading-normal pb-2'>Address</h3>
                                <p className='text-[#4D4D4D] leading-normal font-normal text-sm'>{ item?.city || '-'}</p>
                                <p className='text-[#4D4D4D] leading-normal font-normal text-sm'>Postal Code : {item?.postalCode || '-'}</p>
                            </div>
                            <hr className='custom-hr' />
                            {/*  Show more */}
                            <div className='flex w-full justify-between items-center'>
                                <div className='-mt-1'><DeleteButton height={4} width={4} handleClick={()=>{handleDelete(item?.campName, item?.campId)}}/></div>
                                <Link to={`/main/accomodation/camp-info/${officeId}/view/${item?.campId}`} state={{data : item}}>
                                    <div className='flex gap-0.5'>
                                        <h3 className='text-[#048178] font-normal text-[14px]'>See More</h3>
                                        <span className='pt-[3px] text-[#048178]'><RightArrow/></span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    
                )
            })}
            </div>
            :
            <p className='flex justify-center text-lg '><b>No Data found</b></p>
            }
            </div>
            {/* {!data &&
            (<div className='w-full h-full flex justify-center pt-32'>
                    <h3 className='font-normal font-sans text-gray-500 whitespace-break-spaces'>No  Data  to  Display</h3>
                </div>)} */}
                {/* {showFilterDialog && (            
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-1 flex justify-center items-center">
               <div ref={filterDialogRef} className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Client</h2> */}
                 <Modal
        open={showFilterDialog}
        title={"Filter Camp"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    
                    <NuInputNumber label='Camp Number' placeholder='Camp Number' formik={formik} name='campNo' width='w-full' />
                    <NuSelect
                                    isRequired={true}
                                    label='Branch'
                                    options={branches}
                                    formik={formik}
                                    placeholder='Choose'
                                    name='branchId'
                                    width='w-full'
                                />
                    </div>
                    
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
           <div className='w-full h-20 flex justify-end'>
            <Pagination
             pageIndex={page}
             setPageIndex={setPage}
             dataCount={pageCount}
             setDataCount={setPageCount}
             total={totalCount}
            />
            </div>
        </div >
    )
}

export default CampInfo;