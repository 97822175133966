import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const FoodCategory = () => {
  return (
    <SettingsMetaData propertyName={"FoodCategory"} viewPropertyName={"Food Category"} />

  )
}

export default FoodCategory