import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { notification } from 'antd';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputText, NuMobileNumber, NuSelect, NuSelectCurrency, NuTextArea, NuTimePicker } from '../../../../Components/Utils/NuInput';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseAddData from '../../../../Components/Hooks/useAddData';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function CreateTakeAwayRequest() {
  const { value: MealType} = useFetchMataData('MealType') 
  const { value: FoodCategory} = useFetchMataData('FoodCategory')
  
  const {data:user} =UseDropDown('/user/drop-down')
  const navigate = useNavigate()
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData ();
  const [createNew, setCreateNew] = useState({
      // reqType: "RoomService",
      
      ethnicity:"",
      requestedBy:"",
      mealType:"",
      foodCategory: "",
      deliveryLocation: "",
      date:"",
      startDate:"",
      endDate: ""
  })
 

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
         

          addFormData('/take-away-requests/new', value, resetForm);
      }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Catering</p>) },
        { title: (<p className=' text-[12px] '>Take Away Request</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
            <NuInputText
              // isRequired={true}
              label=" Ethnicity"
              placeholder=" Ethnicity"
              formik={formik}
              name="ethnicity"
              width="w-full md:w-1/3"
            />
             <NuSelect
              label="Requested BY"
              options={user}
              formik={formik}
              placeholder="Choose"
              name="requestedBy"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              label="Meal Type"
              options={MealType}
              formik={formik}
              placeholder="Choose"
              name="mealType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              label="FoodCategory"
              options={FoodCategory}
              formik={formik}
              placeholder="Choose"
              name="foodCategory"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
            <NuInputText
              // isRequired={true}
              label=" Delivery Location"
              placeholder=" Location"
              formik={formik}
              name="deliveryLocation"
              width="w-full md:w-1/3"
            />
            <NuDatePicker
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          
          <NuDatePicker
              label="Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="startDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
            <NuDatePicker
              label="End Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="endDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
             
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
                    </div>
  )
}

export default CreateTakeAwayRequest