import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { NuDatePicker, NuInputNumber, NuInputText, NuInputfile, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function EditReferences() {
  const { value: value } = useFetchMataData('Category_ContactList')

  const [images, setImages] = useState([]);
  const [selectedFile,setSelectedFile] =useState(null)
  // const [division, setDivision] = useState([]);
const [ api ] = notification.useNotification();

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [url,setUrl] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);

    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    const { referenceEditId } = useParams();
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
      subject:data?.subject, 
      details:data?.details, 
      category:data?.category, 
      comments:data?.comments, 
    })
    // const handleChangeImg = (e) => {
    //     if (e.target.files.length) {
    //         setImage({
    //             preview: URL.createObjectURL(e.target.files[0]),
    //             raw: e.target.files[0],
    //         });
    //     }
    // };
    // const handleImageRemove = () => {
    //     setImage({
    //         preview: "",
    //         raw: "",
    //     });
    //     setCreateNew(prevState => ({ ...createNew, image: "" }))
    //     formik.values.image = ""
    // }

    function handleImageChange(e) {
      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = []
      files.map(item=>{
        if (item.size < maxFileSize ){
          fileArr.push(item)
        }else{
          api.warning({
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            placement: "bottomRight",
          });
        }
      })    
      let tempArr = []
      setSelectedFile(fileArr);
      files.map(item=>{
        if (item.size < maxFileSize ){
        let fileName = item?.name
        console.log('fname',fileName);
        let fileExtention =  fileName.split(".").reverse()[0]
        if(filetype?.includes(fileExtention)){
          tempArr.push(fileName)
        }else{
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
      })
      console.log(tempArr,'temp');
      setImages(tempArr)
  }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            let formData = new FormData();
            formData.append('referenceData', JSON.stringify(value));
            formData.append('attachments', image.raw);

            editFormData(`/reference/edit/${referenceEditId}`, formData, resetForm, from);
        }
    })
    return (
        <div className="w-full h-full p-2 bg-[#F1F2FB]">
        <div className="flex justify-between px-4 ">
            <BreadCrumb items={[
            {
                title: (<Link to="/main/dashboard">
                    <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>)
            },
            { title: (<p className=' text-[12px] '>Clinic</p>) },
            { title: (<p className=' text-[12px] '>Reference</p>) },
            { title: (<p className=' text-[12px] '>Edit</p>) },
        ]} />
        <BackButton/>
        </div>
     
        <form onSubmit={formik.handleSubmit} className=' pt-3'>
            <div className="w-full  mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
              <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
                
              <NuSelect
              label="Category"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuInputText
              label="Subject"
              placeholder="Subject"
              formik={formik}
              name="subject"
              width="w-full md:w-1/3"
            />
            <NuTextArea
              label="Details"
              placeholder="Details"
              formik={formik}
              name="details"
              width='w-full md:w-1/3'
            />
             <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
               <Attachement
                  handleChange={handleImageChange}
                  preview={images||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
              <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
  {/* {data?.image?.map((imgs, idx) => {
                                      console.log('img', imgs);
                                      return ( */}
                                          <div
                                              className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                              // key={idx}
                                              style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                          >
                                              <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                              // onClick={() => handleImageClick(imgs)}
                                              onClick={() => {setShowFilterDialog(true);setUrl(data?.attachments?.file)}}
                                              >
                                                  {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                  <img src={data?.attachments?.file} alt={data?.attachments?.file} />
                                              </div>
                                          </div>
                                      {/* );
                                  })} */}
                                  </div>
                <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                  <div className="md:flex justify-end gap-4">
                    <CancelButton handleClick={()=>{Oncancel(-1)}} />
                    <SubmitButton />
                  </div>
                </div>
              </div>
            </div>
          </form>
          </div>
      )
    }
    
export default EditReferences