import React from 'react';
import Pagination from '../Utils/Pagenation';

function DataTable({headers,total,pageIndex,setPageIndex,dataCount,setDataCount,children,height="h-[calc(100vh-130px)]",bodyHeight="max-h-[calc(100vh-200px)]"}) {
  return (
    <div className={`w-full ${height} rounded-md bg-white p-3 mt-2`}>
    <div className="w-full h-[93%] overflow-hidden">
  <div className="w-full">
    <div className=" w-full " style={{borderBottom:"1px solid black"}}>
      <table className="w-full">
        <thead>
          <tr className="text-[13px] font-medium">
            {headers &&
              headers.map((header, i) => (
                <th
                  className={`py-2 font-medium truncate ${header.width} ${header?.align?`text-${header.align}`:"text-center"} `}
                  key={i}
                >
                  {header.label || "-"}
                </th>
              ))}
          </tr>
        </thead>
      </table>
    </div>
    <div className={`${bodyHeight} z-10 pt-1 pb-4 overflow-y-auto`}>
      <table className="w-full table-auto border-collapse">
        <tbody className="text-[13px] w-full ">
            {children}
        </tbody>
      </table>
    </div>
  </div>
</div>
<div className=' w-full h-[40px] '>
<Pagination
  total={total}
  pageIndex={pageIndex}
  setPageIndex={setPageIndex}
  dataCount={dataCount}
  setDataCount={setDataCount}
/>
</div>
</div>
  )
}

export default DataTable