import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { NuDatePicker, NuInputNumber, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';

function EditIssuance() {
  const {data:medicalstocks} =UseDropDown('/medical-stocks/drop-down')
  const {data:patients} =UseDropDown('/patients/drop-down')

    const { serviceRequestEditId } = useParams();
    const location=useLocation()
    const data =location.state.data
    const from=location.state.from
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
        date: data?.date,
        stockId: data?.stockId,
        patientId: data?.patientId,
        issuedQty: data?.issuedQty,
    
       
    })
    console.log(data);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
           

            editFormData(`/issuances/edit/${data.issuanceId}`, value, resetForm, from);
        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Clinic</p>) },
        { title: (<p className=' text-[12px] '>Issuance</p>) },
        { title: (<p className=' text-[12px] '>Edit</p>) },
    ]} />
    <BackButton/>
    </div>
 
    <form onSubmit={formik.handleSubmit} className='pt-3'>
         <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
           <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
             
           <NuDatePicker
            label="Date"
            placeholder="dd/mm/yyyy"
            formik={formik}
            name="date"
            width="w-full md:w-1/2 lg:w-1/3"
            />
            
            <NuSelect
              label="Stock Id"
              options={medicalstocks}
              formik={formik}
              placeholder="Choose"
              name="stockId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              label="Patient Id"
              options={patients}
              formik={formik}
              placeholder="Choose"
              name="patientId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             
             
              <NuInputNumber
               label="Issued Quality"
               placeholder="Issued Quality"
               formik={formik}
               name="issuedQty"
               width="w-full md:w-1/3"
             />
             
             
             <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
               <div className="md:flex justify-end gap-4">
                 <CancelButton handleClick={()=>{Oncancel(-1)}} />
                 <SubmitButton />
               </div>
             </div>
           </div>
         </div>
       </form>
      </div>
  )
}

export default EditIssuance