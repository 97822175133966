import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';

const ViewStocks = () => {
    const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const location = useLocation();
    const data = location.state.data;
    console.log(location.state,'state');
    return (
        <div className=' bg-[#E6F2F2] h-full'>
            <div className='mx-6 pt-3'>
                <BreadCrumb items={[
                    { title: <p className='text-[13px]'>Clinic</p> },
                    { title: <p className='text-[13px]'>Medical-Stocks</p> },
                    { title: <p className='text-[13px]'>View</p> },

                ]}
                />
            </div>
            <div className='flex justify-end'>
                <div className='w-1/2 flex justify-end'>
                    <div className='pr-6 flex gap-3'>
                        <div className='flex justify-end gap-2'>
                        <Link to={`/main/clinic/medical-stocks/${officeId}`}><BackButton link={true} /></Link>
          <Link to={`/main/clinic/medical-stocks/${officeId}/edit/${data?.stockId}`} state={{ from: `/main/clinic/medical-stocks/${officeId}/view/${data?.stockId}`, data: data }}>
            <EditButton height='8' /></Link>
       
                            </div>
                    </div>
                </div>
            </div>
            {/*  Section-1 */}
            <div className='px-6 py-5 flex  gap-9'>
                {/* <div className='flex flex-col gap-2 px-5 py-3 w-[30%] h-auto bg-[#FFF] rounded-[5px] p-5'>
                  
                    <div className='flex gap-5 h-full'>
                        <div className='w-full h-full flex items-center p-5 gap-2 bg-[#E6F2F2] rounded-[5px]'>
                        {data?.image && <img className='w-full h-auto' src={data?.image } alt='Product' />}
                        </div>
                         <div className='mx-10 border-r border-#E6F2F2' /> 
                        
                    </div>
                </div> */}
                <div className='flex flex-col gap-2 px-5 py-3 w-full h-[calc(100vh-170px)] bg-[#FFF] rounded-[5px] p-5'>
                    <h3 className='text-[#212121] text-sm font-bold leading-normal'>products Information</h3>
                    <div className='flex gap-5'>
                        {/* <div className='w-1/2 h-auto flex items-center p-5 gap-2 bg-[#E6F2F2] rounded-[5px]'>
                        </div>
                        <div className='mx-10 border-r border-#E6F2F2' /> */}
                        <div className='w-full'>
                            <div className='pb-8 pt-5 flex flex-wrap gap-6 w-auto'>
                            <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Product Id</h4>
                                    <p className='text-[#4D4D4D]'>{data?.productId || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Is Asset</h4>
                                    <p className='text-[#4D4D4D]'>{data?.isAsset?'Yes':'No'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Supplier</h4>
                                    <p className='text-[#4D4D4D]'>{data?.supplier || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Batch No</h4>
                                    <p className='text-[#4D4D4D]'>{data?.batchNo || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Serial No</h4>
                                    <p className='text-[#4D4D4D]'>{data?.serialNo || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Unit</h4>
                                    <p className='text-[#4D4D4D]'>{data?.unit || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Quantity</h4>
                                    <p className='text-[#4D4D4D]'>{data?.quantity || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Mfd Date</h4>
                                    <p className='text-[#4D4D4D]'>{data?.mfdDate || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Expiry Date</h4>
                                    <p className='text-[#4D4D4D]'>{data?.expiryDate || '-'}</p>
                                </div>
                            
                            </div> 
                           
                        </div>
                    </div>
                </div>
               
            </div>
          
          
        </div>
    )
}


export default ViewStocks