// import { faGear,faQrcode,faUserInjured,faFile,faUserPen,faUserGroup,faHandshake} from "@fortawesome/free-solid-svg-icons"

import Dashboard from "../Icons/Dasboard";
import MyTask from "../Icons/MyTask";
import Accomodation from "../Icons/Accomodation";
import Staff from "../Icons/Staff";
import Clinic from "../Icons/Clinic";
import Incidents from "../Icons/Incidents";
import Catering from "../Icons/Catering";
import Inventory from "../Icons/Inventory";
import Maintanance from "../Icons/Maintanance";
import Transportation from "../Icons/Transportation";
import Procurement from "../Icons/Procurement";
import Meetings from "../Icons/Meetings";
import Report from "../Icons/Report";
import Vendors from "../Icons/Vendors";
import listingLogo from '../../assets/images/listing_logo.png';
import settingsLogo from '../../assets/images/setting_logo.png';
import dashboardLogo from '../../assets/images/dashboard_logo.png';
import accomodationLogo from '../../assets/images/accomodation_logo.png';
import Task from "../Icons/Task";
// import settingsLogo from '../../assets/images/setting_logo.png';


// let NotesView = userAccess?userAccess.some((permission)=>permission.permissionName == "Note_view" ):true;


// function Dasboard () {
//     // console.log("m",x);
//     return (
//         <Dashboard />
//     )
// }
export const NavItems=[
    {
        /*dashboard*/
        link:"/main/dashboard",
        icon: <img src={dashboardLogo} className="w-[14px] h-[14px]" alt="#"/>,
        name:'Dashboard',
        access:true,
        subItems: []
    },
    {
        /*Accomodation*/
        icon: <img src={accomodationLogo} className="w-[14px] h-[14px]" alt="#"/>,
        name:'Accomodation',
        subMenu:true,
        subItems: [
            {
                name: 'Camp Info',
                icon: 'info-circle',
                link:'/main/accomodation/camp-info'
            },
            {
                name: 'building',
                icon: 'info-circle',
                link:'/main/accomodation/building'
            },
            {
                name: 'Room',
                icon: 'info-circle',
                link:'/main/accomodation/room'
            },
            {
                name: 'Occupants',
                icon: 'info-circle',
                link:'/main/accomodation/occupants'
            },
            {
                name: 'Visitors Tracking',
                icon: 'info-circle',
                link:'/main/accomodation/visitors-tracking'
            },
            {
                name: 'Check-In / Out',
                icon: 'info-circle',
                link:'/main/accomodation/check-in-out/check-in'
            },
            {
                name: 'Service Request',
                icon: 'info-circle',
                link:'/main/accomodation/service-request'
            },
        ],
        access:true

    },
    {
        name: 'Listing',
        icon: <img src={listingLogo} className="w-[12px] h-[14px]" alt="#"/>,
        subMenu: true,
        subItems: [
            {
                name: 'Employees',
                icon: 'info-circle',
                link: '/main/listing/employees'
            },
            {
                name: 'Guests',
                icon: 'info-circle',
                link: '/main/listing/guests'
            },
            {
                name: 'Movements',
                icon: 'info-circle',
                link: '/main/listing/movements'
            },
        ]
    },
    {
        /*Clinic*/
        icon: <><Clinic/></>,
        name:'Clinic',
        subMenu:true,
        subItems: [
            {
                name: 'Patients',
                icon: 'info-circle',
                link:'/main/clinic/patients'
            },
            {
                name: 'Medical Products',
                icon: 'info-circle',
                link:'/main/clinic/medical-products'
            },
            {
                name: 'Medical Stocks',
                icon: 'info-circle',
                link:'/main/clinic/medical-stocks'
            },
            {
                name: 'Issuance',
                icon: 'info-circle',
                link:'/main/clinic/issuance'
            },
            {
                name: 'Reference',
                icon: 'info-circle',
                link:'/main/clinic/reference'
            },
            {
                name: 'Contact List',
                icon: 'info-circle',
                link:'/main/clinic/contact-list'
            },
        ],
        access:true
    },
    {
        /*Catering*/
        icon: <><Catering/></>,
        name:'Catering',
        subMenu:true,
        subItems: [
            {
                name: 'Dinning Record',
                icon: 'info-circle',
                link:'/main/catering/dinning-record'
            },
            {
                name: 'Food Menu',
                icon: 'info-circle',
                link:'/main/catering/food-menu'
            },
            {
                name: 'Food Schedule',
                icon: 'info-circle',
                link:'/main/catering/food-schedule'
            },
            {
                name: 'Take Away Request',
                icon: 'info-circle',
                link:'/main/catering/take-away-request'
            },
            {
                name: 'Take Away Schedule',
                icon: 'info-circle',
                link:'/main/catering/take-away-schedule'
            }
        ]
    },
    {
        /*Inventory*/
        path:"/Inventory",
        icon: <><Inventory/></>,
        name:'Inventory',
        subMenu:true,
        subItems: [
            {
                name: 'Stocks',
                icon: 'info-circle',
                link:'/main/inventary/stocks'
            },
            {
                name: 'Asserts',
                icon: 'info-circle',
                link:'/main/inventary/asserts'
            },
            {
                name: 'Vehicles',
                icon: 'info-circle',
                link:'/main/inventary/vehicles'
            },
            {
                name: 'Equipments',
                icon: 'info-circle',
                link:'/main/inventary/equipments'
            },
            {
                name: 'Materials',
                icon: 'info-circle',
                link:'/main/inventary/materials'
            },
        ],
        access:true
    },
    {
        /*Maintanance*/
        path:"/",
        icon: <><Maintanance/></>,
        name:'Maintanance',
        subMenu:true,
        subItems: [
            {
                name: 'Vehicle',
                icon: 'info-circle',
                link:'/main/maintenance/vehicle-maintenance'
            },
            {
                name: 'Equipment',
                icon: 'info-circle',
                link:'/main/maintenance/equipment-maintenance'
            },
            {
                name: 'Daily Inspection By Staff',
                icon: 'info-circle',
                link:'/main/maintenance/daily-inspection-by-staff'
            },
            {
                name: 'Inspection Schedule',
                icon: 'info-circle',
                link:'/main/maintenance/inspection-schedule'
            },
            {
                name: 'Service Request',
                icon: 'info-circle',
                link:'/main/maintenance/service-request'
            },
        ],
        access:true
    },
    {
        /*Transportation*/
        path:"/",
        icon: <><Transportation/></>,
        name:'Transportation',
        subMenu:true,
        subItems: [
            {
                name: 'Schedule',
                icon: 'info-circle',
                link:'/main/transportation/schedule'
            },
            {
                name: 'Parking Lots',
                icon: 'info-circle',
                link:'/main/transportation/parking-lots'
            },
            {
                name: 'Trips',
                icon: 'info-circle',
                link:'/main/transportation/trips'
            }
        ],
        access:true
    },
    {
        /*Procurement*/
        path:"/",
        icon: <><Procurement/></>,
        name:'Procurement',
        subMenu:true,
        subItems: [
            {
                name: 'Purchase Request',
                icon: 'info-circle',
                link:'/main/procurment/purchase-request'
            },
            {
                name: 'Purchase Order',
                icon: 'info-circle',
                link:'/main/procurment/purchase-order'
            },
            {
                name: 'Delivary Notes',
                icon: 'info-circle',
                link:'/main/procurment/delivary-notes'
            },
            {
                name: 'Products',
                icon: 'info-circle',
                link:'/main/procurment/products'
            },
        ],
        access:true
    },
    {
        /*Staff*/
        path:"/",
        icon: <><Staff/></>,
        name:'Staff',
        subMenu:true,
        subItems: [
            {
                name: 'Staff Details',
                icon: 'info-circle',
                link:'/main/staff/staff-details'
            },
            // {
            //     name: 'Staff Attendence',
            //     icon: 'info-circle',
            //     link:'/main/staff/staff-attendance'
            // },
            {
                name: 'Daily Service For Staff',
                icon: 'info-circle',
                link:'/main/staff/daily-service-for-staff'
            },
            // {
            //     name: 'Service Schedule',
            //     icon: 'info-circle',
            //     link:'/main/staff/service-shedule'
            // },
            // {
            //     name: 'Training',
            //     icon: 'info-circle',
            //     link:'/main/staff/trainning'
            // }
        ],
        access:true
    },
    {
        /*Meetings*/
        path:"/",
        icon: <><Meetings/></>,
        name:'Meetings',
        subMenu:true,
        subItems: [
            {
                name: 'Meeting',
                icon: 'info-circle',
                link:'/main/meetings/meeting'
            },
            {
                name: 'Meeting Points',
                icon: 'info-circle',
                link:'/main/meetings/meeting-points'
            },
            {
                name: 'Participants',
                icon: 'info-circle',
                link:'/main/meetings/participants'
            },
        ],
        access:true
    },
    {
        /*Incidents*/
        icon: <><Incidents/></>,
        name:'Incidents',
        link: '/main/incidents',
        subMenu: false,
        access:true
    },
    {
        /*My Task*/
        icon: <><Task/></>,
        name:'My Task',
        link:"/main/task",
        subItems: false,
        access:true
    },
    {
        /*Vendors*/
        link:'/main/vendors',
        icon: <><Vendors/></>,
        name:'Vendors',
        subItems: []
    },
    {
        /*Reports*/
        path:"/",
        icon: <><Report/></>,
        name:'Reports',
        access:true
    },
    // {
    //     name: 'Settings',
    //     link:'/main/settings/company-info', 
    //     icon:<img src={settingsLogo} className="w-[20px] h-[21px]" alt="#"/>, 
    //     subItems: [] 
    // }
    
];