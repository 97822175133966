import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import EquipmentType from './EquipmentType'
import CalliberReq from './CalliberReq'
import Condition from './Condition'


const Equipment = () => {
  return (
    <>
    <TextMediumSM content="Equipment" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <EquipmentType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <CalliberReq/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Condition/>
     </div>
    
    
     </div>
     </>
  )
}

export default Equipment