import React, { useState } from 'react'
import UseDropDown from '../../../Components/Hooks/useDropDown';
import UseAddData from '../../../Components/Hooks/useAddData';
import { useFormik } from 'formik';
import * as yup from 'yup';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import { Link } from 'react-router-dom';
import Dashboard from '../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { NuDatePicker, NuDateTime, NuInputText, NuMobileNumber, NuSelect, NuTextArea, NuTimePicker } from '../../../Components/Utils/NuInput';
import useFetchMataData from '../../../Components/Hooks/useFetchMataData';

function CreateIncidents() {
  const { value: value } = useFetchMataData('Incident_Type')

  const { value: value1 } = useFetchMataData('Severity_Incident')

  const { value: value2 } = useFetchMataData('Status_Incident')

  const { data: user } = UseDropDown('/user/drop-down');
  const { Oncancel, addData, contextHolder, isLoading } = UseAddData();
  const [createNew, setCreateNew] = useState({
    dateTime: "",
    incidentType:"",
    reportedBy: "",
    witness: "",
    location: "",
    severity: "",
    incidentDesc: "",
    investigation: "",
    actionTaken: "",
    recommendation: "",
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // date: yup.string().required('Date is required.'),
      // visitorName: yup.string().required('Visitor Name is required.'),
      // timeIn: yup.string().required('Time -In is required.'),
      // whomToVisit: yup.string().required('Please choose Occupant.'),
    }),

    onSubmit: async (value, { resetForm }) => {
      addData('/incident/new', value, resetForm)
    }
  });
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            
            { title: <p className=" text-[12px] ">Incident</p> },
            { title: <p className=" text-[12px] text-[#4E4B4B]">Create</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">

          <NuDateTime
          label="Date Time"
          name="dateTime"
          formik={formik}
          width="w-full md:w-1/3"
          />
          <NuSelect
              // isRequired={true}
              label="Incident Type"
             
              formik={formik}
              placeholder="Choose"
              name="incidentType"
              width="w-full md:w-1/3"
              options={value}
             
             
            />
             <NuSelect
              // isRequired={true}
              label="Reported by"
              // options={user}
              formik={formik}
              placeholder="Choose"
              name="reportedBy"
              width="w-full md:w-1/3"
              options={user}
             
            />
            {/* <NuInputText
             label='Reported by' 
             placeholder='Text'
            formik={formik} 
            name='reportedBy'
             width='w-full  md:w-1/3' /> */}
            <NuInputText
              // isRequired={true}
              label="Witness"
              placeholder="Witness Name"
              formik={formik}
              name="witness"
              width="w-full md:w-1/3"
            />
            <NuInputText
             label='Location' 
             placeholder='Text'
            formik={formik} 
            name='location'
             width='w-full  md:w-1/3' />
              <NuSelect
              // isRequired={true}
              label="Severity"
              // options={user}
              formik={formik}
              placeholder="Choose"
              name="severity"
              width="w-full md:w-1/3"
              options={value1}
            />
              <NuInputText
              // isRequired={true}
              label="Investigation"
              placeholder="Type"
              formik={formik}
              name="investigation"
              width="w-full md:w-1/3"
            />
              <NuInputText
              // isRequired={true}
              label="Action Token"
              placeholder="Type"
              formik={formik}
              name="actionTaken"
              width="w-full md:w-1/3"
            />
              <NuInputText
              // isRequired={true}
              label="Recommendation"
              placeholder="Type"
              formik={formik}
              name="recommendation"
              width="w-full md:w-1/3"
            />

           
            
           
            <NuTextArea
              label="Incident Desc"
              placeholder="Type"
              formik={formik}
              name="incidentDesc"
              width='w-full md:w-1/3'
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel() }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateIncidents