import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import axiosInstance from '../../Services/axiosCommon';
import { Modal, notification } from 'antd';

export const useDeleteData = (fecher) => {
    const [api, contextHolder] = notification.useNotification();
    const { confirm } = Modal;

    const handle = (url, removevalue, module) => {
    confirm({
        title: `Do you want to delete ${removevalue}?`,
        centered: true,
        icon: <ExclamationCircleFilled />,
        content: `${removevalue || 'undefined'} will be removed from the ${module} list`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
                axiosInstance.delete(url)
                    .then(res => {
                        if (res?.data.status === true) {
                            api.success({
                                message: 'Deleted Successfully',
                                description: res.data.msg || '',
                                placement: 'bottomRight',
                                duration: 3,
                            })
                            fecher();
                        }
                        else {
                            api.error({
                                message: 'Failed!',
                                description: res.data.msg || '',
                                placement: 'bottomRight',
                                duration: 3,
                            })
                        }
        
                    }).catch(
                        error => {
                            console.log(error);
                            api.error({
                                message: `Failed`,
                                description: 'Internal server Error..!',
                                placement: 'bottomRight',
                            });
                        }
                    );
        }
    });};

    return ( { contextHolder, handle } )
}
