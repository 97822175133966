import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const RequestedService = () => {
  return (
    <SettingsMetaData propertyName={"RequestedService"} viewPropertyName={"Requested Service"} />

  )
}

export default RequestedService