import React, { useEffect, useState } from "react";
import { NormalHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import NuDate from "../../../../Components/Utils/NuDate";

function ViewMaterials() {
  const { officeId } = useParams();
  const location = useLocation();
  const data = location.state.data;
    return (
      <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: (<p className=' text-[12px] '>Invontary</p>) },
            { title: (<p className=' text-[12px] '>Materials</p>) },
            { title: (<p className=' text-[12px] '>View</p>) },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/inventary/materials/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/inventary/materials/${officeId}/edit/${data?.materialId}`} state={{data: data, from:`/main/inventary/materials/${officeId}/view/${data?.materialId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
              <h2 className='text-base font-normal text-[#111537] p-3'>Details</h2>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-5 gap-x-8 m-10">
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.materialId || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Manufacturer</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.manufacturer || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Unit</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.unit|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Model</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.model || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Quantity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.quantity || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Serial No</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.serialNo|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Warrenty Period</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.warrentyPeriod && NuDate({value:data?.warrentyPeriod,format:"DD-MM-YYYY"}) || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Warrenty Expire Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.warrentyExpDate && NuDate({value:data?.warrentyExpDate,format:"DD-MM-YYYY"}) || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Received Quantity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.receivedQty || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Issued Quantity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.issuedQty || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Stock Quantity</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.stockQty || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Condition</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.condition || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments || '-'}</p>
                  </div>
                  
                  </div>
                  </div>
          </div>
          
      </div>
    );
}

export default ViewMaterials