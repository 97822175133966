import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import imgMain from "../../../assets/Images/mainImg.png";
// import loginicon from "../../../assets/Images/nutaskLgScreen.png";
// import loginMobicon from "../../../assets/Images/nutasklogo.png";
// import { NuInputPassword, NuInputText } from '../../../Components/Utils/NuInput';
import { notification } from 'antd';
import { Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { NuInputPassword, NuInputText } from '../../../../Components/Utils/NuInput';
// import picture from '../../../../assets/images/Rectangle 87.jpg';
import axios from 'axios';
import { MainHeading, NormalHeading, SecondHeading } from '../../../../Components/Utils/Heading';
import logo from '../../../../assets/images/building.png';
axios.defaults.baseURL = window.WEB_APP_API_URL;

function Login() {
  const [api, contextHolder] = notification.useNotification();
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });


  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      window.location.replace("/main/dashboard");
    }
  }, []);

  const [createNew, setCreateNew] = useState({
    email: '',
    password: '',
  });
  const formik = useFormik({
    initialValues: createNew,
    validateOnChange: false,
    validationSchema: yup.object({
      email: yup.string().required('Email is Required'),
      password: yup.string().required('Password is Required'),
    }),
    onSubmit: async (value) => {
      // console.log("ssssssssssss");
      //   alert(value.username);
      await axios
        .post('/auth/login', value)
        .then((res) => {
          if (res.data.status) {
            let userToken = res?.data?.token;
            let decodeToken = jwt_decode(userToken);
            localStorage.setItem('userDetails', JSON.stringify(decodeToken));
            localStorage.setItem('userToken', JSON.stringify(res?.data?.token));
            localStorage.setItem('userAccess', JSON.stringify(res?.data?.permissions));
            api.success({
              message: `Login successfully`,
              description: res.data.msg || 'Successfully logged in  ',
              placement: 'bottomRight',
            });
            window.location.replace("/");
            // navigate('');
          } else {
            api.error({
              message: `Login failed`,
              description: res.data.msg || 'Network error',
              placement: 'bottomRight',
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `Login failed`,
            description: err.msg || 'Network error',
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <div className='w-full h-[100vh] login-image'>
      <div className='w-full h-[calc(100vh-30px)] flex items-center justify-center'>
        {contextHolder}
        <div className='w-full mx-5 md:mx-0 md:w-3/5 h-2/3 rounded-2xl flex'>
          <div className='hidden md:flex w-1/2 bg-white rounded-l-2xl flex-col items-center justify-center gap-6'>
            <h1 className='text-center text-[#231942] capitalize font-bold text-[20px] lg:text-[23px] xl:text-[26px]'>Login</h1>
            <div className='w-[45%] rounded-[1.5rem] content-center h-[35%] bg-[#e6f3f2] flex items-center justify-center drop-shadow-lg'>
              <img className='w-1/2' src={logo} alt="logo"/>
            </div>
          </div>
          <div className='w-full md:w-1/2 h-full rounded-2xl md:rounded-l-none md:rounded-r-2xl bg-[#048178]/20 backdrop-blur-sm flex justify-center items-center'>
            <div className='w-full px-3 flex flex-col '>
              <form onSubmit={formik.handleSubmit} className=''>
                <div className=' justify-center '>
                  <NuInputText
                    label='Email *'
                    placeholder='Enter Your Mail'
                    formik={formik}
                    name='email'
                    width='w-full'
                    padding='py-2'
                  />

                  <NuInputPassword
                    label='Password *'
                    placeholder='************'
                    formik={formik}
                    name='password'
                    width='w-full'
                    padding='py-2'
                  />
                </div>

                <Link to="/auth/forget-password">
                  <p className='text-[#048178] px-3 font-sans not-italic text-sm medium text-right'>Forgot Password?</p>
                </Link>
                <button type='submit' className='border-none cursor-pointer text-white text-xs bg-[#048178] h-10  mx-3 mt-6 rounded-md w-[95%]'>
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <p className=' mt- font-semibold text-center text-s text-slate-200'>&#169;  numota</p>
    </div>
  );
}
export default Login;
{/* {screenSize.width<="786"?
      
      <div className=' w-full h-full flex flex-col justify-center items-center'>
        <div className=' w-[98px]'> */}
{/* <img src={loginMobicon} width="100%" alt="loginicon" /> */ }
{/* </div>

        <p className="font-semibold text-[36px] text-[#344767] mb-32">Manage Easily</p>

        <div className=' w-11/12  text-left'>
          <div className='w-full  flex flex-col text-sm '>
            <form onSubmit={formik.handleSubmit} className=''>
              <div className=' justify-center '>
                <NuInputText
                  label='Email *'
                  placeholder='Enter Your Mail'
                  formik={formik}
                  name='email'
                  width='w-full'
                  padding='py-3'
                />

                <NuInputPassword
                  label='Password *'
                  placeholder='************'
                  formik={formik}
                  name='password'
                  width='w-full'
                  padding='py-3'
                />
              </div>

              <Link to="/auth/forget-password">
                <p className='text-[#3267ff] px-3 font-sans not-italic text-xs medium text-right'>Forgot Password-</p>
              </Link>
              <button type='submit' className=' border cursor-pointer text-white text-xs  border-[#EAEBED] bg-[#119DEE] h-12  mx-3 mt-6 rounded-md w-[95%]'>
                Login
              </button>
            </form>
          </div>
        </div>
        <p className=' mt-28 pt-9  font-semibold text-center text-xs text-[#B3B2B9]'>&#169;  numota</p>

      </div> 
       :
      <div className=' w-[70%] rounded-2xl shadow-2xl px-4 pt-9 pb-12'>
        
      <div className=' w-full h-[30%] flex justify-center gap-8 items-center pb-16'>
        <div className=' w-[63px] '>
        </div>
        <p className="font-semibold text-[36px] text-[#344767]">Manage Easily</p>
      </div>

      <h3 className=' my-10 text-[24px] font-semibold text-center text-[#344767]'>Log in</h3>

      <div className='w-full h-[70%] flex items-start lg:items-center justify-center'>
        <div className=' w-10/12  text-left'>
          <div className='w-full  flex flex-col '>
            <form onSubmit={formik.handleSubmit} className=''>
              <div className=' justify-center '>
                <NuInputText
                  label='Email *'
                  placeholder='Enter Your Mail'
                  formik={formik}
                  name='email'
                  width='w-full'
                  padding='py-2'
                />

                <NuInputPassword
                  label='Password *'
                  placeholder='************'
                  formik={formik}
                  name='password'
                  width='w-full'
                  padding='py-2'
                />
              </div>

              <Link to="/auth/forget-password">
                <p className='text-[#3267ff] px-3 font-sans not-italic text-sm medium text-right'>Forgot Password-</p>
              </Link>
              <button type='submit' className=' border cursor-pointer text-white text-xs  border-[#EAEBED] bg-[#119DEE] h-10  mx-3 mt-6 rounded-md w-[95%]'>
                Login
              </button>
            </form>
          </div>
        </div>
      </div>

      </div> */}

