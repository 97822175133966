import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import PaymentStatus from './PaymentStatus'
import ApprovalStatus from './ApprovalStatus'


const PurchaseOrder = () => {
  return (
    <>
    <TextMediumSM content="Purchase Order" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <PaymentStatus/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <ApprovalStatus/>
     </div>
    
    
     </div>
     </>
  )
}

export default PurchaseOrder