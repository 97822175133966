import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import { Link, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuInputNumber, NuInputText, NuSelect,NuTextArea,NuDatePicker } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import axiosInstance from '../../../../Services/axiosCommon';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
// import { notification } from 'antd';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function EditPurchaseRequest() {
    const {data:products} =UseDropDown('/products/drop-down')
  const { value: ApprovalStatus} = useFetchMataData('ApprovalStatus_PR')

  const location = useLocation();
    const data = location.state.data;
    console.log("dataaaa", data);
    const from = location.state.from;
    const initialData = location.state.data;
    const { purchaseRequestEditId } = useParams();
    const { data: camps } = UseDropDown('/camp/drop-down')
    const {data:vendors} =UseDropDown('/vendors/drop-down')
    const [images, setImages] = useState([]);
  const [selectedFile,setSelectedFile] =useState(null)
  const [ api, contextHolder ] = notification.useNotification();
    const [image, setImage] = useState({ preview: "", raw: "" });
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, isLoading } = useEditFormData();
    const { OncancelForm, addFormData, contextHolderForm, isLoadingForm } = useAddFormData();
    // const [ api, contextHolders ] = notification.useNotification();

    const [PoData, setPoData] = useState(initialData);

    const fetchPoData = async () => {
        try {
            const response = await axiosInstance.get(`/PR/${purchaseRequestEditId}`);
            if (response.data?.status === true) {
                setPoData(response.data.data);
            } else {
                api.error({
                    message: `Failed`,
                    description: response.data?.msg || 'Failed to fetch data.',
                    placement: 'bottomRight',
                    duration: 3,
                });
            }
        } catch (error) {
            api.error({
                message: `Error`,
                description: error.message || 'An error occurred while fetching data.',
                placement: 'bottomRight',
                duration: 3,
            });
        }
    };
    const [url,setUrl] = useState(false);

 
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };

    useEffect(() => {
        fetchPoData();
    }, []);

    const [createNew, setCreateNew] = useState({
        // campId: "",
        // buildName:"",
        // buildNo: "",
        // floors: ""
        
        project:data?.project,
        vendor:data?.vendor,
        projectCode:data?.projectCode,
        costCode:data?.costCode,
        deadLine:data?.deadLine,
        comments:data?.comments,
        productList:data?.productList
      

    })
    const [createFloorNew, setCreateFloorNew] = useState({
      
      productId:"",
      quantity:"",
     

    })
        const floorformik = useFormik({
        enableReinitialize: true,
        initialValues: createFloorNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            axiosInstance.post(`/PR/edit-PR/${purchaseRequestEditId}`, value)
        // .then((res)=>{
        //     if (res.data?.status === true) {
        //         api.success({
        //           message: `Success`,
        //           description: res.data?.msg || 'Created successfully',
        //           placement: 'bottomRight',
        //           duration: 3,
        //         });
        //         // data();
        //         resetForm();
        //       } else {
        //         api.error({
        //           message: `Failed`,
        //           description: res.data?.msg || 'Creation failure.!',
        //           placement: 'bottomRight',
        //           duration: 3,
        //         });
        //       }  
        // })
        }
    })




    const [items, setItems] = useState([])
    const [newData, setNewData] = useState({
     
      productId: data?.productId,
      quantity: data?.quantity,
    

        // floorName:"",
        // floorNo:"",
        // numOfRooms:""

        // Total Rooms on Floor:
    })
    // console.log("ddd", items);
    // console.log("ddd", items);

    function handleDelete(idx) {
        const list = items.filter((item, i) => i !== idx);
        setItems(list);


    }
    const handleDeleteData = (productId) => {
        // console.log("check data", buildId);
        // Filter out the item to be deleted based on productId
        axiosInstance.delete(`/PR/remove-products/${productId}`  )
        // .then((res)=>{
        //     if (res.data?.status === true) {
        //         api.success({
        //           message: `Success`,
        //           description: res.data?.msg || 'Created successfully',
        //           placement: 'bottomRight',
        //           duration: 3,
        //         });
        //         // data();
        //         fetchPoData();
        //       } else {
        //         api.error({
        //           message: `Failed`,
        //           description: res.data?.msg || 'Creation failure.!',
        //           placement: 'bottomRight',
        //           duration: 3,
        //         });
        //       }
        // })
    };
    const handleAdd = () => {
        // if (items.length < formik.values.numberOfFloors) {
        const newItem = {

        
          productId: formik.values.productId,
          quantity: formik.values.quantity,
          
            // Total Rooms on Floor:newData.floorNo
        };
        console.log(newItem, 'jkh');
        setItems([...items, newItem]);
        // Update local storage
        setNewData({
          
            productId: "",
            quantity: "",
           
            // Total Rooms on Floor:""
        });
        // }
        // else{
        //     alert("You have reached the maximum limit of floors.");
        // }
    };
    // const handleChange = (e) => {
    //     setNewData({
    //         ...newData,
    //         [e.target.name]: e.target.value,
    //     });
    // };
    function handleImageChange(e) {
        const maxFileSize = 200000000; // 200 MB
        const files = Array.from(e.target.files);
        let fileArr = []
        files.map(item=>{
          if (item.size < maxFileSize ){
            fileArr.push(item)
          }else{
            api.warning({
              message: `File Size to big`,
              description: `${item.name} is Removed and not Uploaded`,
              placement: "bottomRight",
            });
          }
        })    
        let tempArr = []
        setSelectedFile(fileArr);
        files.map(item=>{
          if (item.size < maxFileSize ){
          let fileName = item?.name
          console.log('fname',fileName);
          let fileExtention =  fileName.split(".").reverse()[0]
          if(filetype?.includes(fileExtention)){
            tempArr.push(fileName)
          }else{
            let x = URL.createObjectURL(item)
            tempArr.push(x)
          }
        }
        })
        console.log(tempArr,'temp');
        setImages(tempArr)
    }

    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };
    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            const valueWithItems = {
                ...value,
                productList: items,
            };
            let formData = new FormData();
            formData.append('prDetails', JSON.stringify(valueWithItems));
            formData.append('attachments', image.raw);

            editFormData(`/PR/edit-PR/${purchaseRequestEditId}`, formData, resetForm, from);
        }
    })
    return (
        <div className="w-full h-full p-2 bg-[#F1F2FB]">
            <div className="flex justify-between px-4">
                <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Procurement</p>) },
                    { title: (<p className=' text-[12px] '>Purchase Request</p>) },
                    { title: (<p className=' text-[12px] '>Edit</p>) },
                ]} />
                <BackButton />
            </div>
            <div className="w-full h-full ">
                <div className="w-full h-[calc(100vh-130px)]">
                    {contextHolder}
                    <div className="w-full ">

                        <div className="w-full flex gap-6 pt-6 ">
                        <form onSubmit={formik.handleSubmit} className="w-full ">
                        <div className="w-full flex gap-6 pt-6">
                            <div className='flex gap-8 md:flex-nowrap p-4  h-[calc(100vh-130px)] w-full md:w-1/2 bg-white rounded-lg'>
                            {/* <div className='w-full md:w-1/2 rounded-md border border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 flex-col'> */}
                       
                            {/* <div className='w-full mx-auto h-[calc(100vh-200px)] bg-white rounded-xl overflow-hidden mt-4'>
                           
                                <div className='w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto'>
                                {/* <h3 className=" text-[#231942] w-full  capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"Floor Layout"}</h3> */}

                                <div className='w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto'> 
                                {/* <div className='w-full md:w-1/2 rounded-md border border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 flex-col'>

<h3 className=" text-[#231942] w-full text-center capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"PR Layout"}</h3>

    <CustomImgUpload
        borderStyle="dashed"
        isRemove={true}
        width='w-full mx-auto'
        justify='justify-evenly'
        removeFunction={handleImageRemove}
        handleChange={handleChangeImg}
        preview={image.preview || createNew.image || ""}
    />
    </div> */}
                                <NuInputText 
                                        label='Project'
                                         placeholder='Project'
                                          formik={formik} 
                                          name='project'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width=' w-full md:w-1/3' />
                                          <NuSelect
            label="Approval Status"
            options={ApprovalStatus}
            formik={formik}
            placeholder="Choose"
            name="approvalStatus"
            width="w-full md:w-1/3"
          />
          <NuSelect 
                                        label='Vendor '
                                         placeholder='Vendor '
                                         options={vendors}
                                          formik={formik} 
                                          name='vendor'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full md:w-1/3' />
          <NuInputText
                                        label='Project Code'
                                         placeholder='Project Code'
                                          formik={formik} 
                                          name='projectCode'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full md:w-1/3' />
                                           <NuInputText
                                        label='Cost Code'
                                         placeholder='Cost Code'
                                          formik={formik} 
                                          name='costCode'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full md:w-1/3' />
                                            <NuSelect
            label="Status"
            options={[
              { value: "Reviewed", label: "Reviewed" },
              { value: "Not Reviewed", label: " Not Reviewed" },
            ]}
            formik={formik}
            placeholder="Choose"
            name="status"
            width="w-full md:w-1/3"
          />
                                           <NuDatePicker
                                        label='Dead Line'
                                         placeholder='DeadLine'
                                          formik={formik} 
                                          name='deadLine'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full md:w-1/3' />
                                    
                                           <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
             <Attachement
                  handleChange={handleImageChange}
                  preview={images||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
                   <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
  {data?.attachments?.map((imgs, idx) => {
                                      return (
                                          <div
                                              className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                              key={idx}
                                              style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                          >
                                              <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                              // onClick={() => handleImageClick(imgs)}
                                              onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                              >
                                                  {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                  <img src={imgs?.file} alt={imgs?.file} />
                                              </div>
                                          </div>
                                      );
                                  })}
                                  </div>
                                   </div>
                                   </div>
                                   
                                   <div className='flex gap-8 md:flex-nowrap p-4   w-full md:w-1/2 bg-white rounded-lg'>
                           
                           <div className='w-full'>
                           <h3 className=" text-[#231942] w-full  capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"Product List"}</h3>
                               
                                    <div className='flex w-full gap-3'>
                                        
                                    <NuSelect 
                                        label='Product Id'
                                         placeholder='Product Id'
                                         options={products}
                                          formik={formik} 
                                          name='productId'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full md:w-1/2 md:-mt-2' />
                                        <NuInputNumber 
                                        label='Quantity'
                                         placeholder='Quantity'
                                          formik={formik} 
                                          name='quantity'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                        //   value={newData.floorNo}
                                           width='w-full md:w-1/2 md:-mt-2' />
                                           
                                           {/* <div className='flex gap-3 items-center w-full'> */}
                                        
                                           {/* <div className={`md:w-1/2 w-ful  my-3`}>
                                        <div className=' px-3'>
                                            <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{"Floor Name"}</label>
                                            <Input 
                                            name={"floorName"}
                                             className='mt-2' 
                                             placeholder={"Floor Name"}
                                              value={newData.floorName}
                                               type='text'  />

                                                </div>
                                        </div>
                                        <div className={`md:w-1/2 w-ful  my-3`}>
                                        <div className=' px-3'>
                                            <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{"Floor Number"}</label>
                                            <Input 
                                            name={"floorNo"} 
                                            className='mt-2' 
                                            placeholder={"Floor Number"} 
                                            value={newData.floorNo} 
                                            type='number'  />
                                                </div>
                                        </div> */}
                                        {/* <div className={`md:w-1/2 w-ful  my-3`}>
                                        <div className=' px-3'>
                                            <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{"Floor Number"}</label>
                                            <Input 
                                            name={"floorNo"}
                                             className='mt-2' 
                                             placeholder={"Floor Number"}
                                              value={newData.floorNo} 
                                              type='number'  />
                                                </div>
                                        </div> */}
                                             <button
                                                onClick={handleAdd}
                                                type="button"
                                                className="w-full md:w-[12%] h-8 text-center cursor-pointer bg-[#048178] border border-solid border-[#9B74F2] text-white font-semibold rounded-lg mt-5"
                                                >
                                                Add
                                                </button>
                                             {/* <CreateButton/> */}
                                           {/* </div> */}
                                          
                                    </div>
                                   
                                   <div className='w-full h-auto bg-white overflow-y-scroll mt-5'>
                                        <div className='w-full py-[1%] bg-[#048178] flex justify-between rounded-lg p-4'>
                                       
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Product Id
                                        </p>
                                        <p className=" w-[25%]  text-left mr-[100px] text-white text-sm font-medium">
                                       Quantity
                                        </p>
                                        
                                        </div>
                                   </div>
                                   {items.map((data,index)=>{
                                  return(
                                    <div key={index} className="w-full py-2 flex justify-between p-4">
                                    <p className=" w-[20%] text-center text-black ml-10 text-sm font-light1">
                                      {data?.productId}
                                    </p>
                                    <p className=" w-[20%] text-center ml-72 text-black text-sm font-light">
                                      {data?.quantity}
                                    </p>
                                   
                                      
                                      <button
                                            onClick={() => handleDelete(index)}
                                            type="button"
                                            className="cursor-pointer w-[15%] h-8 bg-[#048178] rounded border-none ml-40"
                                        >
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            >
                                            <path
                                                d="M10 15.0227L10 12.0227"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M14 15.0227L14 12.0227"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M3 7.02271H21V7.02271C20.0681 7.02271 19.6022 7.02271 19.2346 7.17495C18.7446 7.37793 18.3552 7.76728 18.1522 8.25734C18 8.62488 18 9.09082 18 10.0227V16.0227C18 17.9083 18 18.8511 17.4142 19.4369C16.8284 20.0227 15.8856 20.0227 14 20.0227H10C8.11438 20.0227 7.17157 20.0227 6.58579 19.4369C6 18.8511 6 17.9083 6 16.0227V10.0227C6 9.09082 6 8.62488 5.84776 8.25734C5.64477 7.76728 5.25542 7.37793 4.76537 7.17495C4.39782 7.02271 3.93188 7.02271 3 7.02271V7.02271Z"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M10.0681 3.3933C10.1821 3.28698 10.4332 3.19303 10.7825 3.12603C11.1318 3.05902 11.5597 3.02271 12 3.02271C12.4403 3.02271 12.8682 3.05902 13.2175 3.12603C13.5668 3.19303 13.8179 3.28698 13.9319 3.3933"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            </svg>
                                        </button>
                                    
                                  
                                    </div>
                                  );
                                })}
                                <div className="w-full">
                                <div className="md:flex justify-end gap-4 mt-5">
                                    <CancelButton handleClick={() => { Oncancel() }} />
                                    <SubmitButton isLoading={isLoading} />
                                </div>
                            </div> 
                                
                            </div>
                            </div>
                            </div>
                         
                            
                        </form>
                        </div>


                        {/* </form> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPurchaseRequest