import { Outlet, useLocation, useParams } from 'react-router-dom';
import Header from '../../Components/Layout/Header';
import { Layout } from 'antd';
import Sidebar, { SidebarItem } from '../../Components/Layout/Sidebar';
import { NavItems } from '../../Components/Utils/NavItems';


function MainPage() {
  const {officeId}=useParams()
  
  console.log("officeid",officeId);
  
  // const navItems = [
  //   {
  //     name: 'Dashboard',
  //     link: `/main/dashboard/${officeId}`,
  //     icon: 'D',
  //     subItems: []
  //   },
  //   {
  //     name: 'Accomodation',
  //     icon: 'BC',
  //     subMenu: true,
  //     subItems: [
  //       {
  //         name: 'Bidding',
  //         icon: 'info-circle',
  //         link: '/main/bidding-cotracts/bidding'
  //       },
  //       // { name: 'Clients',
  //       //   icon: 'headset',
  //       //   link:'/main/bidding-cotracts/clients'
  //       //  },
  //       {
  //         name: 'Contract Agreements',
  //         icon: 'headset',
  //         link: '/main/bidding-cotracts/contract-agreements'
  //       },
  //       {
  //         name: 'Amendmends',
  //         icon: 'headset',
  //         link: '/main/bidding-cotracts/amendmends'
  //       },
  //       {
  //         name: 'Sub-Contracts',
  //         icon: 'headset',
  //         link: '/main/bidding-cotracts/sub-contract'
  //       },
  //       {
  //         name: 'Budget',
  //         icon: 'headset',
  //         link: '/main/bidding-cotracts/budget',
  //       },
  //     ],
  //   },

  //   {
  //     name: 'Project',
  //     link: '/main/projects',
  //     icon: 'P',
  //     subItems: []
  //   },

  //   {
  //     name: 'Resource Management',
  //     icon: 'RM',
  //     subMenu: true,
  //     subItems: [
  //       {
  //         name: 'Employees',
  //         icon: 'info-circle',
  //         link: '/main/resource-management/employees'
  //       },
  //       {
  //         name: 'Company Policies',
  //         icon: 'info-circle',
  //         link: '/main/resource-management/company-policies'
  //       },
  //       {
  //         name: 'Roles & Responsibilities',
  //         icon: 'info-circle',
  //         link: '/main/resource-management/roles-responsibilities'
  //       },
  //     ],
  //   },

  //   {
  //     name: 'Inventory / Store',
  //     icon: 'IS',
  //     subMenu: true,
  //     subItems: [
  //       {
  //         name: 'Stock',
  //         icon: 'info-circle',
  //         link: '/main/inventory-store/stock'
  //       },
  //       {
  //         name: 'Assets',
  //         icon: 'info-circle',
  //         link: '/main/inventory-store/assets'
  //       },
  //       {
  //         name: 'Maintenance',
  //         icon: 'info-circle',
  //         link: '/main/inventory-store/maintenance'
  //       },
  //       {
  //         name: 'DN-Incoming',
  //         icon: 'info-circle',
  //         link: '/main/inventory-store/dn-incoming'
  //       },
  //       {
  //         name: 'DN-Outgoing',
  //         icon: 'info-circle',
  //         link: '/main/inventory-store/dn-outgoing'
  //       },
  //       {
  //         name: 'GRN',
  //         icon: 'info-circle',
  //         link: '/main/inventory-store/grn'
  //       },
  //       {
  //         name: 'Listing of items',
  //         icon: 'info-circle',
  //         link: '/main/inventory-store/listingofitems'
  //       },
  //       {
  //         name: 'Item Issuance',
  //         icon: 'info-circle',
  //         link: '/main/inventory-store/item-issuance'
  //       },
  //       {
  //         name: 'Calibration',
  //         icon: 'info-circle',
  //         link: '/main/inventory-store/calibration'
  //       },
  //     ],
  //   },
  //   {
  //     name: 'Contacts',
  //     // link:'/main/settings', 
  //     icon: 'C',
  //     subMenu: true,
  //     subItems: [
  //       {
  //         name: 'Clients',
  //         icon: 'info-circle',
  //         link: '/main/contacts/clients'
  //       },
  //       {
  //         name: 'Sub-Contractors',
  //         icon: 'info-circle',
  //         link: '/main/contacts/sub-contractors'
  //       },
  //       {
  //         name: 'Vendors',
  //         icon: 'info-circle',
  //         link: '/main/contacts/vendors'
  //       },
  //       {
  //         name: 'Products',
  //         icon: 'info-circle',
  //         link: '/main/contacts/products'
  //       },
  //     ]
  //   },
  //   {
  //     name: 'Settings',
  //     link: '/main/settings',
  //     icon: 'S',
  //     subItems: []
  //   },
  // ];

  const location = useLocation();

  function isActiveFun(x){
    let actualPath = location.pathname.split("/")[1]
    let linkPath = ""
    if(x?.hasSubMenu){
      if(x?.subMenu){
         let y = x.subMenu[0].link;
         let z = y.split("/")[0]
         linkPath = z
      }
    }else{
      linkPath = x?.link;
    }
    if(actualPath == linkPath) {
      return true;
    }else{
      return false;
    }
  }
  return (
    // <div className=" w-[100vw] h-screen overflow-hidden">
    //   <Layout className=' w-full h-screen'>
    //     <Sidebar navItems={navItems} />
    //     <Layout>
    //       <Header />
    //       <Outlet />

    //     </Layout>
    //   </Layout>
    // </div>
    <div className=" w-full h-screen bg-slate-100 flex justify-start items-center overflow-hidden">
    <Sidebar>
      {NavItems &&
        NavItems.map((item) => {
          return (
            <SidebarItem
              icon={item?.icon}
              text={item?.name}
              hasSubMenu={item?.subMenu}
              subItem={item?.subItems}
              link={item?.link}
              active={isActiveFun(item)}
              valuesId = {officeId}
              //  alert = "test"
            />
          );
        })}
    </Sidebar>

    <Layout>
    <div className=" w-full h-[50px] bg-white z-50 shadow-lg">
      <Header />
      </div>
      <div className=" w-full h-[calc(100vh-50px)]  overflow-hidden">
        <Outlet />
      </div>
    </Layout>    
  </div>
  );
}

export default MainPage;