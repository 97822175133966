import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const RoomCondition = () => {
  return (
    <SettingsMetaData propertyName={"RoomCondition"} viewPropertyName={"Room Condition"} />

  )
}

export default RoomCondition