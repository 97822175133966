import './App.css';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div className=' w-[100vw] h-screen'>
      <Outlet/>
    </div>
  );
};

export default App;
