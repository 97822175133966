import React, { useEffect, useState } from 'react'
import ViewMoreReference from './ViewMoreReference';
import { currencies } from '../../../../assets/CountryMini';
import DataTable from '../../../../Components/Layout/DataTable';
import { Link, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import Dashboard from '../../../../Components/Icons/Dasboard';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import { Modal } from 'antd';
import { NuInputNumber, NuInputText, NuSelect } from '../../../../Components/Utils/NuInput';
import * as yup from 'yup'
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function ReferenceIndex() {
  const { value: value } = useFetchMataData('Category_ContactList')


  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
    const { data, totalCount, getData} = UseGetDataSearch( '/reference/',params);
    console.log(data,'dddd');
  
      let headers = [
          {
            label: <span>ID</span>,
            width: "w-[5%]",
          },
          {
            label: <span>Category</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Subject</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Details</span>,
            width: "w-[10%]",
          },
        
         
          {
            label: <span>Actions</span>,
            width: "w-[5%]",
          },
        ];

        const [showFilterDialog, setShowFilterDialog] = useState(false);
        const [filter, setFilter] = useState(false);
          const closeFilterDialog = () => {
              setShowFilterDialog(false);
            };
            const[filterReference,setfilterReference]=useState({
              category:"",
              subject:"",
              
            });
            const formik = useFormik({
              enableReinitialize: true,
              initialValues: filterReference,
              validateOnChange: false,
              validateOnBlur: false,
              validationSchema: yup.object({
                // email: yup.string().required('Email is required!'),
              }),
              onSubmit: async (value, { resetForm }) => {
                console.log("ffff",value);
                setParams({...params, category: value?.category, subject: value?.subject})
                closeFilterDialog();
                setFilter(true)
                setfilterReference({
                  category: "",
                  subject: "",
                });
              }
            })
            const clearFilter = ()=> {
              setParams({...params,
                category: "",
                subject: "",
             
              })
              formik.values.category=""
              formik.values.subject=""
                setFilter(false)
            }
       
    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <BreadCrumb items={[
        {title:(<Link to="/main/dashboard">
        <Dashboard color="gray" width={10} height={10} />{" "}
      </Link>)},
        {title:(<p className=' text-[12px] '>Clinic</p>)},
        {title:(<p className=' text-[12px] '>Reference</p>)}
        ]} />
      <div className=' flex gap-2'>
      <SearchBar
       handleChange={(e) =>
        setParams({ ...params, search: e.target.value })
      }
       />
        <FilterButton height='8'
       handleClick={() => setShowFilterDialog(true)}/>
       {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
       <CreateButton label='Import' />
       <Link to={`/main/clinic/reference/${officeId}/create`}><CreateButton label='Reference'/></Link> 
      </div>
      </div>
  
      <DataTable
       bodyHeight='max-h-[calc(100vh-230px)]'
       height='h-[calc(100vh-130px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={page}
       setPageIndex={setPage}
       dataCount={pageCount}
       setDataCount={setPageCount}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              currencies.find((i) => {
                if(i.value == item?.currency){
                  getCurrency=i.label;
                }
              })
              return (
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                  <td className="w-[5%] p-2 px-[1px] text-center ">{item?.referenceId||"#"}</td>
                  {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.category||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.subject||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.details||""}</td>
                  <td className="w-[5%] p-2 px-[1px] text-center ">
                      <ViewMoreReference data={item} fetcher={getData} id={item?.referenceId}/>
                  </td>
                </tr>
              );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
          }
      </DataTable>
      <Modal
        open={showFilterDialog}
        title={"Medical Products"}
        width={"50%"}
        className="my-modal"

        
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="">
                    <NuSelect
              label="Category"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="category"
              width="w-full"
            />
             <NuInputText
              label="Subject"
              placeholder="Subject"
              formik={formik}
              name="subject"
              width="w-full"
            />
                    
                    </div>
                    
                   <div className="flex justify-end gap-2 mt-5">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
    </div>
    )
  }
export default ReferenceIndex