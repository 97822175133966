import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import ViewMore from '../../../../Components/Icons/ViewMore';
import { useDeleteData } from "../../../../Components/Hooks/useDeleteData";

function EmployeeViewMore({ id, fetcher, data }) {
    const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [showRole, setShowRole] = useState(false);
    const { contextHolder, handle } = useDeleteData(fetcher);
    const handleDelete = async (removevalue) => handle(`/employees/${id}`, removevalue, 'employee');

    // const userAccess = JSON.parse(localStorage.getItem("userAccess"));
    // let UserEditAccess = userAccess
    //     ? userAccess.some((permission) => permission.permissionName == "Settings_Alluser_edit")
    //     : false;
    // let UserDeleteAccess = userAccess
    //     ? userAccess.some((permission) => permission.permissionName == "Settings_Alluser_delete")
    //     : false;
    const Edit = true;
    const Delete = true;

    return (    
        <>
            {contextHolder}
            <div className='text-[#344767]  relative dropdown inline-block z-50'>
                <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
                    <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full' style={{ border: '1px solid #C0C0C0' }}>
                        <ViewMore />
                    </span>
                </div>
                <ul className='dropdown-menuOne text-[#344767] text-left  rounded-lg absolute -left-[70px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
                    <li className='w-[100px] bg-black block m-0 hover:cursor-pointer'>
                        <span className='bg-white hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium' >
                            <Link className='text-black hover:text-black' to={`/main/listing/employees/${officeId}/view/${id}`} state={{ data: data }}>View</Link>
                        </span>
                    </li>
                    {Edit && <li className=' w-[100px] bg-black block m-0 hover:cursor-pointer'>
                        <span
                            className='bg-white hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium'
                        // onClick={() => setOpen(true)}
                        >
                            <Link className='text-black hover:text-black' to={`/main/listing/employees/${officeId}/edit/${id}`} state={{ from:`/main/listing/employees/${officeId}` ,data: data }} >Edit</Link>
                        </span>
                    </li>}
                    {
                        Delete &&
                        <li className=''>
                            <a
                                className='bg-white hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium'
                                href="#"
                                onClick={() => {
                                    handleDelete(data.name);
                                }}
                            >
                                Delete
                            </a>
                        </li>
                    }
                </ul>
            </div>
        </>
    )
}

export default EmployeeViewMore;