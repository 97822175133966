import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import { Link, useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import {
  NuInputNumber,
  NuInputText,
  NuSelect,
} from "../../../../Components/Utils/NuInput";
import CustomImgUpload from "../../../../Components/Utils/CustomImgUpload";
import Dashboard from "../../../../Components/Icons/Dasboard";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import axiosInstance from "../../../../Services/axiosCommon";
import { notification } from "antd";

function EditBuildings() {
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const initialData = location.state.data;
  const { buildingEditId } = useParams();
  const { data: camps } = UseDropDown("/camp/drop-down");
  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const { OncancelForm, addFormData, contextHolderForm, isLoadingForm } =
    useAddFormData();
  const [api, contextHolders] = notification.useNotification();

  const [buildingData, setBuildingData] = useState(initialData);

  const fetchBuildingData = async () => {
    try {
      const response = await axiosInstance.get(`/buildings/${buildingEditId}`);
      if (response.data?.status === true) {
        setItems(response.data.data?.floors);
      } else {
        api.error({
          message: `Failed`,
          description: response.data?.msg || "Failed to fetch data.",
          placement: "bottomRight",
          duration: 3,
        });
      }
    } catch (error) {
      api.error({
        message: `Error`,
        description: error.message || "An error occurred while fetching data.",
        placement: "bottomRight",
        duration: 3,
      });
    }
  };

  useEffect(() => {
    fetchBuildingData();
  }, []);

  const [createNew, setCreateNew] = useState({
    // campId: "",
    // buildName:"",
    // buildNo: "",
    // floors: ""
    buildPlan: data?.buildPlan,
    campId: data?.campId,
    buildName: data?.buildName,
    buildNo: data?.buildNo,
    numOfFloors: data?.numOfFloors,
    floors: data?.floors,
  });
  const [createFloorNew, setCreateFloorNew] = useState({
    floorName: "",
    floorNo: "",
    numOfRooms: "",
  });
  const floorformik = useFormik({
    enableReinitialize: true,
    initialValues: createFloorNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance
        .post(`/buildings/new-floor/${buildingEditId}`, value)
        .then((res) => {
          if (res.data?.status === true) {
            api.success({
              message: `Success`,
              description: res.data?.msg || "Created successfully",
              placement: "bottomRight",
              duration: 3,
            });
            // data();
            resetForm();
          } else {
            api.error({
              message: `Failed`,
              description: res.data?.msg || "Creation failure.!",
              placement: "bottomRight",
              duration: 3,
            });
          }
        });
    },
  });

  const [items, setItems] = useState([]);
  const [newData, setNewData] = useState({
    floorName: "",
    floorNo: "",
    numOfRooms: "",

    // floorName:"",
    // floorNo:"",
    // numOfRooms:""

    // Total Rooms on Floor:
  });
  // console.log("ddd", items);
  // console.log("ddd", items);

  function handleDelete(idx) {
    const list = items.filter((item, i) => i !== idx);
    setItems(list);
    console.log("i", idx);
  }
  const handleDeleteData = (floorId) => {
    // console.log("check data", buildId);
    // Filter out the item to be deleted based on productId
    axiosInstance.delete(`/buildings/remove-floor/${floorId}`).then((res) => {
      if (res.data?.status === true) {
        api.success({
          message: `Success`,
          description: res.data?.msg || "Created successfully",
          placement: "bottomRight",
          duration: 3,
        });
        // data();
        fetchBuildingData();
      } else {
        api.error({
          message: `Failed`,
          description: res.data?.msg || "Creation failure.!",
          placement: "bottomRight",
          duration: 3,
        });
      }
    });
  };
  const handleAdd = () => {
    // if (items.length < formik.values.numberOfFloors) {
    const newItem = {
      floorName: floorformik.values.floorName,
      floorNo: floorformik.values.floorNo,
      numOfRooms: floorformik.values.numOfRooms,
      // Total Rooms on Floor:newData.floorNo
    };
    console.log(newItem, "jkh");
    setItems([...items, newItem]);
    axiosInstance
    .post(`/buildings/new-floor/${buildingEditId}`, floorformik.values)
    .then((res) => {
      if (res.data?.status === true) {
        api.success({
          message: `Success`,
          description: res.data?.msg || "Created successfully",
          placement: "bottomRight",
          duration: 3,
        });
        // data();
        // resetForm();
        fetchBuildingData()
      } else {
        api.error({
          message: `Failed`,
          description: res.data?.msg || "Creation failure.!",
          placement: "bottomRight",
          duration: 3,
        });
      }
    });
    floorformik.resetForm();
  };

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew((prevState) => ({ ...createNew, buildPlan: "" }));
    formik.values.buildPlan = "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      const valueWithItems = {
        ...value,
        floors: items,
      };
      let formData = new FormData();
      formData.append("json_data", JSON.stringify(valueWithItems));
      formData.append("attachments", image.raw);

      editFormData(`/buildings/${buildingEditId}`, formData, resetForm, from);
    },
  });



  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
      {contextHolder}
      <div className="flex justify-between px-4 mb-2">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/main/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Accommodation</p> },
            { title: <p className=" text-[12px] ">Buildings</p> },
            { title: <p className=" text-[12px] ">Edit</p> },
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full h-[calc(100vh-115px)]   ">
          <div className=" w-full flex gap-6  h-[calc(100vh-170px)] ">
            <div className=" w-[40%] p-4 md:flex  bg-white rounded-lg">
              <div className="w-full md:w-1/2 rounded-md  border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 flex-col">
                <h3 className=" text-[#231942] w-full text-center capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">
                  {"Building Layout"}
                </h3>

                <CustomImgUpload
                  borderStyle="dashed"
                  isRemove={true}
                  width="w-full mx-auto"
                  justify="justify-evenly"
                  removeFunction={handleImageRemove}
                  handleChange={handleChangeImg}
                  preview={image.preview || createNew.buildPlan || ""}
                />
              </div>
              <div className="w-full md:w-1/2">
                <div className="">
                  <NuSelect
                    label="Camp Id"
                    options={camps}
                    formik={formik}
                    placeholder="Choose"
                    name="campId"
                    width="w-full "
                  />
                  <NuInputText
                    label="Building Name"
                    placeholder="Building Name"
                    formik={formik}
                    name="buildName"
                    width="w-full "
                  />
                  <NuInputNumber
                    label="Building Number"
                    placeholder="Building Number"
                    formik={formik}
                    name="buildNo"
                    width="w-full "
                  />
                  <NuInputNumber
                    label="Number of Floor"
                    placeholder="Number of Floor"
                    formik={formik}
                    name="numOfFloors"
                    width="w-full "
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-8 md:flex-nowrap p-4   w-full md:w-[58%] bg-white rounded-lg h-full">
              <div className="w-full">
                <h3 className=" text-[#231942] w-full  capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">
                  {"Floor Layout"}
                </h3>

                <form onSubmit={floorformik?.handleSubmit}>
                  <div className="flex w-full gap-3">
                    <NuInputText
                      label="Floor Name"
                      placeholder="Floor Name"
                      formik={floorformik}
                      name="floorName"
                      width="w-full md:w-1/2 md:-mt-2"
                    />
                    <NuInputNumber
                      label="Floor Number"
                      placeholder="Floor Number"
                      formik={floorformik}
                      name="floorNo"
                      width="w-full md:w-1/2 md:-mt-2"
                    />

                    <NuInputNumber
                      label="Total Rooms on Floor"
                      placeholder="Total Rooms on Floor"
                      formik={floorformik}
                      name="numOfRooms"
                      width="w-full md:w-1/2 md:-mt-2"
                    />

                    <button
                      className="w-full md:w-[12%] h-8 text-center cursor-pointer bg-[#048178] border border-solid border-[#9B74F2] text-white font-semibold rounded-lg mt-5"
                      onClick={handleAdd}
                      type="button"
                    >
                      Add
                    </button>
                  </div>
                </form>

                <div className="w-full bg-white  mt-1">
                  <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg">
                    <p className=" w-[40%] pl-1 text-left text-white text-xs 2xl:text-sm font-medium">
                      Floor Name
                    </p>
                    <p className=" w-[26%] text-left text-white text-xs 2xl:text-sm font-medium">
                      Floor Number
                    </p>

                    <p className=" w-[26%] text-left text-white text-xs 2xl:text-sm font-medium">
                      Total Rooms on Floor
                    </p>
                    <p className=" w-[8%] text-center text-white text-xs 2xl:text-sm font-medium">
                      Action
                    </p>
                  </div>
                </div>
                <div className=" overflow-scroll h-[calc(100vh-370px)]">
                  {items.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full py-2 flex justify-between overflow-scroll"
                      >
                        <p className=" w-[40%] text-left pl-1 text-black text-sm font-light py-1">
                          {data?.floorName}
                        </p>
                        <p className=" w-[26%] text-left pl-1 text-black text-sm font-light py-1">
                          {data?.floorNo}
                        </p>
                        <p className=" w-[26%] text-left pl-1 text-black text-sm font-light py-1">
                          {data?.numOfRooms}
                        </p>
                        <p className=" w-[8%] text-center">
                          <button
                            onClick={() => {handleDeleteData(data?.floorId)}}
                            type="button"
                            className="cursor-pointer w-8 h-8 bg-[#048178] rounded border-none"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 25"
                              fill="none"
                            >
                              <path
                                d="M10 15.0227L10 12.0227"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                              <path
                                d="M14 15.0227L14 12.0227"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                              <path
                                d="M3 7.02271H21V7.02271C20.0681 7.02271 19.6022 7.02271 19.2346 7.17495C18.7446 7.37793 18.3552 7.76728 18.1522 8.25734C18 8.62488 18 9.09082 18 10.0227V16.0227C18 17.9083 18 18.8511 17.4142 19.4369C16.8284 20.0227 15.8856 20.0227 14 20.0227H10C8.11438 20.0227 7.17157 20.0227 6.58579 19.4369C6 18.8511 6 17.9083 6 16.0227V10.0227C6 9.09082 6 8.62488 5.84776 8.25734C5.64477 7.76728 5.25542 7.37793 4.76537 7.17495C4.39782 7.02271 3.93188 7.02271 3 7.02271V7.02271Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                              <path
                                d="M10.0681 3.3933C10.1821 3.28698 10.4332 3.19303 10.7825 3.12603C11.1318 3.05902 11.5597 3.02271 12 3.02271C12.4403 3.02271 12.8682 3.05902 13.2175 3.12603C13.5668 3.19303 13.8179 3.28698 13.9319 3.3933"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                            </svg>
                          </button>
                        </p>
                      </div>
                    );
                  })}
                  {/* {data?.floors.map((data, index) => {
        console.log("gcgc", data);
        return (
            <div key={index} className="w-full py-2 flex justify-between  ">
                <p className=" w-[20%] text-center text-black text-sm font-light py-1">
                    {data?.floorName}
                </p>
                <p className=" w-[20%] text-center text-black text-sm font-light py-1">
                    {data?.floorNo}
                </p>
                <p className=" w-[25%] text-center text-black text-sm font-light py-1">
                    {data?.numOfRooms}
                </p>
                <button
                    onClick={() => handleDelete(index)}
                    type="button"
                    className="cursor-pointer w-[5%] h-8 bg-[#048178] rounded border-none"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 25"
                        fill="none"
                    >
                        <path
                            d="M10 15.0227L10 12.0227"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                        <path
                            d="M14 15.0227L14 12.0227"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                        <path
                            d="M3 7.02271H21V7.02271C20.0681 7.02271 19.6022 7.02271 19.2346 7.17495C18.7446 7.37793 18.3552 7.76728 18.1522 8.25734C18 8.62488 18 9.09082 18 10.0227V16.0227C18 17.9083 18 18.8511 17.4142 19.4369C16.8284 20.0227 15.8856 20.0227 14 20.0227H10C8.11438 20.0227 7.17157 20.0227 6.58579 19.4369C6 18.8511 6 17.9083 6 16.0227V10.0227C6 9.09082 6 8.62488 5.84776 8.25734C5.64477 7.76728 5.25542 7.37793 4.76537 7.17495C4.39782 7.02271 3.93188 7.02271 3 7.02271V7.02271Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                        <path
                            d="M10.0681 3.3933C10.1821 3.28698 10.4332 3.19303 10.7825 3.12603C11.1318 3.05902 11.5597 3.02271 12 3.02271C12.4403 3.02271 12.8682 3.05902 13.2175 3.12603C13.5668 3.19303 13.8179 3.28698 13.9319 3.3933"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </button>
            </div>
        );
    })} */}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:block mx-auto my-3 pr-2">
            <div className="md:flex justify-end gap-4">
             <CancelButton color="#fff" handleClick={() => { Oncancel() }} />
             <SubmitButton isLoading={isLoading} />
            </div>
          </div> 
        </div>
      </form>
    </div>
  );
}

export default EditBuildings;
