import React, { useState } from 'react'
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { notification } from 'antd';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import { useFormik } from 'formik';
import * as yup from 'yup';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuDateTime, NuInputText, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx", "mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateMeetingPoints() {
  const { value: value } = useFetchMataData('Category_Points')

  const {data:user} =UseDropDown('/user/drop-down')
  const navigate = useNavigate()
  const {data:meeting} =UseDropDown('/meeting/drop-down')
  const [productList, setProductList] = useState([]);
console.log("pro",productList);
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null)
  // const [division, setDivision] = useState([]);
  const [api,contextHolder] = notification.useNotification();
  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    meetingId:"",
    topic: "",
    category: "",
    points: "",
    dueDate: "",
    comments: "",
    
  })
  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item => {
      if (item.size < maxFileSize) {
        fileArr.push(item)
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item => {
      if (item.size < maxFileSize) {
        let fileName = item?.name
        console.log('fname', fileName);
        let fileExtention = fileName.split(".").reverse()[0]
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName)
        } else {
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
    })
    console.log(tempArr, 'temp');
    setImages(tempArr)
  }

  console.log("cccc", selectedFile);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      const withAgendaValues={
        ...value,
        agendaItems: productList
      }
      let formData = new FormData();
      formData.append('json_data', JSON.stringify(withAgendaValues));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", [])
      }

      addFormData('/meeting-point/new-meeting', formData, resetForm);
    }
  })
  const handleAddProducts = () => {
    const products = formik.values.agendaItems
    // console.log("man",products)
    setProductList((prevList) => [...prevList, products]);
    formik.setFieldValue('agendaItems', '');
  };
  const handleDeleteProduct = (index) => {
    setProductList((prevList) => prevList.filter((_, i) => i !== index));
  };


  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
      <div className="flex justify-between px-4">
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Meetings</p>) },
          { title: (<p className=' text-[12px] '>Meeting-Points</p>) },
          { title: (<p className=' text-[12px] '>Create</p>) },
        ]} />
        <BackButton />
      </div>
      <div className="w-full h-full">
        <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
          {contextHolder}
          <div className="w-[96%] mx-auto  ">
            <form onSubmit={formik.handleSubmit} className="w-full ">
              <div className='flex gap-8 md:flex-nowrap p-6  mt-4  bg-white rounded-lg'>

                {/* <div className='flex flex-wrap md:flex-nowrap pb-4 bg-white'> */}
                {/* <div className='w-full md:w-1/2 rounded-md border border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 flex-col'>

                              <h3 className=" text-[#231942] w-full text-center capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"Product Image"}</h3>

                                  <CustomImgUpload
                                      borderStyle="dashed"
                                      isRemove={true}
                                      width='w-full mx-auto'
                                      justify='justify-center'
                                      removeFunction={handleImageRemove}
                                      handleChange={handleChangeImg}
                                      preview={image.preview || createNew.image || ""}
                                  />
                              </div> */}
                <div className='w-full'>
                  <div className='flex h-[calc(100vh-180px)] flex-wrap w-full'>
                  <NuSelect
                      label="Meeting Id"
                      options={meeting}
                      formik={formik}
                      placeholder="Choose"
                      name="meetingId"
                      width="w-full md:w-1/3 md:-mt-2"
                    />
                    <NuInputText
                      label='Topic'
                      placeholder='topic'
                      formik={formik}
                      name='topic'
                      width='w-full md:w-1/3 md:-mt-2' />

                    <NuSelect
                      label="Category"
                      options={value}
                      formik={formik}
                      placeholder="Choose"
                      name="category"
                      width="w-full md:w-1/3 md:-mt-2"
                    />
                     <NuInputText
            // isRequired={true}
            label=" Points"
            placeholder="header"
            formik={formik}
            name="points"
           width="w-full md:w-1/3 md:-mt-2"
          />
                   <NuDatePicker
                    label="Due-Date"
                  
                    formik={formik}
                    name="dueDate"
                    width="w-full md:w-1/3 md:-mt-2"
                   />
             
             <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3 md:-mt-2'
            />
                {/* <div className="flex flex-col md:flex-row justify-start gap-2"> 
                <div className="w-full flex flex-col ">
                <div className="flex justify-start ">
                  <NuInputText
                   type="text"
                   label="Agenda items"
                   placeholder="Agenda Items"
                   formik={formik}
                   name="agendaItems"
                   width="w-11/12"
                  
                   
                   />
                   <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-9 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
                </div>
                <div className="w-12/12 h-28 ml-[4.1%] rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {productList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
                  </span>
                ))}
              </div>


                  </div>

                  </div> */}

              
                    <Attachement
                      handleChange={handleImageChange}
                      preview={images || ''}
                      width="w-full"
                      label="Attach"
                      multiple={true}
                    />

                    <div className="w-full md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton handleClick={() => { Oncancel() }} />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
                  </div>

                </div>
              </div>


              
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateMeetingPoints