import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const AssignedProject = () => {
  return (
    <SettingsMetaData propertyName={"Assigned Project"} viewPropertyName={"Assigned Project"} />

  )
}

export default AssignedProject