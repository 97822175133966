import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const EquipmentType = () => {
  return (
    <SettingsMetaData propertyName={"EquipmentType"} viewPropertyName={"Equipment Type"} />

  )
}

export default EquipmentType