import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { Modal } from 'antd';

const ViewProduct = () => {
    const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
//   const { medicalProductsViewId } = useParams();
  const location = useLocation();
    const data = location.state.data;
    console.log(location.state,'state');

    const [url,setUrl] = useState(false);

 
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };

    return (
        <>
                <div className=' bg-[#E6F2F2] h-full'>
            <div className='mx-6 pt-3'>
                <BreadCrumb items={[
                    { title: <p className='text-[13px]'>Clinic</p> },
                    { title: <p className='text-[13px]'>Medical Products</p> },
                    { title: <p className='text-[13px]'>View</p> },

                ]}
                />
            </div>
            <div className='flex justify-end'>
                <div className='w-1/2 flex justify-end'>
                    <div className='pr-6 flex gap-3'>
                        <div className='flex justify-end gap-2'>
                        <Link to={`/main/clinic/medical-products/${officeId}`}><BackButton link={true} /></Link>
          <Link to={`/main/clinic/medical-products/${officeId}/edit/${data.productId}`} state={{ from: `/main/clinic/medical-products/${officeId}/view/${data?.productId}`, data: data }}><EditButton height='8' /></Link>
       
                            </div>
                    </div>
                </div>
            </div>
            {/*  Section-1 */}
            <div className='px-6 py-5 flex  gap-9'>
                {/* <div className='flex flex-col gap-2 px-5 py-3 w-[30%] h-auto bg-[#FFF] rounded-[5px] p-5'>
                  
                    <div className='flex gap-5 h-full'>
                        <div className='w-full h-full flex items-center p-5 gap-2 bg-[#E6F2F2] rounded-[5px]'>
                        {data?.image && <img className='w-full h-auto' src={data?.image } alt='Product' />}
                        </div>
                        <div className='mx-10 border-r border-#E6F2F2' />
                        
                    </div>
                </div> */}
                <div className='flex flex-col gap-2 px-5 py-3 w-full h-[calc(100vh-170px)] bg-[#FFF] rounded-[5px] p-5'>
                    <h3 className='text-[#212121] text-sm font-bold leading-normal'>Products Information</h3>
                    <div className='flex gap-5'>
                        {/* <div className='w-1/2 h-auto flex items-center p-5 gap-2 bg-[#E6F2F2] rounded-[5px]'>
                        </div>
                        <div className='mx-10 border-r border-#E6F2F2' /> */}
                        <div className='w-full'>
                            <div className='pb-8 pt-5 flex flex-wrap gap-6 w-auto'>
                            <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Product Name</h4>
                                    <p className='text-[#4D4D4D]'>{data?.productName || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Category</h4>
                                    <p className='text-[#4D4D4D]'>{data?.category || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Sub-Category</h4>
                                    <p className='text-[#4D4D4D]'>{data?.subCategory || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Product Code</h4>
                                    <p className='text-[#4D4D4D]'>{data?.productCode || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Unit</h4>
                                    <p className='text-[#4D4D4D]'>{data?.unit || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Brand</h4>
                                    <p className='text-[#4D4D4D]'>{data?.brand || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Manufacturer</h4>
                                    <p className='text-[#4D4D4D]'>{data?.manufacturer || '-'}</p>
                                </div>
                            
                            </div> 
                           
                        </div>
                    </div>
                    <h3>Product Attachement Files</h3>
                    <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
    {data?.image?.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                                // onClick={() => handleImageClick(imgs)}
                                                onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                                >
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    <img src={imgs?.file} alt={imgs?.file} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    </div>
                </div>
               


            </div>
          
          
        </div>
        
        
        <Modal
        open={showFilterDialog}
        title={"Medical Products"}
        width={"80%"}
        
        className="my-modal"
        onCancel={closeFilterDialog}
        footer={[]}
      >
        <div className='w-full h-[80vh] overflow-hidden'>
        <img src={url} alt="" className='' width={"100%"} />
        </div>
           </Modal>
        </>

    )
}

export default ViewProduct