import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Shift = () => {
  return (
    <SettingsMetaData propertyName={"Shift_DuttyAllocation"} viewPropertyName={"Shift "} />

  )
}

export default Shift