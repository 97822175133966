import React from 'react'

export default function Catering() {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="#b1d8d5" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M9.73353 0.380004C9.62945 0.409146 9.49623 0.496574 9.37133 0.621469C9.12987 0.867098 9.07158 1.07942 9.0924 1.71639L9.10489 2.16602H9.99998H10.8951L10.9076 1.71639C10.9284 1.07942 10.8701 0.862935 10.6286 0.621469C10.3622 0.359188 10.0957 0.28425 9.73353 0.380004Z" fill="#b1d8d5"/>
<path id="Vector_2" d="M9.4172 2.74449C6.07415 2.98179 3.17657 5.30069 2.07332 8.62292C1.77773 9.51801 1.64451 10.284 1.6237 11.2582L1.61121 11.7578H10H18.3889L18.3764 11.2582C18.3556 10.284 18.2224 9.51801 17.9268 8.62292C17.2107 6.46639 15.7369 4.70119 13.7802 3.66456C12.4605 2.96097 10.9243 2.64041 9.4172 2.74449ZM10.1874 4.51385C10.3123 4.63458 10.3206 4.73866 10.2082 4.87605C10.1374 4.9718 10.0916 4.98429 9.70446 5.00927C8.93843 5.05923 8.08914 5.28404 7.43968 5.60461C6.60288 6.02509 5.69947 6.79944 5.06666 7.64041C4.52128 8.36064 4.08831 9.30153 4.08831 9.75531C4.08831 10.0426 3.75942 10.1758 3.58872 9.95515C3.50962 9.85939 3.5013 9.80944 3.52628 9.60128C3.58872 9.04757 3.8968 8.31901 4.37557 7.6071C5.56624 5.83774 7.46466 4.63874 9.3839 4.45556C10.0084 4.39312 10.075 4.39728 10.1874 4.51385Z" fill="#b1d8d5"/>
<path id="Vector_3" d="M0.0249792 12.474C0.10408 12.7946 0.516236 13.2026 0.932556 13.3733L1.15321 13.4648H10C18.3805 13.4648 18.8551 13.4607 19.0341 13.3899C19.4671 13.2192 19.8959 12.8029 19.975 12.474L20 12.3616H10H0L0.0249792 12.474Z" fill="#b1d8d5"/>
</g>
</svg>
  )
}

