import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Status from './Brand'
import Brand from './Brand'
import Manufacturer from './Manufacturer'
import Unit from './Unit'
import Category from './Category'
import SubCategory from './SubCategory'

const Product = () => {
  return (
    <>
    <TextMediumSM
     content="Product" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
    {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
     </div> */}
     {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <SubCategory/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
     <Category/>
     </div> */}
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Brand/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Manufacturer/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Unit/>
     </div>
     
    </div>
    <Category/>
     </>
  )
}

export default Product