import React from "react"
import { Route } from "react-router-dom"
import MainVendor from "../Views/Web/Vendor/MainVendor";
import ViewVendor from "../Views/Web/Vendor/ViewVendor";
import EditVendor from "../Views/Web/Vendor/EditVendor";
import CreateVendor from "../Views/Web/Vendor/CreateVendor";
import VendorIndex from "../Views/Web/Vendor/VendorIndex"
import ViewMoreVendor from "../Views/Web/Vendor/ViewMoreVendor";


const VendorRoutes = () => {
    return(
        <Route path='vendors/:officeId' element={<MainVendor />}>
         <Route index element={<VendorIndex/>} />
         <Route path='create' element={<CreateVendor/>} />
         <Route path='edit/:EditVendorId' element={<EditVendor />} />
         <Route path='view/:ViewVendorId' element={<ViewVendor />} />
         <Route path='viewMore/:ViewMoreVendorId' element={<ViewMoreVendor />} />
       </Route>
    )
}

export default VendorRoutes;