import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const RoomFacilities = () => {
  return (
    <SettingsMetaData propertyName={"RoomFacilities"} viewPropertyName={"Room Facilities"} />

  )
}

export default RoomFacilities