import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import VisitPurpose from './VisitPurpose'

const VisitorsTracking = () => {
  return (
    <>
    <TextMediumSM content="Visitors Tracking" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <VisitPurpose/>
     </div>
    
    </div>
     </>
  )
}

export default VisitorsTracking