import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const MaxOccupancy = () => {
  return (
    <SettingsMetaData propertyName={"MaxOccupancy"} viewPropertyName={"Max Occupancy"} />

  )
}

export default MaxOccupancy