import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axiosCommon';

const UseGetDataSearch = (url, params=null) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  async function getData() {
    try {
      const res = await axiosInstance.get(url, { params });
      console.log(res,'duby');
      res?.data?.data?.data && setData(res?.data?.data?.data)
      res?.data?.data?.total && setTotalCount(res?.data?.data?.total)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, [url, params]);

  return { data, totalCount , getData };
};

export default UseGetDataSearch;