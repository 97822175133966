import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Category from './category'
import Type from './type'
import Status from './status'

const ContactList = () => {
  return (
    <>
    <TextMediumSM content="Contact List" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
    <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Type/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Category/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Status/>
     </div>
     
    </div>
     </>
  )
}

export default ContactList