import React from 'react'

export default function Incidents() {
  return (
    <svg width="14" height="14" viewBox="0 0 19 19" fill="#b1d8d5" xmlns="http://www.w3.org/2000/svg">
<path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M0.470108 5.32373C0.470108 4.13026 0.944214 2.98566 1.78813 2.14175C2.63204 1.29784 3.77663 0.82373 4.97011 0.82373L13.9701 0.82373C15.1636 0.82373 16.3082 1.29784 17.1521 2.14175C17.996 2.98566 18.4701 4.13026 18.4701 5.32373V14.3237C18.4701 15.5172 17.996 16.6618 17.1521 17.5057C16.3082 18.3496 15.1636 18.8237 13.9701 18.8237H4.97011C3.77663 18.8237 2.63204 18.3496 1.78813 17.5057C0.944214 16.6618 0.470108 15.5172 0.470108 14.3237V5.32373ZM8.34061 5.50936C8.31649 5.3467 8.32771 5.18076 8.37352 5.02283C8.41932 4.86491 8.49863 4.71871 8.60604 4.5942C8.71344 4.46969 8.84643 4.3698 8.99592 4.30133C9.14542 4.23285 9.30792 4.19741 9.47236 4.19741C9.63679 4.19741 9.79929 4.23285 9.94879 4.30133C10.0983 4.3698 10.2313 4.46969 10.3387 4.5942C10.4461 4.71871 10.5254 4.86491 10.5712 5.02283C10.617 5.18076 10.6282 5.3467 10.6041 5.50936L9.47011 13.1987L8.34061 5.50936ZM9.47011 13.1987C9.76848 13.1987 10.0546 13.3173 10.2656 13.5282C10.4766 13.7392 10.5951 14.0254 10.5951 14.3237C10.5951 14.6221 10.4766 14.9082 10.2656 15.1192C10.0546 15.3302 9.76848 15.4487 9.47011 15.4487C9.17174 15.4487 8.88559 15.3302 8.67461 15.1192C8.46363 14.9082 8.34511 14.6221 8.34511 14.3237C8.34511 14.0254 8.46363 13.7392 8.67461 13.5282C8.88559 13.3173 9.17174 13.1987 9.47011 13.1987Z" fill="#b1d8d5"/>
</svg>

  )
}
