import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const IncidentType = () => {
  return (
    <SettingsMetaData propertyName={"Incident_Type"} viewPropertyName={"Incident Type"} />

  )
}

export default IncidentType