import React, { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker,  NuInputText, NuSelect, NuTextArea,NuInputNumber } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Navigate,useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dropdown from 'antd/es/dropdown/dropdown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function CreateVehicles() {
  const { value: FuelType} = useFetchMataData('FuelType') 
  const { value: VehicleType} = useFetchMataData('VehicleType')
  const navigate=useNavigate()
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:vehicle} = UseDropDown('/vehicle/drop-down')
  const {data:employees} = UseDropDown('/employees/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const {data:vendors} =UseDropDown('/vendors/drop-down')
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData ();
  const [createNew, setCreateNew] = useState({
      // reqType: "RoomService",
      allocatedTo:"",
      companyId:"",
      name:"",
      brand: "",
      model:"",
      yearOfMan:"",
      fuelType:"",
      purchasedOn:"",
      condition:"",
      plateNo:"",
      chasisNo:"",
      assignedTo:"",
      comments:"",
      vendor:"",
      vehicleType:""
  })
 

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
         

          addFormData('/vehicle/add-vehicle', value, resetForm);
      }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Invontory</p>) },
        { title: (<p className=' text-[12px] '>Vehicles</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-140px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          <NuSelect
              label="Allocated To"
              options={employees}
              formik={formik}
              placeholder="Choose"
              name="allocatedTo"
              width="w-full md:w-1/2 lg:w-1/3"
          />

          <NuSelect
              label="Company Id"
              options={[
                { value: 1, label: "Company" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="companyId"
              width="w-full md:w-1/2 lg:w-1/3"
          />  

            <NuInputText
              // isRequired={true}
              label="Name"
              placeholder="Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Brand"
              placeholder=" Brand"
              formik={formik}
              name="brand"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Modal"
              placeholder=" Modal"
              formik={formik}
              name="model"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              label="Year Of Manufacture"
              placeholder="year"
              formik={formik}
              name="yearOfMan"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label="Fuel Type"
              options={FuelType}
              placeholder=" Fuel Type"
              formik={formik}
              name="fuelType"
              width="w-full md:w-1/3"
            />

            <NuDatePicker
              label="Purchased On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="purchasedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Condition"
              placeholder=" Condition"
              formik={formik}
              name="condition"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Plate No"
              placeholder=" Plate No"
              formik={formik}
              name="plateNo"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Chasis No"
              placeholder=" Chasis No"
              formik={formik}
              name="chasisNo"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Assigned To"
              // options={user}
              options={employees}
              formik={formik}
              placeholder="Choose"
              name="assignedTo"
              width="w-full md:w-1/2 lg:w-1/3"
          />
            <NuSelect
              label="Vendor"
              options={vendors}
              formik={formik}
              placeholder="Choose"
              name="vendor"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuSelect
              label="Vehicle Type"
              options={VehicleType}
              formik={formik}
              placeholder="Choose"
              name="vehicleType"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
              {/* <NuInputNumber
              label="Id"
              placeholder="Id"
              formik={formik}
              name="roomserviceId"
              width="w-full md:w-1/3"
            /> */}
            {/* <NuSelect
              label="Menu Id"
              options={[
                { value: 1, label: "Veg" },
                { value: 2, label: "Non-veg" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="menuId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
          
            {/* <NuInputNumber
              label="Prpared By"
              placeholder="prparedBy"
              formik={formik}
              name="prparedBy"
              width="w-full md:w-1/3"
            /> */}
             {/* <NuInputNumber
              label="Bed No"
              placeholder="Bed No"
              formik={formik}
              name="Bed No"
              width="w-full md:w-1/3"
            /> */}
            
            
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateVehicles