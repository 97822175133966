import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from 'yup';
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import { NuSelect, NuInputText, NuInputNumber, NuLabel, NuInputEmail, NuMobileNumber, NuSelectwithSearch } from "../../../../Components/Utils/NuInput";
import CustomImgUpload from "../../../../Components/Utils/CustomImgUpload";
import { CountryMIN } from "../../../../assets/CountryMini";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";


function EditUser() {
  const { id } = useParams();
  const location = useLocation();
  const data = location.state.data
  const from = location.state.from
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    city: data?.city,
    streetName: data?.streetName,
    district: data?.district,
    state: data?.state,
    country: data?.country,
    postalCode: data?.postalCode,
    name: data?.name,
    designation: data?.designation,
    division: data?.division,
    email: data?.email,
    phone: data?.phone?.number,
    userImage: data?.userImage,
    countryCode: data?.phone?.countryCode,
    status: data?.status,
  })
  const { contextHolder, editFormData, isLoading, Oncancel } = useEditFormData();

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleRemoveImg = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, userImage: "" }))
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      email: yup.string().required('Email is required!'),
      status: yup.string().required('Status is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append('userData', JSON.stringify(value));
      formData.append('userImage', image.raw);

      editFormData(`/user/edit-user-details/${id}`, formData, resetForm, from); // params: url, formData, resetForm, onCancel
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="w-full h-full p-2 bg-[#F1F2FB] overflow-y-scroll">
        {contextHolder}
        <div className="w-[96%] mx-auto ">
          <NuLabel label={"Profile"} />
          <CustomImgUpload
            removeFunction={handleRemoveImg}
            isRemove={true}
            handleChange={handleChangeImg}
            preview={image.preview || createNew.userImage || ""}
          />
          <div className="w-full flex justify-start gap-x-10 gap-y-2 content-start flex-wrap">
            <NuInputText label='Name' placeholder='name' formik={formik} name='name' width='w-full md:w-[45%]' />
            <NuMobileNumber
              nunCode={"countryCode"}
              label="Phone"
              placeholder="phone"
              formik={formik}
              name="phone"
              width="w-full md:w-[45%]"
            />
            <NuInputEmail label='Email' placeholder='email' formik={formik} name='email' width='w-full md:w-[45%]' />
            <NuInputText label='Desigination' placeholder='designation' formik={formik} name='designation' width='w-full md:w-[45%]' />
            <NuSelect
              label='Division / Department'
              options={[
                { value: 'A', label: 'A' },
                { value: 'B', label: 'B' },
                { value: 'C', label: 'C' },
                { value: 'D', label: 'D' },
              ]}
              formik={formik}
              placeholder='Choose'
              name='division'
              width='w-full md:w-[45%]'
            />
            <NuSelect
              label='Status'
              options={[
                { value: 1, label: 'Active' },
                { value: 0, label: 'In-Active' },
              ]}
              isRequired={true}
              formik={formik}
              placeholder='Choose'
              name='status'
              width='w-full md:w-[45%]'
            />
          </div>
          <p className="text-xs font-semibold text-[#000000] pt-3">Address</p>
          <div className="w-full flex justify-start gap-x-10 gap-y-2 content-start flex-wrap">
            <NuSelectwithSearch
              label="Country"
              options={CountryMIN}
              formik={formik}
              placeholder="Choose"
              name="country"
              width="w-full md:w-[45%]"/>
            <NuInputText label='State' placeholder='state' formik={formik} name='state' width='w-full md:w-[45%]' />
            <NuInputText label='District' placeholder='district' formik={formik} name='district' width='w-full md:w-[45%]' />
            <NuInputText label='City' placeholder='city' formik={formik} name='city' width='w-full md:w-[45%]' />
            <NuInputText label='Street Name' placeholder='streetName' formik={formik} name='streetName' width='w-full md:w-[45%]' />
            <NuInputNumber label='Postal Code' placeholder='postalCode' formik={formik} name='postalCode' width='w-full md:w-[45%]' />
          </div>
          <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton handleClick={() => { Oncancel() }} />
              <SubmitButton />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditUser;