import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const FuelType = () => {
  return (
    <SettingsMetaData propertyName={"FuelType"} viewPropertyName={"Fuel Type"} />

  )
}

export default FuelType