import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { notification } from 'antd';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputText, NuMobileNumber, NuSelect, NuSelectCurrency, NuTextArea, NuTimePicker } from '../../../../Components/Utils/NuInput';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseAddData from '../../../../Components/Hooks/useAddData';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import Room from '../Rooms';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function CreateOccupants() {
  const { data: employee } = UseDropDown('/employees/drop-down');
  // const { data: bed } = UseDropDown('/rooms/drop-down');
  const { value: BedType} = useFetchMataData('BedType')
  const { value: EmployeeType} = useFetchMataData('OccupantType')
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
    const [createNew, setCreateNew] = useState({
      occupantName: "",
      refId: "",
      refType: "",
      dob: "",
      bedId: ""
    })
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
      }),
      onSubmit: async (value, { resetForm }) => {
          addFormData('/occupants/new-occupant', value, resetForm);
      }
  })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
            <div className="flex justify-between px-4">
                <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Accomodation</p>) },
                    { title: (<p className=' text-[12px] '>Occupants</p>) },
                    { title: (<p className=' text-[12px] '>Add</p>) },
                ]} />
                <BackButton />
            </div>
            <form onSubmit={formik.handleSubmit} className='p-4'>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden ">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInputText
              isRequired={true}
              label="Occupant Name"
              placeholder="Occupant Name"
              formik={formik}
              name="occupantName"
              width="w-full md:w-1/3"
            />
            <NuSelect
              isRequired={true}
              label="Employee"
              options={employee}
              formik={formik}
              placeholder="Choose"
              name="refId"
              width="w-full md:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label="Employee Type"
              options={EmployeeType}
              formik={formik}
              placeholder="Choose"
              name="refType"
              width="w-full md:w-1/3"
            />
            <NuDatePicker
              // isRequired={true}
              label="Date Of Birth"
              formik={formik}
              name="dob"
              width="w-full md:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label="Bed"
              options={BedType}
              formik={formik}
              placeholder="Choose"
              name="bedId"
              width="w-full md:w-1/3"
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel() }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateOccupants