import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuDateTime, NuInputNumber, NuInputText, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import axiosInstance from '../../../../Services/axiosCommon';
import { notification } from 'antd';
import NuDate from '../../../../Components/Utils/NuDate';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditTakeAwayRequest() {
  const { value: MealType} = useFetchMataData('MealType') 
  const { value: FoodCategory} = useFetchMataData('FoodCategory')
  const { value: StatusReq} = useFetchMataData('Status_Req')

  // const {data:occupant} =UseDropDown('/occupants/drop-down')
  const {data:user} =UseDropDown('/user/drop-down')
  const navigate = useNavigate()
  
  const { EditTakeAwayRequest } = useParams();
  const location=useLocation()
  const data =location.state.data
  const from=location.state.from
  // console.log(location.state,'from');
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
  const [createNew, setCreateNew] = useState({
    date: (data?.date && NuDate({value:data?.date,format:"YYYY-MM-DD"}) )||"",
    ethnicity: data?.ethnicity,
    mealType: data?.mealType,
    foodCategory: data?.foodCategory,
    deliveryLocation: data?.deliveryLocation,
    requestedBy: data?.requestedBy,
    startDate: (data?.startDate && NuDate({value:data?.startDate,format:"YYYY-MM-DD"}) )||"",
    status: data?.status,
    endDate: (data?.endDate && NuDate({value:data?.endDate,format:"YYYY-MM-DD"}) )||"",
     
  })
  console.log(data);

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
    
          editFormData(`/take-away-requests/edit/${EditTakeAwayRequest}`, value, resetForm, from);

      }
  })
return (
  <div className="w-full h-full p-2 bg-[#F1F2FB]">
  <div className="flex justify-between px-4">
      <BreadCrumb items={[
      {
          title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
          </Link>)
      },
      { title: (<p className=' text-[12px] '>Catering</p>) },
      { title: (<p className=' text-[12px] '>TakeAway Request</p>) },
      { title: (<p className=' text-[12px] '>Edit</p>) },
  ]} />
  <BackButton/>
  </div>

  <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
        <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
        <NuDatePicker
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
        <NuInputText
            // isRequired={true}
            label=" Ethnicity"
            placeholder=" Ethnicity"
            formik={formik}
            name="ethnicity"
            width="w-full md:w-1/3"
          />
          <NuSelect
              label="Meal Type"
              options={MealType}
              formik={formik}
              placeholder="Choose"
              name="mealType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              label="FoodCategory"
              options={ FoodCategory}
              formik={formik}
              placeholder="Choose"
              name="foodCategory"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label=" Delivery Location"
              placeholder=" Location"
              formik={formik}
              name="deliveryLocation"
              width="w-full md:w-1/3"
            />
             <NuSelect
              label="Requested BY"
              options={user}
              formik={formik}
              placeholder="Choose"
              name="requestedBy"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="startDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="End Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="endDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuSelect
              label="Status"
              options={ StatusReq}
              formik={formik}
              placeholder="Choose"
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
        
           
          <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton handleClick={()=>{navigate(-1)}} />
              <SubmitButton />
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
)
}

export default EditTakeAwayRequest