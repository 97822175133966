import React, { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker,  NuInputText, NuSelect, NuTextArea,NuInputNumber, NuSelectwithSearch } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { json, Link, Navigate,useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dropdown from 'antd/es/dropdown/dropdown';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateProducts() {
  const navigate=useNavigate()
  const { value: Category } = useFetchMataData('Category_Products')
  const { value: SubCategory } = useFetchMataData('SubCategory_Products')


  const [images, setImages] = useState([]);
    const [selectedFile,setSelectedFile] =useState(null)
    const [ api, contextHolder ] = notification.useNotification();
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const {data:suppliers} = UseDropDown('/vendors/drop-down')
  const {data:menus} = UseDropDown('/food-menu/drop-down')
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, isLoading } = useAddFormData ();
  const [createNew, setCreateNew] = useState({
      // reqType: "RoomService",
      itemCode:"",
      productName:"",
      category: "",
      subCategory:"",
      unit:"",
      brand:"",
      manufaturer:"",
      comments:"",
  })
 
  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item=>{
      if (item.size < maxFileSize ){
        fileArr.push(item)
      }else{
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })    
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item=>{
      if (item.size < maxFileSize ){
      let fileName = item?.name
      console.log('fname',fileName);
      let fileExtention =  fileName.split(".").reverse()[0]
      if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      }else{
        let x = URL.createObjectURL(item)
        tempArr.push(x)
      }
    }
    })
    console.log(tempArr,'temp');
    setImages(tempArr)
}
  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
         
        const formData = new FormData();
        formData.append("itemDetails",JSON.stringify(value))
        if (selectedFile!=null){
          selectedFile.forEach(element => {
             formData.append("itemImage",element)
          });
        }
        else{
          formData.append("itemImage",[])
        }

          addFormData('/products/new-item', formData, resetForm);
      }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Procurement</p>) },
        { title: (<p className=' text-[12px] '>Products</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">

          <NuInputText
              // isRequired={true}
              label="Product Name"
              placeholder="productName"
              formik={formik}
              name="productName"
              width="w-full md:w-1/3"
            />
          <NuInputText
              // isRequired={true}
              label="Item Code"
              placeholder="itemCode"
              formik={formik}
              name="itemCode"
              width="w-full md:w-1/3"
            />
             {/* <NuInputText
              isRequired={true}
              label="Is Asset"
              placeholder="isAsset"
              formik={formik}
              name="manufacturer"
              width="w-full md:w-1/3"
            /> */}
            
                 
            <NuSelect
              label="Category"
              options={Category}
              formik={formik}
              placeholder="Category"
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              label="Sub Category"
              options={SubCategory}
              formik={formik}
              placeholder="Sub Category"
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Unit"
              placeholder="Unit"
              formik={formik}
              name="unit"
              width="w-full md:w-1/3"
            />
            <NuInputText
              label="Brand"
              placeholder="Brand"
              formik={formik}
              name="brand"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Manufacturer"
              placeholder="Manufacturer"
              formik={formik}
              name="manufaturer"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />

<Attachement
                  handleChange={handleImageChange}
                  preview={images||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateProducts