import { useEffect, useState } from "react";
import { EditTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
import axiosInstance from "../../../../Services/axiosCommon";
import { MainHeading, SecondHeading, SmallHeading } from "../../../../Components/Utils/Heading";
import { EditButton } from "../../../../Components/Utils/Buttons";
import { TextBold } from "../../../../Components/Utils/Text";
import useFetchById from "../../../../Components/Hooks/useFetchById";

const CompanyInfoIndex = () => {
  const [open, setOpen] = useState(false);
  const data = useFetchById(`/company-info/company-details/1`);

  return (
    <>
      <div className=" relative w-full h-48">
        <div className=" w-full h-28 rounded-md bg-[#048178]"></div>
        <div className=" w-28 h-28 rounded-full border border-[#048178] bg-white flex justify-center items-center  absolute left-[8%] top-[35%]">
          <div className=" w-[98px] h-[98px] border border-dashed border-[#048178] text-white text-4xl capitalize overflow-hidden flex items-center justify-center rounded-full bg-mainColor-500 ">
          {data.compLogo? <img src={data.compLogo} width="130%" alt="#" />:`${data.companyName ? data.companyName.charAt(0) : ""}`}
          </div>
        </div>
        <div className=" w-full  flex justify-end">
          <div className=" xl:w-[80%] lg:w-[75%] w-[72%] mt-2 py-2 flex items-center justify-end md:justify-between">
            <div className="hidden md:block">
              <MainHeading title={data.companyName || "-"} />
              <a
                className=" text-[#6C6580] font-medium text-base"
                target="_blank"
                rel="noreferrer"
                href={`https://${data.website}`}
              >
                {data.website || "-"}
              </a>
            </div>
            <Link to='/settings/company-info/edit' state={{ data : data }} > <EditButton /> </Link> 
          </div>
        </div>
      </div>

      <div className="md:hidden block ml-5">
              <MainHeading title={data.companyName || "-"} />
              <a
                className=" text-[#6C6580] font-medium text-base"
                target="_blank"
                rel="noreferrer"
                href={`https://${data.website}`}
              >
                {data.website || "-"}
              </a>
      </div>

      <div className=" w-full mt-2">
        <SecondHeading title="Company Info" />
        <div className=" w-full h-[1px] bg-slate-300 my-4"></div>
      </div>
      <div className=" w-full flex flex-wrap">
        <div className=" w-full grid grid-cols-1 md:grid-cols-4 md:gap-3 gap-0">
        <SubComponent
            width="w-1/2"
            label="Business Industry"
            value={data.businessIndustry || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Registration No"
            value={data.registrationNo || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Established On"
            value={data.establishedOn || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Tax Id"
            value={data.taxId || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Email"
            value={data.email || "-"}
          />
          <SubComponent
            width="w-full"
            label="Phone"
            value={data.phone || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Mobile"
            value={data.mobile?.display || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="City"
            value={data.address?.city || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="State"
            value={data.address?.state || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Country"
            value={data.address?.country || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Pincode"
            value={data.address?.postalCode || "-"}
          />
        </div>
        <div className=" w-full mt-2">
        <SecondHeading title="Social Media" />
        <div className=" w-full h-[1px] bg-slate-300 my-4"></div>
       </div>
      <div className="flex flex-col justify-start gap-6 pb-3">
        {data.socialMediaLinks?.length > 0 ? (
          data.socialMediaLinks.map(element => (
            <p className=" text-mainColor-400 font-semibold text-[13px] lg:text-[14px] xl:text-[15px]" key={element}>{element}</p>
          ))
        ) : (
          <p className=" text-mainColor-400 font-semibold text-[13px] lg:text-[14px] xl:text-[15px]">Social media data is not available.</p>
        )}
      </div>
    </div>
  </>
);};

export default CompanyInfoIndex;

function SubComponent({ label, value }) {
  return (
    <div className={` my-3 px-2`}>
      <div className=" w-full mx-auto">
        <SmallHeading title={label} />
        <TextBold text={value} />
      </div>
    </div>
  );
};