import React from 'react'
import { Route } from 'react-router-dom';
import MainAccomodation from '../Views/Web/Accommodation/mainAccomodation';
import MainCampInfo from '../Views/Web/Accommodation/CampInfo/mainCampInfo';
import CampInfo from '../Views/Web/Accommodation/CampInfo';
import ViewCamp from '../Views/Web/Accommodation/CampInfo/viewPage';
import CreateCamp from '../Views/Web/Accommodation/CampInfo/create';
import EditCamp from '../Views/Web/Accommodation/CampInfo/edit';
import MainRoom from '../Views/Web/Accommodation/Rooms/mainRoom';
import Room from '../Views/Web/Accommodation/Rooms';
import CreateRoom from '../Views/Web/Accommodation/Rooms/addPage';
import EditRoom from '../Views/Web/Accommodation/Rooms/editPage';
import ViewRoom from '../Views/Web/Accommodation/Rooms/viewPage';
import MainVisitorsTracking from '../Views/Web/Accommodation/VisitorsTracking/mainVisitorsTracking';
import VisitorTracking from '../Views/Web/Accommodation/VisitorsTracking';
import CreateVisitorTracking from '../Views/Web/Accommodation/VisitorsTracking/addPage';
import EditVisitorTracking from '../Views/Web/Accommodation/VisitorsTracking/editPage';
import ViewVisitorTracking from '../Views/Web/Accommodation/VisitorsTracking/viewPage';

import CheckIn from '../Views/Web/Accommodation/CheckInOut/CheckIn';
import CreateCheckIn from '../Views/Web/Accommodation/CheckInOut/CheckIn/addPage';
import CheckOut from '../Views/Web/Accommodation/CheckInOut/CheckOut';
import CheckInOut from '../Views/Web/Accommodation/CheckInOut';

import BuildingsIndex from '../Views/Web/Accommodation/Buildings';
import MainBuildings from '../Views/Web/Accommodation/Buildings/MainBuildings';
import ViewBuildings from '../Views/Web/Accommodation/Buildings/ViewBuildings';
import CreateBuildings from '../Views/Web/Accommodation/Buildings/CreateBuildings';
import EditBuildings from '../Views/Web/Accommodation/Buildings/EditBuildings';
import ViewServiceRequest from '../Views/Web/Accommodation/ServiceRequest/ViewServiceRequest';
import EditServiceRequest from '../Views/Web/Accommodation/ServiceRequest/EditServiceRequest';
import CreateServiceRequest from '../Views/Web/Accommodation/ServiceRequest/CreateServiceRequest';
import ServiceRequestIndex from '../Views/Web/Accommodation/ServiceRequest';
import MainServiceRequest from '../Views/Web/Accommodation/ServiceRequest/MainServiceRequest';

import CheckInMain from '../Views/Web/Accommodation/CheckInOut/CheckIn/checkInMain';
import CheckOutMain from '../Views/Web/Accommodation/CheckInOut/CheckOut/checkOutMain';
import MainOccupants from '../Views/Web/Accommodation/Occupants/MainOccupants';
import IndexOccupants from '../Views/Web/Accommodation/Occupants/indexOccupants';
import CreateOccupants from '../Views/Web/Accommodation/Occupants/CreateOccupants';
import EditOccupants from '../Views/Web/Accommodation/Occupants/EditOccupants';
import ViewOccupants from '../Views/Web/Accommodation/Occupants/ViewOccupants';
import CreateCheckOut from '../Views/Web/Accommodation/CheckInOut/CheckOut/addPage';
import EditCheckIn from '../Views/Web/Accommodation/CheckInOut/CheckIn/editPage';
import ViewCheckIn from '../Views/Web/Accommodation/CheckInOut/CheckIn/viewPage';
import EditCheckOut from '../Views/Web/Accommodation/CheckInOut/CheckOut/editPage';
import ViewCheckOut from '../Views/Web/Accommodation/CheckInOut/CheckOut/viewPage';


const AccomodationRoutes = () => {
  return (
    <Route path='accomodation' element={<MainAccomodation />}>
      <Route path='camp-info/:officeId' element={<MainCampInfo />}>
        <Route index element={<CampInfo />} />
        <Route path='create' element={<CreateCamp />} />
        <Route path='edit/:id' element={<EditCamp />} />
        <Route path='view/:id' element={<ViewCamp />} />
      </Route>
      <Route path='room/:officeId' element={<MainRoom />}>
        <Route index element={<Room />} />
        <Route path='create' element={<CreateRoom />} />
        <Route path='edit/:RoomEditId' element={<EditRoom />} />
        <Route path='view/:RoomViewId' element={<ViewRoom />} />
      </Route>
      <Route path='occupants/:officeId' element={<MainOccupants />}>
        <Route index element={<IndexOccupants />} />
        <Route path='create' element={<CreateOccupants />} />
        <Route path='edit/:OccupantsEditId' element={<EditOccupants />} />
        <Route path='view/:OccupantsViewId' element={<ViewOccupants />} />
      </Route>
      <Route path='visitors-tracking/:officeId' element={<MainVisitorsTracking />}>
        <Route index element={<VisitorTracking />} />
        <Route path='create' element={<CreateVisitorTracking />} />
        <Route path='edit/:VisitorTrackingEditId' element={<EditVisitorTracking />} />
        <Route path='view/:VisitorTrackingViewId' element={<ViewVisitorTracking />} />
      </Route>

      <Route path='check-in-out' element={<CheckInOut/>}>
        <Route path='check-in/:officeId' element={<CheckInMain/>}>
          <Route index element={<CheckIn/>} />
          <Route path='create' element={<CreateCheckIn />} />
          <Route path='edit/:CheckInEditId' element={<EditCheckIn />} />
          <Route path='view/:CheckInViewId' element={<ViewCheckIn />} />
        </Route>
        <Route path='check-out/:officeId' element={<CheckOutMain/>}>
          <Route index element={<CheckOut/>} />
          <Route path='create' element={<CreateCheckOut />} />
          <Route path='edit/:CheckOutEditId' element={<EditCheckOut />} />
          <Route path='view/:CheckOutViewId' element={<ViewCheckOut />} />
        </Route>
      </Route>

      <Route path='building/:officeId' element={<MainBuildings/>}>
            <Route index element={<BuildingsIndex/>} />
            <Route path='create' element={<CreateBuildings/>} />
            <Route path='edit/:buildingEditId' element={<EditBuildings/>} />
            <Route path='view/:buildingViewId' element={<ViewBuildings/>} />
      </Route>
      <Route path='service-request/:officeId' element={<MainServiceRequest/>}>
            <Route index element={<ServiceRequestIndex/>} />
            <Route path='create' element={<CreateServiceRequest/>} />
            <Route path='edit/:serviceRequestEditId' element={<EditServiceRequest/>} />
            <Route path='view/:serviceRequestViewId' element={<ViewServiceRequest/>} />
      </Route>
    </Route>
  )
}

export default AccomodationRoutes;