import React, { useState } from 'react'
import { BackButton, EditButton } from '../../../Components/Utils/Buttons'
import { Link, useLocation, useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../Components/Icons/Dasboard';
import { Modal } from 'antd';

const ViewTask = () => {
    const { officeId } = useParams();
    const [url, setUrl] = useState(false);


    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
    };
    const location = useLocation();
    const data = location.state?.data
    console.log("find", data.id)
    return (
        <>
            <div className="bg-[#E6F2F2] w-full h-full px-3 py-2">
                <div className="w-full h-[46px]  flex justify-between ">
                    <BreadCrumb
                        items={[
                            {
                                title: (
                                    <Link to="/main/dashboard">
                                        <Dashboard color="gray" width={10} height={10} />{" "}
                                    </Link>
                                ),
                            },

                            { title: <p className=" text-[12px] ">TAsk</p> },
                            { title: <p className=" text-[12px] ">View</p> },
                        ]}
                    />
                    <div className=" flex gap-2">
                        <Link to={`/main/task/${officeId}`}>
                            <BackButton link={true} />
                        </Link>
                        <Link
                            to={`/main/task/${officeId}/edit/${data.id}`}
                            state={{
                                from: `/main/task/${officeId}`,
                                data: data,
                            }}
                        >
                            <EditButton height="8" />
                        </Link>
                    </div>
                </div>
                <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-y-scroll p-3">
                    <h3 className="text-[#212121] font-semibold pb-3"> Task Details</h3>
                    <div className="flex flex-wrap gap-y-5 w-full">
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.id || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>CreatedBy</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.createdBy || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Assigned To</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.assignedTo?.map((j => {
                                return j.name
                            })) || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Copy TO</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.copyTo?.map((j => {
                                return j.name
                            })) || '-'}</p>
                        </div>




                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Created By Name</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.createdByName || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'> End Date</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.endDate || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>No Of Days</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.noOfDays || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>No Of Weeks</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.noOfWeeks || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Priority</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.priority || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>PubId</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.pubId || '-'}</p>
                        </div>

                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reason For Date Revised</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.reasonForDateRevised || 'True'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Ref No</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.refNo || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reminder Date</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.reminderDate || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reminder Time</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.reminderTime || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Revised End Date</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.revisedEndDate || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Start Date</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.startDate || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Task Completion</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.taskCompletion || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Task Desc</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.taskDesc || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Task Name</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.taskName || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Task Status</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.taskStatus || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>UpdatedBy</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.updatedBy || '-'}</p>
                        </div>
                        <div className="w-1/4">
                            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Updated By Name</p>
                            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.updatedByName || '-'}</p>
                        </div>

                    </div>
                    {/* <h3 className='mt-3'>AgendaItems</h3>
          <div className="w-2/12 h-28  rounded-md p-1 flex flex-col border border-solid overflow-y-scroll mt-2">
          
          { data?.agendaItems?.map((item)=>{
            console.log("hhh",item);
            return(
                 
                  <p className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">{item||"-"}</p>
                
            )
          })}
          </div> */}
                    <h3 className='mt-5'>Meeting-Point Attachement Files</h3>
                    <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
                        {data?.attachment?.map((imgs, idx) => {
                            console.log('img', imgs);
                            return (imgs.file === null ? <p>Attachements not found</p> :
                                <div
                                    className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                    key={idx}
                                    style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                >
                                    <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                                        // onClick={() => handleImageClick(imgs)}
                                        onClick={() => { setShowFilterDialog(true); setUrl(imgs) }}
                                    >
                                        {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                        <img src={imgs} alt={imgs} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Modal
                open={showFilterDialog}
                title={"Medical Products"}
                width={"80%"}

                className="my-modal"
                onCancel={closeFilterDialog}
                footer={[]}
            >
                <div className='w-full h-[80vh] overflow-hidden'>
                    <img src={url} alt="" className='' width={"100%"} />
                </div>

            </Modal>
        </>
    );
}

export default ViewTask