import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Category from './Category'
import SubCategory from './SubCategory'


const InspectionSchedule  = () => {
  return (
    <>
    <TextMediumSM content="Inspection Schedule" />
    {/* <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'> */}
     {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'> */}
       <Category/>
     {/* </div> */}
     {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <SubCategory/>
     </div> */}
    
     {/* </div> */}
     </>
  )
}

export default InspectionSchedule