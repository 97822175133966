import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'

import MealType from './MealType'
import MenuByDays from './MenuByDays'
import MenuOnSpeacialDays from './MenuOnSpeacialDays'

const FoodSchedule = () => {
  return (
    <>
    <TextMediumSM content="Food Schedule" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <MealType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <MenuByDays/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <MenuOnSpeacialDays/>
     </div>
    
    
     </div>
     </>
  )
}

export default FoodSchedule