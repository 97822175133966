import React from 'react'
import { Link,useParams } from 'react-router-dom';
import ViewMore from '../../../../Components/Icons/ViewMore';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';

function ViewMoreStocks({ data, fetcher, id }) {
    const { officeId } = useParams();
  const { contextHolder, handle } = useDeleteData(fetcher, );
    const handleDelete = async (removevalue) => handle(`/stocks/${id}`,removevalue, 'Product');
    console.log("nnn",data);

    // const userAccess = JSON.parse(localStorage.getItem("userAccess"));
    let Edit = true

    let Delete = true

    return (
        <>
            {contextHolder}
            <div className='text-[#344767]  relative dropdown inline-block z-50'>
                <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
                    <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full' style={{ border: '1px solid #C0C0C0' }}>
                        <ViewMore />

                    </span>
                </div>
                <ul className='dropdown-menuOne text-[#344767] text-left  rounded-lg absolute -left-[70px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
<li className=' w-[100px] bg-black block m-0'>
                    <Link className=' text-black hover:text-black border-none'  to={`/main/inventary/stocks/${officeId}/view/${id}`} state={{ data: data, from: `/main/inventary/stocks/${officeId}` }} ><span
                            className='bg-white hover:bg-gray-100 py-2 px-2 border-none block whitespace-no-wrap text-xs font-medium'
                        // onClick={() => setOpen(true)}
                        >
                            View
                        </span></Link>
                    </li>
                    {Edit && <li className=' w-[100px] bg-black block m-0'>
                    <Link className=' text-black hover:text-black border-none'  to={`/main/inventary/stocks/${officeId}/edit/${id}`} state={{ data: data, from: `/main/inventary/stocks/${officeId}` }} ><span
                            className='bg-white hover:bg-gray-100 py-2 px-2 border-none block whitespace-no-wrap text-xs font-medium'
                        // onClick={() => setOpen(true)}
                        >
                            Edit
                        </span></Link>
                    </li>}
                    {
                        Delete &&
                        <li className=''>
                            <a
                                className='bg-white hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium'
                                // to='/members'
                                href="#"
                                onClick={() => {
                                    handleDelete(data.branchId, data?.branch_name);
                                }}
                            >
                                Delete
                            </a>
                        </li>
                    }
                </ul>
            </div>
        </>
    );
}

export default ViewMoreStocks