import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const BedType = () => {
  return (
    <SettingsMetaData propertyName={"BedType"} viewPropertyName={"Bed Type"} />

  )
}

export default BedType