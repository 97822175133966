import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const MealType = () => {
  return (
    <SettingsMetaData propertyName={"MealType"} viewPropertyName={"Meal Type"} />

  )
}

export default MealType