import React from 'react'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { BackButton } from '../../../../Components/Utils/Buttons'
import Dashboard from '../../../../Components/Icons/Dasboard'
import { Link } from 'react-router-dom'
import ParkingLots from './ParkingLots'

const MainTransPort = () => {
  return (
    <div className='w-full h-full px-4 py-2'>
    <div className='w-full flex justify-between items-center'>
    <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Settings</p>) },
                    { title: (<p className=' text-[12px] '>Transportation</p>) },
                ]} />
    <BackButton/>
    </div>
    <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md ">
        <div className="p-3"><ParkingLots/></div>
      
    </div>
    </div>
  )
}

export default  MainTransPort