import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const ToPlace = () => {
  return (
    <SettingsMetaData propertyName={"ToPlace"} viewPropertyName={"To Place"} />

  )
}

export default ToPlace