import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const DeliveryStatus = () => {
  return (
    <SettingsMetaData propertyName={"DeliveryStatus"} viewPropertyName={"Delivery Status"} />

  )
}

export default DeliveryStatus