import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Religion from './Religion'
import BloodGroup from './BloodGroup'
import PurposeofStay from './PurposeofStay'
import IdType from './IdType'
import Designation from './Designation'

const Guest = () => {
  return (
    <>
    <TextMediumSM content="Guest" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Religion/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <IdType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <PurposeofStay/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Designation/>
     </div>
    <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <BloodGroup/>
     </div>
    
     </div>
     </>
  )
}

export default Guest