import React from "react"
import { Route } from "react-router-dom"
import MainTask from "../Views/Web/Task/MainTask";
import ViewTask from "../Views/Web/Task/viewPage";
import EditTask from "../Views/Web/Task/editPage";
import CreateTaskPage from "../Views/Web/Task/createTask";
import TaskIndex from "../Views/Web/Task/index"
import ViewMoreTask from "../Views/Web/Task/ViewMoreTask";


const TaskRoutes = () => {
    return(
        <Route path='task/:officeId' element={<MainTask />}>
         <Route index element={<TaskIndex/>} />
         <Route path='create' element={<CreateTaskPage />} />
         <Route path='edit/:EditTaskId' element={<EditTask />} />
         <Route path='view/:ViewTaskId' element={<ViewTask />} />
         <Route path='viewMore/:ViewMoreTaskId' element={<ViewMoreTask />} />
       </Route>
    )
}

export default TaskRoutes;