import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';

function ViewParticipants() {
  const { officeId } = useParams();
 // const { EditTakeAwayRequest } = useParams();
    // console.log(EditTakeAwayRequest ,'sd');
    const location = useLocation();
    const data = location.state.data;
    return (
      <div className="bg-[#E6F2F2] w-full h-full px-3 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              {
                title: (
                  <Link to="/main/dashboard">
                    <Dashboard color="gray" width={10} height={10} />{" "}
                  </Link>
                ),
              },
              { title: <p className=" text-[12px] ">Meetings</p> },
              { title: <p className=" text-[12px] ">Participants</p> },
              { title: <p className=" text-[12px] ">View</p> },
            ]}
          />
          <div className=" flex gap-2">
            <Link to={`/main/meetings/participants/${officeId}`}>
              <BackButton link={true} />
            </Link>
            <Link
              to={`/main/meetings/participants/${officeId}/edit/${data?.participentId}`}
              state={{
                from: `/main/meetings/participants/${officeId}`,
                data: data,
              }}
            >
              <EditButton height="8" />
            </Link>
          </div>
        </div>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-y-scroll p-3">
          <h3 className="text-[#212121] font-semibold pb-3"> Participants Details</h3>
          <div className="flex flex-wrap gap-y-5 w-full">
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Created At</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.createdAt || '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Designation</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.designation|| '-'}</p>
            </div>
           
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Email</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.email|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Meeting Id</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.meetingName|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Mobile</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mobile|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ParticipentId</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.participentId|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ParticipentName</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.participentName|| '-'}</p>
            </div>
            
          </div>
        </div>
      </div>
    );
}

export default ViewParticipants