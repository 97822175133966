export const mobileCode = [
    {
        label: "91",
        value: "91"
    },
    {
        label: "1",
        value: "1"
    },
    {
        label: "27",
        value: "27"
    },
    {
        label: "30",
        value: "30"
    },
    {
        label: "32",
        value: "32"
    },
    {
        label: "33",
        value: "33"
    },
    {
        label: "34",
        value: "34"
    },
    {
        label: "351",
        value: "351"
    },
    {
        label: "353",
        value: "353"
    },
    {
        label: "354",
        value: "354"
    },
    {
        label: "358",
        value: "358"
    },
    {
        label: "380",
        value: "380"
    },
    {
        label: "39",
        value: "39"
    },
    {
        label: "41",
        value: "41"
    },
    {
        label: "44",
        value: "44"
    },
    {
        label: "45",
        value: "45"
    },
    {
        label: "46",
        value: "46"
    },
    {
        label: "47",
        value: "47"
    },
    {
        label: "48",
        value: "48"
    },
    {
        label: "49",
        value: "49"
    },
    {
        label: "60",
        value: "60"
    },
    {
        label: "61",
        value: "61"
    },
    {
        label: "62",
        value: "62"
    },
    {
        label: "63",
        value: "63"
    },
    {
        label: "64",
        value: "64"
    },
    {
        label: "7",
        value: "7"
    },
    {
        label: "81",
        value: "81"
    },
    {
        label: "82",
        value: "82"
    },
    {
        label: "86",
        value: "86"
    },
    {
        label: "90",
        value: "90"
    },
    {
        label: "94",
        value: "94"
    },
    {
        label: "960",
        value: "960"
    },
    {
        label: "962",
        value: "962"
    },
    {
        label: "965",
        value: "965"
    },
    {
        label: "968",
        value: "968"
    },

    {
        label: "972",
        value: "972"
    },
    {
        label: "971",
        value: "971"
    },
    {
        label: "973",
        value: "973"
    },
    {
        label: "974",
        value: "974"
    },
    {
        label: "966",
        value: "966"
    }

]