import { useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import DataTable from "../../../../../Components/Layout/DataTable";
import SearchBar from "../../../../../Components/Utils/SearchBar";
import { CancelButton, CreateButton, FilterButton, SubmitButton } from "../../../../../Components/Utils/Buttons";
import ViewMoreCheckOut from "./viewMore";
import Item from "antd/es/list/Item";
import UseGetDataSearch from "../../../../../Components/Hooks/useFetchDataBySearch";
import NuDate from "../../../../../Components/Utils/NuDate";
import { useFormik } from "formik";
import * as yup from 'yup';
import { NuInputNumber, NuSelect } from "../../../../../Components/Utils/NuInput";
import { Modal } from "antd";
import BreadCrumb from "../../../../../Components/Utils/Breadcrumbs";
import Dashboard from "../../../../../Components/Icons/Dasboard";

const CheckOut = () => {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const {data, totalCount , getData} = UseGetDataSearch( `/check-out/`, params )
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false); //clear filter
  const closeFilterDialog = () => {
    setShowFilterDialog(false); //close filter
  };
  const [filterCheckIn, setFilterCheckIn] = useState({
    durationType: "",
    stayDuration: "",
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterCheckIn,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff", value);
      setParams({
        ...params,
        durationType: value?.durationType,
        stayDuration: value?.stayDuration,
      });
      closeFilterDialog();
      setFilter(true);
      setFilterCheckIn({
        durationType: "",
        stayDuration: "",
      });
    },
  });
  const clearFilter = () => {
    setParams({ ...params, durationType: "", stayDuration: "" });
    formik.values.durationType = "";
    formik.values.stayDuration = "";
    setFilter(false);
  };
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[5%]",
    },
    {
      label: <span>Occupant Name</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Duration Type</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Chech In</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Planned Stay Duration</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Time</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Comments</span>,
      width: "w-[20%]",
    },
    {
      label: <span>Actions</span>,
      width: "w-[5%]",
    },
  ];
  return (
    <>
    <div className='w-full items-center flex justify-between'>
             <div>
                    <BreadCrumb items={[
                        {
                            title: (<Link to="/main/dashboard">
                              <Dashboard color="gray" width={10} height={10} />{" "}
                            </Link>)
                        },
                        { title: <p className='text-[13px]'>Accommodation</p> },
                        { title: <p className='text-[13px]'>Check-Out</p> },
                    ]}
                    />
                </div>
         <div className=" flex gap-3">
         <div>
          <SearchBar
            handleChange={(e) =>
              setParams({ ...params, search: e.target.value })
            }
          />
        </div>
        <FilterButton
          height="8"
          handleClick={() => setShowFilterDialog(true)}
        />
        {filter && (
          <FilterButton handleClick={() => clearFilter()} label="Clear" />
        )}
                    <Link to={`/main/accomodation/check-in-out/check-out/${officeId}/create`} 
                      // onClick={()=>setIsButtonsVisible(false)}
                    >
                        <CreateButton label={"Check-Out"} />
                    </Link>
                </div>
        </div>
      <DataTable
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-175px)]"
        headers={headers}
         total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
         dataCount={pageCount}
        setDataCount={setPageCount}
      >
        {
            data && data.map((item,idx)=>{
                console.log("data in fire",item);
                return(
                    <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={idx}>
                    <td className="w-[5%] p-2 px-[1px] text-center">{`${item?.checkOutId||""}`}</td>
                    <td className="w-[15%] p-2 px-[1px] text-center">
                      <Link
                        className="hover:text-baseColor-primary text-black "
                        to={`/main/accomodation/check-in-out/check-in/${officeId}/view/${item?.checkOutId}`}
                      >
                        { item?.occupantName || "-" }
                      </Link>
                    </td>
                    <td className="w-[15%] p-2 px-[1px] text-center ">{ item?.durationType || "-" }</td>
                    <td className="w-[10%] p-2 px-[1px] text-center ">{ (item?.date && <NuDate value={item?.date} format="DD-MMM-YYYY" />)||"-" }</td>
                    <td className="w-[10%] p-2 px-[1px] text-center ">{ item?.checkInId || "-" }</td>
                    <td className="w-[10%] p-2 px-[1px] text-center ">{ item?.stayDuration || "-" }</td>
                    <td className="w-[10%] p-2 px-[1px] text-center">{ item?.time || "-" }</td>
                    <td className="w-[20%] p-2 px-[1px] text-center">{ item?.comments||"" }</td>
                    <td className="w-[5%] p-2 px-[1px] text-center ">
                      <ViewMoreCheckOut data={item} id={item?.checkOutId} fetcher={getData} />
                    </td>
                  </tr>
                )
            })
        }
      </DataTable>
      <Modal
        open={showFilterDialog}
        title={"Filter Check-In"}
        width={"30%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col mb-4">
            <NuSelect
              label="Duration Type"
              options={[
                { value: "day", label: "Day" },
                { value: "month", label: "Month" },
                { value: "year", label: "Year" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="durationType"
              width="w-full"
            />
            <NuInputNumber
                isRequired={true}
                label="Planed Stay Duration"
                placeholder="Planed Stay Duration"
                formik={formik}
                name="stayDuration"
                width="w-full"
              />
          </div>
          <div className="flex justify-end gap-2">
            <CancelButton handleClick={closeFilterDialog} />
            <SubmitButton name="Apply" />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CheckOut;
