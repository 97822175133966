import { useState } from 'react';
import axiosInstance from '../../Services/axiosCommon';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const useAddFormData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ api, contextHolder ] = notification.useNotification();
  const navigate = useNavigate()

  function Oncancel() {
      navigate(-1);
  }

  const addFormData = async (url, formData, resetForm) => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.post(url, formData);
      if (res.data?.status === true) {
        api.success({
          message: `Success`,
          description: res.data?.msg || 'Created successfully',
          placement: 'bottomRight',
          duration: 3,
        });
        resetForm();
        Oncancel();
      } else {
        api.error({
          message: `Failed`,
          description: res.data?.msg || 'Creation failure.!',
          placement: 'bottomRight',
          duration: 3,
        });
      }
    } catch (err) {
    //   setError(err.message);
      console.log(err);
      api.error({
        message: `Failed`,
        description: 'Internal server error.!',
        placement: 'bottomRight',
        duration: 3,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { addFormData, isLoading, contextHolder, Oncancel };
};

export default useAddFormData;