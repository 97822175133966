import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Priority = () => {
  return (
    <SettingsMetaData propertyName={"Priority"} viewPropertyName={"Priority"} />

  )
}

export default Priority