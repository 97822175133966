import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import { Link, useLocation, useParams } from "react-router-dom";
import { BackButton, EditButton } from "../../../../Components/Utils/Buttons";
import axiosInstance from "../../../../Services/axiosCommon";
import Dashboard from "../../../../Components/Icons/Dasboard";


function ViewTakeAwaySchedule() {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    // const { EditTakeAwayRequest } = useParams();
    // console.log(EditTakeAwayRequest ,'sd');
    const location = useLocation();
    const data = location.state.data;
    return (
      <div className="bg-[#E6F2F2] w-full h-full px-3 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              {
                title: (
                  <Link to="/main/dashboard">
                    <Dashboard color="gray" width={10} height={10} />{" "}
                  </Link>
                ),
              },
              { title: <p className=" text-[12px] ">Catering</p> },
              { title: <p className=" text-[12px] ">Take Away Schedule</p> },
              { title: <p className=" text-[12px] ">View</p> },
            ]}
          />
          <div className=" flex gap-2">
            <Link to={`/main/catering/take-away-schedule/${officeId}`}>
              <BackButton link={true} />
            </Link>
            <Link
              to={`/main/catering/take-away-schedule/${officeId}/edit/${data?.takeAwayScheduleId}`}
              state={{
                from: `/main/catering/take-away-schedule/${officeId}`,
                data: data,
              }}
            >
              <EditButton height="8" />
            </Link>
          </div>
        </div>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-y-scroll p-3">
          <h3 className="text-[#212121] font-semibold pb-3"> TakeAway Schedule Details</h3>
          <div className="flex flex-wrap gap-y-5 w-full">
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>TakeAway Schedule Id</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.takeAwayScheduleId || '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Name</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.name|| '-'}</p>
            </div>
            <div className='w-1/4'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'> Name</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.occupantName || '-'}</p>
                  </div>
            {/* <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Occupant Id</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.occupantId|| '-'}</p>
            </div> */}
           
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Meal Type</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mealType|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Food Category</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.foodCategory|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Delivery Location</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.deliverLocation|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.status|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Ethnicity</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.ethnicity|| '-'}</p>
            </div>
            <div className="w-1/4">
            <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date</p>
            <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.date|| '-'}</p>
            </div>
           
          </div>
        </div>
      </div>
    );
}

export default ViewTakeAwaySchedule