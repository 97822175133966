import React, { useEffect, useState } from 'react'
import UseGetDataSearch from '../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import * as yep from 'yup'
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import SearchBar from '../../../Components/Utils/SearchBar';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import DataTable from '../../../Components/Layout/DataTable';
import VisitorTrackingViewMore from '../Accommodation/VisitorsTracking/viewMore';
import { Modal } from 'antd';
import { NuInputText, NuSelect } from '../../../Components/Utils/NuInput';
import ViewMoreIncidents from './ViewMoreIncidents';
import useFetchMataData from '../../../Components/Hooks/useFetchMataData';

function IndexIncident() {
  const { value: value } = useFetchMataData('Incident_Type')

  const { value: value1 } = useFetchMataData('Severity_Incident')

  const { value: value2 } = useFetchMataData('Status_Incident')
  const { officeId } = useParams();
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState(20)
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const { data, getData, totalCount } = UseGetDataSearch( '/incident/', params);
  let headers = [
      {
        label: <span>Incident Id</span>,
        width: "w-[5%]",
      },
      {
        label: <span>Incident Type</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Created At</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Severity</span>,
        width: "w-[10%]",
      },
    
      {
        label: <span>Status</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Witness</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Reported By</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Location</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Actions</span>,
        width: "w-[5%]",
      },
     
     
    ];
    
    
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filter, setFilter] = useState(false); //clear filter
    const closeFilterDialog = () => {
      setShowFilterDialog(false); //close filter
    };
    const[filterCamp,setFilterCamp]=useState({
      incidentType:"",
        location:"",
        status:"",
     });
     const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterCamp,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yep.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params, incidentType: value?.incidentType, location: value?.location, status:value?.status})
        closeFilterDialog();
        setFilter(true)
        setFilterCamp({
          incidentType: "",
          location: "",
          status:"",
        });
      }
    })
    const clearFilter = ()=> {
      setParams({...params,
        incidentType: "",
          location: "",
          status:"",
     
      })
      formik.values.incidentType=""
      formik.values.location=""
      formik.values.status=""
        setFilter(false)
    }

    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
     
     
return (
  <div className=" bg-[#E6F2F2] h-full w-full py-3 px-6">
    <div className="w-full items-center flex justify-between">
      <div>
        <BreadCrumb
          items={[
            { title: <p className="text-[13px]">Home</p> },
            
            { title: <p className="text-[13px]">Incidents</p> },
          ]}
        />
      </div>
      <div className=" flex gap-2 mb-4">
        <div>
                    <SearchBar 
                  handleChange={(e) =>
                      setParams({ ...params, search: e.target.value })
                    }
                  /></div>
        <FilterButton height="8"  handleClick={() => setShowFilterDialog(true)}/>
        {filter && ( 
           <FilterButton 
          handleClick={() => clearFilter()} 
          label="Clear" /> 
        )}
        <CreateButton label="Import" />
        <Link to={`/main/incidents/${officeId}/create`}>
          <CreateButton label="Incidents" />
        </Link>
      </div>
    </div>
      <DataTable
   bodyHeight='max-h-[calc(100vh-260px)]'
   height='h-[calc(100vh-150px)]'
   headers={headers} 
   total={totalCount}
   pageIndex={page}
   setPageIndex={setPage}
   dataCount={pageCount}
   setDataCount={setPageCount}
  >
    {data.length>0?data.map((item, i) => {
          return (
            <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105 border" key={i}>
              <td className="w-[5%] p-2 px-[1px] text-center ">{item.incidentId||""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item.incidentType||""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item.createdAt||""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item.severity||""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item.status||""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item.witness||""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item.reportedByName||""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item.location||""}</td>
              
              {/* <td className="w-[10%] p-2 px-[1px] text-center ">
                <Link className='hover:text-baseColor-primary text-black ' 
                to={`/main/accomodation/visitors-tracking/view/${item.visitorId}`}
                state={{data : item}}
                >{item.visitorName}</Link>
              </td> */}
              {/* <td className="w-[10%] p-2 px-[1px] text-center">{item.date || ''}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item.visitorMobile?.display}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item.occupantName || ''}</td>
              <td className="w-[10%] p-2 px-[1px] text-center truncate">{item.purposeOfVisit || ''}</td>
              <td className="w-[10%] p-2 px-[1px] text-center">{item.visitNumber || ''}</td>
              <td className="w-[10%] p-2 px-[1px] text-center">{item.timeIn || ''}</td> */}
              {/* <td className="w-[10%] p-2 px-[1px] text-center">{item.timeOut || '-'}</td> */}
              <td className="w-[5%] p-2 px-[1px] text-center ">
                  <ViewMoreIncidents data={item} id={item.incidentId} fetcher={getData}/>
              </td>
            </tr>
          );
        })
        :
        <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
      }

      
  </DataTable>
  <Modal
  open={showFilterDialog}
      title={"Filter Camp"}
      width={"50%"}
      className="my-modal"
      centered
      onCancel={closeFilterDialog} 
      footer={[]}>
         <form onSubmit={formik.handleSubmit}> 
                  <div className="flex flex-col mb-4">
                  <NuSelect
              // isRequired={true}
              label="Incident Type"
             
              formik={formik}
              placeholder="Choose"
              name="incidentType"
              width="w-full "
              options={value}
             
             
            />
              <NuInputText
             label='Location' 
             placeholder='Text'
            formik={formik} 
            name='ocation'
             width='w-full  '
              />
              <NuSelect
            label="Status"
            placeholder="Avtive"
            formik={formik}
            name="status"
            width='w-full '
            options={value2}
            />
                  
                  </div>
                  
                 <div className="flex justify-end gap-2">
                   <CancelButton handleClick={closeFilterDialog} />
                   <SubmitButton name='Apply' />
                 </div>
                </form> 
             {/* </div>
           </div>
         )} */}
  </Modal>
  <div className='w-full h-20 flex justify-end'>
         
          </div>
  </div>
);
}

export default IndexIncident