import { Route } from "react-router-dom"
import CreateDinningRecord from "../Views/Web/catering/DiningRecord/CreateDinningRecord"
import DinningRecordIndex from "../Views/Web/catering/DiningRecord/DinningRecordIndex"
import EditDinningRecord from "../Views/Web/catering/DiningRecord/EditDinningRecord"
import ViewDinningRecord from "../Views/Web/catering/DiningRecord/ViewDinningRecord"
import MainDiningRecord from "../Views/Web/catering/DiningRecord/mainDiningRecord"
import MainFoodMenu from "../Views/Web/catering/FoodMenu/MainFoodMenu"
import FoodMenuIndex from "../Views/Web/catering/FoodMenu/FoodMenuIndex"
import CreateFoodMenu from "../Views/Web/catering/FoodMenu/CreateFoodMenu"
import EditFoodMenu from "../Views/Web/catering/FoodMenu/EditFoodMenu"
import ViewFoodMenu from "../Views/Web/catering/FoodMenu/ViewFoodMenu"
import MainFoodSchedule from "../Views/Web/catering/FoodSchedule/MainFoodSchedule"
import FoodScheduleIndex from "../Views/Web/catering/FoodSchedule/FoodScheduleIndex"
import MainTakeAwayRequest from "../Views/Web/catering/TakeAwayRequest/MainTakeAwayRequest"
import TakeAwayRequestIndex from "../Views/Web/catering/TakeAwayRequest/TakeAwayRequestIndex"
import CreateTakeAwayRequest from "../Views/Web/catering/TakeAwayRequest/CreateTakeAwayRequest"
import EditTakeAwayRequest from "../Views/Web/catering/TakeAwayRequest/EditTakeAwayRequest"
import ViewTakeAwayRequest from "../Views/Web/catering/TakeAwayRequest/ViewTakeAwayRequest"
import MainTakeAwaySchedule from "../Views/Web/catering/TakeAwaySchedule/MainTakeAwaySchedule"
import TakeAwayScheduleIndex from "../Views/Web/catering/TakeAwaySchedule/TakeAwayScheduleIndex"
import CreateTakeAwaySchedule from "../Views/Web/catering/TakeAwaySchedule/CreateTakeAwaySchedule"
import EditTakeAwaySchedule from "../Views/Web/catering/TakeAwaySchedule/EditTakeAwaySchedule"
import ViewTakeAwaySchedule from "../Views/Web/catering/TakeAwaySchedule/ViewTakeAwaySchedule"
import CreateFoodSchedule from "../Views/Web/catering/FoodSchedule/CreateFoodSchedule"
import EditFoodSchedule from "../Views/Web/catering/FoodSchedule/EditFoodSchedule"
import ViewFoodSchedule from "../Views/Web/catering/FoodSchedule/ViewFoodSchedule"
import MainCatering from "../Views/Web/catering/maincatering"


const CateringRoutes = () => {
    return (
      <Route path='catering' element={<MainCatering />}>
        
        <Route path='dinning-record/:officeId' element={<MainDiningRecord />}>
          <Route index element={<DinningRecordIndex />} />
          <Route path='create' element={<CreateDinningRecord/>} />
          <Route path='edit/:EditDinningRecordId' element={<EditDinningRecord/>} />
          <Route path='view/:ViewDinningRecordId' element={<ViewDinningRecord/>} />
        </Route>
        
        <Route path='food-menu/:officeId' element={<MainFoodMenu />}>
             <Route index element={<FoodMenuIndex/>}/>
             <Route path='create' element={<CreateFoodMenu/>} />
             <Route path='edit/:EditFoodMenuId' element={<EditFoodMenu/>} />
             <Route path='view/:ViewFoodMenuId' element={<ViewFoodMenu/>} />
        </Route>

        <Route path='food-schedule/:officeId' element={<MainFoodSchedule/>}>
             <Route index element={<FoodScheduleIndex/>}/>
             <Route path='create' element={<CreateFoodSchedule/>} />
             <Route path='edit/:EditFoodScheduleId' element={<EditFoodSchedule/>} />
             <Route path='view/:ViewFoodScheduleId' element={<ViewFoodSchedule/>} />
        </Route>
            <Route path='take-away-request/:officeId' element={<MainTakeAwayRequest/>}>
             <Route index element={<TakeAwayRequestIndex/>}/>
             <Route path='create' element={<CreateTakeAwayRequest/>} />
             <Route path='edit/:EditTakeAwayRequest' element={<EditTakeAwayRequest/>} />
             <Route path='view/:ViewTakeAwayRequest' element={<ViewTakeAwayRequest/>} />
        </Route>
        <Route path='take-away-schedule/:officeId' element={<MainTakeAwaySchedule/>}>
             <Route index element={<TakeAwayScheduleIndex/>}/>
             <Route path='create' element={<CreateTakeAwaySchedule/>} />
             <Route path='edit/:EditTakeAwaySchedule' element={<EditTakeAwaySchedule/>} />
             <Route path='view/:ViewTakeAwaySchedule' element={<ViewTakeAwaySchedule/>} />
        </Route>

      </Route>
    )
}

export default CateringRoutes;