import { 
  Navigate,
  Route, 
  Routes, 
  useLocation, 
  useNavigate 
} from "react-router-dom";

import App from "./App";
import PrivateRoute from "./Services/PrivateRouter";
import { useEffect } from "react";
import Login from "./Views/Web/Auth/login";
import Auth from "./Views/Mian/MainAuth";
import MainPage from "./Views/Mian/MainPage";
import Dashboard from "./Views/Web/Dashboard";
import SettingsRoutes from "./Routes/settings.routes";
import ListingRoute from "./Routes/listing.routes";
import AccomodationRoutes from "./Routes/accomodation.routes";
import ClinicRoutes from "./Routes/clinic.routes";
import IncidentsRoutes from "./Routes/incidents.routes";
import CateringRoutes from "./Routes/catering.routes";
import MeetingsRoutes from "./Routes/meetings.routes";
import InvontaryRoutes from "./Routes/inventory.routes";
import StaffRoutes from "./Routes/staff.routes";
import TransportationRotes from "./Routes/transportation.routes";
import ProcurmentRoutes from "./Routes/procurment.routes";
import MaintenanceRoutes from "./Routes/maintenance.routes";
import TaskRoutes from "./Routes/task.routes";
import VendorRoutes from "./Routes/vendor.routes";
import MainProject from "./Views/Mian/MainProject";
import MainDashBoardIndex from "./Views/Web/MainDashBoard/MainDashBoardIndex";
import OfficeIndex from "./Views/Web/Offices/OfficesIndex";

function MainRoutes() {

  const location = useLocation();

  useEffect(()=>{
    if (location.pathname === '/' || location.pathname === '' || location.pathname === '/main'){
      window.location.replace('/main-page/main-dashboard')
    };
    if (location.pathname === '/auth'){
      window.location.replace('/auth/login')
    }
    if (location.pathname === "/main") {
      Navigate("/main/dashboard");
    }
  },[location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <App />
          </PrivateRoute>
        }
      >
        <Route path="main-page" element={<MainProject/>}>
        <Route path="main-dashboard" element={<MainDashBoardIndex/>}></Route>
        <Route path="offices" element={<OfficeIndex/>}></Route>
         </Route>
        <Route path="/main" element={<MainPage/>}>
          <Route path="dashboard/:officeId" element={<Dashboard/>}></Route>
          {ListingRoute()}
          {AccomodationRoutes()}
          {ClinicRoutes()}
          {IncidentsRoutes()}
          {CateringRoutes()}
          {MeetingsRoutes()}
          {InvontaryRoutes()}
          {StaffRoutes()}
          {TransportationRotes()}
          {ProcurmentRoutes()}
          {MaintenanceRoutes()}
          {TaskRoutes()}
          {VendorRoutes()}
        </Route>
        {SettingsRoutes()}
      </Route>
      <Route path="/auth" element={<Auth/>}>
        <Route path="login" element={<Login/>} />
        {/* <Route path="forget-password" element={<ChangePassword />} /> */}
      </Route>
    </Routes>

  );
};

export default MainRoutes;
