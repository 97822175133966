import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const TaskStatus = () => {
  return (
    <SettingsMetaData propertyName={"TaskStatus"} viewPropertyName={"Task Status"} />

  )
}

export default TaskStatus