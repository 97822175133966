import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { CancelButton, CreateButton, DeleteButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import SearchBar from '../../../../Components/Utils/SearchBar';
import RightArrow from '../../../../Components/Icons/RightArrow';
import image from '../../../../assets/images/loginPage.jpg';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { NuInputNumber, NuSelect } from '../../../../Components/Utils/NuInput';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { Modal } from 'antd';
import ViewMoreOccupants from './ViewMoreOccupants';
import DataTable from '../../../../Components/Layout/DataTable';
import Card from '../../../../Components/Icons/card';
import List from '../../../../Components/Icons/list';
import OccupantsCard from './OccupantsCard';
import Pagination from '../../../../Components/Utils/Pagenation';

function IndexOccupants() {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode") || "card");
    const [params, setParams] = useState({
        page: page,
        per_page: pageCount,
        search: "",
      });
    const { data, totalCount, getData} = UseGetDataSearch('/occupants/' , params);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const[filterCamp,setFilterCamp]=useState({
        dob: "",
        status: "",
        refId: "",
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterCamp,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, dob: value?.dob, status: value?.status,})
          closeFilterDialog();
          setFilter(true)
          setFilterCamp({
            dob: "",
            status: "",
            refId: "",
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          dob: "",
          status: "",
          refId: "",
        })
        formik.values.dob=""
        formik.values.status=""
        formik.values.refId=""
          setFilter(false)
      }
      useEffect(()=>{
        localStorage.setItem("viewMode", viewMode);
         setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount,viewMode])
    console.log(data,'dddd');
  
      let headers = [
          {
            label: <span>ID</span>,
            width: "w-[5%]",
          },
          {
            label: <span>Occupant Name</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Occupant Type</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Email</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Mobile</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Designation</span>,
            width: "w-[10%]",
          },
          {
            label: <span>State</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Actions</span>,
            width: "w-[5%]",
          },
        ];
    
  return (
    <div className=' bg-[#E6F2F2] h-full w-full py-3 px-6'>
            <div className='w-full items-center flex justify-between'>
                <div>
                    <BreadCrumb items={[
                        {
                            title: (<Link to="/main/dashboard">
                              <Dashboard color="gray" width={10} height={10} />{" "}
                            </Link>)
                        },
                        { title: <p className='text-[13px]'>Accommodation</p> },
                        { title: <p className='text-[13px]'>Occupants</p> },
                    ]}
                    />
                </div>
                {/* <div><h2 className='font-[Poppins] text-xl'>Camp Info</h2></div> */}
                <div className='flex gap-3'>
                    <div>
                      <SearchBar 
                    handleChange={(e) =>
                        setParams({ ...params, search: e.target.value })
                      }
                    /></div>
                    <div className="flex gap-2">
              <div
                onClick={() => setViewMode("list")}
                className={
                  viewMode === "list"
                    ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white"
                    : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"
                }
              >
                <List />
              </div>
              <div
                onClick={() => setViewMode("card")}
                className={
                  viewMode === "card"
                    ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white"
                    : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"
                }
              >
                <Card />
              </div>
            </div>
                    <FilterButton height='8' 
                     handleClick={() => setShowFilterDialog(true)}/>
                     {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
                    <Link to={`/main/accomodation/occupants/${officeId}/create`}><div>
                        <CreateButton label='Occuppants' />
                    </div></Link>
                </div>
            </div>
            {viewMode === "list" ? (
            <DataTable
       bodyHeight='max-h-[calc(100vh-260px)]'
       height='h-[calc(100vh-175px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={1}
       setPageIndex={()=>{}}
       dataCount={totalCount}
       setDataCount={()=>{}}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              // currencies.find((i) => {
              //   if(i.value == item?.currency){
              //     getCurrency=i.label;
              //   }
              // })
              return (
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                  <td className="w-[5%] p-2 px-[1px] text-center ">{item?.occupantId||"#"}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/occupants/view/${item?.occupantId}`} state={{data: item}}>{item?.occupantName||""}</Link></td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.occupantType||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.occupantDetails?.email ||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.occupantDetails?.mobile?.display||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.occupantDetails?.designation||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.occupantDetails?.state ||""}</td>
                  <td className="w-[5%] p-2 px-[1px] text-center ">
                      <ViewMoreOccupants data={item} fetcher={getData} id={item?.occupantId}/>
                  </td>
                </tr>
              );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
          }
      </DataTable>
            ) : (
              <>
          <div className="w-full h-[calc(100vh-190px)] rounded-md bg-white p-3 mt-4 overflow-y-scroll">
            <div className="w-full flex justify-start flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 pt-4">
              {data?.length > 0 ? data &&
                data.map((item, idx) => (
                  <OccupantsCard fetcher={getData} item={item} key={idx} />
                ))
              :
              <p className='w-full text-center'>No Data Found</p>
              }
            </div>
          </div>
          <div className="w-full h-[40px] rounded-b-md bg-white">
          <Pagination
            total={totalCount}
            pageIndex={page}
            setPageIndex={setPage}
            dataCount={pageCount}
            setDataCount={setPageCount}
          />
        </div>
        </>
            )}
          </div>
  )
}

export default IndexOccupants;