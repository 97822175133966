import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const RequestType = () => {
  return (
    <SettingsMetaData propertyName={"RequestType"} viewPropertyName={"Request Type"} />

  )
}

export default RequestType