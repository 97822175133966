import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Status from './status'
import FromPlace from './fromPlace'
import ToPlace from './ToPlace'

const MoveMents = () => {
  return (
    <>
    <TextMediumSM content="Movements" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <FromPlace/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <ToPlace/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Status/>
     </div>
     
    
     </div>
     </>
  )
}

export default MoveMents