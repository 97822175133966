import React from 'react'
import { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker,  NuInputText, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Navigate,useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dropdown from 'antd/es/dropdown/dropdown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';



function CreateDinningRecord() {
  const { value: DineType} = useFetchMataData('DineType') 
    const { value: Type} = useFetchMataData('Type') 

  const navigate=useNavigate()
  const {data:occupant} =UseDropDown('/occupants/drop-down')
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData ();
  const [createNew, setCreateNew] = useState({
      // reqType: "RoomService",
      consumption:"",
      dineType:"",
      date:"",
      type: "",
      occupantId: "",
      ethnicity:"",
      comments: ""
  })
 

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
         

          addFormData('/dining-records/new', value, resetForm);
      }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Catering</p>) },
        { title: (<p className=' text-[12px] '>Dinning record</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
            <NuInputText
              // isRequired={true}
              label=" Ethnicity"
              placeholder=" Ethnicity"
              formik={formik}
              name="ethnicity"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label=" Consumption"
              placeholder=" Consumption"
              formik={formik}
              name="consumption"
              width="w-full md:w-1/3"
            />
              {/* <NuInputNumber
              label="Id"
              placeholder="Id"
              formik={formik}
              name="roomserviceId"
              width="w-full md:w-1/3"
            /> */}
            <NuSelect
              label="Occupant Id"
              options={occupant}
              formik={formik}
              placeholder="Choose"
              name="occupantId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
{/*           
            <NuInputNumber
              label="Room No"
              placeholder="Room No"
              formik={formik}
              name="Room No"
              width="w-full md:w-1/3"
            />
             <NuInputNumber
              label="Bed No"
              placeholder="Bed No"
              formik={formik}
              name="Bed No"
              width="w-full md:w-1/3"
            /> */}
             <NuSelect
              label="Dine type"
              options={DineType}
              formik={formik}
              placeholder="Choose"
              name="dineType"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuSelect
              label="Type"
              options={Type}
              formik={formik}
              placeholder="Choose"
              name="type"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
             <NuDatePicker
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             {/* <NuDatePicker
              label="Attended on"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="attendedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
          {/* <NuSelect
              label="Status"
              options={[
                { value: "1", label: "A" },
                { value: "2", label: "B" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="Status"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
                    </div>
  )
}

export default CreateDinningRecord