import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Desigination = () => {
  return (
    <SettingsMetaData propertyName={"Desigination_Vendor"} viewPropertyName={"Desigination"} />

  )
}

export default Desigination