import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const OccupantType = () => {
  return (
    <SettingsMetaData propertyName={"OccupantType"} viewPropertyName={"Occupant Type"} />

  )
}

export default OccupantType