import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from 'yup';
import { notification } from "antd";
import CustomImgUpload from "../../../../Components/Utils/CustomImgUpload";
import { NuInputText, NuSelect, NuInputNumber, NuLabel, NuInputEmail, NuInputPassword, NuMobileNumber, NuSelectwithSearch } from "../../../../Components/Utils/NuInput";
import axiosInstance from "../../../../Services/axiosCommon";
import { CountryMIN } from "../../../../assets/CountryMini";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";


function CreateUser() {
  const { value: Division } = useFetchMataData('Division')

    const navigate = useNavigate()
    function Oncancel() {
        navigate(-1);
    }
    const [api, contextHolder] = notification.useNotification();
    const [image, setImage] = useState({ preview: "", raw: "" });
    // const [division, setDivision] = useState([]);
    const [createNew, setCreateNew] = useState({
        city:"",
        streetName:"",
        district:"",
        state:"",
        country:"",
        postalCode: "",
        name:"",
        designation:"",
        division:"",
        email:"",
        password:"",
        phone:"",
        userImage:"",
        countryCode: "",
    })
    const handleChangeImg = (e) => {
        if (e.target.files.length) {
          setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0],
          });
        }
      };
      const handleImageRemove = ()=>{
        setImage({
          preview: "",
          raw: "",
        });
      }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            email: yup.string().required('Email is required!'),
            password: yup.string().required('Password is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            let formData = new FormData();
            formData.append('json_data', JSON.stringify(value));
            formData.append('userImage', image.raw);
            
            axiosInstance
                .post('/user/create-user', formData)
                .then((res) => {
                    if (res.data?.status === true) {
                        resetForm();
                        Oncancel();
                        api.success({
                          message: `Success`,
                          description: res.data?.msg || 'Created successfully',
                          placement: 'bottomRight',
                        });
                      } else {
                        api.error({
                          message: `Failed`,
                          description: res.data?.msg || 'Creation failure.!',
                          placement: 'bottomRight',
                        });
                      }
                })
                .catch((err) => {
                    console.log(err);
                    api.error({
                      message: `Failed`,
                      description: 'Internal server Error..!',
                      placement: 'bottomRight',
                    });
                });
        }
    })
    return (
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full h-full p-2 bg-[#F1F2FB] overflow-y-scroll">
            {contextHolder}
            <div className="w-[96%] mx-auto ">
                <NuLabel label={"Profile"} />
                <CustomImgUpload
                isRemove={true}
                removeFunction={handleImageRemove}
                handleChange={handleChangeImg}
                preview={image.preview || createNew.companyLogo || ""}
                />
                <div className="w-full flex justify-start gap-x-10 gap-y-2 content-start flex-wrap">
                <NuInputText label='Name' placeholder='name' formik={formik} name='name' width='w-full md:w-[45%]' />
                <NuInputPassword isRequired={true} label='Password' placeholder='password' formik={formik} name='password' width='w-full md:w-[45%]' /> 
                <NuMobileNumber
                 nunCode={"countryCode"}
                 label="Phone"
                 placeholder="phone"
                 formik={formik}
                 name="phone"
                 width="w-full md:w-[45%]"
                 />
                <NuInputEmail label='Email' placeholder='email' formik={formik} name='email' width='w-full md:w-[45%]' isRequired={true}/> 
                <NuInputText label='Desigination' placeholder='designation' formik={formik} name='designation' width='w-full md:w-[45%]' />
                <NuSelect
                    label='Division / Department'
                    options={Division}
                    formik={formik}
                    placeholder='Choose'
                    name='division'
                    width='w-full md:w-[45%]'
                />
                </div>
                <p className="text-xs font-semibold text-[#000000] pt-3">Address</p>
                <div className="w-full flex justify-start gap-x-10 gap-y-2 content-start flex-wrap">
                <NuSelectwithSearch
                    label="Country"
                    options={CountryMIN}
                    formik={formik}
                    placeholder="Choose"
                    name="country"
                    width="w-full md:w-[45%]"
                />
                <NuInputText label='State' placeholder='state' formik={formik} name='state' width='w-full md:w-[45%]' /> 
                <NuInputText label='District' placeholder='district' formik={formik} name='district' width='w-[45%]' /> 
                <NuInputText label='City' placeholder='city' formik={formik} name='city' width='w-full md:w-[45%]' /> 
                <NuInputText label='Street Name' placeholder='streetName' formik={formik} name='streetName' width='w-full md:w-[45%]' />                
                <NuInputNumber label='Postal Code' placeholder='postalCode' formik={formik} name='postalCode' width='w-full md:w-[45%]' />
                 </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { navigate(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
        </form>
    )
}

export default CreateUser;