import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useToast } from "../../../../../Components/Others/ToastServices";
import axiosInstance from "../../../../../Services/axiosCommon";
import { TextMediumXS } from "../../../../../Components/Text/MediumText";
import { NuInputSetting } from "../../../../../Components/Utils/NuInput";

const SubCategory = ({id,category,module}) => { 
  const [data, setData] = useState([]);
  const toast = useToast();
  const [createNew, setCreateNew] = useState({
    category: "",
    module: "",
    subCategory: ""
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
      // category: yup.string().required('Status is Required'),
    // }),
    onSubmit: async (value, { resetForm }) => {
      value.category = category
      value.module = module
      axiosInstance.post(`/category/new-sub-cat`, value)
              .then(res => {               
                  if (res.data) {
                      console.log("Created", resetForm)
                      resetForm();
                      GetData(id);
                      toast.open({
                        type:"success",
                        message: `Product SubCategory  Added successfully`,
                        // description:'',
                        // placement: 'bottomRight',
                    });
                  }
                  else {
                      console.log("Failed")
                      toast.open({
                        type:"error",
                        message: `Product SubCategory Added failed`,
                        // description: response.data.msg || 'Network error',
                        // placement: 'bottomRight',
                    });
                  }
              })    
              .catch((err)=>{
                console.log(err);
              })
    },
  });
  const GetData = (id) => {
     axiosInstance.get(`/category/sub-categories/${id}`).then(res => {
        console.log("subcat", res.data?.data)
        let data = res.data?.data;
        res.data?.data && setData(data)        
    }).catch((err)=>{
      console.log("error",err);
    })
}

const handleDelete = (value,id) => {
  axiosInstance.delete(`/category/sub-category/${value}`)
    .then(res => {
      if (res?.status == 200) {
        GetData(id);
        toast.open({
          type:"success",
          message: `Product SubCategory  Deleted successfully`,
          // placement: 'bottomRight',
        });
      }
      else {
        console.log("Failed")
        toast.open({
          type:"error",
          message: `Product SubCategory Deleted failed`,
          // placement: 'bottomRight',
        });
      }
    }).catch((err)=>{
      console.log(err);
    })
}

useEffect(() => {
  GetData(id);
}, [id])
  return (
    <div className=" w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className={`w-full text-xs`}>
          <div className="px-3 mb-1">
            <label className="font-medium text-textColor-500">
            Product SubCategory
            </label>
            <NuInputSetting
              type="text"
              placeholder="Product Category"
              formik={formik}
              name="subCategory"
            />
          </div>
        </div>
      </form>
      <div className=" px-3">
        <div className="w-full rounded-lg h-44 bg-[#EEF4F6] overflow-x-hidden overflow-y-auto">
          <ul className="">
            {data.map((List, idx) => {
                return (
            <li
              className={` m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50 `}
              key={idx}
            >
               <TextMediumXS color={"text-[#009858]"} content={List?.name || "-"} />
              {List?.id && (
              <span
                onClick={() => {
                  handleDelete(List?.id,List?.categoryId);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
               )} 
            </li>
             );
              })} 
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubCategory;