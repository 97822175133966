import { Link, useLocation, useParams } from "react-router-dom";
import { BackButton, EditButton } from "../../../../../Components/Utils/Buttons";
import NuDate from "../../../../../Components/Utils/NuDate";
import BreadCrumb from "../../../../../Components/Utils/Breadcrumbs";
import Dashboard from "../../../../../Components/Icons/Dasboard";


const ViewCheckOut = () => {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const { CheckOutViewId } = useParams();
    const location = useLocation();
    const data = location.state.data;
    return (
      <div className="bg-[#E6F2F2] w-full h-full px-3 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
            <BreadCrumb
              items={[
                {
                  title: (
                    <Link to="/dashboard">
                      <Dashboard color="gray" width={10} height={10} />{" "}
                    </Link>
                  ),
                },
                { title: <p className=" text-[12px] ">Accommodation</p> },
                { title: <p className=" text-[12px] ">Check-Out</p> },
                { title: <p className=" text-[12px] text-[#4E4B4B]">Edit</p> },
              ]}
            />
        <div className="flex gap-3">
          <Link to={`/main/accomodation/check-in-out/check-out`}>
            <BackButton link={true} />
          </Link>
          <Link
            to={`/main/accomodation/check-in-out/check-out/edit/${CheckOutViewId}`}
            state={{
              from: `/main/accomodation/check-in-out/check-out/view/${data?.checkOutId}`,
              data: data,
            }}
          >
            <EditButton height="8" />
          </Link>
        </div>
        </div>
        <div className="w-full mx-auto h-[calc(100vh-120px)] bg-white rounded-xl overflow-y-scroll p-3">
        <h3 className="text-[#212121] font-semibold pb-3">Check-Out Details</h3>
        <div className="flex flex-wrap gap-y-5 w-full">
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Check-Out Id</h4>
            <p className="text-[#4D4D4D]">{ data?.checkOutId || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Occupant Name</h4>
            <p className="text-[#4D4D4D]">{ data?.occupantName || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Duration Type</h4>
            <p className="text-[#4D4D4D]">{data?.durationType || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Check-In</h4>
            <p className="text-[#4D4D4D]">{data?.checkInId || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Planed Stay Duration</h4>
            <p className="text-[#4D4D4D]">{data?.stayDuration || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Time</h4>
            <p className="text-[#4D4D4D]">{data?.time || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Date</h4>
            <p className="text-[#4D4D4D]">{data?.date && <NuDate value={data?.date} format="DD-MMM-YYYY" /> || "-"}</p>
          </div>
          <div className="w-1/4">
            <h4 className="text-[#212121] pb-3">Comments</h4>
            <p className="text-[#4D4D4D]">{data?.comments || "-"}</p>
          </div>
        </div>
      </div>
      </div>
    );
}

export default ViewCheckOut;