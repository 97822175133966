import { useState } from "react";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dashboard from "../../../../Components/Icons/Dasboard";
import SearchBar from "../../../../Components/Utils/SearchBar";
import { CreateButton, FilterButton } from "../../../../Components/Utils/Buttons";

const CheckInOut = () => {
    const {officeId} = useParams();
    const [activeLink, setActiveLink] = useState(
        {
            create: `/main/accomodation/check-in-out/check-in/${officeId}/create`,
            breadCrumb: 'Check-In',
        }
    );
    const handdleClick = (type) => {
        if (type === 'in') {
            setActiveLink({
                create: `/main/accomodation/check-in-out/check-in/${officeId}/create`,
                breadCrumb: 'Check-In',
            })
        }
        else {
            setActiveLink({
                create: `/main/accomodation/check-in-out/check-out/${officeId}/create`,
                breadCrumb: 'Check-Out',
            })
        }

    }
    // const [ isButtonsVisible, setIsButtonsVisible ] = useState(true);
    const check = {
        checkIn: `/main/accomodation/check-in-out/check-in/${officeId}`,
        checkOut: `/main/accomodation/check-in-out/check-out/${officeId}`,
    }
    return (
        <div className=" bg-[#E6F2F2] h-full w-full py-3 px-6">
            {/* <div className="w-full items-center flex justify-between">
                <div>
                    <BreadCrumb
                        items={[
                            {
                                title: (<Link to="/main/dashboard">
                                    <Dashboard color="gray" width={10} height={10} />{" "}
                                </Link>)
                            },
                            { title: <p className="text-[13px]">Accommodation</p> },
                            { title: <p className="text-[13px]">{activeLink.breadCrumb}</p> },
                        ]}
                    />
                </div>
                { <div className=" flex gap-2 mb-4">
                    <SearchBar />
                    <FilterButton height="8" />
                    <CreateButton label="Import" />
                    <Link to={'/main/accomodation/check-in-out/check-in/create'} onClick={()=>{}}>
                        <CreateButton label={activeLink.breadCrumb} />
                    </Link>
                </div>}
            </div> */}
            <div className=" mb-[3px] flex gap-1 item-center">
                <NavLink
                    onClick={() => { handdleClick('in') }}
                    className={({ isActive }) =>
                        isActive
                            ? "text-baseColor-primary px-4 py-[4px] border-0 border-b-2 border-solid border-baseColor-primary"
                            : "text-baseColor-primary px-4 py-[4px] outline-none border-none"
                    }
                    to={check.checkIn}
                >
                    Check in
                </NavLink>
                <NavLink
                    onClick={() => { handdleClick('out') }}
                    className={({ isActive }) =>
                        isActive
                            ? "text-baseColor-primary px-4 py-[4px] border-0 border-b-2 border-solid border-baseColor-primary"
                            : "text-baseColor-primary px-4 py-[4px] outline-none border-none"
                    }
                    to={check.checkOut}
                >
                    Check out
                </NavLink>
            </div>
            <div className="w-full h-full">
                <Outlet />
            </div>
        </div>
    );
};

export default CheckInOut;
