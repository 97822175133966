import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const RoomInventories = () => {
  return (
    <SettingsMetaData propertyName={"RoomInventories"} viewPropertyName={"Room Inventories"} />

  )
}

export default RoomInventories