import { Layout } from "antd";
import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

// import MainSideBar  from "../../Components/Layouts/MainSideBar"
import Header from "../../Components/Layout/Header";

function MainProject() {
  const navItems = [
    { name: 'Dashboard', 
    link:'/main/dashboard', 
    icon: 'PD',
    subItems: [] },
  ];
  let {Content} = Layout;
  return (
    <div className=" w-[100vw] h-screen">
      <Layout className=" w-full h-screen">
        {/* <MainSideBar navItems={navItems} /> */}
        <Layout>
          <Header />
          <Content className=' py-2 px-6'>
            <div className=" w-60 my-3 bg-white rounded-full p-1 h-10 shadow-sm flex justify-between items-center">
              <NavLink to={"/main-page/main-dashboard"} className={({isActive})=> isActive?" bg-baseColor-secondary rounded-full text-sm font-medium text-white px-6 py-[6px] hover:text-white":"rounded-full text-sm font-medium hover:text-baseColor-secondary text-baseColor-secondary px-6 py-[6px]"}>Dashboard</NavLink>
              <NavLink to={"/main-page/offices"} className={({isActive})=> isActive?" bg-baseColor-secondary rounded-full text-sm font-medium text-white px-6 py-[6px] hover:text-white":"rounded-full text-sm font-medium hover:text-baseColor-secondary text-baseColor-secondary px-6 py-[6px]"}>Offices</NavLink>
            </div>
            <div className=" w-full h-auto overflow-y-auto">
            <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default MainProject;