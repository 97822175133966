import { useLocale } from 'antd/es/locale';
import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { NormalHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';

const ViewFoodMenu = () => {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const { ViewFoodMenuId } = useParams();
    const location = useLocation();
    const data = location.state.data;
    const [createNew,setCreateNew] = useState({
      menuId: "",
      foodName:"",
      category:"",
      subCategory:"",
      type: ""
    })
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Settings</p> },
            { title: <p className=" text-[12px] ">Food Menu</p> },
            { title: <p className=" text-[12px] text-[#4E4B4B]">View</p> },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/catering/food-menu/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/catering/food-menu/${officeId}/edit/${ViewFoodMenuId}`} 
          state={{data: data, from:`/main/catering/food-menu/${officeId}/view/${ViewFoodMenuId}`}}
          ><EditButton height='8'/></Link>
        </div>
      </div>
      <div className='w-full flex justify-between'>
      <div className='w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-y-scroll p-3'>
          <NormalHeading title={"Room"} />
          <div className=" w-full flex flex-wrap ">
              <div className=" w-full grid grid-cols-3 gap-x-8 m-4">
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Menu Id</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.menuId || '-'}</p>
                </div>
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Food Name</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.foodName || '-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.category || '-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Sub Category</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.subCategory || '-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Type</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.type || '-'}</p>
                </div> 
                {/* <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Maximum Occupancy</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.maxOccupancy || '-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Room Status</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.roomStatus || '-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Room Condition</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.roomCondition || '-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Room Facilites</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.roomFacilities ||'-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments || '-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Room Usage</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.roomUsage || '-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Kitchen</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{'-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Room Inventory</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.roomInventories || '-'}</p>
                </div>   */}
                </div>
              </div>
              </div>
               {/* <div className='w-[48%] mx-auto h-[calc(100vh-200px)] bg-white rounded-xl overflow-hidden p-3'>
               <div className='w-full py-[1%] bg-[#048178] flex justify-between rounded-lg'>
                                        <p className=" w-[20%] text-center text-white text-sm font-medium">
                                            Menu Id
                                        </p>
                                        <p className=" w-[20%] text-center text-white text-sm font-medium">
                                            Food Name
                                        </p>
                                       
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Category
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Type
                                        </p>
                                        </div>
                                        <div className='overflow-scroll h-[340px]'>
                                        {data?.beds?.map((data,index)=>{
                                  return(
                                    <div key={index} className="w-full py-2 flex justify-between">
                                    <p className=" w-[20%] text-center text-black text-sm font-light py-1">
                                      {data?.menuId}
                                    </p>
                                    <p className=" w-[20%] text-center text-black text-sm font-light py-1">
                                      {data?.foodName}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light py-1">
                                      {data?.category}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light py-1">
                                      {data?.type}
                                    </p>
                                    </div>
                                  );
                                })}
                                </div>
               </div> */}
      </div>
    </div>
  )
};

export default ViewFoodMenu;