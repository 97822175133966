import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const IdType = () => {
  return (
    <SettingsMetaData propertyName={"ID_Type_Guest"} viewPropertyName={"ID Type"} />

  )
}

export default IdType