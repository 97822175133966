import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import IdType from './IdType'
import DeliveryVechileType from './DeliveryVechileType'
import DeliveryStatus from './DeliveryStatus'


const DeliveryNotes = () => {
  return (
    <>
    <TextMediumSM content="Delivery Notes" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <DeliveryVechileType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <IdType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        < DeliveryStatus/>
     </div>
    
    
     </div>
     </>
  )
}

export default DeliveryNotes