import React, { useEffect, useState } from "react";
import { NormalHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import NuDate from "../../../../Components/Utils/NuDate";

function ViewTrips() {
  const { officeId } = useParams();
  const location = useLocation();
  const data = location.state.data;
    return (
      <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: (<p className=' text-[12px] '>Transportation</p>) },
            { title: (<p className=' text-[12px] '>Trips</p>) },
            { title: (<p className=' text-[12px] '>View</p>) },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/transportation/trips/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/transportation/trips/${officeId}/edit/${data?.tripId}`} state={{data: data, from:`/main/transportation/trips/${officeId}/view/${data?.tripId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
              <h2 className='text-base font-normal text-[#111537] p-3'>Details</h2>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-5 gap-x-8 m-10">
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.tripId || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Vehicle Id</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.vehicleName || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Driver Name</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.driverName|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Driver Id</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.driverId|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Departing Date Time</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.departingDateTime|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Destination Location</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.destinationLocation|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Arrival Time</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.arrivalTime|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Seating Cap</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.seatCapacity|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Frequency</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.frequency|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Cancellation</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.cancellation|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments|| '-'}</p>
                  </div>
                  
                  </div>
                  </div>
          </div>
          
      </div>
    );
}

export default ViewTrips