import { Button, Drawer, Layout, Modal } from "antd";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import logout from "../Utils/Logout";
import { Badge, Dropdown, Space } from "antd";
import axiosInstance from "../../Services/axiosCommon";
import { BellOutlined } from "@ant-design/icons";
import useSwr from "swr";
import moment from "moment";
import NuDate from "../Utils/NuDate";
import { MenuOutlined } from "@ant-design/icons";
import { NavItems } from "../Utils/NavItems";
import SettingsLogo from '../../assets/images/setting_logo.png'

const { confirm } = Modal;

const Header = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));


  const [noti, setNoti] = useState([]);
  const [count, setCount] = useState(0);
  const [title, setTitle] = useState({
    Name: "",
    Country: ""
  })

  const fetcher = (url) =>
    axiosInstance.get(url).then((res) => {
      res?.data?.data && setCount(res?.data?.data?.count);
      res?.data?.data?.notifications &&
        setNoti(res?.data?.data?.notifications);
    });
  const mutate = () => { };
  // const { data, mutate } = useSwr(`/notification/user`, fetcher, {
  //   refreshInterval: 30000,
  // });
  // const getNotifications = async () => {
  //   await axiosInstance
  //     .get(`/notification/user`)
  //     .then(async (res) => {
  //       console.log(res?.data?.data);

  //     })
  //     .catch((error) => console.error(error));
  // };

  function getCompanyName() {
    axiosInstance.get(`CompanyInfo/1`).then(res => {
      console.log(res.data.data?.country);
      res.data?.data && setTitle({
        Name: res.data?.data?.companyName,
        Country: res.data.data?.companyLogo || "",
      })
    }).catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    getCompanyName()
    // getNotifications();
  }, []);




  const onClose = () => {
    setOpen(false);
  };

  function Menu() {
    const handleRead = async (id) => {
      axiosInstance
        .put(`/notification/update/is_read/${id}`, {
          is_read: 1,
        })
        .then((res) => {
          mutate();
          console.log(res.data);
        });
      // const data = 1
      // fetch(axiosInstance.put(`/notification/update/is_read/4`),{
      //   headers:{
      //     'Content-Type': 'application/json'
      //   },
      //   body:JSON.stringify(data)
      // })
      // .then((res)=>{
      //   res=1
      // })
      // .catch((error)=>{
      //   error?.
      // })
    };

    function ClearAll() {
      axiosInstance.delete(`/notification/delete`).then((res) => {
        mutate();
      }).catch(err => { })
    }

    function MarkAll() {
      axiosInstance.get(`/notification/update/mark_all_as_read`).then((res) => {
        mutate();
      }).catch(err => { })
    }


    return (
      <>
        <div className="w-72 h-96  bg-white shadow-lg overflow-y-auto rounded-md">
          <div className=" w-full py-2  bg-slate-200 flex justify-between items-center">
            <p className="font-medium ml-2">Notification</p>
            <div className=" flex items-center gap-2">
              {count > 0 ? <button className=" outline-none border-none text-xs font-normal px-2 py-1 rounded bg-mainColor-300 text-white cursor-pointer" onClick={MarkAll}>Marks as read</button> : null}
              {noti.length > 0 ? <button className=" outline-none border-none text-xs font-normal px-2 py-1 rounded bg-mainColor-300 text-white cursor-pointer" onClick={ClearAll}>Clear All</button> : null}
            </div>
          </div>
          <div className="w-full overflow-x-hidden">
            {noti?.map((notification) => {
              return (
                <>
                  <div
                    className={`${notification?.is_read ? "bg-white" : " bg-slate-100"} w-full my-1 p-1 rounded flex justify-between items-center cursor-pointer`}
                    onClick={() => handleRead(notification?.notificationId)}
                  >
                    <div className="w-[15%]">
                      {/* <NuAvatarGroup userdetails={[{name:`${notification?.sender_fName}`,imageUrl:`${notification?.sender_imageUrl}`}]} />    */}
                      <div className=" w-8 h-8 bg-mainColor-500  flex justify-center items-center text-white font-medium text-lg rounded-full overflow-hidden">
                        {
                          notification.sender_imageUrl ?
                            <img
                              src={
                                notification.sender_imageUrl
                                  ? JSON.parse(notification.sender_imageUrl)
                                  : null
                              }
                              width="50px"
                              alt={`${notification.sender_imageUrl}`}
                            /> :
                            `${notification?.sender_fName.charAt(0)}`
                        }

                      </div>
                    </div>
                    <div className={`w-[85%] ${notification?.is_read ? " font-normal " : "font-medium  "}`}>
                      {/* <p className='font-semibold'>Admin <span className='font-normal'>to</span> Login Person</p> */}
                      <p className="pb-1 text-ellipsis leading-5 text-sm">
                        {notification?.msg}
                      </p>
                      <p className="text-xs">
                        {moment(notification?.date).format("DD-MM-YYYY")} at{" "}
                        <span className="text-[10px]">
                          <NuDate
                            value={`${notification?.date} ${notification?.time}`}
                            format="hh:mm a"
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* <p className='py-2 leading-5 cursor-pointer' onClick={()=> handleRead(notification?.notificationId
)}>{notification?.msg}</p>
            <div className='w-full h-[0.5px] bg-black'></div> */}
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  const handleLogOut = async () => {
    confirm({
      title: `Are you sure you want to Log out?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `This action will navigate you to the login page.`,
      type: 'warning',
      onOk() {
        logout();
      },
      onCancel() { },
    });
  };


  const { Header } = Layout;
  return (
    <Header
      style={{ background: "#F4F2F8", boxShadow: "",height:"50px" }}
      className=" shadow-sm flex justify-between items-center w-full"
    >
      <div className=" w-4 lg:w-6/12 h-full">
        <div className=" hidden h-full items-center lg:flex">{
          <div className=" flex justify-center items-center gap-2">
            <div className=" w-10 h-10 overflow-hidden flex justify-center items-center rounded-full">
              {title.Country && <img src={title.Country} width="120%" alt="#" />}
            </div>
            <span className=" text-base capitalize font-medium">{title.Name || " "}</span>
            {/* <small className=" text-[10px] ml-1 text-gray-500">{title.Country||" "}</small> */}
          </div>

        }</div>
        {/* <div className=" lg:hidden cursor-pointer" onClick={showDrawer}>
        <MenuOutlined />
      </div> */}
      </div>
      <div className=" flex justify-center items-center">
        <div className="mr-6">
          <Link to={`/main-page/offices`}>
           <p className="cursor-pointer">C</p>  
           </Link>       
        </div>
        <div className="mr-6">
          <Link to="/settings/company-info">
           <img src={SettingsLogo} className="w-[20px] h-[21px] mt-6" alt="#"/>
          </Link>
        </div>
        <div className="mr-6">
          <Space>
            <Badge count={count}>
              <Dropdown overlay={<Menu />} trigger={["click"]}>
                {/* <a href="#"> */}
                {/* <div className='w-8 h-8 border border-black border-solid rounded-md flex items-center justify-center'> */}
                <BellOutlined
                  style={{ fontSize: "16px", color: "#017a6e" }}
                  className=""
                />
                {/* </div> */}
                {/* </a> */}
              </Dropdown>
            </Badge>
          </Space>
        </div>

        <div class=" md:p-2 flex items-center">
          <div className="dropdown inline-block relative">
            <button className=" text-gray-700 outline-none border-none bg-transparent font-semibold md:px-4 flex items-center">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
              </svg>
              <div className=" relative w-9 h-9 mx-2 rounded-full bg-white overflow-hidden">
                <div className="absolute z-10 bg-transparent top-0 left-0 w-full h-9 border-4 rounded-full border-solid border-[#016c62]"></div>
                <div className="absolute z-20 bottom-0 right-0 bg-transparent w-1/2 h-9 border-8 rounded-r-full border-solid border-white -[#F4F2F8]"></div>
                <div className="absolute z-30 w-full [calc(36px-4px)] h-full [calc(36px-4px)] text-[#016c62] flex justify-center text-slate-900 capitalize items-center bg-transparent">
                  {userDetails?.name.charAt(0)}
                </div>
              </div>
              <div className=" hidden md:flex flex-col leading-none">
                <p className="text-sm font-semibold">
                  {userDetails?.name || " "}
                  {/* {userDetails?.userLastName || " "} */}
                </p>
              </div>
            </button>
            <ul className="dropdown-menu pt-2 leading-none z-50 absolute hidden shadow-sm">
              {/* <li className="list-none">
                <Link
                  to={`/user-details`}
                  className="rounded-t bg-baseColor-teritary text-black hover:text-black hover:bg-baseColor-quartary py-2  px-4 block whitespace-no-wrap"
                  href="#"
                >
                  Profile
                </Link>
              </li> */}
              <li className="list-none cursor-pointer">
                <div
                  className="rounded bg-baseColor-teritary font-medium hover:bg-baseColor-quartary py-2  px-4 block whitespace-no-wrap"
                  href="#"
                  onClick={handleLogOut}
                >
                  Log Out
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <Drawer title="" placement="left" onClose={onClose} open={open}>
        <div className=" h-full flex flex-col items-center space-y-1">
          {NavItems.map((item, index) => {
            const { path, icon, title, access } = item;
            return (
              <>
                {access && (
                  <NavLink
                    to={path}
                    className={({ isActive }) =>
                      isActive
                        ? `w-[100%] h-[28px] rounded-[6px] py-1 overflow-hidden border bg-sky-300 block text-white`
                        : `w-[100%] h-[28px] overflow-hidden py-1 border block rounded-[9px]`
                    }
                    onClick={onClose}
                  >
                    <div key={index} className=" flex  items-center">
                      <span className=" w-[28px] h-[28px] border rounded-[9px] flex items-center justify-center ">
                        {/* <FontAwesomeIcon icon={icon} /> */}
                      </span>
                      <div className=" mt-1 text-xs font-medium">{title}</div>
                    </div>
                  </NavLink>
                )}
              </>
            );
          })}
        </div>
      </Drawer>
    </Header>
  );
};

export default Header;

function TimeShow(data) {
  const timeString = data;
  const time = new Date(timeString);
  const formattedTime = time.toLocaleTimeString();
}
