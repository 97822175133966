import React, { useState } from 'react'
import TaskStatus from './taskComp'
import { CreateButton } from '../../../Components/Utils/Buttons'
import GroupIcon from '../../../Components/Icons/GroupIcon'
import ListIcon from '../../../Components/Icons/ListIcon'
import { Link, useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Utils/Breadcrumbs'
import ViewMore from '../../../Components/Icons/ViewMore';
import ViewMoreIncidents from '../Incidents/ViewMoreIncidents'
import ViewMoreTask from './ViewMoreTask'
import UseGetDataSearch from '../../../Components/Hooks/useFetchDataBySearch'
import CalenderIcon from '../../../Components/Icons/CalenderIcon'

// import { Calendar } from 'antd'
// import CalendarIcon from '../../Components/Icons/Calendar'
// import TaskOptions from '../../Components/Icons/TaskOptions'
// import {SquareIcon} from '../../assets/icons/square-more.svg'

const TaskPage = () => {
  const { officeId } = useParams();
  const { data, totalCount, getData } = UseGetDataSearch( '/tasks/');
  console.log(data,'dd')

//   const pending = [
//     {
//         heading: "Payment Pending",
//         para: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
//         datetime:"Tomorrow 11:30 pm",
       

//     },
//     {
//       heading: "Payment ",
//       para: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
//       datetime:"Tomorrow 11:30 pm",
     

//   },
//   {
//     heading: " Pending",
//     para: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
//     datetime:"Tomorrow 11:30 pm",
   

// },
//   ]
//   const open = [
//     {
//         head: "Payment Pending",
//         text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
       
       

//     },
//     {
//       head: "Payment ",
//       text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
      
     

//   },
//   {
//     head: " Pending",
//     text: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
   
   

// },
//   ]
//   const close = [
//     {
//         headingg: "Payment Pending",
//         data: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
//         date:"Tomorrow 11:30 pm",
       

//     },
//     {
//       headingg: "Payment ",
//       data: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
//       datetime:"Tomorrow 11:30 pm",
     

//   },
//   {
//     headingg: " Pending",
//     data: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
//     date:"Tomorrow 11:30 pm",
   

// },
//   ]
const closeLen = data?.close ?? []
console.log("drsn",closeLen.length)
const openLen = data?.open ?? []
console.log("drsn",openLen.length)
const pendingLen = data?.pending ?? []
console.log("drsn",pendingLen.length)

  return (
    <>
    <div className='mx-6 mt-2'>
        <BreadCrumb items={[
            { title: <p className='text-[13px]'>Home</p> },
            { title: <p className='text-[13px]'>Task</p> }]}
        />
    </div>
    <div className='mx-6 my-3 flex justify-between items-center'>
          <div className='flex gap-7'>
            <TaskStatus
            count={totalCount}
            label='Total'
            countBg='bg-[#048178]'
          />
            <TaskStatus
              count={openLen.length}
              label='Open'
              countBg='bg-[#19D442]'
            />
            <TaskStatus
              count={pendingLen.length}
              label='Pending'
              countBg='bg-[#ECBE1B]'
              labelBg='bg-[#F9EFCE]'
            />
            <TaskStatus
              count={closeLen.length}
              label='Closed'
              countBg='bg-[#FF5050]'
              labelBg='bg-[#FED7D7]' 
            />
          </div>
          <div className='flex gap-3'>
            {/* <div className='flex items-center gap-2'>
              <GroupIcon/>
              <ListIcon/>
            </div> */}
            <Link to={`/main/task/${officeId}/create/`}><CreateButton 
              label='Task'
              bg='bg-[#048178]'
            /></Link>
          </div>
    </div>
    <div className='flex  gap-6 mx-6 justify-around'>
    <div className='w-1/3 h-[calc(100vh-170px)] overflow-y-scroll flex flex-col gap-5 pb-10 bg-[#B1D8D5] rounded-lg text-[#4D4D4D] font-medium text-[13px] text-center'>
        <h3 className="mt-5 sticky top-0" style={{fontSize: 16, fontWeight: '500', wordWrap: 'break-word'}}>Open</h3>
        {data?.open?.map((i =>{
             console.log("bbbbb",i)
                return(
                  <div className='h-full bg-white p-4 text-start rounded-xl mx-8 ' style={{border: '1px #F4F2F8 solid'}} >
          <div className='mb-7'>
            <div className='flex justify-between'>
              <h2 className='text-[#212121] text-4 font-semibold mb-3 mr-auto'>{i.taskName}</h2>
              <div className='p-1  h-6 rounded-lg '>
                        <ViewMoreTask fetcher={getData}  id={i.id} data={i} />
                      </div>
            </div>
            <p className='text-[#999999] text-[13px] mr-10 font-medium '>{i.taskDesc}</p>
          </div>
          <div className='flex justify-between'>
            <div className='flex gap-3 content-center'>
              <div style={{width: '11px', height: '11px',marginTop:'3px', background: '#19D442', borderRadius: 9999}} />
              <h3 className='font-semibold text-sm text-[#999999]'>{i.taskStatus}</h3>
            </div>
            <div className='flex gap-2 content-center'>
                      {<CalenderIcon/>}
                      <p className='font-semibold text-sm text-[#999999]'>{i.reminderDate}</p>
                      
                      <p className='font-semibold text-sm text-[#999999]'>{i.reminderTime}</p>
                    </div>
          </div>
        </div>
                )
              }))}
        
        
       
      </div>
      <div className='w-1/3 h-[calc(100vh-170px)] overflow-y-scroll flex flex-col gap-5 pb-10 bg-[#B1D8D5] rounded-lg text-[#4D4D4D] font-medium text-[13px] text-center '>
        <h3 className="mt-5 sticky top-0"  style={{fontSize: 16, fontWeight: '500', wordWrap: 'break-word'}}>Pending</h3>
        {data?.pending?.map((i =>{
             console.log("bbbbb",i)
                return(
                  <div className='h-full bg-white p-4 text-start rounded-xl mx-8 ' style={{border: '1px #F4F2F8 solid'}} >
                  <div className='mb-7'>
                    <div className='flex justify-between'>

                      <h2 className='text-[#212121] text-4 font-semibold mb-3 mr-auto'>{i.taskName}</h2>
                      <div className='p-1  h-6 rounded-lg '>
                        <ViewMoreTask fetcher={getData} id={i.id} data={i} />
                      </div>
                    </div>
                    <p className='text-[#999999] text-[13px] mr-10 font-medium '>{i.taskDesc}</p>
                  </div>
                  <div className='flex justify-between'>
                    <div className='flex gap-3 content-center'>
                      <div style={{width: '11px', height: '11px',marginTop:'3px', background: '#ECBE1B', borderRadius: 9999}} />
                      <h3 className='font-semibold text-sm text-[#999999]'>{i.taskStatus}</h3>
                     
                    </div>
                    <div className='flex gap-2 content-center'>
                      {<CalenderIcon/>}
                      <p className='font-semibold text-sm text-[#999999]'>{i.reminderDate}</p>
                      
                      <p className='font-semibold text-sm text-[#999999]'>{i.reminderTime}</p>
                    </div>
                  </div>
                </div>

             
           
               )
            }))}
             
       
       
      </div>
     
      <div className='w-1/3 h-[calc(100vh-170px)] overflow-y-scroll flex flex-col gap-5 pb-10 bg-[#B1D8D5] rounded-lg text-[#4D4D4D] font-medium text-[13px] text-center'>
        <h3 className="mt-5 sticky top-0" style={{fontSize: 16, fontWeight: '500', wordWrap: 'break-word'}}>Closed</h3>
        {data?.close?.map((i =>{
                return(
                  <div className='h-full bg-white p-4 text-start rounded-xl mx-8 ' style={{border: '1px #F4F2F8 solid'}} >
          <div className='mb-7'>
            <div className='flex justify-between'>
              <h2 className='text-[#212121] text-4 font-semibold mb-3 mr-auto'>{i.taskName}</h2>
              <div className='p-1  h-6 rounded-lg '>
                        <ViewMoreTask fetcher={getData}  id={i.id} data={i}/>
                      </div>
            </div>
            <p className='text-[#999999] text-[13px] mr-10 font-medium '>{i.taskDesc}</p>
          </div>
          <div className='flex justify-between'>
            <div className='flex gap-3 content-center'>
              <div style={{width: '11px', height: '11px',marginTop:'3px', background: '#FF5050', borderRadius: 9999}} />
              <h3 className='font-semibold text-sm text-[#999999]'>{i.taskStatus}</h3>
            </div>
            <div className='flex gap-2 content-center'>
                      {<CalenderIcon/>}
                      <p className='font-semibold text-sm text-[#999999]'>{i.reminderDate}</p>
                      
                      <p className='font-semibold text-sm text-[#999999]'>{i.reminderTime}</p>
                    </div>
          </div>
        </div>

                )
              }))}
        
        
       
      </div>
    </div>
    </>
  )
}

export default TaskPage;