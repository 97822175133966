import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const ApprovalStatusPR = () => {
  return (
    <SettingsMetaData propertyName={"ApprovalStatus_PR"} viewPropertyName={"Approval Status"} />

  )
}

export default ApprovalStatusPR