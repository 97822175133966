import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import ApprovalStatusPR from './ApprovalStatus'


const PurchaseRequest = () => {
  return (
    <>
    <TextMediumSM content="Purchase Request" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>

     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <ApprovalStatusPR/>
     </div>
    
    
     </div>
     </>
  )
}

export default PurchaseRequest