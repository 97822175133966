import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import { CancelButton, CreateButton, FilterButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import SearchBar from "../../../../Components/Utils/SearchBar";
import { useEffect, useState } from "react";
import DataTable from "../../../../Components/Layout/DataTable";
import RoomViewMore from "./viewMore";
import * as yep from 'yup'
import UseGetData from "../../../../Components/Hooks/useFetchData";
import VisitorTrackingViewMore from "./viewMore";
import Modal from "antd/es/modal/Modal";
import { useFormik } from "formik";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import { NuInputNumber, NuInputText, NuSelect,NuDatePicker } from "../../../../Components/Utils/NuInput";
import Pagination from "../../../../Components/Utils/Pagenation";
import NuDate from "../../../../Components/Utils/NuDate";

const VisitorTracking = () => {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, getData, totalCount } = UseGetDataSearch( '/visitors-tracking/', params);
    let headers = [
        {
          label: <span>Ref ID</span>,
          width: "w-[5%]",
          align:"left"
        },
        {
          label: <span>Visitor Name</span>,
          width: "w-[15%]",
          align:"left"
        },
        {
          label: <span>Date</span>,
          width: "w-[10%]",
          align:"left"
        },
      
        {
          label: <span>Mobile</span>,
          width: "w-[10%]",
          align:"left"
        },
        {
          label: <span>Whome To Visit</span>,
          width: "w-[15%]",
          align:"left"
        },
        {
          label: <span>Purpose Of Visit</span>,
          width: "w-[10%]",
          align:"left"
        },
        {
          label: <span>Visiters Number</span>,
          width: "w-[10%]",
          align:"left"
        },
        {
          label: <span>Time In</span>,
          width: "w-[10%]",
          align:"left"
        },
        {
          label: <span>Time Out</span>,
          width: "w-[10%]",
          align:"left"
        },
        {
          label: <span>Actions</span>,
          width: "w-[5%]",
        },
      ];
      
      
      const [showFilterDialog, setShowFilterDialog] = useState(false);
      const [filter, setFilter] = useState(false); //clear filter
      const closeFilterDialog = () => {
        setShowFilterDialog(false); //close filter
      };
      const[filterCamp,setFilterCamp]=useState({
        fromDate:"",
        toDate:""
       });
       const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterCamp,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yep.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, fromDate: value?.fromDate, toDate: value?.toDate,})
          closeFilterDialog();
          setFilter(true)
          setFilterCamp({
            fromDate: "",
            toDate: "",
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          fromDate: "",
          toDate: "",
       
        })
        formik.values.fromDate=""
        formik.values.toDate=""
          setFilter(false)
      }

      useEffect(()=>{
        setParams({...params,page:page,per_page:pageCount})
       },[page,pageCount])
       
       
  return (
    <div className=" bg-[#E6F2F2] h-full w-full py-3 px-6">
      <div className="w-full items-center flex justify-between">
        <div>
          <BreadCrumb
            items={[
              { title: <p className="text-[13px]">Home</p> },
              { title: <p className="text-[13px]">Accommodation</p> },
              { title: <p className="text-[13px]">Visitors Tracking</p> },
            ]}
          />
        </div>
        <div className=" flex gap-2 mb-4">
          <div>
                      <SearchBar 
                    handleChange={(e) =>
                        setParams({ ...params, search: e.target.value })
                      }
                    /></div>
          <FilterButton height="8"  handleClick={() => setShowFilterDialog(true)}/>
          {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
          <CreateButton label="Import" />
          <Link to={`/main/accomodation/visitors-tracking/${officeId}/create`}>
            <CreateButton label="Visitors Tracking" />
          </Link>
        </div>
      </div>
        <DataTable
     bodyHeight='max-h-[calc(100vh-260px)]'
     height='h-[calc(100vh-150px)]'
     headers={headers} 
     total={totalCount}
     pageIndex={page}
     setPageIndex={setPage}
     dataCount={pageCount}
     setDataCount={setPageCount}
    >
      {data.length>0?data.map((item, i) => {
            return (
              <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                <td className="w-[5%] p-2 px-[1px] text-left ">{item.visitorId}</td>
                
                <td className="w-[15%] p-2 px-[1px] text-left ">
                  <Link className='hover:text-baseColor-primary text-black ' 
                  to={`/main/accomodation/visitors-tracking/${officeId}/view/${item.visitorId}`}
                  state={{data : item}}
                  >{item.visitorName}</Link>
                </td>
                <td className="w-[10%] p-2 px-[1px] text-left">{ item.date && <NuDate value={item.date} format="DD-MMM-YYYY" />|| ''}</td>
                <td className="w-[10%] p-2 px-[1px] text-left ">{item.visitorMobile?.display}</td>
                <td className="w-[15%] p-2 px-[1px] text-left ">{item.occupantName || ''}</td>
                <td className="w-[10%] p-2 px-[1px] text-left truncate">{item.purposeOfVisit || ''}</td>
                <td className="w-[10%] p-2 px-[1px] text-left">{item.visitNumber || ''}</td>
                <td className="w-[10%] p-2 px-[1px] text-left">{item.timeIn || ''}</td>
                <td className="w-[10%] p-2 px-[1px] text-left">{item.timeOut || '-'}</td>
                <td className="w-[5%] p-2 px-[1px] text-center ">
                    <VisitorTrackingViewMore data={item} id={item.visitorId} fetcher={getData}/>
                </td>
              </tr>
            );
          })
          :
          <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
        }

        
    </DataTable>
    <Modal
    open={showFilterDialog}
        title={"Filter Camp"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog} 
        footer={[]}>
           <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <NuDatePicker
              isRequired={true}
              label="From Date"
              formik={formik}
              name="fromDate"
              width="w-full"
            />
                   <NuDatePicker
              isRequired={true}
              label="To Date"
              formik={formik}
              name="toDate"
              width="w-full"
            />
                    </div>
                    
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
    </Modal>
    <div className='w-full h-20 flex justify-end'>
           
            </div>
    </div>
  );
};

export default VisitorTracking;
