import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Unit = () => {
  return (
    <SettingsMetaData propertyName={"Unit_Product"} viewPropertyName={"Unit"} />

  )
}

export default Unit