import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Department from './Division'
import Designation from './Designation'
import IdType from './IdType'
import AssignedProject from './Assigned Project'
import Status from './Status'

const Employee = () => {
  return (
    <>
        <TextMediumSM content="Employee" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <AssignedProject/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <IdType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Designation/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Department/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
        
         </div>
         </>
  )
}

export default Employee