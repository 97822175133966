import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';

const ViewCamp = () => {
    const {officeId} = useParams();
    console.log("nnnnnn0",officeId)
    const location = useLocation();
    const data = location.state.data;
    return (
        <div className=' bg-[#E6F2F2] h-full px-3 py-2'>
            <div className='flex justify-between items-center py-2'>
                <BreadCrumb items={[
                    
                    { title: <p className='text-[13px]'>Accommodation</p> },
                    { title: <p className='text-[13px]'>Camp Info</p> },
                    { title: <p className='text-[13px]'>View</p> },
                ]}
                />
                <div className='w-1/2 flex justify-end'>
                    <div className='flex gap-3'>
                        <Link to={`/main/accomodation/camp-info/${officeId}`}><BackButton link={true}/></Link>
                        <div>
                            <Link 
                            to={`/main/accomodation/camp-info/${officeId}/edit/${data?.campId}`} 
                            state={{ data : data , from : `/main/accomodation/camp-info/${officeId}/view/${data?.campId}`}} >
                                <EditButton height='8' />
                            </Link>
                        </div> 
                    </div>
                </div>
            </div>
            {/*  Section-1 */}
            <div className='px-2 py-2 h-[calc(100vh-130px)] flex flex-col gap-5 overflow-y-scroll'>
                <div className='flex flex-col gap-2 px-3 py-3 w-full h-auto bg-[#FFF] rounded-[5px] overflow-scroll-y'>
                    <h3 className='text-[#212121] text-sm font-semibold leading-normal'>Layout</h3>
                    <div className='flex gap-5 h-[calc(100vh-66vh)] w-full items-center'>
                        {data?.layoutDrawing && <>
                            <div className='w-1/5 h-full flex items-center p-2 bg-[#E6F2F2] rounded-[5px] overflow-hidden'>
                                <img className='w-full flex justify-center items-center' src={data?.layoutDrawing} alt='#' />
                            </div>
                            <div className='border-r border-[1px] border-slate-200/60 border-solid' /></>
                        }
                        <div className='w-full flex flex-col justify-between h-full'>
                            <div className='flex w-auto '>
                            <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>ID</h4>
                                    <p className='text-[#4D4D4D]'>{data?.campId || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Camp Name</h4>
                                    <p className='text-[#4D4D4D]'>{data?.campName || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Camp Number</h4>
                                    <p className='text-[#4D4D4D]'>{data?.campNo || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Camp Layout Area</h4>
                                    <p className='text-[#4D4D4D]'>Width : {data?.layoutArea?.width || '0.00'} m, Height : {data?.layoutArea?.height || '0.00'} m </p>
                                </div>
                            </div>
                            <div className='flex w-auto py-3'>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Email</h4>
                                    <p className='text-[#4D4D4D]'>{data?.email || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Mobile</h4>
                                    <p className='text-[#4D4D4D]'>{data?.mobile.display || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Branch</h4>
                                    <p className='text-[#4D4D4D]'>{data?.branchName || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Camp Boss</h4>
                                    <p className='text-[#4D4D4D]'>{data?.campBossName || '-'}</p>
                                </div>
                            </div>
                            <div className='w-auto py-3'>
                                <div>
                                    <h3 className='text-[#212121] font-semibold pb-3'>Address</h3>
                                </div>
                                <div className='flex w-auto'>
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>City</h4>
                                        <p className='text-[#4D4D4D]'>{data?.city || '-'}</p>
                                    </div>
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>State</h4>
                                        <p className='text-[#4D4D4D]'>{data?.state || '-'}</p>
                                    </div>
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>Country</h4>
                                        <p className='text-[#4D4D4D]'>{data?.country || '-'}</p>
                                    </div>
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>Postcode</h4>
                                        <p className='text-[#4D4D4D]'>{data?.postalCode || '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-2 px-3 py-3 w-full h-[calc(100vh-300px)] bg-[#FFF] rounded-[5px] p-5'>
                    <h3 className='text-[#212121] pb-5 font-semibold leading-normal'>Facilities</h3>
                    <div className='w-full'>
                        <div className='flex w-full flex-wrap'>
                            <div className='w-[14.28%] py-2'>
                                <h4 className='text-[#212121] pb-3'>Accomodation</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.accomodation === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                                <h4 className='text-[#212121] pb-3'>Dining</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.dining === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Kitchen</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.kitchen === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Laundary</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.laundary === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Recreation</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.recreation === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Medical</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.medical === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Administrative Office</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.adminOffice === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Security</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.security === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Swimming Pool</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.dining === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Footbal Court</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.footballCourt === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Basketball Court</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.basketballCourt === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Badminton Court</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.badmintonCourt === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Tennis Court</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.tennisCourt === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>CCTV System</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.cctvSystem === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Fire Protection</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.fireProtection === true ? 'Yes' : 'No'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>Ambulance</h4>
                                <p className='text-[#4D4D4D]'>{data?.facilities?.ambulance === true ? 'Yes' : 'No'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewCamp;