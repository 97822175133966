import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const BloodGroup = () => {
  return (
    <SettingsMetaData propertyName={"BloodGroup"} viewPropertyName={"Blood Group"} />

  )
}

export default BloodGroup