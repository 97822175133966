import React, { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker,  NuInputText, NuSelect, NuTextArea,NuInputNumber } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Navigate,useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dropdown from 'antd/es/dropdown/dropdown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function CreateFoodSchedule() {
  const { value: MealType} = useFetchMataData('MealType') 
  const { value: MenuByDays} = useFetchMataData('MenuByDays') 
  const { value: MenuOnSpeacialDays} = useFetchMataData('MenuOnSpeacialDays')
  const navigate=useNavigate()
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const {data:menus} = UseDropDown('/food-menu/drop-down')
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData ();
  const [createNew, setCreateNew] = useState({
      // reqType: "RoomService",
      menuByDays:"",
      prparedBy:"",
      mealType:"",
      menuOnSpecialDays: "",
      ethnicity:"",
      menuId:""
  })
 

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
         

          addFormData('/food-schedule/new', value, resetForm);
      }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Catering</p>) },
        { title: (<p className=' text-[12px] '>Food Schedule</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
            <NuInputText
              // isRequired={true}
              label=" Ethnicity"
              placeholder=" Ethnicity"
              formik={formik}
              name="ethnicity"
              width="w-full md:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label=" Menu By Days"
              placeholder="Choose"
              options={MenuByDays}
              formik={formik}
              name="menuByDays"
              width="w-full md:w-1/3"
            />
              {/* <NuInputNumber
              label="Id"
              placeholder="Id"
              formik={formik}
              name="roomserviceId"
              width="w-full md:w-1/3"
            /> */}
            <NuSelect
              label="Menu Id"
              options={menus}
              formik={formik}
              placeholder="Choose"
              name="menuId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          
            {/* <NuInputNumber
              label="Prpared By"
              placeholder="prparedBy"
              formik={formik}
              name="prparedBy"
              width="w-full md:w-1/3"
            /> */}
             {/* <NuInputNumber
              label="Bed No"
              placeholder="Bed No"
              formik={formik}
              name="Bed No"
              width="w-full md:w-1/3"
            /> */}
            <NuSelect
              label="Prpared By"
              options={user}
              formik={formik}
              placeholder="Choose"
              name="prparedBy"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuSelect
              label="Meal Type"
              options={MealType}
              formik={formik}
              placeholder="Choose"
              name="mealType"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuSelect
              label="Menu On Special Days"
              options={MenuOnSpeacialDays}
              formik={formik}
              placeholder="Choose"
              name="menuOnSpecialDays"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
             {/* <NuDatePicker
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             {/* <NuDatePicker
              label="Attended on"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="attendedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
          {/* <NuSelect
              label="Status"
              options={[
                { value: "1", label: "A" },
                { value: "2", label: "B" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="Status"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            /> */}
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateFoodSchedule