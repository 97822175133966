import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Status from './Status'
import Priority from './Priority'

const Task = () => {
  return (
    <>
    <TextMediumSM content="Task" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Priority/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Status
        />
     </div>
    </div>
     </>
  )
}

export default Task