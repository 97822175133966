import { Link, useNavigate } from "react-router-dom";
import { BackButton, CancelButton, SubmitButton } from "../../../../../Components/Utils/Buttons";
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectCurrency, NuSelectwithSearch, NuTextArea, NuTimePicker } from "../../../../../Components/Utils/NuInput";
import BreadCrumb from "../../../../../Components/Utils/Breadcrumbs";
import Dashboard from "../../../../../Components/Icons/Dasboard";
import axiosInstance from "../../../../../Services/axiosCommon";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useState } from "react";
import { notification } from "antd";
import UseDropDown from "../../../../../Components/Hooks/useDropDown";
import useAddFormData from "../../../../../Components/Hooks/useAddFormData";


function CreateCheckIn() {
    const { data:occupant } = UseDropDown('/occupants/drop-down');
    let navigate = useNavigate();
    const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
    const [createNew,setCreateNew] = useState({
        date: "",
        time:"",
        planedStayDuration: "",
        durationType: "",
        occupantId:"",
        comments: ""
    })
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // branch_name : yup.string().required('Branch name is required.'),
          // email : yup.string().required('Email is required.'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ccc");
        addFormData('/check-in/new-check-in', value, resetForm);
      }
    });
    return (
      <div className="w-full h-full px-4 py-2">
      {contextHolder}
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              {
                title: (
                  <Link to="/dashboard">
                    <Dashboard color="gray" width={10} height={10} />{" "}
                  </Link>
                ),
              },
              { title: <p className=" text-[12px] ">Accommodation</p> },
              { title: <p className=" text-[12px] ">Check-In</p> },
              { title: <p className=" text-[12px] text-[#4E4B4B]">Create</p> },
            ]}
          />
          <div className="flex gap-2">
            <BackButton/>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto h-[calc(100vh-200px)] bg-white rounded-xl overflow-hidden">
            <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
              <NuSelect
                label="Occupant"
                options={occupant}
                formik={formik}
                placeholder="Choose"
                name="occupantId"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            <NuSelect
                label="Stay Type"
                options={[
                  { value: "day", label: "Day" },
                  { value: "month", label: "Month" },
                  { value: "year", label: "Year" },
                ]}
                formik={formik}
                placeholder="Choose"
                name="durationType"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            <NuInputNumber
                isRequired={true}
                label="Planed Stay Duration"
                placeholder="Planed Stay Duration"
                formik={formik}
                name="planedStayDuration"
                width="w-full md:w-1/3"
              />
              <NuDatePicker
                isRequired={true}
                label="Date"
                placeholder="Date"
                formik={formik}
                name="date"
                width="w-full md:w-1/3"
              />
              <NuTimePicker
                isRequired={true}
                label="Time In"
                placeholder="Time In"
                formik={formik}
                name="time"
                width="w-full md:w-1/3"
              />
              <NuTextArea
                label="Comments"
                placeholder="Comments"
                formik={formik}
                name="comments"
                width='w-full md:w-1/3'
              />

              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton handleClick={()=>{navigate(-1)}} />
                  <SubmitButton />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  export default CreateCheckIn