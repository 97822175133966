import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const DurationTime = () => {
  return (
    <SettingsMetaData propertyName={"DurationTime_checkOut"} viewPropertyName={"Duration Time"} />

  )
}

export default DurationTime