import { useLocale } from 'antd/es/locale';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { NormalHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';

const ViewBranch = () => {
    const { id } = useParams();
    const location = useLocation();
    const data = location.state.data;
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Settings</p> },
            { title: <p className=" text-[12px] ">Branches</p> },
            { title: <p className=" text-[12px] text-[#4E4B4B]">View</p> },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={'/settings/branch'}><BackButton link={true}/></Link>
          <Link to={`/settings/branch/edit/${id}`} state={{data: data, from:`/settings/branch/view/${id}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className='w-full mx-auto h-[calc(100vh-200px)] bg-white rounded-xl overflow-hidden pb-3'>
          <NormalHeading title={data?.branch_name} />
          <div className='flex flex-col gap-4 mt-4 text-gray-800'>
            <div className='flex gap-2 items-center'>
                <SmallsubHeading title={'Email'}/>:<span>{data?.email || ''}</span>
            </div>
            <div className='flex gap-2 items-center'>
                <SmallsubHeading title={'Mobile'}/>:<span>{data?.mobile?.display || ''}</span>
            </div>
            <div className='flex gap-2 items-center'>
                <SmallsubHeading title={'Website'}/><span>{data?.website || ''}</span>
            </div>
            <div className='flex flex-col gap-2 items-start'>
                <SmallsubHeading title={'Address :'}/><span><pre>{data?.address || ''}</pre></span>
            </div>
            <div className='flex gap-2 items-center'>
                <SmallsubHeading title={'Currency'}/>:<span>{data?.currency || ''}</span>
            </div>
          </div>
      </div>
    </div>
  )
};

export default ViewBranch;