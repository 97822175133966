export function MainHeading ({title}){
    return (
        <h1 className=" text-[#231942] capitalize font-bold text-[20px] lg:text-[23px] xl:text-[26px]">{title}</h1>
    )
}

export function SecondHeading ({title}){
    return (
        <h2 className=" text-[#231942] capitalize font-bold text-[16px] lg:text-[18px] xl:text-[20px]">{title}</h2>
    )
}

export function NormalHeading ({title}){
    return (
        <h3 className=" text-[#231942] capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px]">{title}</h3>
    )
}

export function SmallHeading ({title}){
    return (
        <h4 className=" text-[#9A95A8] capitalize font-medium text-[13px]">{title}</h4>
    )
}

export function SmallsubHeading ({title}){
    return (
        <h5 className=" text-[#9A95A8]  capitalize font-normal text-[12px]">{title}</h5>
    )
}
