import { Route } from "react-router-dom";
import MainTransportation from "../Views/Web/Transportation/MainTransportation";
import MainSchedule from "../Views/Web/Transportation/Schedule/MainSchedule";
import ScheduleIndex from "../Views/Web/Transportation/Schedule/ScheduleIndex";
import CreateSchedule from "../Views/Web/Transportation/Schedule/CreateSchedule";
import EditSchedule from "../Views/Web/Transportation/Schedule/EditSchedule";
import ViewSchedule from "../Views/Web/Transportation/Schedule/ViewSchedule";
import MainParkingLots from "../Views/Web/Transportation/ParkingLots/MainParkingLots";
import ParkingLotsIndex from "../Views/Web/Transportation/ParkingLots/ParkingLotsIndex";
import CreateParkingLots from "../Views/Web/Transportation/ParkingLots/CreateParkingLots";
import EditParkingLots from "../Views/Web/Transportation/ParkingLots/EditParkingLots";
import ViewParkingLots from "../Views/Web/Transportation/ParkingLots/ViewParkingLots";
import MainTrips from "../Views/Web/Transportation/Trips/MainTrips";
import TripsIndex from "../Views/Web/Transportation/Trips/TripsIndex";
import CreateTrips from "../Views/Web/Transportation/Trips/CreateTrips";
import EditTrips from "../Views/Web/Transportation/Trips/EditTrips";
import ViewTrips from "../Views/Web/Transportation/Trips/ViewTrips";


const TransportationRotes = () =>{
    return (
        <Route path="transportation" element={<MainTransportation/>}>

          <Route path="schedule/:officeId" element={<MainSchedule/>}>
              <Route index element={<ScheduleIndex/>}/>
              <Route path="create" element={<CreateSchedule/>}/>
              <Route path='edit/:scheduleEditId' element={<EditSchedule/>} />
              <Route path='view/:scheduleViewId' element={<ViewSchedule/>} />
          </Route>

          <Route path="parking-lots/:officeId" element={<MainParkingLots/>}>
              <Route index element={<ParkingLotsIndex/>}/>
              <Route path="create" element={<CreateParkingLots/>}/>
              <Route path='edit/:parkingLotsEditId' element={<EditParkingLots/>} />
              <Route path='view/:parkingLotsViewId' element={<ViewParkingLots/>} />
          </Route>

          <Route path="trips/:officeId" element={<MainTrips/>}>
              <Route index element={<TripsIndex/>}/>
              <Route path="create" element={<CreateTrips/>}/>
              <Route path='edit/:tripsEditId' element={<EditTrips/>} />
              <Route path='view/:tripsViewId' element={<ViewTrips/>} />
          </Route>

        </Route>
    )
}

export default TransportationRotes;