import { Route } from "react-router-dom"
import MainInventory from "../Views/Web/Inventory/MainInventory"
import MainStocks from "../Views/Web/Inventory/Stocks/MainStocks"
import CreateStocks from "../Views/Web/Inventory/Stocks/CreateStocks"
import StocksIndex from "../Views/Web/Inventory/Stocks/StocksIndex"
import EditStocks from "../Views/Web/Inventory/Stocks/EditStocks"
import ViewStocks from "../Views/Web/Inventory/Stocks/ViewStocks"
import MainAsserts from "../Views/Web/Inventory/Asserts/MainAsserts"
import AssertsIndex from "../Views/Web/Inventory/Asserts/AssertsIndex"
import CreateAsserts from "../Views/Web/Inventory/Asserts/CreateAsserts"
import EditAsserts from "../Views/Web/Inventory/Asserts/EditAsserts"
import ViewAsserts from "../Views/Web/Inventory/Asserts/ViewAsserts"
import MainVehicles from "../Views/Web/Inventory/Vehicles/MainVehicles"
import VehiclesIndex from "../Views/Web/Inventory/Vehicles/VehiclesIndex"
import CreateVehicles from "../Views/Web/Inventory/Vehicles/CreateVehicles"
import EditVehicles from "../Views/Web/Inventory/Vehicles/EditVehicles"
import ViewVehicles from "../Views/Web/Inventory/Vehicles/ViewVehicles"
import MainEquipments from "../Views/Web/Inventory/Equipments/MainEquipments"
import EquipmentsIndex from "../Views/Web/Inventory/Equipments/EquipmentsIndex"
import CreateEquipments from "../Views/Web/Inventory/Equipments/CreateEquipments"
import ViewEquipments from "../Views/Web/Inventory/Equipments/ViewEquipments"
import MainMaterials from "../Views/Web/Inventory/Materials/MainMaterials"
import MaterialsIndex from "../Views/Web/Inventory/Materials/MaterialsIndex"
import CreateMaterials from "../Views/Web/Inventory/Materials/CreateMaterials"
import EditMaterials from "../Views/Web/Inventory/Materials/EditMaterials"
import ViewMaterials from "../Views/Web/Inventory/Materials/ViewMaterials"
import EditEquipments from "../Views/Web/Inventory/Equipments/EditEquipments"


const InvontaryRoutes = () =>{
    return(
        <Route path="inventary" element={<MainInventory/>}>
          <Route path="stocks/:officeId" element={<MainStocks/>}>
              <Route index element={<StocksIndex/>}/>
              <Route path="create" element={<CreateStocks/>}/>
              <Route path='edit/:stocksEditId' element={<EditStocks/>} />
              <Route path='view/:stocksViewId' element={<ViewStocks/>} />
          </Route>
          <Route path="asserts/:officeId" element={<MainAsserts/>}>
              <Route index element={<AssertsIndex/>}/>
              <Route path="create" element={<CreateAsserts/>}/>
              <Route path='edit/:assertsEditId' element={<EditAsserts/>} />
              <Route path='view/:assertsViewId' element={<ViewAsserts/>} />
          </Route>
          <Route path="vehicles/:officeId" element={<MainVehicles/>}>
              <Route index element={<VehiclesIndex/>}/>
              <Route path="create" element={<CreateVehicles/>}/>
              <Route path='edit/:EditVehiclesId' element={<EditVehicles/>} />
              <Route path='view/:vehiclesViewId' element={<ViewVehicles/>} />
          </Route>
          <Route path="equipments/:officeId" element={<MainEquipments/>}>
              <Route index element={<EquipmentsIndex/>}/>
              <Route path="create" element={<CreateEquipments/>}/>
              <Route path='edit/:equipmentsEditId' element={<EditEquipments/>} />
              <Route path='view/:equipmentsViewId' element={<ViewEquipments/>} />
          </Route>
          <Route path="materials/:officeId" element={<MainMaterials/>}>
              <Route index element={<MaterialsIndex/>}/>
              <Route path="create" element={<CreateMaterials/>}/>
              <Route path='edit/:EditMaterialsId' element={<EditMaterials/>} />
              <Route path='view/:materialsViewId' element={<ViewMaterials/>} />
          </Route>
        </Route>
    )}

export default InvontaryRoutes