import React, { useState } from 'react'
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuDateTime, NuInputText, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import NuDate from '../../../../Components/Utils/NuDate';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';


function EditMeetingPoints() {
  const { value: value } = useFetchMataData('Category_Points')
  const { value: value1 } = useFetchMataData('Status_Points')

  const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx", "mp4", "mov", "wmv", "avi", "flv", "mkv"];
  const {data:meeting} =UseDropDown('/meeting/drop-down')
  const navigate = useNavigate()
  const {data:user} =UseDropDown('/user/drop-down')
  const [productList, setProductList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null)
  
  const { EditMeeting } = useParams();
  const location=useLocation()
  const data =location.state.data
  const from=location.state.from
  const [images, setImages] = useState([]);
  // console.log(location.state,'from');
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData,  isLoading } = useEditFormData();
  const [api,contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    // date: (data?.date && NuDate({value:data?.date,format:"YYYY-MM-DD"}) )||"",
    meetingId: data?.meetingId,
    topic: data?.topic,
    category: data?.category,
    points: data?.points,
    status: data?.status,
    dueDate: (data?.dueDate && NuDate({value:data?.dueDate,format:"YYYY-MM-DD"}) )||"",
    comments:data?.comments,
    // startDate: (data?.startDate && NuDate({value:data?.startDate,format:"YYYY-MM-DD"}) )||"",
    // comments: data?.comments,
    // endDate: (data?.endDate && NuDate({value:data?.endDate,format:"YYYY-MM-DD"}) )||"",
     
  })

  const [url,setUrl] = useState(true);

  const [showFilterDialog, setShowFilterDialog] = useState(true);

  console.log(data);
  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item => {
      if (item.size < maxFileSize) {
        fileArr.push(item)
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item => {
      if (item.size < maxFileSize) {
        let fileName = item?.name
        console.log('fname', fileName);
        let fileExtention = fileName.split(".").reverse()[0]
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName)
        } else {
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
    })
    console.log(tempArr, 'temp');
    setImages(tempArr)
  }

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        // const withAgendaValues={
        //   ...value,
        //   agendaItems: productList
        // }
        let formData = new FormData();
        formData.append('json_data', JSON.stringify(value));
        if (selectedFile !== null) {
          selectedFile.forEach((file) => {
            formData.append("attachments", file);
          });
        } else {
          formData.append("attachments", [])
        }
  
        editFormData(`/meeting-point/edit-meeting/${data.meetingPointId}`, formData, resetForm,from);
      }
  })
  const handleAddProducts = () => {
    const products = formik.values.agendaItems
    // console.log("man",products)
    setProductList((prevList) => [...prevList, products]);
    formik.setFieldValue('agendaItems', '');
  };
  const handleDeleteProduct = (index) => {
    setProductList((prevList) => prevList.filter((_, i) => i !== index));
  };
return (
  <div className="w-full h-full p-2 bg-[#F1F2FB]">
  <div className="flex justify-between px-4">
      <BreadCrumb items={[
      {
          title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
          </Link>)
      },
      { title: (<p className=' text-[12px] '>Meetings</p>) },
      { title: (<p className=' text-[12px] '>Meeting-point</p>) },
      { title: (<p className=' text-[12px] '>Edit</p>) },
  ]} />
  <BackButton/>
  </div>

  <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto  bg-white rounded-xl overflow-hidden mt-4">
        <div className=" w-full h-[calc(100vh-180px)] py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
        <NuSelect
                      label="Meeting Id"
                      options={meeting}
                      formik={formik}
                      placeholder="Choose"
                      name="meetingId"
                      width="w-full md:w-1/3"
                    />
                       <NuInputText
                      label='Topic'
                      placeholder='topic'
                      formik={formik}
                      name='topic'
                      width="w-full md:w-1/3"
                       />
                       <NuSelect
                      label="Category"
                      options={value}
                      formik={formik}
                      placeholder="Choose"
                      name="category"
                       width="w-full md:w-1/3"
                    />
           <NuInputText
            // isRequired={true}
            label=" Points"
            placeholder="header"
            formik={formik}
            name="points"
            width="w-full md:w-1/3"
          />
                    <NuDatePicker
                    label="Due-Date"
                  
                    formik={formik}
                    name="dueDate"
                    width="w-full md:w-1/3"
                   />
                   <NuSelect
                      label="Status"
                      options={value1}
                      formik={formik}
                      placeholder="Choose"
                      name="status"
                      width="w-full md:w-1/3 md:-mt-2"
                    />
            
             <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <Attachement
                      handleChange={handleImageChange}
                      preview={images || ''}
                      width="w-full"
                      label="Attach"
                      multiple={true}
                    />
                   
                   <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
  {data?.attchments?.map((imgs, idx) => {
                                      console.log('img', imgs);
                                      return (
                                          <div
                                              className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                              key={idx}
                                              style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                          >
                                              <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                              // onClick={() => handleImageClick(imgs)}
                                              onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                              >
                                                  {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                  <img src={imgs?.file} alt={imgs?.file} />
                                              </div>
                                          </div>
                                   );
                                   })} 

                                   <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton handleClick={()=>{navigate(-1)}} />
              <SubmitButton />
            </div>
          </div>
                                  </div>
           
           
          
            
        
           
          
        </div>
      </div>
    </form>
    </div>
)
}

export default EditMeetingPoints