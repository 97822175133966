import React, { useState,useEffect } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import ViewMoreProduct from './ViewMoreProduct';
import { currencies } from '../../../../assets/CountryMini';
import DataTable from '../../../../Components/Layout/DataTable';
import { CreateButton, FilterButton } from '../../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import { Modal } from 'antd';
import { CancelButton,SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuSelect,NuInputText } from '../../../../Components/Utils/NuInput';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function ProductIndex() {
  const { value: Category} = useFetchMataData('Category') 
  const { value: SubCategory } = useFetchMataData('SubCategory') 

  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, totalCount, getData} = UseGetDataSearch('/medical-products/',params);
    console.log(data,'dddd');

     useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])

     const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const[filterProducts,setfilterProducts]=useState({
        category:"",
        subCategory:"",
        brand:"",
        manufacturer:"",
        
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterProducts,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, category: value?.category, subCategory: value?.subCategory,brand: value?.brand,manufacturer: value?.manufacturer})
          closeFilterDialog();
          setFilter(true)
          setfilterProducts({
            category: "",
            subCategory: "",
            brand:"",
            manufacturer:""
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          category: "",
          subCategory: "",
          brand:"",
          manufacturer:""
       
        })
        formik.values.category=""
        formik.values.subCategory=""
        formik.values.brand=""
        formik.values.manufacturer=""
          setFilter(false)
      }
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState('');
  // const  { data, totalCount, getData } = UseGetData(1,20,'/employees/');
  // console.log('ddd',data);
  
  // const userAccess = true;JSON.parse(localStorage.getItem("userAccess"));
  let AllUserCreateAccess = true;//userAccess
  // ? userAccess.some((permission) => permission.permissionName == "Settings_Alluser_create")
  // : false;
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
  
      let headers = [
          {
            label: <span>ID</span>,
            width: "w-[5%]",
          },
          {
            label: <span>Product Name</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Category</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Sub-Category</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Product Code</span>,
            width: "w-[10%]",
          },
         
          {
            label: <span> Brand</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Manufacturer</span>,
            width: "w-[10%]",
          },
        
         
          {
            label: <span>Actions</span>,
            width: "w-[5%]",
          },
        ];
       
    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <BreadCrumb items={[
        {title:(<Link to="/main/dashboard">
        <Dashboard color="gray" width={10} height={10} />{" "}
      </Link>)},
        { title: <p className='text-[13px]'>Clinic</p> },
        { title: <p className='text-[13px]'>Medical Product</p> },

        ]} />
      <div className=' flex gap-2'>
       <SearchBar
       handleChange={(e) =>
        setParams({ ...params, search: e.target.value })
      }
       />
       <FilterButton height='8'
       handleClick={() => setShowFilterDialog(true)}/>
       {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
       <CreateButton label='Import' />
       <Link to={`/main/clinic/medical-products/${officeId}/create`}><CreateButton label='Product'/></Link> 
      </div>
      </div>
  
      <DataTable
       bodyHeight='max-h-[calc(100vh-260px)]'
       height='h-[calc(100vh-130px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={page}
       setPageIndex={setPage}
       dataCount={pageCount}
       setDataCount={setPageCount}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              currencies.find((i) => {
                if(i.value == item?.currency){
                  getCurrency=i.label;
                }
              })
              return (
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                  <td className="w-[5%] p-2 px-[1px] text-center ">{item?.productId||"#"}</td>
                  {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.productName||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.category||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.subCategory||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.productCode||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.brand ||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.manufacturer ||""}</td>

                  <td className="w-[5%] p-2 px-[1px] text-center ">
                      <ViewMoreProduct data={item} fetcher={getData} id={item?.productId}/>
                  </td>
                </tr>
              );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
          }
      </DataTable>
      <Modal
        open={showFilterDialog}
        title={"Medical Products"}
        width={"50%"}
        className="my-modal"

        
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="">
                    <NuSelect
                                            label="Category"
                                            options={Category}
                                            formik={formik}
                                            placeholder="Choose"
                                            name="category"
                                            width="w-full "
                                            />
                                             <NuSelect
                                            label="Sub-Category"
                                            options={SubCategory}
                                            formik={formik}
                                            placeholder="Choose"
                                            name="subCategory"
                                            width="w-full "
                                            />
                                            <NuInputText 
                                        label='Brand'
                                         placeholder='Brand' 
                                         formik={formik}
                                          name='brand'
                                           width='w-full ' />
                                           <NuInputText 
                                        label='Manufacturer'
                                         placeholder='Manufacturer' 
                                         formik={formik}
                                          name='manufacturer'
                                           width='w-full' />
                    
                    </div>
                    
                   <div className="flex justify-end gap-2 mt-5">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
    </div>
    )
  }

export default ProductIndex