import React, { useEffect, useState } from 'react'
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuSelect } from '../../../../Components/Utils/NuInput';
import { Modal } from 'antd';
import { currencies } from '../../../../assets/CountryMini';
import { Link, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import ViewMoreDelivaryNotes from './ViewMoreDelivaryNotes';
import DataTable from '../../../../Components/Layout/DataTable';
import SearchBar from '../../../../Components/Utils/SearchBar';
import * as yup from 'yup'
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function DelivaryNotesIndex() {
  const { value: value1 } = useFetchMataData('DeliveryVechileType')
  const { value: value2 } = useFetchMataData('DeliveryStatus')


  const { officeId } = useParams();
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(20)
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const { data, totalCount, getData } = UseGetDataSearch('/delivery-notes/', params);
  console.log(data, 'dddd');
  useEffect(() => {
    setParams({ ...params, page: page, per_page: pageCount })
  }, [page, pageCount])
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  const [filterPO, setfilterPO] = useState({
    deliveryStatus: "",
    deliveryVechicleType: "",
    deliveredOnFrom: "",
    deliveredOnTo: "",
    dateFrom: "",
    dateTo: "",

  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterPO,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff", value);
      setParams({ ...params, deliveryStatus: value?.deliveryStatus, deliveryVechicleType: value?.deliveryVechicleType, deliveredOnFrom: value?.deliveredOnFrom, deliveredOnTo: value?.deliveredOnTo, dateFrom: value?.dateFrom, dateTo: value?.dateTo, })
      closeFilterDialog();
      setFilter(true)
      setfilterPO({
        deliveryStatus: "",
        deliveryVechicleType: "",
        deliveredOnFrom: "",
        deliveredOnTo: "",
        dateFrom: "",
        dateTo: "",
      });
    }
  })
  const clearFilter = () => {
    setParams({
      ...params,
      deliveryStatus: "",
      deliveryVechicleType: "",
      deliveredOnFrom: "",
      deliveredOnTo: "",
      dateFrom: "",
      dateTo: "",

    })
    formik.values.deliveryStatus = ""
    formik.values.deliveryVechicleType = ""
    formik.values.deliveredOnFrom = ""
    formik.values.deliveredOnTo = ""
    formik.values.dateFrom = ""
    formik.values.dateTo = ""
    setFilter(false)
  }

  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[5%]",
    },
    // {
    //   label: <span>Meal Id</span>,
    //   width: "w-[5%]",
    // },
    {
      label: <span>Name</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Vechicle Type</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Delivery Status</span>,
      width: "w-[10%]",
    },
    {
      label: <span> Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Actions</span>,
      width: "w-[5%]",
    },
  ];

  useEffect(() => {
    setParams({ ...params, page: page, per_page: pageCount })
  }, [page, pageCount])

  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Procurement</p>) },
          { title: (<p className=' text-[12px] '>Delivery Notes</p>) }
        ]} />
        <div className=' flex gap-2'>
          <SearchBar
            handleChange={(e) =>
              setParams({ ...params, search: e.target.value })
            }
          />
          <FilterButton height='8'
            handleClick={() => setShowFilterDialog(true)} />
          {filter && (
            <FilterButton
              handleClick={() => clearFilter()}
              label="Clear" />
          )}
          <CreateButton label='Import' />
          <Link to={`/main/procurment/delivary-notes/${officeId}/create`}><CreateButton label='Delivery Notes' /></Link>
        </div>
      </div>
      <DataTable
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-130px)]'
        headers={headers}
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
      >
        {data.length > 0 ? data.map((item, i) => {
          let getCurrency;
          currencies.find((i) => {
            if (i.value == item?.currency) {
              getCurrency = i.label;
            }
          })
          console.log("aaa", data?.brand);
          return (
            <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
              <td className="w-[5%] p-2 px-[1px] text-center ">{item?.deliveryNoteId || "#"}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item?.deliveryPersonName || ""}</td>
              {/* <td className="w-[10%] p-2 px-[1px] text-center ">{item?.products?.map((n)=>{
                    return(
                      <p>{n?.orderedQty}</p>
                    )
                  })||""}</td> */}
              {/* <td className="w-[5%] p-2 px-[1px] text-center ">{item?.menuId||"#"}</td> */}
              {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}

              <td className="w-[10%] p-2 px-[1px] text-center ">{item?.deliveryVechicleType || "kkk"}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item?.deliveryStatus || "kkk"}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item?.date || ""}</td>

              <td className="w-[5%] p-2 px-[1px] text-center ">
                <ViewMoreDelivaryNotes data={item} fetcher={getData} id={item?.deliveryNoteId} />
              </td>
            </tr>
          );
        })
          :
          <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
        }
      </DataTable>

      <Modal
        open={showFilterDialog}
        title={"Purchase Order"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col mb-4">


            <NuSelect
              label="Delivery Status"
              options={value2}
              formik={formik}
              placeholder="Choose"
              name="deliveryStatus"
              width="w-full"
            />
            <NuSelect
              label="Delivery Vechicle Type"
              options={value1}
              formik={formik}
              placeholder="Choose"
              name="deliveryVechicleType"
              width="w-full "
            />
            <NuDatePicker
              label='Delivered On From'
              placeholder='deliveredOnFrom'
              formik={formik}
              name='deliveredOnFrom'
              width='w-full' />

                <NuDatePicker
              label='Delivered On To'
              placeholder='deliveredOnTo'
              formik={formik}
              name='deliveredOnTo'
              width='w-full' />

                <NuDatePicker
              label='DateFrom'
              placeholder='dateFrom'
              formik={formik}
              name='dateFrom'
              width='w-full' />

              <NuDatePicker
              label='DateTo'
              placeholder='dateTo'
              formik={formik}
              name='dateTo'
              width='w-full' />
          </div>

          <div className="flex justify-end gap-2">
            <CancelButton handleClick={closeFilterDialog} />
            <SubmitButton name='Apply' />
          </div>
        </form>
        {/* </div>
           </div>
         )} */}
      </Modal>
    </div>
  )
}

export default DelivaryNotesIndex