import React from 'react'
import { TextXS} from '../Text/NormalText';
import ImageView from '../Utils/ImageView';
import { TextMediumSM } from '../Text/MediumText';

function OfficeCard({name,officeType,status,address,city}) {
 
  return (
    <div className=' bg-white rounded-xl h-36 xl:h-40 shadow-md hover:scale-105 ease-out duration-300  transition-all p-2 '>
      <div className=' w-full flex justify-end items-center'>
          <span className={` px-3 py-[2px] text-[9px] text-white rounded-lg ${status == true? 'bg-green-500' : 'bg-red-500'}`}>{status==true?"Active":"In-active"}</span>
      </div>
      <div className=' flex justify-start gap-2 items-center'>
        <div className=' w-5/12 flex justify-center items-center'>
          <ImageView  image="" />
        </div>
        <div className=' w-6/12 h-20 '>
          <TextMediumSM content={name||""}  />
          <TextXS content={officeType||""}  />
          <TextXS content={`${city||""} ${city?",":""} ${address}` || ""}/>
        </div>
      </div>
    </div>
  )
}

export default OfficeCard;