import React from 'react'
import Status from './Status'
import OccupantType from './OccupantType'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'

const Occupants = () => {
  return (
    <>
    <TextMediumSM content="Occupants" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <OccupantType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Status/>
     </div>
    </div>
     </>
  )
}

export default Occupants