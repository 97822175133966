import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const MenuOnSpeacialDays = () => {
  return (
    <SettingsMetaData propertyName={"MenuOnSpeacialDays"} viewPropertyName={"Menu On Speacial Days"} />

  )
}

export default MenuOnSpeacialDays