import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const RoomUsage = () => {
  return (
    <SettingsMetaData propertyName={"RoomUsage"} viewPropertyName={"Room Usage"} />

  )
}

export default RoomUsage