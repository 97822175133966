import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SettingsIndex from '../Views/Web/Settings'
import CompanyInfoIndex from '../Views/Web/Settings/CompanyInfo'
import EditCompanyInfo from '../Views/Web/Settings/CompanyInfo/EditCompanyInfo'
import MainCompanyInfo from '../Views/Web/Settings/CompanyInfo/CompanyInfoMain'
import BranchMain from '../Views/Web/Settings/CompanyBranches/BranchMain'
import BranchIndex from '../Views/Web/Settings/CompanyBranches'
import CreateBranch from '../Views/Web/Settings/CompanyBranches/CreateBranch'
import EditBranch from '../Views/Web/Settings/CompanyBranches/EditBranch'
import ViewBranch from '../Views/Web/Settings/CompanyBranches/ViewBranch'
import MainAllUsers from '../Views/Web/Settings/AllUsers/AllUserMain'
import Index from '../Views/Web/Settings/AllUsers'
import CreateUser from '../Views/Web/Settings/AllUsers/createUser'
import EditUser from '../Views/Web/Settings/AllUsers/editUser'
import MainListing from '../Views/Web/Settings/Listing/MainListing'
import MainAccomodation from '../Views/Web/Settings/Accomodation/MainAccomodation'
import MainStaff from '../Views/Web/Settings/Staff/MainStaff'
import MainClininc from '../Views/Web/Settings/Clinic/MainClininc'
import MainIncident from '../Views/Web/Settings/Incident/MainIncident'
import MainCatering from '../Views/Web/Settings/Catering/MainCatering'
import MainInventory from '../Views/Web/Settings/Inventory/MainInventory'
import MainProcurement from '../Views/Web/Settings/Procurement/MainProcurement'
import MainMeeting from '../Views/Web/Settings/Meeting/MainMeeting'
import MainTask from '../Views/Web/Settings/Task/MainTask'
import MainVendors from '../Views/Web/Settings/Vendors/MainVendors'
import MainSetting from '../Views/Web/Settings/MainSetting'
import MainMaintanece from '../Views/Web/Settings/Maintanance/MainMaintanance'
import MainMaintanance from '../Views/Web/Settings/Maintanance/MainMaintanance'
import MainTransPort from '../Views/Web/Settings/Transportation/MainTransport'

const SettingsRoutes = () => {
  return (
    <Route path='settings' element={<SettingsIndex/>}>
      {/* <Route index element={<SettingsIndex/>}/> */}
      <Route path='company-info' element={<MainCompanyInfo/>}>
          <Route index element={<CompanyInfoIndex/>}/>
          <Route path='edit' element={<EditCompanyInfo/>}/>
        </Route>
        <Route path='branch' element={<BranchMain/>}>
          <Route index element={<BranchIndex/>}/>
          <Route path='create' element={<CreateBranch/>}/>
          <Route path='edit/:id' element={<EditBranch/>}/>
          <Route path='view/:id' element={<ViewBranch/>}/>
        </Route>
        <Route path='users' element={<MainAllUsers/>}>
          <Route index element={<Index/>}/>
          <Route path='create' element={<CreateUser/>} />
          <Route path='edit/:id' element={<EditUser/>} />
        </Route>
        <Route path='listing' element={<MainListing/>}/>
        <Route path='accomodation' element={<MainAccomodation/>}/>
        <Route path='staff' element={<MainStaff/>}/>
        <Route path='clinic' element={<MainClininc/>}/>
        <Route path='incident' element={<MainIncident/>}/>
        <Route path='catering' element={<MainCatering/>}/>
        <Route path='maintanance' element={<MainMaintanance/>}/>
        <Route path='inventory' element={<MainInventory/>}/>
        <Route path='procurment' element={<MainProcurement/>}/>
        <Route path='transpotation' element={< MainTransPort/>}/>
        <Route path='meetings' element={<MainMeeting/>}/>
        <Route path='my-task' element={<MainTask/>}/>
        <Route path='vendors' element={<MainVendors/>}/>

        
    </Route>
  )
}

export default SettingsRoutes;