import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditStocks() {
  const {data:medicalproduct} =UseDropDown('/medical-products/drop-down')
  const { value: Supplier } = useFetchMataData('Supplier') 

    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    console.log(location.state,'state');
    const { stocksEditId } = useParams();
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData,contextHolder,isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
        productId:data.productId,
        isAsset:data.isAsset,
        supplier:data.supplier,
        batchNo:data.batchNo,
        serialNo:data.serialNo,
        unit:data.unit,
        quantity:data.quantity,
        mfdDate:data.mfdDate,
        expiryDate:data.expiryDate,

      
      
    })
    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };
    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
        setCreateNew(prevState => ({ ...createNew, image: "" }))
        formik.values.image = ""
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            // let formData = new FormData();
            // formData.append('medicalProductData', JSON.stringify(value));
            // formData.append('image', image.raw);

            editFormData(`/medical-stocks/edit/${stocksEditId}`, value, resetForm, from);
        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: <p className='text-[13px]'>Clinic</p> },
        { title: <p className='text-[13px]'>Medical-Stocks</p> },
        { title: <p className='text-[13px]'>Edit</p> }

    ]} />
    <BackButton/>
    </div>
    <div className="w-full h-full">
                <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
                    {contextHolder}
                    <div className="w-[96%] mx-auto  ">
                        <form onSubmit={formik.handleSubmit} className="w-full ">
                        <div className='flex gap-8 md:flex-nowrap p-6 h-[calc(100vh-135px)] mt-4  bg-white rounded-lg'>
                            
                            {/* <div className='flex flex-wrap md:flex-nowrap pb-4 bg-white'> */}
                           
                                <div className='w-full'>
                                    <div className='flex flex-wrap w-full'>
                                    <NuSelect
                                            label="Product Id"
                                            options={medicalproduct}
                                            formik={formik}
                                            placeholder="Choose"
                                            name="productId"
                                            width="w-full md:w-1/2 md:-mt-2"
                                            />
                                             <NuSelect
                                            label="Is Asset"
                                            options={[
                                                { value: true, label: "Yes" },
                                                { value: false, label: "No" },
                                            ]}
                                            formik={formik}
                                            placeholder="Choose"
                                            name="isAsset"
                                            width="w-full md:w-1/2 md:-mt-2"
                                            />
                                      

                                            <NuSelect
                                            label="Supplier"
                                            options={Supplier}
                                            formik={formik}
                                            placeholder="Choose"
                                            name="supplier"
                                            width="w-full md:w-1/2 md:-mt-2"
                                            />
                                           


                                        <NuInputNumber
                                         label='Batch No' 
                                         placeholder='Batch No'
                                          formik={formik}
                                           name='batchNo'
                                            width='w-full md:w-1/2 md:-mt-2' />
                                            
                                        <NuInputNumber
                                         label='Serial No' 
                                         placeholder='Serial No'
                                          formik={formik}
                                           name='serialNo'
                                            width='w-full md:w-1/2 md:-mt-2' />

                                            <NuInputNumber
                                        label='Unit'
                                         placeholder='Unit' 
                                         formik={formik}
                                          name='unit'
                                           width='w-full md:w-1/2 md:-mt-2' />
                                             <NuInputNumber
                                        label='Quantity'
                                         placeholder='Quantity' 
                                         formik={formik}
                                          name='quantity'
                                           width='w-full md:w-1/2 md:-mt-2' />
                                            <NuDatePicker
                                            label="Mfd Date"
                                            placeholder="dd/mm/yyyy"
                                            formik={formik}
                                            name="mfdDate"
                                            width="w-full md:w-1/2 md:-mt-2"
                                            />
                                             <NuDatePicker
                                            label="Expiry Date"
                                            placeholder="dd/mm/yyyy"
                                            formik={formik}
                                            name="expiryDate"
                                            width="w-full md:w-1/2 md:-mt-2"
                                            />

                                           <div className="w-full md:block mx-auto my-4 mt-8 ">
                                <div className="md:flex justify-end gap-4">
                                    <CancelButton handleClick={() => { Oncancel() }} />
                                    <SubmitButton isLoading={isLoading} />
                                </div>
                            </div>  
                                    </div>
                                    
                                </div>
                            </div>
                            
                           
                            
                        </form>
                    </div>
                </div>
            </div>
 </div>
  )
}

export default EditStocks