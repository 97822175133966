import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Facilities = () => {
  return (
    <SettingsMetaData propertyName={"Facilities"} viewPropertyName={"Facilities"} />

  )
}

export default Facilities