import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../Components/Icons/Dasboard';
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import { NormalHeading, SmallsubHeading } from '../../../Components/Utils/Heading';

function ViewIncidents() {
  const { officeId } = useParams();
  const { ViewIncidentsId } = useParams();
    const location = useLocation();
    const data = location.state.data;
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Incident</p> },
            { title: <p className=" text-[12px] ">View</p> },
           
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/incidents/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/incidents/${officeId}/edit/${ViewIncidentsId}`} 
          state={{data: data, from:`/main/incidents/${officeId}/view/${ViewIncidentsId}`}}
          ><EditButton height='8' /></Link>
        </div>
      </div>
      <div className='w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden p-3'>
          <NormalHeading title={"Incidents"} />
          <div className=" w-full flex flex-wrap ">
              <div className=" w-full grid grid-cols-5 gap-x-8 m-4">
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Incident Id</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.incidentId}</p>
                </div>
               
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Incident Type</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.incidentType}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Cteated At</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.createdAt}</p>
                </div>
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date&Time</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.dateTime}</p>
                </div> 
                {/* <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Mobile</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data.visitorMobile.display}</p>
                </div>  */}
                {/* <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Id Type</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{'-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Id Number</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{'-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Attach Id</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{'-'}</p>
                </div>  */}
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Action Token</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.actionTaken }</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Incident Desc</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.incidentDesc || ''}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Investigation</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.investigation || 0}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Location</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.location}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Recommendation</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.recommendation }</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reported by</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.reportedByName}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Severity</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.severity }</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.status }</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Witness</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.witness }</p>
                </div> 
                {/* <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Vehicle Type</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{'-'}</p>
                </div> 
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Vehicle Plate No</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{'-'}</p>
                </div>
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{'-'}</p>
                </div>
                <div className='w-full grid px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Visit History</p>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{'-'}</p>
                </div>  */}
                </div>
              </div>
      </div>
    </div>
  )
}

export default ViewIncidents