import { Route } from "react-router-dom";
import MainClinic from "../Views/Web/clinic/MainClinic";
import MainPatient from "../Views/Web/clinic/Patients/MainPatient";
import CreatePatient from "../Views/Web/clinic/Patients/createPatient";
import Patientsindex from "../Views/Web/clinic/Patients";
import ViewPatients from "../Views/Web/clinic/Patients/Patients";
import EditPatients from "../Views/Web/clinic/Patients/EditPatients";
import ViewIssuance from "../Views/Web/clinic/Issuance/Issuance";
import EditIssuance from "../Views/Web/clinic/Issuance/EditIssuance";
import CreateIssunance from "../Views/Web/clinic/Issuance/CreateIssunance";
import IssuanceIndex from "../Views/Web/clinic/Issuance";
import MainIssuance from "../Views/Web/clinic/Issuance/MainIssuance";
import EditReferences from "../Views/Web/clinic/References/EditReferences";
import ViewReferences from "../Views/Web/clinic/References/References";
import CreateReferrence from "../Views/Web/clinic/References/CreateReferrence";
import ReferenceIndex from "../Views/Web/clinic/References/Index";
import MainReference from "../Views/Web/clinic/References/MainReference";
import EditProduct from "../Views/Web/clinic/Products/EditProduct";
import CreateProduct from "../Views/Web/clinic/Products/CreateProduct";
import ProductIndex from "../Views/Web/clinic/Products/Index";
import MainProduct from "../Views/Web/clinic/Products/MainProduct";
import ViewProduct from "../Views/Web/clinic/Products/products";
import ViewStocks from "../Views/Web/clinic/Stocks/ViewShocks";
import EditStocks from "../Views/Web/clinic/Stocks/EditStocks";
import CreateStocks from "../Views/Web/clinic/Stocks/CreateStocks";
import StocksIndex from "../Views/Web/clinic/Stocks/Index";
import MainStocks from "../Views/Web/clinic/Stocks/MainShocks";
import ContactListIndex from "../Views/Web/clinic/ContactList/Index";
import CreateContactList from "../Views/Web/clinic/ContactList/CreateContactList";
import EditContactList from "../Views/Web/clinic/ContactList/EditContactList";
import ViewContactList from "../Views/Web/clinic/ContactList/ViewContactList";
import MainContactList from "../Views/Web/clinic/ContactList/mainContactList";


const ClinicRoutes = () =>{
  return(
      <Route path="clinic" element={<MainClinic/>}>
        <Route path="patients/:officeId" element={<MainPatient/>}>
            <Route index element={<Patientsindex/>}/>
            <Route path="create" element={<CreatePatient/>}/>
            <Route path='edit/:patientsEditId' element={<EditPatients/>} />
            <Route path='view/:patientsViewId' element={<ViewPatients/>} />
        </Route>
        <Route path="issuance/:officeId" element={<MainIssuance/>}>
            <Route index element={<IssuanceIndex/>}/>
            <Route path="create" element={<CreateIssunance/>}/>
            <Route path='edit/:issuanceEditId' element={<EditIssuance/>} />
            <Route path='view/:issuanceViewId' element={<ViewIssuance/>} />
        </Route>
        <Route path="reference/:officeId" element={<MainReference/>}>
            <Route index element={<ReferenceIndex/>}/>
            <Route path="create" element={<CreateReferrence/>}/>
            <Route path='edit/:referenceEditId' element={<EditReferences/>} />
            <Route path='view/:referenceViewId' element={<ViewReferences/>} />
        </Route>
        <Route path="medical-stocks/:officeId" element={<MainStocks/>}>
            <Route index element={<StocksIndex/>}/>
            <Route path="create" element={<CreateStocks/>}/>
            <Route path='edit/:stocksEditId' element={<EditStocks/>} />
            <Route path='view/:stocksViewId' element={<ViewStocks/>} />
        </Route>
        <Route path="medical-products/:officeId" element={<MainProduct/>}>
            <Route index element={<ProductIndex/>}/>
            <Route path="create" element={<CreateProduct/>}/>
            <Route path='edit/:medicalProductsEditId' element={<EditProduct/>} />
            <Route path='view/:medicalProductsViewId' element={<ViewProduct/>} />
        </Route>
        <Route path="contact-list/:officeId" element={<MainContactList/>}>
            <Route index element={<ContactListIndex/>}/>
            <Route path="create" element={<CreateContactList/>}/>
            <Route path='edit/:contactlistEditId' element={<EditContactList/>} />
            <Route path='view/:contactlistViewId' element={<ViewContactList/>} />
        </Route>
       
      </Route>
  )
}

export default ClinicRoutes;