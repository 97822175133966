import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Shift from './Shift'
import Status from './Status'
import Designation from './Designation'

const Staff = () => {
  return (
    <>
    <TextMediumSM content="Staff" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Shift/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Status
        />
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Designation
        />
     </div>
    </div>
     </>
  )
}

export default Staff