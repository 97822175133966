import { useContext, createContext, useState, useEffect } from "react"
import { Link } from "react-router-dom";
// import CircleLeftArrow from "../../Components/Icons/CircleLeftArrow";
// import CircleRightArrow from "../../Components/Icons/CircleRightArrow";
// import { useTranslation } from "react-i18next";
import Settings from "../../Components/Icons/Settings";
import CircleRightArrow from "../Icons/CircleRightArrow";
import CircleLeftArrow from "../Icons/CircleLeftArrow";

const SidebarContext = createContext()



const Submenu = ({ subItems,valuesId }) => {
  // const {t,i18n} = useTranslation();

  const [topPosition, setTopPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
 
  useEffect(() => {
    const screenHeight = window.innerHeight;
    const submenuHeight = subItems.length * 75; // Adjust this value based on the actual submenu item height
    if (screenHeight < submenuHeight) {
      console.log(screenHeight - submenuHeight);
      setTopPosition(screenHeight - submenuHeight);
    }
    setIsVisible(true);
  }, [subItems]);

  return(
  
    <div   style={{ top: `${topPosition}px` }}
    //  className={`absolute rounded-md border border-[#048178] border-solid border-opacity-20 overflow-hidden left-full bg-[#E6F2F2] w-48 shadow-lg p-2 ${isVisible ? 'transition-opacity duration-1000 opacity-100' : 'opacity-0'} ease-in-out`}
    className={`
    absolute left-full  rounded-md px-1 ml-1
    bg-indigo-100 text-indigo-800  text-left
    invisible opacity-20 -translate-x-3 transition-all
    group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 w-52 text-sm
`}
     >
      {subItems.map((subItem, subIndex) => (
        <Link
          key={subIndex}
          to={`${subItem.link}/${valuesId}`}
          className="flex justify-start my-1 rounded-md px-3 py-[6px] bg-[#E6F2F2] text-gray-800 hover:bg-[#048178] hover:text-[#E6F2F2]"
        >
          {/* <i className={`fas fa-${subItem.icon} text-lg mr-2`}></i> */}
          <p className={` text-xs  text-left "text-left mx-auto"`}>{subItem.name}</p>
        </Link>
      ))}
    </div>
  );
};




export default function Sidebar({ children }) {
  const [expanded, setExpanded] = useState(true)
  // const {t,i18n} = useTranslation();
  

  function getExpand(){
    let locExp = localStorage.getItem('expand')
    if (locExp) {
      setExpanded(JSON.parse(locExp));
    }else{
      localStorage.setItem('expand', true)
      setExpanded(true)
    }
  }

  useEffect(() => {
    getExpand()
  },[])


  function toggleExpanded(e){
    setExpanded(e)
    localStorage.setItem('expand',e)
  }


  return (
    <aside className="h-full z-[999]">
      <nav className="h-full flex flex-col bg-baseColor-primary border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <div className={`overflow-hidden font-medium text-xl text-white text-center  transition-all ${
              expanded ? "w-32" : "w-0"
            }`}>
              nucamp
          </div>
          {/* <img
            src="https://img.logoipsum.com/243.svg"
            className={`overflow-hidden transition-all ${
              expanded ? "w-32" : "w-0"
            }`}
            alt=""
          /> */}
          <button
            onClick={() => toggleExpanded(!expanded)}
            className=" w-[29px] h-[29px] rounded-full flex justify-center items-center  border-none"
          >
            {expanded ? <CircleLeftArrow/> :<CircleRightArrow />}
          </button>
        </div>
        <div className=" w-full h-[calc(100vh-120px)] ">
        <SidebarContext.Provider value={{ expanded }}>
          <ul className="flex-1 px-3" >{children}</ul>
        </SidebarContext.Provider>
        </div>
        {/* <div className="border-t flex p-3">
        <Link className="flex justify-between items-center" to="/settings">
          <div className="p-2 bg-[#c7d2fe] rounded-lg">
          <Settings color="#048178" height={18} width={18}/>

          </div>
          </Link>
          <Link className="flex justify-between items-center" to="/settings">

          <div
            className={`
              flex justify-between items-center
               overflow-hidden transition-all ${expanded ? "w-28 xl:w-32 ml-3" : "w-0"}
          `}
          >
          <div className="leading-4">
              <h4 className="font-semibold  text-xs 2xl:text-sm">Settings</h4>
            </div>
          
          </div>
          </Link>
        </div> */}
      </nav>
    </aside>
  )
}

export function SidebarItem({ icon, text, active, alert,hasSubMenu,subItem,link,valuesId }) {
  const { expanded } = useContext(SidebarContext)
  // const {t,i18n} = useTranslation();
  
  
  return (
    <li
      className={`
        relative flex items-center justify-center py-[6px]   px-3 my-1 gap-2
        font-medium rounded-md cursor-pointer text-white hover:text-baseColor-primary
        transition-colors group
        ${
          active ==true
            ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
            : "hover:bg-indigo-50 text-baseColor-primary"
        }
    `}
    >
      {
        hasSubMenu ? <span className=" w-4 flex justify-center items-center  text-xs ">
        {icon}
        </span>
        :
        <Link to={link}>
          <span className=" w-4 justify-center items-center  text-xs">
        {icon}
        </span>
        </Link> 
      }
      
      <span
        className={`overflow-hidden transition-all text-xs  hover:text-baseColor-primary  ${
          expanded ? `w-28 xl:w-32 "ml-3" ` : "w-0"
        }`}
      >
       {hasSubMenu ? text : <Link className=" text-white hover:text-baseColor-primary" to={`${link}/${valuesId}`}>{text}</Link> }  
      </span>
      {alert && (
        <div
          className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
            expanded ? "" : "top-2"
          }`}
        />
      )}

      {hasSubMenu && (
            <Submenu subItems={subItem||[]} valuesId={valuesId} />
      )}
    </li>
  )
}