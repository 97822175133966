import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { MainHeading, NormalHeading, SmallHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';

const ViewEmployee = () => {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const location = useLocation();
  const data = location.state.data;
  console.log(data, 'jkk');
  const { id } = useParams();
  return (
    <div className='px-4 py-2 bg-[#E6F2F2] h-full w-full'>
      <div className="flex justify-between px-4">
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Listing</p>) },
          { title: (<p className=' text-[12px] '>Employees</p>) },
        ]} />
        <div className='flex justify-end gap-2'>
          <Link to={`/main/listing/employees/${officeId}`}><BackButton link={true} /></Link>
          <Link to={`/main/listing/employees/${officeId}/edit/${id}`} state={{ from: `/main/listing/employees/${officeId}/view/${data.employee_id}`, data: data }}><EditButton height='8' /></Link>
        </div>
      </div>
      <div className=' overflow-scroll h-[600px]'>
      <div className='px-4 mt-3 '>
        <div className={`h-[calc(100vh-360px)]  w-full p-3 rounded-[10px] ${!data?.image && 'justify-around'} flex gap-3 bg-white`}>
          {data?.image.file && (<div className='w-[20%] overflow-hidden rounded-md h-full bg-[#cce7e4]/30 backdrop-blur-sm flex items-center'>
            <img className='w-full h-auto' src={data?.image.file} alt='employee' />
          </div>)}
          <div className='w-[16%] h-full flex flex-col justify-center'>
            <div className='w-full h-[50%] p-2 flex flex-col justify-between'>
              <div>
                <MainHeading title={data?.name || '-'} />
                <p className='text-md text-[#048178] font-medium'>{data?.designation || '-'}</p>
              </div>
              {data?.status === 'Active' ? (<div className='w-[30%] flex items-center h-5 justify-center text-xs font-bold text-[#00A441] bg-[#DBFFE9] rounded-[3px]'>
                Active
              </div>) : (<div className='w-[30%] flex items-center h-5 justify-center text-xs font-bold text-red-800 bg-red-400 rounded-[3px]'>
                In Active
              </div>)}
            </div>
          </div>
          <div className={`${data?.image ? 'w-[64%]' : 'w-[70%]'} rounded-r-md h-full p-2 flex`}>
            <div className='w-full h-full'>
              <div className='w-full h-[calc(50%)] p-1 pb-4 flex gap-2'>
                <div className='w-[14%] truncate flex flex-col justify-end gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'ID'}</h3>
                  <p>{data?.employee_id}</p>
                </div>
                <div className='w-[14%] truncate flex flex-col justify-end gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Email'}</h3>
                  <p className='truncate'>{data?.email}</p>
                </div>
                <div className='w-[14%] truncate flex flex-col justify-end gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Mobile'}</h3>
                  <p>{data?.mobile?.display || '-'}</p>
                </div>
                <div className='w-[12%] truncate flex flex-col justify-end gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'DOB'}</h3>
                  <p>{data?.dob || '-'}</p>
                </div>
                <div className='w-[16%] truncate flex flex-col justify-end gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Blood Group'}</h3>
                  <p>{data?.bloodGroup || '-'}</p>
                </div>
                <div className='w-[14%] truncate flex flex-col justify-end gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Religion'}</h3>
                  <p>{data?.religion || '-'}</p>
                </div>
                <div className='w-[14%] truncate flex flex-col justify-end gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Gender'}</h3>
                  <p>{data?.gender || '-'}</p>
                </div>
              </div>
              <div className='w-full h-[calc(50%)] p-1 pt-8 flex gap-2'>
                <div className='w-[14%] truncate flex flex-col gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Project'}</h3>
                  <p>{data?.assignedProject || '-'}</p>
                </div>
                <div className='w-[14%] truncate flex flex-col gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Join Date'}</h3>
                  <p>{data?.join_date || '-'}</p>
                </div>
                <div className='w-[14%] truncate flex flex-col gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Releiving Date'}</h3>
                  <p>{data?.releiving_date || '-'}</p>
                </div>
                <div className='w-[12%] truncate flex flex-col gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'ID No'}</h3>
                  <p>{data?.proof_id || '-'}</p>
                </div>
                <div className='w-[16%] truncate flex flex-col gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'ID Type'}</h3>
                  <p>{data?.idType || '-'}</p>
                </div>
                <div className='w-[14%] truncate flex flex-col gap-3'>
                  <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Department'}</h3>
                  <p>{data?.department || '-'}</p>
                </div>
                <div className='w-[14%] flex flex-col gap-3' >
                  <h3 className="text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Comments'}</h3>
                  <p className='overflow-x-scroll' style={{ scrollbarWidth: 'none', '-ms-overflow-style': 'none' }}>{data?.comments || '-'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='px-4 mt-3'>
        <div className=' h-[calc(100vh-410px)] w-full px-5 rounded-[10px]  bg-white'>
          <div className='pt-3'>
          <h3 className="truncate text-gray-900 font-semibold text-[13px] lg:text-[14px] xl:text-[15px]">{'Address Details'}</h3>
          </div>
          <div className='py-1 flex justify-between h-[37%]'>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Country'}</h3>
              <p>{data?.country || '-'}</p>
            </div>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'State'}</h3>
              <p>{data?.state || '-'}</p>
            </div>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'District'}</h3>
              <p>{data?.district || '-'}</p>
            </div>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'City'}</h3>
              <p>{data?.city || '-'}</p>
            </div>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Street name'}</h3>
              <p>{data?.streetName || '-'}</p>
            </div>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Postal code'}</h3>
              <p>{data?.postalCode || '-'}</p>
            </div>
          </div>
          <div className=''>
          <h3 className="truncate text-gray-900 font-semibold text-[13px] lg:text-[14px] xl:text-[15px]">{`Contact Person's Details`}</h3>
          </div>
          <div className='py-1 pb-2 flex justify-between h-[40%]'>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Name'}</h3>
              <p>{data?.cpName || '-'}</p>
            </div>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Email'}</h3>
              <p>{data?.cpEmail || '-'}</p>
            </div>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Mobil number'}</h3>
              <p>{data?.cpPhoneWithCC || '-'}</p>
            </div>
            <div className='w-[16%] py-1.5 flex flex-col justify-center gap-3'>
              <h3 className="truncate text-gray-600 capitalize font-bold text-[13px] lg:text-[14px] xl:text-[15px]">{'Designation'}</h3>
              <p>{data?.cpDesignation || '-'}</p>
            </div>
            <div className='w-[16%]'></div>
            <div className='w-[16%]'></div>
          </div>
        </div>
      </div>
      </div>

    </div>
  )
}

export default ViewEmployee;