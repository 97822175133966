import { Route } from "react-router-dom"
import MainMeetings from "../Views/Web/Meetings/MainMeetings";
import MainMeeting from "../Views/Web/Meetings/Meeting/MainMeeting";
import MeetingIndex from "../Views/Web/Meetings/Meeting/MeetingIndex";
import CreateMeeting from "../Views/Web/Meetings/Meeting/CreateMeeting";
import EditMeeting from "../Views/Web/Meetings/Meeting/EditMeeting";
import ViewMeeting from "../Views/Web/Meetings/Meeting/ViewMeeting";
import MainMeetingPoints from "../Views/Web/Meetings/MeetingPoints/MainMeetingPoints";
import MeetingPointsIndex from "../Views/Web/Meetings/MeetingPoints/MeetingPointsIndex";
import CreateMeetingPoints from "../Views/Web/Meetings/MeetingPoints/CreateMeetingPoints";
import EditMeetingPoints from "../Views/Web/Meetings/MeetingPoints/EditMeetingPoints";
import ViewMeetingPoints from "../Views/Web/Meetings/MeetingPoints/ViewMeetingPoints";
import MainParticipants from "../Views/Web/Meetings/Participants/MainParticipants";
import ParticipantsIndex from "../Views/Web/Meetings/Participants/ParticipantsIndex";
import CreateParticipants from "../Views/Web/Meetings/Participants/CreateParticipants";
import EditParticipants from "../Views/Web/Meetings/Participants/EditParticipants";
import ViewParticipants from "../Views/Web/Meetings/Participants/ViewParticipants";


const MeetingsRoutes = () =>{
    return(
        <Route path="meetings" element={<MainMeetings/>}>
          <Route path="meeting/:officeId" element={<MainMeeting/>}>
              <Route index element={<MeetingIndex/>}/>
              <Route path="create" element={<CreateMeeting/>}/>
              <Route path='edit/:meetingEditId' element={<EditMeeting/>} />
              <Route path='view/:meetingViewId' element={<ViewMeeting/>} />
          </Route>
          <Route path="meeting-points/:officeId" element={<MainMeetingPoints/>}>
              <Route index element={<MeetingPointsIndex/>}/>
              <Route path="create" element={<CreateMeetingPoints/>}/>
              <Route path='edit/:meetingPointsEditId' element={<EditMeetingPoints/>} />
              <Route path='view/:meetingPointsViewId' element={<ViewMeetingPoints/>} />
          </Route>
          <Route path="participants/:officeId" element={<MainParticipants/>}>
              <Route index element={<ParticipantsIndex/>}/>
              <Route path="create" element={<CreateParticipants/>}/>
              <Route path='edit/:participantsEditId' element={<EditParticipants/>} />
              <Route path='view/:participantsViewId' element={<ViewParticipants/>} />
          </Route>
        </Route>
    )}

export default MeetingsRoutes;