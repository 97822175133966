import { Route } from "react-router-dom"
import MainMaintenance from "../Views/Web/maintenance/MainMaintenance"
import MainVehicleMaintenance from "../Views/Web/maintenance/VehicleMaintenance/MainVehicleMaintenance"
import VehicleMaintenanceIndex from "../Views/Web/maintenance/VehicleMaintenance/VehicleMaintenanceIndex"
import CreateVehicleMaintenance from "../Views/Web/maintenance/VehicleMaintenance/CreateVehicleMaintenance"
import EditVehicleMaintenance from "../Views/Web/maintenance/VehicleMaintenance/EditVehicleMaintenance"
import ViewVehicleMaintenance from "../Views/Web/maintenance/VehicleMaintenance/ViewVehicleMaintenance"
import MainEquipmentMaintenance from "../Views/Web/maintenance/EquipmentMaintenance/MainEquipmentMaintenance"
import EquipmentMaintenanceIndex from "../Views/Web/maintenance/EquipmentMaintenance/EquipmentMaintenanceIndex"
import CreateEquipmentMaintenance from "../Views/Web/maintenance/EquipmentMaintenance/CreateEquipmentMaintenance"
import EditEquipmentMaintenance from "../Views/Web/maintenance/EquipmentMaintenance/EditEquipmentMaintenance"
import ViewEquipmentMaintenance from "../Views/Web/maintenance/EquipmentMaintenance/ViewEquipmentMaintenance"
import MainDailyInspectionByStaff from "../Views/Web/maintenance/DailyInspectionByStaff/MainDailyInspectionByStaff"
import DailyInspectionByStaffIndex from "../Views/Web/maintenance/DailyInspectionByStaff/DailyInspectionByStaffIndex"
import CreateDailyInspectionByStaff from "../Views/Web/maintenance/DailyInspectionByStaff/CreateDailyInspectionByStaff"
import EditDailyInspectionByStaff from "../Views/Web/maintenance/DailyInspectionByStaff/EditDailyInspectionByStaff"
import ViewDailyInspectionByStaff from "../Views/Web/maintenance/DailyInspectionByStaff/ViewDailyInspectionByStaff"
import MainInspectionSchedule from "../Views/Web/maintenance/InspectionSchedule/MainInspectionSchedule"
import InspectionScheduleIndex from "../Views/Web/maintenance/InspectionSchedule/InspectionScheduleIndex"
import CreateInspectionSchedule from "../Views/Web/maintenance/InspectionSchedule/CreateInspectionSchedule"
import EditInspectionSchedule from "../Views/Web/maintenance/InspectionSchedule/EditInspectionSchedule"
import ViewInspectionSchedule from "../Views/Web/maintenance/InspectionSchedule/ViewInspectionSchedule"
import MainServiceRequest from "../Views/Web/maintenance/ServiceRequest/MainServiceRequest"
import ServiceRequestIndex from "../Views/Web/maintenance/ServiceRequest/ServiceRequestIndex"
import CreateServiceRequest from "../Views/Web/maintenance/ServiceRequest/CreateServiceRequest"
import EditServiceRequest from "../Views/Web/maintenance/ServiceRequest/EditServiceRequest"
import ViewServiceRequest from "../Views/Web/maintenance/ServiceRequest/ViewServiceRequest"



const MaintenanceRoutes = () =>{
    return(
        <Route path="maintenance" element={<MainMaintenance/>}>

          <Route path="vehicle-maintenance/:officeId" element={<MainVehicleMaintenance/>}>
              <Route index element={<VehicleMaintenanceIndex/>}/>
              <Route path="create" element={<CreateVehicleMaintenance/>}/>
              <Route path='edit/:vehicleMaintenanceEditId' element={<EditVehicleMaintenance/>} />
              <Route path='view/:vehicleMaintenanceViewId' element={<ViewVehicleMaintenance/>} />
          </Route>

          <Route path="equipment-maintenance/:officeId" element={<MainEquipmentMaintenance/>}>
              <Route index element={<EquipmentMaintenanceIndex/>}/>
              <Route path="create" element={<CreateEquipmentMaintenance/>}/>
              <Route path='edit/:equipmentMaintenanceEditId' element={<EditEquipmentMaintenance/>} />
              <Route path='view/:equipmentMaintenanceViewId' element={<ViewEquipmentMaintenance/>} />
          </Route>

          <Route path="daily-inspection-by-staff/:officeId" element={<MainDailyInspectionByStaff/>}>
              <Route index element={<DailyInspectionByStaffIndex/>}/>
              <Route path="create" element={<CreateDailyInspectionByStaff/>}/>
              <Route path='edit/:dailyInspectionByStaffEditId' element={<EditDailyInspectionByStaff/>} />
              <Route path='view/:dailyInspectionByStaffViewId' element={<ViewDailyInspectionByStaff/>} />
          </Route>
          
          <Route path="inspection-schedule/:officeId" element={<MainInspectionSchedule/>}>
              <Route index element={<InspectionScheduleIndex/>}/>
              <Route path="create" element={<CreateInspectionSchedule/>}/>
              <Route path='edit/:inspectionScheduleEditId' element={<EditInspectionSchedule/>} />
              <Route path='view/:inspectionScheduleViewId' element={<ViewInspectionSchedule/>} />
          </Route>
          
          <Route path="service-request/:officeId" element={<MainServiceRequest/>}>
              <Route index element={<ServiceRequestIndex/>}/>
              <Route path="create" element={<CreateServiceRequest/>}/>
              <Route path='edit/:serviceRequestEditId' element={<EditServiceRequest/>} />
              <Route path='view/:serviceRequestViewId' element={<ViewServiceRequest/>} />
          </Route>
          
        </Route>
    )
}

export default MaintenanceRoutes;