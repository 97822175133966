import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const LayoutArea = () => {
  return (
    <SettingsMetaData propertyName={"LayoutArea"} viewPropertyName={"Layout Area"} />

  )
}

export default LayoutArea