import { Layout } from 'antd';
import React from 'react'
import { BackButton } from '../../../Components/Utils/Buttons';
import { NavLink, Outlet } from 'react-router-dom';
import Header from '../../../Components/Layout/Header';

function SettingsIndex() {
  let { Content } = Layout;

  const settingsItems = [
    {
      name: "Company Info",
      link: `/settings/company-info`,
    },
    {
      name: "Company Branchs",
      link: `/settings/branch`,
    },
    {
      name: "Users",
      link: `/settings/users`,
    },
    {
      name: "Accomodation",
      link: `/settings/accomodation`,
    },
    {
      name: "Listing",
      link: `/settings/listing`,
    },
    {
      name: "Clinic",
      link: `/settings/clinic`,
    },
    {
      name: "Catering",
      link: `/settings/catering`,
    },
    {
      name: "Maintanance",
      link: `/settings/maintanance`,
    },
    {
      name: "Inventory",
      link: `/settings/inventory`,
    },
    {
      name: "Transpotation",
      link: `/settings/transpotation`,
    },
    {
      name: "Procurment",
      link: `/settings/procurment`,
    },
    {
      name: "Staff",
      link: `/settings/staff`,
    },
    {
      name: "Meetings",
      link: `/settings/meetings`,
    },
    {
      name: "Incident",
      link: `/settings/incident`,
    },
    {
      name: "My Task",
      link: `/settings/my-task`,
    },
    {
      name: "Vendors",
      link: `/settings/vendors`,
    },
  ];
  return (
    <Layout className=" w-full h-screen ">
    <Layout>
      <Header />
      <Content className=" px-6">
        <div className=" w-full py-2 flex justify-end ">
          {/* <BackButton /> */}
        </div>
        <div className=" w-full flex gap-4  ">
          <div className="w-[15%] bg-white rounded-lg shadow-lg h-[calc(100vh-110px)] p-2 mb-3 overflow-scroll">
            <div className=" w-full ">
              {settingsItems &&
                settingsItems.map((item) => {
                  return (
                    <div className="flex flex-col my-0.5 w-full">
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "bg-[#048178] rounded-md px-4 py-2 w-full text-xs font-medium hover:text-white text-white"
                            : "hover:bg-[#048178] rounded-md px-4 py-2 text-xs font-medium w-full hover:text-white text-black"
                        }
                        to={item.link}
                      >
                        <span>{item.name}</span>
                      </NavLink>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="w-[85%] bg-white rounded-lg shadow-lg h-[calc(100vh-110px)] p-2 mb-3 overflow-y-scroll">
            <Outlet />
          </div>
        </div>
      </Content>
    </Layout>
  </Layout>
  )
}

export default SettingsIndex;