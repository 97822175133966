import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Service = () => {
  return (
    <SettingsMetaData propertyName={"Service"} viewPropertyName={"Service "} />

  )
}

export default Service