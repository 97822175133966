import React from 'react'

export default function Edit() {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Subtract">
    <path d="M11.0597 1.52779L11.0356 1.50367C10.8234 1.2914 10.6462 1.1142 10.4908 0.978645C10.329 0.837536 10.1637 0.716763 9.96753 0.635524C9.51243 0.447013 9.00107 0.447013 8.54597 0.635524C8.34984 0.716763 8.18451 0.837536 8.02274 0.978645C7.86734 1.1142 7.69014 1.2914 7.47787 1.50367L7.34648 1.63506L10.9284 5.21693L11.0597 5.08554C11.272 4.87329 11.4492 4.69608 11.5848 4.54068C11.7259 4.3789 11.8467 4.21358 11.9279 4.01745C12.1164 3.56234 12.1164 3.05099 11.9279 2.59589C11.8467 2.39976 11.7259 2.23443 11.5848 2.07266C11.4492 1.91726 11.272 1.74005 11.0597 1.52779Z" fill="white"/>
    <path d="M10.212 5.93331L6.63011 2.35144L2.00553 6.97602L1.97993 7.00155C1.87465 7.10641 1.76145 7.21917 1.68116 7.36076C1.60088 7.50234 1.56224 7.65738 1.52631 7.80156L1.51754 7.83664L1.1874 9.15022C1.07267 9.60656 0.972849 10.0036 0.941547 10.3195C0.908774 10.6503 0.935086 11.034 1.23226 11.3312C1.52944 11.6283 1.91317 11.6546 2.24391 11.6219C2.55982 11.5906 2.95684 11.4908 3.41318 11.376L4.72678 11.0459L4.76185 11.0371C4.90604 11.0012 5.06107 10.9625 5.20266 10.8823C5.34425 10.802 5.457 10.6888 5.56187 10.5835L5.5874 10.5579L10.212 5.93331Z" fill="white"/>
    </g>
    </svg>
    
  )
}
